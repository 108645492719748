import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import axios from 'axios'

export const successToastOptions: ToastOptions = {
  position: 'top-center',
  hideProgressBar: true,
  className: 'text-success toast-success',
  icon: <i className='ri-check-double-line'></i>,
  closeButton: <i className='ri-close-line fs-18 fw-light pt-1'></i>,
}

export const warningToastOptions: ToastOptions = {
  position: 'top-center',
  hideProgressBar: true,
  className: 'text-warning toast-warning',
  icon: <i className='ri-error-warning-line'></i>,
  closeButton: <i className='ri-close-line fs-18 fw-light pt-1'></i>,
}

export const errorToastOptions: ToastOptions = {
  position: 'top-center',
  hideProgressBar: true,
  className: 'toast-danger fs-14',
  icon: <i className='ri-error-warning-line'></i>,
  closeButton: (
    <i className='ri-close-line fs-18 fw-light pt-1 text-danger'></i>
  ),
}

export const handleError = (error: any) => {
  let message = error.message
  if (axios.isAxiosError(error)) {
    message =
      error.response?.status !== 401
        ? error.response?.data?.message || message
        : null
  }
  if (message) {
    toast(message, errorToastOptions)
  }
}
