import React, { useState } from 'react'
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from 'reactstrap'

import { Link } from 'react-router-dom'
import withRouter, { WithRouterProps } from '../../Components/Common/withRouter'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'
import ParticlesAuthHeader from '../../Components/Common/ParticlesAuthHeader'
import { LordIcon } from '../../Components/Common/lord-icon'
import { forgotPassword } from '../../helpers/api_helper'
import { ForgotPasswordDTO } from '../../sharedTypes'
import axios from 'axios'

type ForgetPasswordProps = WithRouterProps

const ForgetPasswordPage = (props: ForgetPasswordProps) => {
  const [isLoading, setLoading] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [isError, setError] = useState<string>('')

  const submitForm = async (payload: ForgotPasswordDTO.Request) => {
    setLoading(true)
    setError('')
    try {
      await forgotPassword(payload)
      setIsSuccessful(true)
    } catch (error: any) {
      let message = error.message
      if (axios.isAxiosError(error)) {
        message = error.response?.data?.message || message
      }
      setError(message)
    }
    setLoading(false)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Please Enter Your Email')
        .email('Email not valid'),
    }),
    onSubmit: submitForm,
  })

  document.title =
    'Forgot Password | Mastered - Premium Admin & Dashboard Platform'

  return (
    <ParticlesAuth>
      <div className='auth-page-content'>
        <Container>
          <ParticlesAuthHeader />

          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='mt-4'>
                <CardBody className='p-4'>
                  <div className='text-center mt-2'>
                    <h5 className='text-primary fw-normal'>Forgot Password?</h5>
                    <p className='text-muted fw-light fs-14 ff-base'>
                      Reset password with email
                    </p>

                    <LordIcon
                      src='https://cdn.lordicon.com/rhvddzym.json'
                      colors={{ primary: '#0ab39c' }}
                      style={{ width: '120px', height: '120px' }}
                      className='avatar-xl'
                    />
                  </div>

                  <Alert
                    className='alert-borderless alert-warning  mb-2 mx-2'
                    role='alert'
                  >
                    {isSuccessful ? (
                      <>
                        We have sent the instructions to reset your password to
                        the email.
                        <br />
                        If you don't receive an email soon, check your spam
                        folder or check that the email address you entered is
                        correct.
                      </>
                    ) : (
                      'Enter your email and instructions will be sent to you!'
                    )}
                  </Alert>
                  <div className='p-2'>
                    {isError ? (
                      <Alert color='danger' style={{ marginTop: '13px' }}>
                        {isError}
                      </Alert>
                    ) : null}
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className='mb-4'>
                        <Label className='form-label'>Email</Label>
                        <Input
                          name='email'
                          className='form-control fw-light'
                          placeholder='Enter email'
                          type='email'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(
                              validation.touched.email &&
                              validation.errors.email
                            )
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type='invalid'>
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className='text-center mt-4'>
                        {isSuccessful ? (
                          <Link to='/login' className='btn btn-primary w-100'>
                            Back to Login
                          </Link>
                        ) : (
                          <button
                            className='btn btn-primary w-100'
                            type='submit'
                            disabled={
                              !(validation.isValid && validation.dirty) ||
                              isLoading
                            }
                          >
                            Send Reset Link
                          </button>
                        )}
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className='mt-4 text-center'>
                <p className='mb-0 fw-normal'>
                  Wait, I remember my password...{' '}
                  <Link
                    to='/login'
                    className='text-primary text-decoration-underline fw-normal'
                  >
                    {' '}
                    Click here{' '}
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  )
}

export default withRouter(ForgetPasswordPage)
