import { TPosition } from '../models'
import { PaginationRequest } from './pagination'

export enum PositionGroupBy {
  NAME = 'name',
}

export namespace GetPositionsDTO {
  export interface Request extends PaginationRequest {
    groupBy?: PositionGroupBy
    facilityIds?: number[]
  }
  export interface Response {
    count: number
    positions: TPosition[]
    page: number
    pages: number
  }
}
