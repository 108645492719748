import React, { useEffect } from 'react'
import { Spinner } from 'reactstrap'

const DownloadRedirect = () => {
  const redirectToAppStore = () => {
    window.location.href =
      'https://apps.apple.com/us/app/mastered-it/id6483932643'
  }

  const redirectToGooglePlay = () => {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.mastered'
  }

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent)
  }

  useEffect(() => {
    if (isIOS()) {
      redirectToAppStore()
    } else {
      redirectToGooglePlay()
    }
  }, [])

  return (
    <div className='w-100 download-app-container d-flex justify-content-center align-items-center'>
      <Spinner size={'md'} />
    </div>
  )
}

export default DownloadRedirect
