import React, { useCallback, useState } from 'react'
import { successToastOptions } from '../../helpers/toast_helper'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { deleteOnlineCourse } from '../../helpers/api_helper'
import DeleteConfirmation from '../../Components/Modals/DeleteConfirmation'

export const useDeleteCourse = (afterSubmit?: () => void) => {
  const [deleteCourseId, setDeleteCourseId] = useState<number | null>(null)

  const onSubmit = useCallback(async () => {
    try {
      setDeleteCourseId(null)
      await deleteOnlineCourse(Number(deleteCourseId))
      toast('Success - Course has been deleted', successToastOptions)
      if (afterSubmit) {
        afterSubmit()
      }
    } catch (e) {}
  }, [deleteCourseId, afterSubmit])

  const renderDeleteModal = useCallback(() => {
    return (
      <DeleteConfirmation
        title='Delete Course'
        message='Are you sure you want to delete this course?'
        isOpen={!!deleteCourseId}
        onClose={() => setDeleteCourseId(null)}
        onDelete={onSubmit}
      />
    )
  }, [deleteCourseId, onSubmit])

  return { renderDeleteModal, setDeleteCourseId }
}
