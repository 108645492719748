import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import React, { useState } from 'react'
import {
  ASSIGNMENT_STATUS,
  ScheduledCourseItem,
  TAssignment,
} from '../../../sharedTypes'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import { ASSIGNMENT_ACTIONS } from '../../Calendar/FacilitatorView/types'

interface ActionsDropdownMenuProps {
  assignment: TAssignment
  passingScore: number
  setSelectedAssignment: (selectedAssignment: SelectedAssignment) => void
  permissions: {
    viewCertificate: boolean
    overrideScore: boolean
  }
}

interface SelectedAssignment {
  id: number | null
  type: ASSIGNMENT_ACTIONS
}

const ActionsDropdownMenu = ({
  assignment,
  passingScore,
  setSelectedAssignment,
  permissions,
}: ActionsDropdownMenuProps) => {
  const [actionDropdownOpen, setActionDropdownOpen] = useState(false)
  const navigate = useNavigate()

  const isAssignmentCompleted = (assignmentStatus: ASSIGNMENT_STATUS) =>
    assignmentStatus === ASSIGNMENT_STATUS.COMPLETED

  const isEditable = (
    assignment: TAssignment,
    schedule: ScheduledCourseItem | undefined,
  ) => {
    if (schedule) {
      const end = moment(assignment.dueDate).set({
        hour: moment.utc(schedule.endTime, 'HH:mm:ss').local().hour(),
        minute: moment.utc(schedule.endTime, 'HH:mm:ss').local().minute(),
      })

      return (
        assignment.status === ASSIGNMENT_STATUS.ASSIGNED &&
        end.isAfter(moment(), 'minute')
      )
    }

    return assignment.status === ASSIGNMENT_STATUS.ASSIGNED
  }

  const showOverrideScore = (competencyTestScore: number) =>
    competencyTestScore && competencyTestScore < passingScore

  const toggleActionDropdown = () =>
    setActionDropdownOpen(prevState => !prevState)

  const generateCertificate = () => {
    window.open(assignment.certificate, '_blank')
  }

  return (
    <Dropdown isOpen={actionDropdownOpen} toggle={toggleActionDropdown}>
      <DropdownToggle
        className='badge badge-purple btn btn-ghost-primary'
        tag='button'
      >
        <i className='mdi mdi-dots-horizontal align-middle' />
      </DropdownToggle>
      <DropdownMenu container={'body'}>
        <DropdownItem>
          <div
            onClick={() => {
              navigate(
                assignment.course
                  ? `/courses/general/${assignment.course.id}`
                  : `/courses/packages/manage/${assignment.package.id}`,
              )
            }}
          >
            <i
              className='ri-eye-line cursor-pointer'
              id={`viewAssignment${assignment.id}`}
            />
            <span className='mx-2'>View Course</span>
          </div>
        </DropdownItem>
        {isAssignmentCompleted(assignment.status) &&
          assignment.certificate &&
          permissions.viewCertificate && (
            <DropdownItem>
              <div className='cursor-pointer' onClick={generateCertificate}>
                <i
                  className='ri-file-list-3-line'
                  id={`certificatePreviewAssignment${assignment.id}`}
                />
                <span className='mx-2'>View Certificate</span>
              </div>
            </DropdownItem>
          )}
        {permissions.overrideScore && (
          <DropdownItem>
            <div
              className={
                !showOverrideScore(assignment.competencyTestScore)
                  ? 'text-disabled'
                  : 'cursor-pointer'
              }
              onClick={
                showOverrideScore(assignment.competencyTestScore)
                  ? () => {
                      setSelectedAssignment({
                        id: assignment.id,
                        type: ASSIGNMENT_ACTIONS.OVERRIDE,
                      })
                    }
                  : undefined
              }
            >
              <i
                className='ri-indeterminate-circle-line'
                id={`overrideAssignment${assignment.id}`}
              />
              <span className='mx-2'>Override Test Score</span>
            </div>
          </DropdownItem>
        )}
        {!assignment.parentId && (
          <>
            <DropdownItem>
              <div
                className={
                  !isEditable(assignment, assignment.schedule)
                    ? 'text-disabled'
                    : 'cursor-pointer'
                }
                onClick={
                  isEditable(assignment, assignment.schedule)
                    ? () =>
                        setSelectedAssignment({
                          id: assignment.id,
                          type: ASSIGNMENT_ACTIONS.EDIT,
                        })
                    : undefined
                }
              >
                <i
                  className='ri-equalizer-fill'
                  id={`editAssignment${assignment.id}`}
                />
                <span className='mx-2'>Edit Assignment</span>
              </div>
            </DropdownItem>
            <DropdownItem>
              <div
                className={
                  isAssignmentCompleted(assignment.status)
                    ? 'text-disabled'
                    : 'cursor-pointer text-danger'
                }
                onClick={
                  !isAssignmentCompleted(assignment.status)
                    ? () => {
                        setSelectedAssignment({
                          id: assignment.id,
                          type: ASSIGNMENT_ACTIONS.DELETE,
                        })
                      }
                    : undefined
                }
              >
                <i
                  className='ri-delete-bin-2-line'
                  id={`deleteAssignment${assignment.id}`}
                />
                <span className='mx-2'>Remove Assignment</span>
              </div>
            </DropdownItem>
          </>
        )}
        {assignment.courseId && (
          <>
            {!assignment.testFileId && (
              <DropdownItem>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setSelectedAssignment({
                      id: assignment.id,
                      type: ASSIGNMENT_ACTIONS.UPLOAD_TEST,
                    })
                  }}
                >
                  <i className='cursor-pointer ri-download-2-fill' />
                  <span className='mx-2'>Upload Written Test</span>
                </div>
              </DropdownItem>
            )}

            {assignment.testFile && (
              <DropdownItem>
                <div
                  onClick={() => {
                    setSelectedAssignment({
                      id: assignment.id,
                      type: ASSIGNMENT_ACTIONS.TEST_PREVIEW,
                    })
                  }}
                >
                  <i className='cursor-pointer ri-eye-line text-body' />
                  <span className='mx-2 text-body'>View Written Test</span>
                </div>
              </DropdownItem>
            )}

            {permissions.overrideScore && (
              <DropdownItem>
                <div
                  onClick={() => {
                    setSelectedAssignment({
                      type: ASSIGNMENT_ACTIONS.GENERATE_TEST,
                      id: assignment.id,
                    })
                  }}
                >
                  <i className='cursor-pointer ri-survey-line text-muted' />
                  <span className='mx-2 text-body'>Generate Written Test</span>
                </div>
              </DropdownItem>
            )}
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export default ActionsDropdownMenu
