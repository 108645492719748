import React, { useEffect, useRef } from 'react'
import {
  Button,
  FormFeedback,
  Modal,
  ModalBody,
  ModalProps,
  Spinner,
} from 'reactstrap'
import { ReactComponent as CreateSvgIcon } from '../../assets/images/svg/createRevision.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'

interface CourseActionModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onCourseRevisionCreate: (values: IForm) => void
}

export interface IForm {
  expirationDate: string
}

const validationSchema = yup.object().shape({
  expirationDate: yup.date().required('Expiration Date is required'),
})

const CourseRevisionModal = ({
  onClose,
  isOpen,
  onCourseRevisionCreate,
}: CourseActionModalProps) => {
  const datePickerRef = useRef<Flatpickr>(null)

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      expirationDate: '',
    },
    validationSchema,
    onSubmit: values => {
      onCourseRevisionCreate(values)
    },
  })

  useEffect(() => {
    !isOpen && form.resetForm()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center px-4'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-primary text-primary'>
              <CreateSvgIcon />,
            </div>
          </div>

          <h3 className='mt-4'>Create Course Revision</h3>
          <span className='text-muted fw-light'>
            {'This course will be cloned and put into Draft mode \n for editing. ' +
              'Please indicate the expiration date \n for the current active version'}
          </span>
        </div>
        <div className='mt-2'>
          <label htmlFor='date' className='form-label'>
            Expiration Date*
          </label>
          <div
            className={`form-icon right ${
              form.touched.expirationDate && form.errors.expirationDate
                ? ' is-invalid'
                : ''
            }`}
          >
            <Flatpickr
              className={`form-control form-control-icon ${
                form.touched.expirationDate && form.errors.expirationDate
                  ? ' is-invalid'
                  : ''
              }`}
              id='date'
              name='expirationDate'
              placeholder='--.--.----'
              ref={datePickerRef}
              onChange={option => {
                form.setFieldValue('expirationDate', option[0])
              }}
              onBlur={() => {
                form.setFieldTouched('expirationDate', true)
              }}
              value={form.values.expirationDate}
              options={{
                dateFormat: 'mm/dd/yyyy',
                formatDate: date => moment(date).format('L'),
                minDate: moment().add(2, 'weeks').toDate(),
                allowInvalidPreload: true,
              }}
            />
            <i
              className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
              onClick={() => {
                datePickerRef.current?.flatpickr.open()
              }}
            ></i>
          </div>
          {form.touched.expirationDate && form.errors.expirationDate ? (
            <FormFeedback type='invalid'>
              {form.errors.expirationDate}
            </FormFeedback>
          ) : null}
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-danger w-100'
            onClick={onClose}
            active={false}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='w-100'
            onClick={() => form.handleSubmit()}
            disabled={!(form.isValid && form.dirty) || form.isSubmitting}
          >
            {form.isSubmitting ? <Spinner size={'sm'} /> : 'Create'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CourseRevisionModal
