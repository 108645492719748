import React from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'

interface CourseActionModalProps {
  onClose: () => void
  icon: string
  isOpen: ModalProps['isOpen']
  title: string
  message: string
  onSubmit: () => void
  confirmText: string
}

const CourseActionModal = ({
  icon,
  onClose,
  isOpen,
  title,
  message,
  onSubmit,
  confirmText,
}: CourseActionModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-primary text-primary'>
              <i className={icon} style={{ fontSize: 62 }}></i>
            </div>
          </div>

          <h3 className='mt-4'>{title}</h3>
          <span className='text-muted fw-light'>{message}</span>
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-danger w-100'
            onClick={onClose}
            active={false}
          >
            Cancel
          </Button>
          <Button color='primary' className='w-100' onClick={onSubmit}>
            {confirmText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CourseActionModal
