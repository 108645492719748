import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import 'cropperjs/dist/cropper.css'
import Cropper from 'react-cropper'
import { TAttachment } from '../../sharedTypes'
import { range } from 'lodash'

interface ImageCropperProps {
  onClose: () => void
  onSave: (attachment: TAttachment, images: Blob[]) => void
  show: boolean
  aspectRatios?: number[]
  attachment: TAttachment | null
}

const ImageCropperModal = ({
  show,
  onClose,
  attachment,
  aspectRatios = [1],
  onSave,
}: ImageCropperProps) => {
  const ref = useRef<any>(null)
  const steps = useMemo(() => aspectRatios.length, [aspectRatios])
  const [step, setStep] = useState(1)
  const canSave = useMemo(() => steps === step, [step, steps])
  const [croppedImages, setCroppedImages] = useState<any>({})

  const onToBlob = useCallback(
    (blob: Blob) => {
      const updatedCroppedImages = croppedImages

      updatedCroppedImages[aspectRatios[step - 1]] = blob

      setCroppedImages(updatedCroppedImages)
      if (canSave) {
        onSave(attachment as TAttachment, updatedCroppedImages)
        setStep(1)
      }
    },
    [croppedImages, step, attachment],
  )

  const handleCropSave = () => {
    const nextStep = step + 1
    const croppedCanvas = ref.current.cropper.getCroppedCanvas()
    ref.current.cropper.setAspectRatio(aspectRatios[step])
    setStep(nextStep)
    croppedCanvas.toBlob(onToBlob, 'image/jpeg')
  }

  return (
    <Modal isOpen={show} size='xl' toggle={onClose} centered={true}>
      <ModalHeader className='border-0 p-3 pb-0' toggle={onClose}>
        <span>Image crop</span>
      </ModalHeader>
      <ModalBody className='p-3'>
        {attachment && (
          <div>
            <section className='d-flex gap-1 justify-content-center mb-3'>
              {range(steps).map(s => (
                <div key={s}>
                  <svg
                    style={{ width: 10 }}
                    focusable='false'
                    aria-hidden='true'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      cx='12'
                      cy='12'
                      r='12'
                      fill={s + 1 === step ? '#0039c7' : '#6c7393'}
                    ></circle>
                    <text
                      x='12'
                      y='12'
                      textAnchor='middle'
                      dominantBaseline='central'
                    >
                      {s + 1}
                    </text>
                  </svg>
                </div>
              ))}
            </section>
            <Cropper
              ref={ref}
              src={attachment.path}
              style={{ height: 550, width: '100%' }}
              zoomTo={0.5}
              aspectRatio={aspectRatios[step - 1]}
              preview='.img-preview'
              viewMode={1}
              cropBoxResizable={false}
              dragMode={'move'}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
            />
          </div>
        )}
        <div className='d-flex gap-2 justify-content-end mt-4 mb-2'>
          <button
            type='button'
            className='btn btn-light'
            data-bs-dismiss='modal'
            onClick={onClose}
          >
            Close
          </button>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => handleCropSave()}
          >
            {canSave ? 'Save' : 'Next'}
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ImageCropperModal
