import {
  COURSE_FILTER_FORMATS,
  CoursesTabs,
  OnlineCourseItem,
} from '../../../sharedTypes'
import { ModalProps } from 'reactstrap'
import {
  CoursePackageFormatsEnum,
  PackageCourse,
} from '../../../sharedTypes/models/coursePackage'
import {
  GetPackageCoursesDTO,
  PackageCoursesSortBy,
} from '../../../sharedTypes/api/coursePackages'
import React from 'react'

export interface SelectedPackageCourse {
  course: OnlineCourseItem
  format?: COURSE_FILTER_FORMATS
}
;[]

export interface PackageCoursesTableProps {
  selected: SelectedPackageCourse
  setSelected: (
    element: OnlineCourseItem,
    format?: COURSE_FILTER_FORMATS,
  ) => void
  isLoading: boolean
  data: OnlineCourseItem[]
  dataType: CoursesTabs
  searchKey?: string
}

export interface SelectedCoursesData {
  id: number
  format?: COURSE_FILTER_FORMATS
}

export interface SelectCoursesModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  handleSelect: (courses: SelectedCoursesData[]) => Promise<void>
  packageCourses: PackageCourse[]
  filteredFormats: COURSE_FILTER_FORMATS[]
}

export interface FormValues {
  courses: SelectedPackageCourse[]
}

export interface PackageCoursesTableViewProps {
  packageCourses: PackageCourse[]
  query: GetPackageCoursesDTO.Request
  handleSort: (column: PackageCoursesSortBy) => void
  handleDelete: (course: OnlineCourseItem) => void
  viewOnly: boolean
  fromFacilitatorView?: boolean
  disableReleaseBtn?: boolean
  onReleaseTest?: (courseId: number) => void
}

export interface TrainingDayProps {
  coursePackageId: number
  trainingDaysCount: number
  setTrainingDaysCount: React.Dispatch<React.SetStateAction<number>>
}

export interface EmptyPackageCoursesProps {
  emptyCoursesLabel: string
  emptyImg: string
  addLabel: string
  onAdd: () => void
  hasPermissionToAddCourse: boolean
}

export const formats = {
  [CoursePackageFormatsEnum.PACKAGE]: 'Course Package',
  [CoursePackageFormatsEnum.TRAINING_DAY]: 'Training Day',
}

export enum PACKAGE_TABS {
  COURSES = 'courses',
  SCHEDULE = 'schedule',
}
