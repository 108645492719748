import React, { useMemo } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { LOG_TYPES } from '../../../sharedTypes'
import DetailsInfoModal from '../../../Components/Modals/DetailsInfoModal'
import { v4 as uuidv4 } from 'uuid'

interface FilterTabsProps {
  navTab?: LOG_TYPES
  navToggle: (tab?: LOG_TYPES) => void
}
const tabs = [
  { label: 'All', value: undefined },
  {
    label: 'New',
    value: LOG_TYPES.NEW,
    info: 'New users imported from your HR system (select ‘Filter by Date’ to filter by specific dates).',
  },
  {
    label: 'Updated',
    value: LOG_TYPES.UPDATED,
    info: 'Users whose information has been updated in your HR system.',
  },
  {
    label: 'Error',
    value: LOG_TYPES.ERROR,
    info: 'Users with an error that needs to be corrected in your HR system.',
  },
]

const FilterTabs = ({ navTab, navToggle }: FilterTabsProps) => {
  const memoizedTabs = useMemo(() => {
    return tabs.map(item => ({
      ...item,
      id: uuidv4(),
    }))
  }, [tabs])
  return (
    <Nav className='nav-customs-bordered'>
      {memoizedTabs.map(tab => (
        <NavItem key={tab.value}>
          <NavLink
            style={{
              cursor: 'pointer',
            }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
            <DetailsInfoModal
              name={'import-logs-break-down'}
              id={tab.id}
              info={tab.info}
              onHover={true}
            />
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
