import React, { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Form,
  Col,
  Card,
  Row,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import Dropzone from 'react-dropzone'

import { postNewAttachment } from '../../helpers/api_helper'
import formatBytes from '../../utils/formatBytes'

import { successToastOptions, handleError } from '../../helpers/toast_helper'
import { TAttachment } from '../../sharedTypes'

interface FileForm {
  file?: File
}

interface InstructorModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (media: TAttachment) => void
}

const maxFileSize = 1500000000

const MediaUploadModal = ({
  onClose,
  onSubmit,
  isOpen,
}: InstructorModalProps) => {
  const form = useFormik<FileForm>({
    enableReinitialize: true,
    initialValues: {
      file: undefined,
    },
    validationSchema: Yup.object({
      file: Yup.mixed()
        .nullable()
        .required('Please select file')
        .test(
          'valid-size',
          'Max allowed size is 1.5GB',
          value => value?.size <= maxFileSize,
        ),
    }),
    onSubmit: values => {
      if (values.file) {
        form.setSubmitting(true)
        const data = new FormData()
        data.append('file', values.file)
        return postNewAttachment(data)
          .then(res => {
            toast(
              'Success - Media file added successfully',
              successToastOptions,
            )
            onSubmit(res.data)
          })
          .catch(handleError)
          .finally(() => {
            form.setSubmitting(false)
          })
      }
    },
  })

  useEffect(() => {
    if (!isOpen) {
      form.resetForm()
    }
  }, [isOpen])

  const handleAcceptedFiles = (file: File) => {
    form.setFieldValue('file', file)
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Add media</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <Dropzone
            maxFiles={1}
            disabled={form.isSubmitting}
            multiple={false}
            accept={{
              'image/jpeg': [],
              'image/jpg': [],
              'image/png': [],
              'video/mp4': [],
              'application/pdf': [],
              'application/msword': [], //.doc
              'application/vnd.ms-powerpoint': [],
              'audio/mpeg': [],
            }}
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles[0])
            }}
            maxSize={maxFileSize}
          >
            {({ getRootProps }) => (
              <div className='dropzone dz-clickable cursor-pointer'>
                <div className='dz-message needsclick' {...getRootProps()}>
                  <div className='mt-2'>
                    <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                  </div>
                  <p className='m-0 text-muted fw-light fs-14'>
                    Drop file here or click to upload.<br></br>
                    (doc, pdf, mp4, mp3, jpeg, png, ppt)
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
          {form.errors.file ? (
            <p style={{ color: '#F06548', fontSize: '0.875em' }}>
              {form.errors.file}
            </p>
          ) : null}
          {form.values.file && (
            <ul className='list-unstyled mb-0' id='dropzone-preview'>
              <Card
                className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                key={'-file'}
              >
                <div className='p-2'>
                  <Row className='align-items-center'>
                    <Col className='col-auto'>
                      {form.values.file.type.includes('video/') ? (
                        <video
                          data-dz-thumbnail=''
                          height='80'
                          autoPlay={false}
                          controls={false}
                          className='avatar-sm rounded bg-light'
                          src={URL.createObjectURL(form.values.file)}
                        />
                      ) : form.values.file.type.includes('image/') ? (
                        <img
                          data-dz-thumbnail=''
                          height='80'
                          className='avatar-sm rounded bg-light'
                          alt={form.values.file.name}
                          src={URL.createObjectURL(form.values.file)}
                        />
                      ) : (
                        <div className='avatar-sm flex-shrink-0'>
                          <span className='avatar-title rounded fs-3 image-container'>
                            <i className='ri-file-list-3-line'></i>
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col>
                      <Link to='#' className='text-muted font-weight-bold'>
                        {form.values.file.name}
                      </Link>
                      <p className='mb-0'>
                        {formatBytes(form.values.file.size)}
                      </p>
                    </Col>
                    <Col className='d-flex justify-content-end align-items-end'>
                      <Button
                        disabled={form.isSubmitting}
                        color='danger'
                        onClick={() => {
                          form.setFieldValue('file', undefined)
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </ul>
          )}
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={onClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={!(form.isValid && form.dirty) || form.isSubmitting}
            >
              {form.isSubmitting ? <Spinner size={'sm'} /> : 'Add'}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default MediaUploadModal
