import React, { useEffect, useContext, useMemo } from 'react'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { CourseLayout } from '../../../../Components/Course'
import _ from 'lodash'
import { QuestionableTypes } from '../../../../sharedTypes'
import { QuestionsModule } from '../../../../Components/Course/Questions'
import GeneralCompetencyInfo from '../../../../Components/Course/GeneralCompetencyInfo'
import { CourseContext } from '../../../../context/CourseContext'

const CompetencyTest = ({ router }: WithRouterProps) => {
  const {
    course,
    setCourseId,
    loading,
    onPublish,
    competencyQuestions,
    tabs,
    onBack,
    isPublished,
    isPublishing,
    competencyStep,
    onNavigate,
  } = useContext(CourseContext)

  const testError = useMemo(
    () =>
      tabs.COMPETENCY.error &&
      competencyQuestions.data.count <
        competencyStep.form.values.quizQuestionsNumber,
    [
      tabs.COMPETENCY,
      competencyQuestions,
      competencyStep.form.values.quizQuestionsNumber,
    ],
  )

  useEffect(() => {
    setCourseId(Number(router.params.courseId))
  }, [router.params.courseId])

  return (
    <CourseLayout
      tabs={tabs}
      viewOnly={isPublished}
      showSaveAsDraft
      backText='Back'
      allowPublish
      hasUnsavedData={false}
      loading={loading}
      hideNext
      onNavigate={direction => {
        if (isPublished || !competencyStep.hasUnsavedData) {
          competencyStep.onDiscardChanges()
          onNavigate(direction)
        } else {
          competencyStep.onPreHandleSubmit({ direction })
        }
      }}
      onBack={() => {
        if (isPublished || !competencyStep.hasUnsavedData) {
          competencyStep.onDiscardChanges()
          onBack()
        } else {
          competencyStep.onPreHandleSubmit({ direction: 'prev' })
        }
      }}
      leftSideBar={true}
      router={router}
      onNext={() => {}}
      course={course}
      onSaveAsDraft={props => {
        competencyStep.onPreHandleSubmit({ direction: 'courses' })
      }}
      onPublish={() => {
        onPublish()
      }}
    >
      <div className='w-100'>
        <GeneralCompetencyInfo
          course={course}
          questionsAmount={competencyQuestions.data.count}
          defaultShow={!competencyQuestions.data.count}
          viewOnly={isPublished}
          {...competencyStep}
        />
        <div className='mt-3'>
          <QuestionsModule
            {...competencyQuestions}
            viewOnly={isPublished}
            error={
              testError
                ? `Must include at least ${competencyStep.form.values.quizQuestionsNumber} questions based on Number of Test Questions to Display`
                : null
            }
            isPublishing={isPublishing}
            languages={
              course?.translations
                .map(t => t.language)
                .filter(trans => trans.code === 'en') || []
            }
            questionableType={QuestionableTypes.COMPETENCY_ONLY}
            courseId={Number(router.params.courseId)}
          />
        </div>
      </div>
    </CourseLayout>
  )
}
export default withRouter(CompetencyTest)
