import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { Option } from '../../helpers/facility'

export const initialState: FacilitiesState = {
  selectedFacilityId: null,
  selectedGroupId: null,
  lastUpdated: null,
  groupOptions: [],
}

const facilitiesSlice = createSlice({
  name: 'facilities',
  initialState,
  reducers: {
    setSelectedFacility(state, { payload }: { payload: SelectedOptionState }) {
      switch (payload.type) {
        case 'facility':
          state.selectedFacilityId = payload.id
          state.selectedGroupId = null
          break
        case 'group':
          state.selectedFacilityId = null
          state.selectedGroupId = payload.id
          break
        default:
          state.selectedFacilityId = null
          state.selectedGroupId = null
          break
      }
    },
    clearFacilities(state) {
      state.selectedFacilityId = null
      state.selectedGroupId = null
      state.groupOptions = []
      state.lastUpdated = null
    },
    setGroupOptions(state, { payload }: { payload: Option[] }) {
      state.groupOptions = payload
      state.lastUpdated = moment().toDate().toISOString()
      const facilities = payload.filter(option =>
        option.value.startsWith('facility'),
      )
      if (facilities.length === 1) {
        const [type, id] = facilities[0].value.split(':')
        state.selectedFacilityId = +id
      }
    },
    setSelectedGroup(state, { payload }: { payload: number }) {
      state.selectedGroupId = payload
      state.selectedFacilityId = null
    },
  },
})

export const { setSelectedFacility, clearFacilities, setGroupOptions } =
  facilitiesSlice.actions

export type SelectedOptionState = {
  id: number
  type: string
}

export type FacilitiesState = {
  groupOptions: Option[]
  selectedFacilityId: number | null
  selectedGroupId: number | null
  lastUpdated: string | null
}

export default facilitiesSlice.reducer
