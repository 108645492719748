import { LiveCourseSettings, LiveCourseMedia } from '../models'

export namespace GetLiveCourseDTO {
  export interface Response extends LiveCourseSettings {}
}

export namespace AddLiveCourseMediaDTO {
  export interface Response extends LiveCourseMedia {}
  export interface Request {
    languageId: number
    attachmentId: number
  }
}
