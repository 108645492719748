import { TFacility } from './facility'
import { TGroup } from './groups'
import { StatesEnum } from '../lib/locations'
import { TAttachment } from './attachments'

export enum COMPANY_STATUSES {
  'ACTIVE' = 'active',
  'INACTIVE' = 'inactive',
}

export type CompanyAccountContact = {
  name: string
  email: string
  phone: string
}

export type CompanyBillingContact = {
  name: string
  email: string
  phone: string
}

export type TCompany = {
  id: number
  name: string
  key: string
  description: string | null
  address: string | null
  city: string | null
  state: StatesEnum
  status: COMPANY_STATUSES
  activationDate: Date | null
  notes: string | null
  phone: string | null
  email: string | null
  logo?: TAttachment
  logoId: number
  accountContact: CompanyAccountContact
  billingContact: CompanyBillingContact
  facilitiesCount?: number
  usersCount?: number
  adminsCount?: number
  registeredUsersCount?: number
  notRegisteredUsersCount?: number
  facilities: TFacility[]
  groups: TGroup[]
  createdAt: string
  updatedAt: string
}
