import {
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  GetScheduledCourseDTO,
  OrderType,
  RecurringTypes,
  ScheduledCourseInitialValues,
  ScheduledCourseItem,
  ScheduledCourseSortBy,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
} from '../../sharedTypes'
import {
  deleteScheduledCourse,
  getScheduledCourses,
} from '../../helpers/api_helper'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../helpers/toast_helper'
import moment from 'moment'

export interface IUseScheduled {
  showModal: ScheduledCourseInitialValues | null
  setShowModal: Dispatch<SetStateAction<ScheduledCourseInitialValues | null>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  deleteId: null | number | undefined
  onDelete: () => void
  setDeleteId: Dispatch<SetStateAction<null | number | undefined>>
  onModalClose: (refetch?: boolean) => void
  setQuery: Dispatch<SetStateAction<GetScheduledCourseDTO.Request>>
  query: GetScheduledCourseDTO.Request
  data: GetScheduledCourseDTO.Response
  onEdit: (item: ScheduledCourseItem) => void
  handleSort: (column: ScheduledCourseSortBy) => void
  onCreate: () => void
  courseId: number
}

export const useScheduled = (
  entityId: number,
  entityType: ScheduledEntitiesTypes,
  shouldFetch: boolean,
): IUseScheduled => {
  const [showModal, setShowModal] =
    useState<ScheduledCourseInitialValues | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteId, setDeleteId] = useState<null | number>()
  const [query, setQuery] = useState<GetScheduledCourseDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    entityId,
    entityType,
  })
  const [data, setData] = useState<GetScheduledCourseDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    scheduledEntities: [],
  })

  const onDelete = useCallback(async () => {
    try {
      await deleteScheduledCourse(
        entityId,
        Number(deleteId),
        ScheduledEntitiesTypes.COURSE,
      )
      setDeleteId(null)
      toast('Successfully deleted', successToastOptions)
      setQuery(prev => ({ ...prev, page: 1 }))
    } catch (e) {}
  }, [data.page, deleteId, entityId])

  const fetchList = useCallback(() => {
    setIsLoading(true)
    getScheduledCourses(query)
      .then(response => {
        setData(response)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const onEdit = useCallback((item: ScheduledCourseItem) => {
    setShowModal({
      id: item.id,
      type: item.type,
      recurring: item.recurring,
      facilityId: item.facilityId,
      location: item.location,
      facilitatorId: item.facilitatorId,
      startDate: item.startDate,
      recurringEnd: item.recurringEnd,
      startTime: moment.utc(item.startTime, 'h:mm').local().toDate(),
      endTime: moment.utc(item.endTime, 'h:mm').local().toDate(),
    })
  }, [])

  const onCreate = useCallback(() => {
    setShowModal({
      type: ScheduledCourseTypes.IN_PERSON,
      recurring: RecurringTypes.ONE_TIME,
      facilityId: null,
      recurringEnd: undefined,
      location: '',
      facilitatorId: null,
      startDate: undefined,
      startTime: undefined,
      endTime: undefined,
    })
  }, [])

  const onModalClose = useCallback(
    (refetch?: boolean) => {
      setShowModal(null)
      if (refetch) {
        setQuery(prev => ({ ...prev, page: 1 }))
        fetchList()
      }
    },
    [query.entityId],
  )

  useEffect(() => {
    setQuery(q => ({ ...q, entityId }))
  }, [entityId])

  useEffect(() => {
    if (query.entityId && shouldFetch) {
      fetchList()
    }
  }, [query, entityId])

  const handleSort = useCallback((column: ScheduledCourseSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])
  return {
    showModal,
    setShowModal,
    isLoading,
    setIsLoading,
    deleteId,
    onDelete,
    setDeleteId,
    onModalClose,
    setQuery,
    query,
    data,
    onEdit,
    handleSort,
    onCreate,
    courseId: entityId,
  }
}
