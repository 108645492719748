import { PaginatedBaseResponse, PaginationRequest } from './pagination'
import { TCourseFeedback, TCourseReport } from '../models/courseReport'
import { REPORT_TYPE_FILTERS } from '../../pages/Reporting/CourseReport'
import { COURSE_TYPES } from '../lib/types'
import { COURSE_FILTER_FORMATS } from '../models'
import {
  AVG_DAYS_TO_COMPLETE,
  AVG_TEST_SCORE,
  AVG_USER_RATING,
  RETAKE_COURSE,
  RETAKE_TEST,
} from '../../pages/Reporting/CourseReport/types'

export enum CourseReportSortBy {
  CODE = 'code',
  NAME = 'name',
  TYPE = 'course.type',
  FORMAT = 'courseFormat',
  NUMBER_OF_COMPLETIONS = 'completions',
  AVG_TEST_SCORE_FIRST_ATTEMPT = 'avgScoreFirstAttempt',
  AVG_DAYS_TO_COMPLETE = 'avgDaysToComplete',
  RETAKE_TEST = 'retakeTest',
  RETAKE_COURSE = 'retakeCourse',
  AVG_USER_RATING = 'avgUserRating',
}

export namespace GetCourseReportDTO {
  export interface Request extends PaginationRequest {
    sortBy?: CourseReportSortBy
    type: REPORT_TYPE_FILTERS
    courseType?: COURSE_TYPES[]
    courseFormat?: COURSE_FILTER_FORMATS[]
    avgTestScore?: AVG_TEST_SCORE[]
    avgDaysToComplete?: AVG_DAYS_TO_COMPLETE[]
    retakeTest?: RETAKE_TEST[]
    retakeCourse?: RETAKE_COURSE[]
    avgUserRating?: AVG_USER_RATING[]
    key?: string
  }
  export interface Response extends PaginatedBaseResponse {
    courseReport: TCourseReport[]
  }
}

export enum CourseFeedbackSortBy {
  NAME = 'name',
  OVERALL_RATING = 'overallRating',
  COURSE_MATERIAL = 'courseMaterial',
  COURSE_PRESENTATION = 'coursePresentation',
  HOW_MUCH_LEARNED = 'howMuchLearned',
  HOW_ENGAGING = 'howEngaging',
  INSTRUCTOR = 'instructor',
  DATE = 'createdAt',
  COMMENT = 'comment',
}

export type CourseFeedbackStatistics = {
  courseMaterialAvg: number
  coursePresentationAvg: number
  howMuchLearnedAvg: number
  howEngagingAvg: number
  instructorAvg: number
  overallRatingAvg: number
}

export namespace GetCourseFeedbackDTO {
  export interface Request extends PaginationRequest {
    sortBy?: CourseFeedbackSortBy
    type: REPORT_TYPE_FILTERS
    courseFormat: COURSE_FILTER_FORMATS
    rating?: AVG_USER_RATING[]
  }

  export interface Response extends PaginatedBaseResponse {
    feedback: TCourseFeedback[]
    statistics: CourseFeedbackStatistics
  }
}
