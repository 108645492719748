import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalProps,
  Row,
  Spinner,
} from 'reactstrap'
import _ from 'lodash'
import {
  DateRangeData,
  EXPORT_RANGE,
  EXPORT_TYPES,
  SelectedFilters,
} from '../../pages/Reporting/CourseHistory/types'
import FilterTabs from '../Common/FilterTabs'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { getCoursesAssignments } from '../../helpers/api/assignments'
import { handleError } from '../../helpers/toast_helper'
import { GetCoursesAssignmentsDTO } from '../../sharedTypes/api/courseHistory'
import { ASSIGNMENT_STATUS } from '../../sharedTypes'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { renderToString } from 'react-dom/server'
import CourseHistoryReport from '../../pages/Reporting/CourseHistory/ExportCourseHistory'
import { addImageToPdf, generateXLSOrCSV } from '../../helpers/export'
import { useAppSelector } from '../../hooks/redux'
import { generateFileName } from '../../helpers/getExportFileName'

interface ExportAssignmentsModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  exportType: EXPORT_TYPES
  query: GetCoursesAssignmentsDTO.Request
  selectedFilters: SelectedFilters
  reportType: REPORT_TYPES
}

const tabs = [
  { label: 'All Time', value: EXPORT_RANGE.ALL_TIME },
  { label: 'Date Range', value: EXPORT_RANGE.DATE_RANGE },
]

const ROWS_PER_PAGE = 9

export enum REPORT_TYPES {
  COURSE_HISTORY = 'courseHistory',
  COURSE_HISTORY_SUMMARY = 'courseHistorySummary',
  USER_REPORT = 'userReport',
}

const ExportAssignmentsModal = ({
  onClose,
  isOpen,
  exportType,
  query,
  selectedFilters,
  reportType,
}: ExportAssignmentsModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const fromDateRef = useRef<Flatpickr | null>(null)
  const toDateRef = useRef<Flatpickr | null>(null)

  const [data, setData] = useState<GetCoursesAssignmentsDTO.Response>({
    assignments: [],
    count: 0,
    pages: 0,
    page: 0,
  })

  const [activeTab, setActiveTab] = useState<EXPORT_RANGE>(
    EXPORT_RANGE.DATE_RANGE,
  )
  const [dateRange, setDateRange] = useState<DateRangeData>()

  const courseHistoryFileTitle = reportType === REPORT_TYPES.COURSE_HISTORY

  useEffect(() => {
    const params =
      activeTab === EXPORT_RANGE.ALL_TIME
        ? {
            ...query,
            startDate: undefined,
            endDate: undefined,
            limit: undefined,
          }
        : { ...query, ...dateRange, limit: undefined }

    getCoursesAssignments(params)
      .then(res => {
        setData(res.data)
      })
      .catch(e => {
        handleError(e)
      })
  }, [dateRange])

  useEffect(() => {
    if (activeTab === EXPORT_RANGE.DATE_RANGE) {
      setDateRange({
        startDate: moment().subtract(1, 'year').toDate(),
        endDate: moment().toDate(),
      })
    } else {
      setDateRange({
        startDate: undefined,
        endDate: undefined,
      })
    }
  }, [activeTab])

  const dataToExport = useMemo(() => {
    if (reportType === REPORT_TYPES.COURSE_HISTORY_SUMMARY) {
      return data.assignments.map(row => ({
        user: `${row.user.firstName} ${row.user.lastName}`,
        facility: row.user.facility?.name || '',
        position: row.user.position?.name || '',
        department: row.user.departments?.[0]?.name || '',
        code: row.code,
        courseName: row.course?.translations[0].content.name || '-',
        courseType: row.course.type,
        completedDate: moment(row.endDate).format('MM/DD/YYYY, hh:MM A'),
      }))
    }

    return data.assignments.map(row => ({
      user: `${row.user.firstName} ${row.user.lastName}`,
      facility: row.user.facility?.name || '',
      position: row.user.position?.name || '',
      department: row.user.departments?.[0]?.name || '',
      code: row.code,
      packageCode: row.package ? row.package.code : '-',
      courseName:
        row.course?.translations[0].content.name || row.package?.name || '-',
      progress: row.progress + '%',
      status: row.status,
      completedDate:
        row.status === ASSIGNMENT_STATUS.COMPLETED
          ? moment(row.endDate).format('MM/DD/YYYY, hh:MM A')
          : '-',
      daysOverdue: 0,
      testAttempts: '',
    }))
  }, [data.assignments])

  const fileHeaders = useMemo(() => {
    if (reportType === REPORT_TYPES.COURSE_HISTORY) {
      return [
        'User',
        'Facility',
        'Position',
        'Department',
        'Code',
        'Package',
        'Course',
        'Progress',
        'Status',
        'Completed Date',
        'Days Overdue',
        'Test Attempts',
      ]
    }

    return [
      'User',
      'Facility',
      'Position',
      'Department',
      'Code',
      'Course',
      'Type',
      'Completed Date',
    ]
  }, [reportType])
  const exportData = () => {
    setIsLoading(true)
    if (exportType === EXPORT_TYPES.PDF) {
      generatePDF()
    } else {
      // XLS or CSV
      generateXLSOrCSV(
        exportType,
        fileHeaders,
        dataToExport,
        generateFileName(
          courseHistoryFileTitle ? 'Course_History_Report' : 'Course_Report',
        ),
      )
      setIsLoading(false)
    }
  }

  const generatePDF = () => {
    const pdf = new jsPDF({
      unit: 'px',
      format: 'a4',
      orientation: 'landscape',
    })

    const totalPages = Math.ceil(data.assignments.length / ROWS_PER_PAGE)

    addContentToPDF(0, pdf, totalPages)
  }

  const addContentToPDF = (
    currentPageIndex: number,
    pdf: jsPDF,
    totalPages: number,
  ) => {
    const pdfContent = document.createElement('div')

    const startIndex = currentPageIndex * ROWS_PER_PAGE
    const endIndex = Math.min(
      startIndex + ROWS_PER_PAGE,
      data.assignments.length,
    )
    const chunk = data.assignments.slice(startIndex, endIndex)

    pdfContent.innerHTML = renderToString(
      <CourseHistoryReport
        dateRange={dateRange}
        assignments={chunk}
        page={currentPageIndex + 1}
        selectedFilters={{
          ...selectedFilters,
          completedDate: query.completedDate,
          courseStatus: query.status,
          courseType: query.courseType,
        }}
        forSummary={reportType === REPORT_TYPES.COURSE_HISTORY_SUMMARY}
      />,
    )

    document.body.appendChild(pdfContent)

    html2canvas(pdfContent).then(function (canvas) {
      addImageToPdf(canvas, pdf, currentPageIndex)

      if (totalPages > 1) {
        currentPageIndex = currentPageIndex + 1
        totalPages = totalPages - 1
        addContentToPDF(currentPageIndex, pdf, totalPages)
      } else {
        const pdfBlob = new Blob([pdf.output('blob')], {
          type: 'application/pdf',
        })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        link.href = pdfUrl
        link.download = `${generateFileName(
          courseHistoryFileTitle ? 'Course_History_Report' : 'Course_Report',
        )}.pdf`
        link.click()
        setIsLoading(false)
      }
    })

    if (document.body.contains(pdfContent)) {
      document.body.removeChild(pdfContent)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalBody className='p-3'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Export as {_.toUpper(exportType)}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>
        <div>
          <FilterTabs
            tabs={tabs}
            navTab={activeTab}
            navToggle={type => {
              setActiveTab(type as EXPORT_RANGE)
            }}
            style={{ width: 'fit-content' }}
          />
        </div>
        <Row className='py-4'>
          <Col>
            <label htmlFor='date' className='form-label'>
              From*
            </label>
            <div className='form-icon right'>
              <Flatpickr
                className={'form-control form-control-icon'}
                placeholder={'--/--/----'}
                disabled={activeTab === EXPORT_RANGE.ALL_TIME}
                ref={fromDateRef}
                id='fromDate'
                name='fromDate'
                onChange={option => {
                  setDateRange(prev => ({ ...prev, startDate: option[0] }))
                }}
                value={dateRange?.startDate}
                options={{
                  dateFormat: 'MM/DD/YYYY',
                  formatDate: date => moment(date).format('MM/DD/YYYY'),
                }}
              />
              <i
                className='ri-calendar-2-line fs-20 text-primary'
                onClick={() => {
                  fromDateRef.current?.flatpickr.open()
                }}
              ></i>
            </div>
          </Col>
          <Col>
            <label htmlFor='date' className='form-label'>
              To*
            </label>
            <div className='form-icon right'>
              <Flatpickr
                className={'form-control form-control-icon'}
                placeholder={'--/--/----'}
                disabled={activeTab === EXPORT_RANGE.ALL_TIME}
                ref={toDateRef}
                id='toDate'
                name='toDate'
                onChange={option => {
                  setDateRange(prev => ({ ...prev, endDate: option[0] }))
                }}
                value={dateRange?.endDate}
                options={{
                  dateFormat: 'MM/DD/YYYY',
                  formatDate: date => moment(date).format('MM/DD/YYYY'),
                  minDate: dateRange?.startDate,
                }}
              />
              <i
                className='ri-calendar-2-line fs-20 text-primary'
                onClick={() => {
                  toDateRef.current?.flatpickr.open()
                }}
              ></i>
            </div>
          </Col>
        </Row>
        <div className='py-2'>
          Num. of Records :<span className='text-muted ms-2'>{data.count}</span>
        </div>
        <div className='hstack gap-2 justify-content-end'>
          <Button
            className='btn-soft-primary align-middle'
            color='secondary'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className='align-middle'
            color='success'
            disabled={!data.count}
            onClick={exportData}
          >
            {isLoading ? <Spinner size={'sm'} /> : 'Export'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ExportAssignmentsModal
