import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { ASSIGNMENT_STATUS, TAssignment } from '../../../sharedTypes'
import React, { useState } from 'react'
import { ASSIGNMENT_ACTIONS, SelectedAssignment } from './types'

interface ActionsDropdownMenuProps {
  isScheduleStarted: boolean
  assignment: TAssignment
  setSelectedAssignment: (selectedAssignment: SelectedAssignment) => void
  permissions: {
    addParticipants: boolean
    removeParticipant: boolean
    previewTest: boolean
    overrideScore: boolean
  }
}

const ActionsDropdownMenu = ({
  isScheduleStarted,
  assignment,
  setSelectedAssignment,
  permissions,
}: ActionsDropdownMenuProps) => {
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)

  const toggleActionDropdown = () =>
    setActionDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={actionDropdownOpen} toggle={toggleActionDropdown}>
      <DropdownToggle
        className='badge badge-purple btn btn-ghost-primary'
        tag='button'
      >
        <i className='mdi mdi-dots-horizontal align-middle' />
      </DropdownToggle>
      <DropdownMenu container={'body'}>
        {!assignment.testFileId && (
          <DropdownItem>
            <div
              className={`${
                !isScheduleStarted
                  ? 'text-disabled pe-none'
                  : 'text-green-500 cursor-pointer'
              }`}
              onClick={
                isScheduleStarted
                  ? () => {
                      setSelectedAssignment({
                        type: ASSIGNMENT_ACTIONS.UPLOAD_TEST,
                        data: assignment,
                      })
                    }
                  : undefined
              }
            >
              <i className='ri-download-2-fill' />
              <span className='mx-2'>Upload Written Test</span>
            </div>
          </DropdownItem>
        )}
        {assignment.testFile && (
          <DropdownItem>
            <div
              onClick={() => {
                setSelectedAssignment({
                  type: ASSIGNMENT_ACTIONS.TEST_PREVIEW,
                  data: assignment,
                })
              }}
            >
              <i className='cursor-pointer ri-eye-line text-muted' />
              <span className='mx-2 text-body'>View Written Test</span>
            </div>
          </DropdownItem>
        )}
        {permissions.previewTest && (
          <DropdownItem>
            <div
              onClick={() => {
                setSelectedAssignment({
                  type: ASSIGNMENT_ACTIONS.GENERATE_TEST,
                  data: assignment,
                })
              }}
            >
              <i className='cursor-pointer ri-survey-line text-muted' />
              <span className='mx-2 text-body'>Generate Written Test</span>
            </div>
          </DropdownItem>
        )}
        {permissions.overrideScore && (
          <DropdownItem>
            <div
              className={`${
                assignment.status === ASSIGNMENT_STATUS.COMPLETED ||
                !isScheduleStarted
                  ? 'text-disabled'
                  : ' text-muted cursor-pointer'
              }`}
              onClick={
                assignment.status !== ASSIGNMENT_STATUS.COMPLETED &&
                isScheduleStarted
                  ? () => {
                      setSelectedAssignment({
                        type: ASSIGNMENT_ACTIONS.OVERRIDE,
                        data: assignment,
                      })
                    }
                  : undefined
              }
            >
              <i className='ri-indeterminate-circle-line' />
              <span className='mx-2'>Override</span>
            </div>
          </DropdownItem>
        )}
        {permissions.removeParticipant && (
          <DropdownItem>
            <div
              className={`${
                assignment.status === ASSIGNMENT_STATUS.COMPLETED
                  ? 'text-disabled pe-none'
                  : 'text-danger cursor-pointer'
              }`}
              onClick={
                assignment.status !== ASSIGNMENT_STATUS.COMPLETED
                  ? () => {
                      setSelectedAssignment({
                        type: ASSIGNMENT_ACTIONS.DELETE,
                        data: assignment,
                      })
                    }
                  : undefined
              }
            >
              <i
                className='ri-delete-bin-2-line'
                id={`deleteAssignment${assignment.id}`}
              ></i>
              <span className='mx-2'>Remove</span>
            </div>
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export default ActionsDropdownMenu
