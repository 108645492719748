import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Button,
} from 'reactstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import BootstrapTheme from '@fullcalendar/bootstrap'
import listPlugin from '@fullcalendar/list'
import SimpleBar from 'simplebar-react'
import { EventClickArg } from '@fullcalendar/core'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import moment from 'moment'
import { CSVLink } from 'react-csv'

import BreadCrumb from '../../Components/Common/BreadCrumb'
import { scheduledEventTypes } from './data'
import { SearchInput } from '../../Components/Common/SearchInput'
import { getSchedulesByFacility } from '../../helpers/api_helper'
import UpcomingEvents from './UpcomingEvents'
import EventModal from '../../Components/Modals/EventModal'
import withRouter, { WithRouterProps } from '../../Components/Common/withRouter'
import {
  GetSchedulesByFacilityDTO,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
  ScheduledEvent,
} from '../../sharedTypes'
import { handleError } from '../../helpers/toast_helper'
import { useAppSelector } from '../../hooks/redux'

export const calculateAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number,
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

  return { width: srcWidth * ratio, height: srcHeight * ratio }
}

const Calender = ({ router }: WithRouterProps) => {
  document.title = 'Calendar | Mastered - Admin & Dashboard'

  const { selectedFacilityId, selectedGroupId, groupOptions } = useAppSelector(
    state => state.FacilityOptions,
  )

  const [showAll, setShowAll] = useState(false)
  const [globalSearch, setSearch] = useState<string>('')
  const [showEvent, setShowEvent] = useState<ScheduledEvent | null>(null)
  const [filters, setFilters] = useState<
    Omit<GetSchedulesByFacilityDTO.Request, 'facilityId'>
  >({
    startDate: null,
    endDate: null,
    type: undefined,
  })

  const [scheduledEvents, setScheduledEvents] = useState<ScheduledEvent[]>([])
  const [upcomingEvents, setUpcomingEvents] = useState<ScheduledEvent[]>([])

  const calendarRef = useRef<FullCalendar>(null)

  const UPCOMING_EVENTS_LIMIT = 5

  useEffect(() => {
    const facilityId = selectedFacilityId ?? undefined
    const groupId = selectedGroupId ?? undefined

    if (filters.startDate && filters.endDate) {
      getSchedulesByFacility({
        ...filters,
        facilityId,
        groupId,
      })
        .then(res => {
          setScheduledEvents(res)
        })
        .catch(e => {
          handleError(e)
        })
    }
  }, [filters, selectedFacilityId, selectedGroupId])

  useEffect(() => {
    const facilityId = selectedFacilityId ?? undefined
    const groupId = selectedGroupId ?? undefined

    getSchedulesByFacility({
      startDate: moment().toISOString(),
      endDate: moment().add(1, 'week').toISOString(),
      type: filters.type,
      facilityId,
      groupId,
    })
      .then(res => {
        setUpcomingEvents(res)
      })
      .catch(e => {
        handleError(e)
      })
  }, [filters.type, selectedFacilityId, selectedGroupId])

  const selectedOptionName = useMemo(() => {
    if (selectedFacilityId) {
      return groupOptions.find(
        option => option.value === `facility:${selectedFacilityId}`,
      )?.label
    }

    if (selectedGroupId) {
      return groupOptions.find(
        option => option.value === `group:${selectedGroupId}`,
      )?.label
    }

    return 'All'
  }, [selectedGroupId, selectedFacilityId, groupOptions])

  const closeEventModal = () => {
    setShowEvent(null)
  }

  const events = useMemo(() => {
    let _events = scheduledEvents.map(scheduledEvent => ({
      ...scheduledEvent,
      className:
        scheduledEvent.extendedProps.type === ScheduledCourseTypes.IN_PERSON
          ? 'bg-soft-info'
          : 'text-light-purple',
    }))

    if (globalSearch) {
      _events = _events.filter(event =>
        event.title.toLowerCase().includes(globalSearch.toLowerCase()),
      )
    }
    return _events
  }, [scheduledEvents, globalSearch])

  const csvFormattedData = useMemo(() => {
    return events.map(event => {
      const extendedPropsString = JSON.stringify(event.extendedProps)

      return { ...event, extendedProps: extendedPropsString }
    })
  }, [events])

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = ({ event }: EventClickArg) => {
    const _event = events.find(e => e.id === event._def.publicId)
    if (_event) {
      handleEvent(_event)
    }
  }

  const handleEvent = (event: ScheduledEvent) => {
    setShowEvent(event)
  }

  const generatePDF = async () => {
    const calendar = document.getElementById('htmlNodeToConvert')

    if (calendar) {
      html2canvas(calendar, {
        onclone: clonedDoc => {
          const header = clonedDoc.querySelector('.fc-header-toolbar')
          if (header) {
            const buttons = header.querySelectorAll('div')
            if (buttons.length > 1) {
              buttons[0].style.display = 'none' // Hide the first button
              buttons[buttons.length - 1].style.display = 'none' // Hide the last button
            }
          }
          const titleElement = clonedDoc.querySelector('.fc-toolbar-title')
          if (titleElement) {
            titleElement.textContent = `${titleElement.textContent} - ${selectedOptionName}`
          }
        },
      }).then(canvas => {
        const pdf = new jsPDF({
          unit: 'mm',
          format: 'a4',
          orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
        })
        const { width, height } = calculateAspectRatioFit(
          canvas.width,
          canvas.height,
          pdf.internal.pageSize.getWidth() - 20,
          pdf.internal.pageSize.getHeight() - 20,
        )

        pdf.addImage({
          imageData: canvas,
          x: (pdf.internal.pageSize.getWidth() - width) / 2,
          y: 10,
          width: width,
          height: height,
          compression: 'NONE',
        })
        // Open the PDF directly in a new tab
        const pdfBlob = new Blob([pdf.output('blob')], {
          type: 'application/pdf',
        })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        window.open(pdfUrl, '_blank')
      })
    }
  }

  const linkToNavigate = useMemo(() => {
    if (showEvent) {
      return showEvent?.extendedProps.entityType ===
        ScheduledEntitiesTypes.COURSE
        ? `/courses/live/${showEvent.extendedProps.courseId}`
        : `/courses/packages/manage/${showEvent?.extendedProps.entityId}`
    }

    return ''
  }, [showEvent])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'Calendar'}
            items={[
              {
                active: true,
                title: 'Calendar',
              },
            ]}
          />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card>
                    <CardBody>
                      {/*<button*/}
                      {/*  className='btn btn-primary w-100'*/}
                      {/*  id='btn-new-event'*/}
                      {/*>*/}
                      {/*  <i className='mdi mdi-plus'></i> Add New Meeting*/}
                      {/*</button>*/}

                      <div id='external-events'>
                        {scheduledEventTypes.map((eventType, key) => (
                          <div
                            className={`bg-soft-${
                              eventType.type
                            } external-event fs-13 text-${eventType.type} ${
                              filters.type === eventType.value
                                ? 'border-' + eventType.type
                                : ''
                            }`}
                            key={`cat-${key}`}
                            onClick={() =>
                              setFilters(prev => ({
                                ...prev,
                                type:
                                  filters.type === eventType.value
                                    ? undefined
                                    : eventType.value,
                              }))
                            }
                          >
                            <i className='mdi mdi-checkbox-blank-circle font-size-11 me-2 border-' />
                            {eventType.title}
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                  <div>
                    <h5 className='mb-1'>Upcoming Events</h5>
                    <SimpleBar className='pe-2 me-n1 mb-3'>
                      <div id='upcoming-event-list'>
                        {(showAll
                          ? upcomingEvents
                          : upcomingEvents.slice(0, UPCOMING_EVENTS_LIMIT)
                        ).map((event, key) => (
                          <UpcomingEvents
                            event={event}
                            key={key}
                            openDetails={handleEvent}
                          />
                        ))}
                      </div>
                      {upcomingEvents.length > UPCOMING_EVENTS_LIMIT && (
                        <div className='d-flex align-items-center mt-3'>
                          <button
                            type='button'
                            className='btn btn-link text-decoration-none fw-medium p-0 m-auto'
                            onClick={() => setShowAll(v => !v)}
                          >
                            <i
                              className={`ri-arrow-${
                                showAll ? 'up' : 'down'
                              }-s-line fs-16 me-2`}
                            ></i>
                            {showAll ? 'Show Less' : `Show More`}
                          </button>
                        </div>
                      )}
                    </SimpleBar>
                  </div>
                </Col>

                <Col xl={9}>
                  <Card className='card-h-100'>
                    <CardHeader>
                      <div className='hstack gap-3 px-3 mx-n3 justify-content-between align-items-center'>
                        <SearchInput
                          style={{ maxWidth: 250 }}
                          onChange={setSearch}
                          value={globalSearch}
                        />
                        <div
                          className='d-flex align-items-center'
                          style={{ zIndex: 1000 }}
                        >
                          <CSVLink
                            className='btn btn-ghost-primary align-middle'
                            data={csvFormattedData}
                            filename={
                              selectedOptionName +
                              ' - ' +
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              calendarRef.current?.calendar.currentData
                                .viewTitle
                            }
                            target='_blank'
                          >
                            <i className='mdi mdi-file-export-outline me-1 fs-16'></i>
                            Export CSV
                          </CSVLink>
                          <Button
                            className='btn-ghost-primary align-middle'
                            onClick={generatePDF}
                          >
                            <i className='mdi mdi-file-export-outline me-1 fs-16'></i>
                            Export PDF
                          </Button>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody id='htmlNodeToConvert'>
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin,
                        ]}
                        ref={calendarRef}
                        initialView='dayGridMonth'
                        slotDuration={'00:15:00'}
                        handleWindowResize={true}
                        themeSystem='bootstrap'
                        headerToolbar={{
                          left: 'prev,next today',
                          center: 'title',
                          right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
                        }}
                        events={events as any}
                        editable={false}
                        droppable={false}
                        selectable={true}
                        height={'auto'}
                        eventTimeFormat={{
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        }}
                        dayMaxEvents={2}
                        eventClick={handleEventClick}
                        eventContent={event => {
                          if (event.view.type === 'listWeek') {
                            return (
                              <span
                                className='fc-event-title fw-light fs-14'
                                style={{
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {event.event.title}
                              </span>
                            )
                          }
                          return (
                            <div className='d-flex px-1 flex-column align-items-start align-self-stretch overflow-hidden text-start'>
                              <span
                                className='fc-event-title fw-light fs-14'
                                style={{
                                  width: 'calc(100%)',
                                }}
                              >
                                {event.event.title}
                              </span>
                              <span className='d-block text-muted'>
                                {moment(event.event.start).format('hh:mm A')}
                                &nbsp;-&nbsp;
                                {moment(event.event.end).format('hh:mm A')}
                              </span>
                            </div>
                          )
                        }}
                        datesSet={dateInfo => {
                          //TODO fix date format
                          setFilters(prev => ({
                            ...prev,
                            startDate: moment(dateInfo.start).format(
                              'YYYY-MM-DD',
                            ),
                            endDate: moment(dateInfo.end).format('YYYY-MM-DD'),
                          }))
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div style={{ clear: 'both' }}></div>
            </Col>
          </Row>
        </Container>
      </div>

      <EventModal
        isOpen={!!showEvent}
        event={showEvent}
        toggle={closeEventModal}
        linkToNavigate={linkToNavigate}
      />
    </React.Fragment>
  )
}

export default withRouter(Calender)
