import { createSlice } from '@reduxjs/toolkit'
import { PermissionSet, TUser } from '../../../sharedTypes'

export const initialState: UserState = {
  user: null,
  accessToken: null,
  permissions: null,
}

const loginSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }: { payload: UserState['user'] }) {
      state.user = payload
    },
    setLoginUser(state, { payload }: { payload: UserState }) {
      state.user = payload.user
      state.accessToken = payload.accessToken
      state.permissions = payload.permissions
      state.switchedToPortal = payload.switchedToPortal ?? false
      state.switched = payload.switchedToPortal ?? false
    },
    clearUser() {
      return initialState
    },
    setToken(state, { payload }: { payload: UserState['accessToken'] }) {
      state.accessToken = payload
    },
    setMasteredToken(
      state,
      { payload }: { payload: UserState['masteredToken'] },
    ) {
      state.masteredToken = payload
    },
    setPermissions(state, { payload }: { payload: UserState['permissions'] }) {
      state.permissions = payload
    },
    setSwitchedToPortal(state, { payload }: { payload: boolean }) {
      state.switchedToPortal = payload
    },
  },
})

export const {
  setUser,
  setLoginUser,
  clearUser,
  setPermissions,
  setSwitchedToPortal,
  setMasteredToken,
} = loginSlice.actions

export type UserState = {
  user: TUser | null
  accessToken: string | null
  permissions: PermissionSet | null
  switchedToPortal?: boolean
  masteredToken?: string
  switched?: boolean
}

export default loginSlice.reducer
