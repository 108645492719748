import { Card, CardBody, Row, UncontrolledTooltip } from 'reactstrap'
import React from 'react'

import {
  AttachmentsAccessEnum,
  AttachmentsSortBy,
  AttachmentsTypesEnum,
  GetAttachmentsDTO,
  TAttachment,
  BadgeColorByAttachmentType,
  BadgeColorByAttachmentAccess,
  CoursesPermissions,
} from '../../../sharedTypes'
import MIcon from '../../../assets/images/svg/M.svg'
import { usePermissions } from '../../../hooks/usePermissions'

interface MediaTableViewProps {
  query: GetAttachmentsDTO.Request
  attachments: TAttachment[]
  handleSort: (column: AttachmentsSortBy) => void
  setDeleteMedia: (media: TAttachment) => void
  onShow: (media: TAttachment) => void
}

const IconByType = {
  [AttachmentsTypesEnum.DOCUMENT]: 'ri-file-list-3-line',
  [AttachmentsTypesEnum.VIDEO]: 'ri-film-line',
  [AttachmentsTypesEnum.IMAGE]: 'mdi mdi-image-outline',
  [AttachmentsTypesEnum.AUDIO]: 'ri-film-line',
}

const MediaGridView = ({ attachments, onShow }: MediaTableViewProps) => {
  const hasPermissionToViewMedia = usePermissions(CoursesPermissions.VIEW_MEDIA)

  return (
    <div className='d-flex flex-shrink-0 gap-4 flex-wrap mt-4 position-relative'>
      <Row className='w-100'>
        {attachments.map(attachment => {
          const ext = attachment.name.substr(
            attachment.name.lastIndexOf('.') + 1,
          )
          return (
            <div
              className='col-xl-2 col-lg-2 col-md-4 col-sm-6 col-auto cursor-pointer'
              key={attachment.id}
              onClick={
                hasPermissionToViewMedia ? () => onShow(attachment) : undefined
              }
            >
              <Card className='position-relative'>
                {attachment.type === AttachmentsTypesEnum.DOCUMENT && (
                  <span
                    className='avatar-title rounded image-container bg-white border-bottom'
                    style={{
                      aspectRatio: 1,
                    }}
                  >
                    <i className='ri-file-list-3-line fs-70'></i>
                  </span>
                )}
                {attachment.type === AttachmentsTypesEnum.IMAGE && (
                  <img
                    className='card-img-top img-fluid'
                    src={attachment.path}
                    alt={attachment.name}
                    style={{
                      aspectRatio: 1,
                      objectFit: 'cover',
                    }}
                  />
                )}
                {attachment.type === AttachmentsTypesEnum.VIDEO && (
                  <video
                    data-dz-thumbnail=''
                    autoPlay={false}
                    controls={false}
                    className='card-img-top img-fluid bg-white border-bottom'
                    style={{
                      aspectRatio: 1,
                      objectFit: 'cover',
                    }}
                    src={attachment.path}
                  />
                )}

                <div className='position-absolute top-0 p-3 d-flex gap-2'>
                  <span
                    className={`badge badge-${
                      BadgeColorByAttachmentType[attachment.type]
                    } fs-12 fw-normal text-capitalize p-1`}
                  >
                    <i className={`${IconByType[attachment.type]} fs-16`}></i>
                  </span>
                  <UncontrolledTooltip
                    placement='top'
                    target={'accessAttachment' + attachment.id}
                  >
                    {attachment.access} Access
                  </UncontrolledTooltip>
                  <span
                    className={`badge badge-${
                      BadgeColorByAttachmentAccess[attachment.access]
                    } fs-14 fw-normal p-1`}
                    id={'accessAttachment' + attachment.id}
                    style={{
                      aspectRatio: 1,
                    }}
                  >
                    {attachment.access === AttachmentsAccessEnum.MASTERED && (
                      <img src={MIcon} className='img-fluid' alt='' />
                    )}
                  </span>
                </div>
                <CardBody className='d-flex'>
                  <span className='text-truncate'>
                    {attachment.name.split('.').slice(0, -1)}.
                  </span>
                  <span className='text-muted'>{ext}</span>
                </CardBody>
              </Card>
            </div>
          )
        })}
      </Row>
    </div>
  )
}

export default MediaGridView
