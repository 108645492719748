import { ITutorialItem } from '../models/resource'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'

export type VideFile = {
  preview: string
  formattedSize: string
  name: string
}

export enum TutorialsSortBy {
  TITLE = 'title',
  CREATED_AT = 'createdAt',
}

export namespace GetTutorialsDTO {
  export interface Request extends PaginationRequest {
    sortBy?: TutorialsSortBy
  }

  export interface Response extends PaginatedBaseResponse {
    tutorials: ITutorialItem[]
  }
}

export namespace CreateTutorialDTO {
  export interface Request extends FormData {}
  export interface Response extends ITutorialItem {}
}

export type TutorialModalInitialValues = {
  id: number
  title: string
  content: string
  categoryId: number | null
  video: VideFile | undefined
}

export type TutorialModalSubmitedValues = {
  id: number
  title: string
  content: string
  categoryId: number
  video: File | undefined
}
