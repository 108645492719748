import React from 'react'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalProps,
  Row,
  Spinner,
} from 'reactstrap'
import { useState } from 'react'
import { TLanguage, Question } from '../../sharedTypes'
import SurveyQuestionsBasePreview from '../Common/SurveyQuestionsBasePreview'
import { useAppSelector } from '../../hooks/redux'

interface PreviewSurveyProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  questions?: Question[]
  languages: TLanguage[]
}

const PreviewSurveyModal = ({
  onClose,
  isOpen,
  languages,
  questions = [],
}: PreviewSurveyProps) => {
  const { defaultLanguageId } = useAppSelector(state => state.Languages)
  const [activeTab, setActiveTab] = useState(defaultLanguageId)

  const tabChange = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered
      className='preview-survey-modal'
    >
      {questions.length ? (
        <ModalBody className='modal-body'>
          <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
            <h5 className='fw-light'>Preview</h5>
            <i
              className='ri-close-line fs-24 cursor-pointer'
              onClick={onClose}
            ></i>
          </div>
          <div className='vstack gap-3'>
            <Row>
              <Col md={12}>
                <SurveyQuestionsBasePreview
                  questions={questions}
                  languages={languages}
                />
              </Col>
            </Row>
          </div>
          <div className='d-flex justify-content-end mt-3'>
            <Button color='primary' onClick={onClose}>
              Close
            </Button>
          </div>
        </ModalBody>
      ) : (
        <div className='d-flex justify-content-center'>
          <Spinner className='m-5' />
        </div>
      )}
    </Modal>
  )
}

export default PreviewSurveyModal
