import React, { useEffect, useState } from 'react'
import withRouter, { WithRouterProps } from '../Components/Common/withRouter'
import { useAppDispatch } from '../hooks/redux'
import { useAppSelector } from '../hooks/redux'

//import Components
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { setLanguages } from '../slices/languages/reducer'
import { getCompaniesHierarchy, getLanguages } from '../helpers/api_helper'

import { changeHTMLAttribute } from '../utils/layout'
import { handleError } from '../helpers/toast_helper'
import { setGroupOptions } from '../slices/facilities/reducer'
import { getCompanyGroupWithFacilitiesOptions } from '../helpers/facility'
import moment from 'moment'

interface LayoutProps extends WithRouterProps {
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const { lastUpdated } = useAppSelector(state => state.FacilityOptions)
  const user = useAppSelector(state => state.User.user)

  const dispatch = useAppDispatch()
  const [headerClass, setHeaderClass] = useState('')

  // class add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true)
  })

  useEffect(() => {
    getLanguages()
      .then(list => {
        dispatch(setLanguages(list))
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    if (
      (!lastUpdated || moment().diff(moment(lastUpdated), 'hour') > 1) &&
      user?.companyId
    ) {
      getCompaniesHierarchy({
        accessible: true,
      })
        .then(res => {
          const groupOptions = getCompanyGroupWithFacilitiesOptions(
            res.data,
            false,
          )
          dispatch(setGroupOptions(groupOptions))
        })
        .catch(handleError)
    }
  }, [user])

  function scrollNavigation() {
    const scrollup = document.documentElement.scrollTop
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow')
    } else {
      setHeaderClass('')
    }
  }

  /*
  layout settings
  */
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
    changeHTMLAttribute('data-layout-style', 'detached')
    changeHTMLAttribute('data-sidebar-size', 'lg')
    changeHTMLAttribute('data-sidebar', 'light')
    changeHTMLAttribute('data-layout-mode', 'light')
    changeHTMLAttribute('data-layout-width', 'lg')
    changeHTMLAttribute('data-layout-position', 'fixed')
    changeHTMLAttribute('data-topbar', 'dark')
    changeHTMLAttribute('data-layout', 'vertical')
    changeHTMLAttribute('data-sidebar-image', 'none')
    changeHTMLAttribute('data-sidebar-visibility', 'show')
  })

  return (
    <React.Fragment>
      <div id='layout-wrapper'>
        <Header headerClass={headerClass} />
        <Sidebar />
        <div className='main-content'>
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Layout)
