import React from 'react'
import { Card, CardBody } from 'reactstrap'
import moment from 'moment'
import { ScheduledCourseTypes, ScheduledEvent } from '../../sharedTypes'
import { CategoryTypes } from './data'

interface UpcomingEventsProps {
  event: ScheduledEvent
  openDetails: (event: ScheduledEvent) => void
}

const getEventClassName = (type: ScheduledCourseTypes) => {
  let className = ''

  switch (type) {
    case ScheduledCourseTypes.WEBINAR:
      className = CategoryTypes.PRIMARY
      break
    case ScheduledCourseTypes.IN_PERSON:
      className = CategoryTypes.INFO
      break
  }

  return className
}

const UpcomingEvents = ({ event, openDetails }: UpcomingEventsProps) => {
  return (
    <Card className='mb-3'>
      <CardBody className='cursor-pointer' onClick={() => openDetails(event)}>
        <div className='d-flex mb-3'>
          <div className='flex-grow-1'>
            <i
              className={
                'mdi mdi-checkbox-blank-circle me-2 text-' +
                getEventClassName(event.extendedProps.type)
              }
            ></i>
            <span className='fw-normal fs-14'>
              {moment(event.start).format('MM/DD/YYYY')}
            </span>
          </div>
          <div className='flex-shrink-0'>
            <span className='badge badge-soft-grey text-muted fw-normal fs-12 ms-auto'>
              {moment(event.start).format('hh:mm A')}&nbsp;to&nbsp;
              {moment(event.end).format('hh:mm A')}
            </span>
          </div>
        </div>
        <div className='d-flex gap-2 align-self-stretch'>
          <h6
            className='card-title fs-16 text-nowrap overflow-hidden'
            style={{
              textOverflow: 'ellipsis',
            }}
          >
            {event.title}
          </h6>
          <h6
            className='card-title fs-16 text-nowrap text-muted'
            style={{
              textAlign: 'right',
              marginLeft: 'auto',
            }}
          >
            {event.extendedProps.facilitator?.name || ''}
          </h6>
        </div>
      </CardBody>
    </Card>
  )
}

export default UpcomingEvents
