import React, { useCallback, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  Col,
  Card,
  Row,
  FormFeedback,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import {
  TutorialModalInitialValues,
  TutorialModalSubmitedValues,
} from '../../../sharedTypes'
import { FormikHelpers } from 'formik'
import { getSupportCategoriesNames } from '../../../helpers/api_helper'
import Select, { SingleValue } from 'react-select'
import { Spinner } from 'reactstrap'
import formatBytes from '../../../utils/formatBytes'
import handleAcceptedFile from '../../../utils/handleAcceptedFile'

interface Option {
  readonly label: string
  readonly value: number
}

interface InstructorModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (
    values: TutorialModalSubmitedValues,
    formikHelpers: FormikHelpers<TutorialModalSubmitedValues>,
  ) => void
  title: string
  initialValues: TutorialModalInitialValues | null
}

const TutorialModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
}: InstructorModalProps) => {
  const [options, setOptions] = React.useState<Option[]>([])

  useEffect(() => {
    getSupportCategoriesNames({ page: 1, limit: 100 }).then(
      ({ categories }) => {
        setOptions(categories.map(c => ({ value: c.id, label: c.name })))
      },
    )
  }, [])

  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: -1,
      title: '',
      email: '',
      content: '',
      categoryId: null,
      video: undefined,
      qualification: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Please enter tutorial name'),
      categoryId: Yup.number().nullable().required('Category is required'),
      content: Yup.string()
        .max(2000, 'Can be only up to 2000 characters')
        .required('Article is required'),
      video: Yup.mixed().nullable().required('video is required'),
    }),
    onSubmit: (values, formik) => {
      onSubmit(
        values as TutorialModalSubmitedValues,
        formik as FormikHelpers<TutorialModalSubmitedValues>,
      )
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <div>
              <label htmlFor='title' className='form-label'>
                Tutorial name
              </label>
              <Input
                name='title'
                className='form-control'
                id='title'
                disabled={form.isSubmitting}
                placeholder='Enter name'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.title || ''}
                invalid={!!(form.touched.title && form.errors.title)}
              />
              {form.touched.title && form.errors.title ? (
                <FormFeedback type='invalid'>{form.errors.title}</FormFeedback>
              ) : null}
            </div>

            <div>
              <label htmlFor='bio' className='form-label'>
                Category
              </label>
              <Select
                value={options.find(o => o.value === form.values.categoryId)}
                onChange={(option: SingleValue<Option>) => {
                  if (option) {
                    form.setFieldValue('categoryId', option.value)
                  }
                }}
                onBlur={() => {
                  form.setFieldTouched('qualification')
                }}
                isDisabled={form.isSubmitting}
                className='is-invalid'
                options={options}
                name='choices-single-default'
                id='idStatus'
              />
              {form.touched.categoryId && form.errors.categoryId ? (
                <FormFeedback type='invalid'>
                  {form.errors.categoryId as string}
                </FormFeedback>
              ) : null}
            </div>

            <div>
              {!form.values.video && (
                <div className='cursor-pointer'>
                  <Dropzone
                    maxFiles={1}
                    disabled={form.isSubmitting}
                    multiple={false}
                    accept={{ image: ['.mp4'] }}
                    onDrop={acceptedFiles => {
                      handleAcceptedFile(
                        acceptedFiles,
                        'video',
                        form.setFieldValue,
                      )
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone dz-clickable'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}
                        >
                          <div className='mt-2'>
                            <i className='display-7 text-muted ri-upload-cloud-2-fill' />
                          </div>
                          <p className='m-0'>
                            Drop file here or click to upload.<br></br>
                            (mp4)
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>{' '}
                </div>
              )}
              {form.errors.video ? (
                <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                  {(form.errors.video as string) || ''}
                </p>
              ) : null}
              {form.values.video && (
                <ul className='list-unstyled mb-0' id='dropzone-preview'>
                  <Card
                    className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                    key={'-file'}
                  >
                    <div className='p-2'>
                      <Row className='align-items-center'>
                        <Col>
                          <Link to='#' className='text-muted font-weight-bold'>
                            {form.values.video.name}
                          </Link>
                          <p className='mb-0'>
                            <strong>{form.values.video.formattedSize}</strong>
                          </p>
                        </Col>
                        <Col className='d-flex justify-content-end align-items-end'>
                          <Button
                            color='danger'
                            disabled={form.isSubmitting}
                            onClick={() => {
                              form.setFieldValue('video', null)
                            }}
                          >
                            {' '}
                            Delete{' '}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </ul>
              )}
            </div>

            <div>
              <label htmlFor='content' className='form-label'>
                Article
              </label>
              <Input
                name='content'
                className='form-control'
                id='content'
                placeholder='Enter content'
                type='textarea'
                rows='6'
                disabled={form.isSubmitting}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.content || ''}
                invalid={!!(form.touched.content && form.errors.content)}
              />
              {form.touched.content && form.errors.content ? (
                <FormFeedback type='invalid'>
                  {form.errors.content as string}
                </FormFeedback>
              ) : null}
            </div>

            <div className='hstack gap-2 justify-content-end mt-4'>
              <Button
                className='btn-soft-primary'
                onClick={beforeClose}
                active={false}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                color='success'
                type='submit'
                disabled={!form.dirty || form.isSubmitting}
              >
                {form.isSubmitting ? <Spinner size={'sm'} /> : 'Submit'}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default TutorialModal
