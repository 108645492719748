import { Col, Row } from 'reactstrap'
import _ from 'lodash-es'
import { ShowMoreWithPopoverProps, Step2PassedProps } from './types'
import React, { useState } from 'react'
import { CoursesTabs } from '../../../sharedTypes'

const ShowMoreBadgeWithPopover = ({
  limit = 5,
  data,
}: ShowMoreWithPopoverProps) => {
  const [showMore, setShowMore] = useState(false)
  const handleShowMore = () => setShowMore(!showMore)
  return (
    <div className='hstack'>
      <span className='text-body d-flex flex-column align-items-baseline'>
        {data.slice(0, showMore ? data.length : limit).map(item => (
          <span
            className={`badge selected-course-badge text-muted fs-12 fw-normal me-1
             mt-1 text-truncate`}
            key={item}
          >
            {item}
          </span>
        ))}

        {data.length > limit && (
          <span
            className='badge selected-course-badge text-primary cursor-pointer ps-1 mt-1'
            id={'viewMore'}
            onClick={handleShowMore}
          >
            {showMore ? 'Show Less' : `+${data.length - limit} more`}
          </span>
        )}
      </span>
    </div>
  )
}

const Step2Passed = ({ enrollment }: Step2PassedProps) => {
  const getSelectedEntityNames = (entityKey: CoursesTabs, nameKey: string) => {
    return enrollment[entityKey].flatMap(courseData => {
      return _.get(courseData, nameKey, '-')
    })
  }

  return (
    <div className='me-1'>
      <Row className='pt-3 text-start bg-body rounded px-3'>
        {!_.isEmpty(enrollment[CoursesTabs.COURSES]) && (
          <Col sm={4}>
            <div className='v-stack gap-2'>
              <span className='text-muted fs-14'>Courses: </span>
              <span className='text-body fs-14'>
                <ShowMoreBadgeWithPopover
                  limit={2}
                  data={getSelectedEntityNames(
                    CoursesTabs.COURSES,
                    'course.translations[0].content.name',
                  )}
                />
              </span>
            </div>
          </Col>
        )}

        {!_.isEmpty(enrollment[CoursesTabs.PACKAGES]) && (
          <Col sm={4}>
            <div className='v-stack gap-2'>
              <span className='text-muted fs-14'>Packages: </span>
              <span className='text-muted fs-14 d-flex align-items-baseline'>
                <ShowMoreBadgeWithPopover
                  limit={2}
                  data={getSelectedEntityNames(CoursesTabs.PACKAGES, 'name')}
                />
              </span>
            </div>
          </Col>
        )}

        {!_.isEmpty(enrollment[CoursesTabs.TRAINING_DAYS]) && (
          <Col sm={4}>
            <div className='v-stack gap-2'>
              <span className='text-muted fs-14'>Training Days: </span>
              <span className='text-muted fs-14 d-flex align-items-baseline'>
                <ShowMoreBadgeWithPopover
                  limit={2}
                  data={getSelectedEntityNames(
                    CoursesTabs.TRAINING_DAYS,
                    'name',
                  )}
                />
              </span>
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Step2Passed
