import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import {
  SCHEDULED_COURSE_FORMATS,
  SCHEDULED_COURSE_STATUSES,
  TScheduledCourse,
} from '../models'
import { COURSE_TYPES } from '../lib/types'
import { notificationOption } from '../forms'
import { ScheduledCourseTypes, ScheduledEntitiesTypes } from './scheduledCource'

export enum ScheduledCategoriesSortBy {
  ID = 'ID',
  NAME = 'name',
  COURSES_COUNT = 'coursesCount',
}

export enum ScheduledCoursesSortBy {
  ID = 'id',
  NAME = 'name',
  FORMAT = 'format',
  INSTRUCTOR = 'instructor',
  CATEGORY = 'category.name',
  STATUS = 'status',
  DATE = 'startDate',
  TIME = 'startTime',
  DURATION = 'endTime',
}

export interface ScheduledCategoryRequest {
  name: string
  description: string
}

export interface ScheduledCategoryItem extends ScheduledCategoryRequest {
  id: number
  coursesCount: number
}

export namespace GetScheduledCategoriesDTO {
  export interface Request extends PaginationRequest {
    sortBy?: ScheduledCategoriesSortBy
  }

  export interface Response extends PaginatedBaseResponse {
    scheduled_categories: ScheduledCategoryItem[]
  }
}

export namespace CreateScheduledCategoryDTO {
  export interface Request extends ScheduledCategoryRequest {}
  export type Response = any
}

export namespace EditScheduledCategoryDTO {
  export interface Request extends ScheduledCategoryRequest {}
  export type Response = any
}

export type ScheduledCategoryInitialValues = {
  name: string
  description: string
}

export type ScheduledCategoryModalSubmitedValues = {
  name: string
  description: string
}

export namespace GetScheduledCoursesDTO {
  export interface Request extends PaginationRequest {
    sortBy?: ScheduledCoursesSortBy
    format?: SCHEDULED_COURSE_FORMATS
    status?: SCHEDULED_COURSE_STATUSES[]
    type?: COURSE_TYPES[]
    instructorIds?: number[]
    categoryIds?: number[]
    key?: string
  }
  export interface Response {
    count: number
    courses: TScheduledCourse[]
    page: number
    pages: number
  }
}

export namespace PostScheduledCoursesDTO {
  export interface Request {
    name: string
    isDraft: boolean
    type: COURSE_TYPES
    categoryId: number
    instructorId: number
    format: SCHEDULED_COURSE_FORMATS
    description?: string
    startDate?: string
    startTime?: string
    endTime?: string
    endDate?: string
    location?: string
    maximumAttendance?: number
    registrationDeadline?: string
    attendees?: number[]
    relevantLinks?: string[]
    notificationsEnabled: boolean
    notifications?: { value: number; type: notificationOption }[] | null
  }
  export type Response = TScheduledCourse
}

export interface ScheduledEvent {
  id: string
  start: Date
  end: Date
  title: string
  extendedProps: {
    entityType: ScheduledEntitiesTypes
    entityId: number
    startTime: Date
    endTime: Date
    courseId?: number | null
    type: ScheduledCourseTypes
    location: string | null
    description: string
    facilitator: {
      id: number
      name: string
    } | null
    meetingLink: string
    notes?: string | null
  }
}

export namespace GetSchedulesByFacilityDTO {
  export interface Request {
    facilityId?: number
    groupId?: number
    startDate: string | null
    endDate: string | null
    type?: ScheduledCourseTypes
  }
}
