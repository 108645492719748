import moment from 'moment/moment'
import masteredLogo from '../../../assets/images/logo.png'
import React from 'react'
import {
  ImageAnswerItem,
  MultiChoiceAnswerItem,
  QuestionItem,
  QuestionTypes,
} from '../../../sharedTypes'
import { Label } from 'reactstrap'
import _ from 'lodash'
import { answerOptions } from './types'
import { CorrectAnswerIcon, NotCorrectAnswerIcon } from './AnswerIcons'

interface ExportCourseTestQuestions {
  courseName: string
  courseCode: string
  userName?: string
  questions: QuestionItem[]
  page: number
  withAnswerKeys: boolean
}

const ExportCourseTestQuestions = ({
  courseName,
  courseCode,
  userName,
  questions,
  page,
  withAnswerKeys,
}: ExportCourseTestQuestions) => {
  return (
    <div id='course-history-report-pdf w-100'>
      {page === 1 ? (
        <>
          <div className='d-flex justify-content-between'>
            <div className='d-flex gap-3 align-items-center'>
              <h1 className='text-body fs-70'>
                {courseName} - {courseCode}
              </h1>
            </div>
            <div className='logo-lg'>
              <img
                className='card-img-top img-fluid w-100'
                src={masteredLogo}
                alt='Mastered'
              />
            </div>
          </div>

          <div className='mt-3 mb-5'>
            <p className='fs-36 text-body mb-2'>
              {`${userName ? userName + ' - ' : ''}`}{' '}
              {moment().format('MM/DD/YYYY')}
              {withAnswerKeys ? '- Answer Key' : ''}
            </p>
          </div>
          {!withAnswerKeys ? (
            <div className='mt-4'>
              <div className='fs-36 text-body mb-2'>
                I confirm receipt of the training and I acknowledge the
                opportunity to seek clarification through questions.
                <br />
                Furthermore, I accept the responsibility to maintain and uphold
                competency standards.
              </div>
              <div
                className='border-top-groove pt-1 mt-5 fs-20 text-body'
                style={{ width: 500 }}
              >
                Your signature
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}

      <div className='vstack gap-4 mt-5 pt-5'>
        {questions.map((questionData, qIndex) => (
          <div key={qIndex}>
            <div className='border-bottom'>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='text-muted fs-48'>
                  Question # {qIndex + 1}
                </span>
              </div>
              <div className='hstack mb-2'>
                <span className='fw-medium fs-48 text-start'>
                  {questionData.question || '-'}
                </span>
              </div>
              {questionData.type === QuestionTypes.MULTIPLE_CHOICE &&
                ((questionData.answers as MultiChoiceAnswerItem[]) || []).map(
                  (answerData, aIndex) => (
                    <div key={aIndex}>
                      <div className='form-check my-2 fs-36 mb-2 px-0 d-flex align-items-center'>
                        {answerData.correct && withAnswerKeys ? (
                          <CorrectAnswerIcon className='fs-36' />
                        ) : (
                          <NotCorrectAnswerIcon className='fs-36' />
                        )}
                        <Label
                          className={`form-check-label fw-medium fs-36 ${
                            answerData.correct && withAnswerKeys
                              ? 'text-green-500'
                              : 'text-muted'
                          }`}
                        >
                          {'answer' in answerData && answerData?.answer}
                        </Label>
                      </div>
                    </div>
                  ),
                )}

              {questionData.type === QuestionTypes.IMAGE_CHOICE &&
                ((questionData.answers as ImageAnswerItem[]) || []).map(
                  (answerData, aIndex) => (
                    <div key={aIndex}>
                      <div className='form-check my-2 fs-36 mb-2 px-0 d-flex align-items-center'>
                        {answerData.correct && withAnswerKeys ? (
                          <CorrectAnswerIcon className='fs-36' />
                        ) : (
                          <NotCorrectAnswerIcon className='fs-36' />
                        )}
                        <div
                          className='d-flex justify-content-center align-items-center
                                      rounded test-question-image-answer'
                          style={{
                            width: 150,
                            height: 'auto',
                          }}
                        >
                          {_.get(answerData, 'path') ? (
                            <img
                              src={_.get(answerData, 'path')}
                              className='w-100 h-100 rounded'
                            />
                          ) : (
                            <i className='display-7 text-muted ri-upload-cloud-2-fill fs-5'></i>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                )}

              {questionData.type === QuestionTypes.TRUE_FALSE && (
                <div>
                  {Object.values(answerOptions).map((option, index) => (
                    <div
                      key={index}
                      className='form-check mb-2 px-0 d-flex align-items-center'
                    >
                      {((_.get(questionData, 'answers.correct') &&
                        option === answerOptions.YES) ||
                        (!_.get(questionData, 'answers.correct') &&
                          option === answerOptions.NO)) &&
                      withAnswerKeys ? (
                        <CorrectAnswerIcon className='fs-36' />
                      ) : (
                        <NotCorrectAnswerIcon className='fs-36' />
                      )}
                      <Label
                        className={`form-check-label fs-36 ${
                          ((_.get(questionData, 'answers.correct') &&
                            option === answerOptions.YES) ||
                            (!_.get(questionData, 'answers.correct') &&
                              option === answerOptions.NO)) &&
                          withAnswerKeys
                            ? 'text-green-500'
                            : 'text-muted'
                        }`}
                      >
                        {option}
                      </Label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ExportCourseTestQuestions
