import { PaginationRequest, PaginatedBaseResponse } from './pagination'

export enum DocumentSortBy {
  TYPE = 'type',
  EXPIRY_DATE = 'expiryDate',
  CERTIFICATED_ID = 'certificateId',
  ORGANIZATION = 'organization',
  VALID_DATE = 'validDate',
}

export type DocumentFile = {
  attachmentId: number
  preview: string
  formattedSize: string
  name: string
  type?: string
  blob?: string
}

interface ExtendedFile extends File, Omit<DocumentFile, 'name' | 'type'> {}

export type IDocumentItem = {
  id?: number
  type: string
  url: string
  certificateId: string
  organization: string
  validDate: string
  expiryDate: string
}

export namespace CreateDocumentDto {
  export interface Request extends DocumentModalSubmitedValues {}
  export interface Response extends IDocumentItem {}
}

export namespace GetDocumentsDTO {
  export interface Request extends PaginationRequest {
    sortBy?: DocumentSortBy
  }

  export interface Response extends PaginatedBaseResponse {
    documents: IDocumentItem[]
  }
}

export type DocumentModalSubmitedValues = {
  id: number
  type: string
  document: Blob
  certificateId: string
  organization: string
  validDate: Date
  expiryDate: Date
}

export type DocumentModalInitialValues = {
  id: number
  type: string
  document: undefined | ExtendedFile | DocumentFile
  certificateId: string | undefined
  organization: string | undefined
  validDate: Date | undefined
  expiryDate: Date | undefined
}
