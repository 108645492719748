import React from 'react'
import { OrderType } from '../../sharedTypes'

interface ColumnSortProps<SortBy> {
  sortOrder?: OrderType
  column: SortBy
  sortedColumn?: SortBy | null
  handleSort: (value: SortBy) => void
}

const ColumnSortIcon: <SortBy>(
  props: ColumnSortProps<SortBy>,
) => React.ReactElement<ColumnSortProps<SortBy>> = ({
  sortOrder,
  column,
  sortedColumn,
  handleSort,
}) => {
  if (sortOrder && sortedColumn === column) {
    return (
      <button
        className={`gridjs-sort gridjs-sort-${sortOrder.toLocaleLowerCase()}`}
        onClick={() => handleSort(column)}
      ></button>
    )
  }
  return (
    <button
      className='gridjs-sort gridjs-sort-neutral'
      onClick={() => handleSort(column)}
    ></button>
  )
}

export default ColumnSortIcon
