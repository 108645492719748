import React, { useEffect, useState, useCallback, useMemo } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  Col,
  FormFeedback,
  Card,
  Row,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import { getTutorials } from '../../../helpers/api_helper'
import _ from 'lodash'
import { FormikHelpers } from 'formik'
import {
  ResourceCategoryModalValues,
  SupportPermissions,
} from '../../../sharedTypes'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import {
  postSupportCategory,
  putSupportCategory,
} from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../helpers/toast_helper'
import { errorToastOptions } from '../../../helpers/toast_helper'
import { Spinner } from 'reactstrap'
import { usePermissions } from '../../../hooks/usePermissions'
import handleAcceptedFile from '../../../utils/handleAcceptedFile'

interface Option {
  value: number
  label: string
}
const useTutorialsOptions = (hasPermissionToViewTutorials: boolean) => {
  const [tutorials, setTutorials] = useState<Option[]>([])

  useEffect(() => {
    if (hasPermissionToViewTutorials) {
      getTutorials({ page: 1, limit: 100 }).then(r => {
        setTutorials(r.tutorials.map(f => ({ value: f.id, label: f.title })))
      })
    }
  }, [])

  return tutorials
}

interface SupportCategoryProps {
  isOpen: ModalProps['isOpen']
  onClose: (submited?: boolean) => void
  initialValues: ResourceCategoryModalValues | null
}

const FaqCategoriesModal = (props: SupportCategoryProps) => {
  const { isOpen, onClose, initialValues } = props
  const hasPermissionToViewTutorials = usePermissions(
    SupportPermissions.VIEW_TUTORIALS_LISTING,
  )
  const hasPermissionToEditTutorial = usePermissions(
    SupportPermissions.EDIT_TUTORIAL,
  )
  const tutorials = useTutorialsOptions(hasPermissionToViewTutorials)
  const isEditMode = useMemo(
    () => initialValues?.id && initialValues?.id > 0,
    [props.initialValues],
  )

  const onSubmit = useCallback(
    async (
      values: ResourceCategoryModalValues,
      form: FormikHelpers<ResourceCategoryModalValues>,
    ) => {
      form.setSubmitting(true)
      try {
        const formData = new FormData()
        formData.append('name', values.name)

        const resourceIds = _.concat(values.faq, values.tutorials)

        if (_.size(resourceIds)) {
          _.concat(values.faq, values.tutorials).forEach(v => {
            formData.append('resourceIds[]', String(v))
          })
        } else {
          formData.append('resourceIds[]', '-1')
        }

        if (values.icon && values.icon instanceof Blob) {
          formData.append('icon', values.icon)
        }

        isEditMode
          ? await putSupportCategory(values.id, formData)
          : await postSupportCategory(formData)

        toast(
          isEditMode ? 'Successfully edited' : 'Successfully edited',
          successToastOptions,
        )

        onClose(true)
      } catch (e: any) {
        toast(e.response.data.message, errorToastOptions)
      }

      form.setSubmitting(false)
    },
    [isEditMode],
  )

  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: -1,
      name: '',
      faq: [],
      tutorials: [],
      icon: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      icon: Yup.mixed().nullable().required('icon is required'),
    }),
    onSubmit,
  })

  return (
    <Modal isOpen={isOpen} toggle={() => onClose(false)} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>
            {isEditMode ? 'Edit Category' : 'Add Category'}
          </h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={() => onClose(false)}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <Col lg={12}>
              <div>
                <label htmlFor='name' className='form-label'>
                  Category
                </label>
                <Input
                  name='name'
                  className='form-control'
                  id='name'
                  placeholder='Enter category'
                  type='text'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.name || ''}
                  invalid={!!(form.touched.name && form.errors.name)}
                />
                {form.touched.name && form.errors.name ? (
                  <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <div>
              {!form.values.icon && (
                <div className='cursor-pointer'>
                  <Dropzone
                    maxFiles={1}
                    disabled={form.isSubmitting}
                    multiple={false}
                    accept={{ image: ['.png', '.jpg'] }}
                    onDrop={acceptedFiles => {
                      handleAcceptedFile(
                        acceptedFiles,
                        'icon',
                        form.setFieldValue,
                      )
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone dz-clickable'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}
                        >
                          <div className='mt-2'>
                            <i className='display-7 text-muted ri-upload-cloud-2-fill' />
                          </div>
                          <p className='m-0'>
                            Drop file here or click to upload.<br></br>
                            (.png, .jpg)
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>{' '}
                </div>
              )}
              {form.errors.icon ? (
                <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                  {(form.errors.icon as string) || ''}
                </p>
              ) : null}
              {form.values.icon && (
                <ul className='list-unstyled mb-0' id='dropzone-preview'>
                  <Card
                    className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                    key={'-file'}
                  >
                    <div className='p-2'>
                      <Row className='align-items-center'>
                        <Col className='col-auto'>
                          <img
                            data-dz-thumbnail=''
                            height='80'
                            className='avatar-sm rounded bg-light'
                            alt={
                              'name' in form.values.icon
                                ? form.values.icon.name
                                : ''
                            }
                            src={
                              'preview' in form.values.icon
                                ? form.values.icon.preview
                                : ''
                            }
                          />
                        </Col>

                        <Col>
                          <Link to='#' className='text-muted font-weight-bold'>
                            {'name' in form.values.icon
                              ? form.values.icon.name
                              : ''}
                          </Link>
                          <p className='mb-0'>
                            <strong>{form.values.icon.size} KB</strong>
                          </p>
                        </Col>
                        <Col className='d-flex justify-content-end align-items-end'>
                          <Button
                            disabled={form.isSubmitting}
                            color='danger'
                            onClick={() => {
                              form.setFieldValue('icon', null)
                            }}
                          >
                            {' '}
                            Delete{' '}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </ul>
              )}
            </div>
            {hasPermissionToViewTutorials && (
              <Col>
                <div>
                  <label htmlFor='tutorials' className='form-label'>
                    Tutorials
                  </label>
                  <Select
                    name='tutorials'
                    id='tutorials'
                    isMulti
                    options={tutorials}
                    isSearchable={true}
                    placeholder='Choose tutorials'
                    onChange={option => {
                      form.setFieldValue('tutorials', _.map(option, 'value'))
                    }}
                    onBlur={form.handleBlur}
                    value={tutorials.filter((t: Option) =>
                      form.values.tutorials.includes(t.value),
                    )}
                    isDisabled={!hasPermissionToEditTutorial}
                    className='select2-container'
                    classNamePrefix='select2-selection form-select'
                  />
                </div>
              </Col>
            )}
            <Col lg={12}>
              <div className='hstack gap-2 justify-content-end'>
                <Button
                  color='light'
                  disabled={form.isSubmitting}
                  onClick={() => onClose(false)}
                >
                  Close
                </Button>
                <Button
                  color='primary'
                  onClick={() => form.handleSubmit()}
                  disabled={!form.dirty || !form.isValid || form.isSubmitting}
                >
                  {form.isSubmitting ? <Spinner size={'sm'} /> : 'Submit'}
                </Button>
              </div>
            </Col>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default FaqCategoriesModal
