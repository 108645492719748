//SCHEDULED CATEGORIES
import {
  CreateScheduledCategoryDTO,
  GetScheduledCategoriesDTO,
  TScheduledCourseCategory,
} from '../../sharedTypes'
import {
  DELETE_SCHEDULED_CATEGORY,
  GET_SCHEDULED_CATEGORIES,
  GET_SCHEDULED_CATEGORIES_USED,
  POST_SCHEDULED_CATEGORY,
} from '../url_helper'
import { API } from '../api_helper'

export const getScheduledCategories = (
  params: GetScheduledCategoriesDTO.Request,
) => {
  return API.get<GetScheduledCategoriesDTO.Response>(GET_SCHEDULED_CATEGORIES, {
    params,
  }).then(d => d.data)
}

export const getScheduledCategory = (id: number) => {
  return API.get<any>(`${GET_SCHEDULED_CATEGORIES}/${id}`).then(d => d.data)
}

export const getUsedScheduledCategory = () => {
  return API.get<TScheduledCourseCategory[]>(
    GET_SCHEDULED_CATEGORIES_USED,
  ).then(d => d.data)
}

export const postScheduledCategory = (
  data: CreateScheduledCategoryDTO.Request,
) => {
  return API.post<CreateScheduledCategoryDTO.Response>(
    POST_SCHEDULED_CATEGORY,
    data,
  )
}

export const putScheduledCategory = (
  id: number,
  data: CreateScheduledCategoryDTO.Request,
) => {
  return API.put<CreateScheduledCategoryDTO.Response>(
    POST_SCHEDULED_CATEGORY + `/${id}`,
    data,
  )
}

export const deleteScheduledCategory = (id: number) => {
  return API.delete(DELETE_SCHEDULED_CATEGORY + `/${id}`)
}
