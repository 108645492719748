import BreadCrumb from '../../../Components/Common/BreadCrumb'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { COURSE_FILTER_FORMATS, OrderType } from '../../../sharedTypes'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { handleError } from '../../../helpers/toast_helper'
import Highlighter from 'react-highlight-words'
import { Pagination } from '../../../Components/Common/Pagination'
import _ from 'lodash'
import {
  CourseReportSortBy,
  GetCourseReportDTO,
} from '../../../sharedTypes/api/courseReport'
import { getCourseReport } from '../../../helpers/api/courses'
import { EXPORT_TYPES } from '../CourseHistory/types'
import { BadgeColorByAllFormats } from '../../../helpers/course'
import Select from 'react-select'
import Filters from './Filters'
import ExportCourseReport from './ExportCourseReport'
import { Link } from 'react-router-dom'
import CourseDetailInfo from '../../../Components/Course/CoursesTable/CourseDetailInfo'
import moment from 'moment/moment'
import ItemsPerPageDropdown from '../../../Components/Common/ItemsPerPageDropdown'

const Columns = [
  {
    name: 'Code',
    sortBy: CourseReportSortBy.CODE,
  },
  {
    name: 'Name',
    sortBy: CourseReportSortBy.NAME,
  },
  {
    name: 'Type',
    sortBy: CourseReportSortBy.TYPE,
  },
  {
    name: 'Format',
    sortBy: CourseReportSortBy.FORMAT,
  },
  {
    name: 'Number of \n Completions',
    sortBy: CourseReportSortBy.NUMBER_OF_COMPLETIONS,
    style: { width: 70 },
  },
  {
    name: 'Avg. Test Score \n (1st attempt)',
    sortBy: CourseReportSortBy.AVG_TEST_SCORE_FIRST_ATTEMPT,
    style: { width: 140, minWidth: 140 },
  },
  {
    name: 'Avg. Days Taken to \n Complete the Course',
    sortBy: CourseReportSortBy.AVG_DAYS_TO_COMPLETE,
    style: { width: 180, minWidth: 180 },
  },
  {
    name: 'Retake \n Test',
    sortBy: CourseReportSortBy.RETAKE_TEST,
    style: { width: 70 },
  },
  {
    name: 'Retake \n Course',
    sortBy: CourseReportSortBy.RETAKE_COURSE,
    style: { width: 70 },
  },
  {
    name: 'Avg. User \n Rating',
    sortBy: CourseReportSortBy.AVG_USER_RATING,
    style: { width: 110, minWidth: 110 },
  },
  {
    name: 'Feedback',
    style: { width: 110 },
  },
]

export enum REPORT_TYPE_FILTERS {
  THIS_MONTH = 'thisMonth',
  PAST_MONTH = 'pastMonth',
  PAST_YEAR = 'pastYear',
}

export const reportFilters = [
  {
    label: 'This Month',
    value: REPORT_TYPE_FILTERS.THIS_MONTH,
  },
  {
    label: 'Past Month',
    value: REPORT_TYPE_FILTERS.PAST_MONTH,
  },
  {
    label: 'Past Year',
    value: REPORT_TYPE_FILTERS.PAST_YEAR,
  },
]

const CourseReport = () => {
  document.title = 'Course Report | Mastered - Admin & Dashboard'

  const [showFilters, setShowFilters] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const [exportType, setExportType] = useState<EXPORT_TYPES | null>(null)

  const [query, setQuery] = useState<GetCourseReportDTO.Request>({
    page: 1,
    limit: 10,
    type: REPORT_TYPE_FILTERS.THIS_MONTH,
    sortBy: CourseReportSortBy.CODE,
    orderBy: OrderType.ASC,
  })

  const [data, setData] = useState<GetCourseReportDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    courseReport: [],
  })

  const tooltipRefs = useRef<{ [key: string]: HTMLElement | null }>({})

  useEffect(() => {
    setIsLoading(true)

    getCourseReport(query)
      .then(res => {
        setData(res)
      })
      .catch(e => {
        handleError(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const handleSort = useCallback((column: CourseReportSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const getCourseRedirectionLink = (
    id: number,
    format: COURSE_FILTER_FORMATS,
  ) => {
    if (format === COURSE_FILTER_FORMATS.DIGITAL) {
      return `/courses/digital/${id}`
    }

    if (
      [COURSE_FILTER_FORMATS.WEBINAR, COURSE_FILTER_FORMATS.IN_PERSON].includes(
        format,
      )
    ) {
      return `/courses/live/${id}`
    }

    return `/courses/competency/${id}`
  }

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'Course Report'}
            items={[
              {
                active: true,
                title: 'Course Reports',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <div className='d-flex align-items-center gap-3'>
                      <SearchInput
                        style={{ maxWidth: 400 }}
                        onChange={key => {
                          setQuery(prev => ({ ...prev, key, page: 1 }))
                        }}
                        value={query.key}
                      />
                      <div className='text-muted fs-14'>
                        Updated{' '}
                        {moment().subtract(1, 'day').format('MM/DD/YYYY')}
                      </div>
                    </div>
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Select
                        isSearchable={false}
                        onChange={option => {
                          if (option) {
                            setQuery(prev => ({ ...prev, type: option.value }))
                          }
                        }}
                        value={reportFilters.find(
                          filter => filter.value === query.type,
                        )}
                        options={reportFilters}
                        className='select2-container course-report-type'
                        classNamePrefix='select2-selection form-select'
                      />
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      <Dropdown
                        isOpen={exportDropdownOpen}
                        toggle={() => {
                          setExportDropdownOpen(prevState => !prevState)
                        }}
                      >
                        <DropdownToggle
                          className=' btn btn-secondary align-middle'
                          tag='button'
                        >
                          <i className='ri-file-list-3-line me-1 fs-16'></i>
                          Generate Report
                        </DropdownToggle>
                        <DropdownMenu end>
                          {Object.values(EXPORT_TYPES).map(type => (
                            <DropdownItem key={type}>
                              <span
                                className={`mx-2 ${
                                  type === EXPORT_TYPES.PDF
                                    ? 'text-green-500'
                                    : ''
                                }`}
                                onClick={() => setExportType(type)}
                              >
                                Export as {type}
                              </span>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>

                  <Row
                    className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}
                  >
                    <Filters
                      showFilters={showFilters}
                      setFilters={setQuery}
                      query={query}
                    />
                  </Row>
                </CardHeader>

                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-auto'>
                      <Table
                        className='align-middle mb-0'
                        id='course-report-section'
                      >
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {Columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex align-items-center justify-content-center'>
                                    <span className='header-name'>
                                      {column.name}
                                    </span>
                                    {!!column.sortBy && (
                                      <ColumnSortIcon<CourseReportSortBy>
                                        sortOrder={query.orderBy}
                                        sortedColumn={query.sortBy}
                                        column={column.sortBy}
                                        handleSort={handleSort}
                                      />
                                    )}{' '}
                                  </div>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.courseReport.map((report, i) => (
                            <tr key={i} className='fs-14'>
                              <td>
                                <Link
                                  className='cursor-pointer'
                                  to={getCourseRedirectionLink(
                                    report.course.id,
                                    report.courseFormat,
                                  )}
                                  target='_blank'
                                >
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    className='text-primary cursor-pointer'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={report.code}
                                  />
                                </Link>
                              </td>
                              <td>
                                <div
                                  className='text-truncate'
                                  style={{ maxWidth: 280 }}
                                  id={`course-info-${report.course.id}`}
                                  ref={ref =>
                                    (tooltipRefs.current[
                                      `course-${report.course.id}`
                                    ] = ref)
                                  }
                                >
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    className='fw-semibold'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={_.get(
                                      report.course,
                                      'translations[0].content.name',
                                      '-',
                                    )}
                                  />
                                </div>
                                <div>
                                  <UncontrolledTooltip
                                    className='course-details--tooltip'
                                    placement='right'
                                    target={`course-info-${report.course.id}`}
                                  >
                                    <CourseDetailInfo
                                      course={report.course}
                                      index={i}
                                      tooltipRefs={tooltipRefs}
                                    />
                                  </UncontrolledTooltip>
                                </div>
                              </td>
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  className='fw-semibold'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={
                                    report.course.type.length > 1
                                      ? report.course.type.join(', ')
                                      : report.course.type[0]
                                  }
                                />
                              </td>
                              <td>
                                <span
                                  className={`badge badge-${
                                    BadgeColorByAllFormats[report.courseFormat]
                                  } fs-12 fw-normal text-capitalize`}
                                >
                                  {report.courseFormat}
                                </span>
                              </td>
                              <td>
                                <span className='badge badge-soft-grey fs-12 fw-medium'>
                                  {report.completions}
                                </span>
                              </td>
                              <td>
                                <span className='badge badge-soft-grey fs-12 fw-medium'>
                                  {report.avgScoreFirstAttempt + '%'}
                                </span>
                              </td>
                              <td>
                                <span className='badge badge-soft-grey fs-12 fw-medium'>
                                  {report.avgDaysToComplete}
                                </span>
                              </td>
                              <td className='fw-semibold'>
                                {report.retakeTest + '%'}
                              </td>
                              <td>
                                <span className='badge badge-soft-grey fs-12 fw-medium'>
                                  {report.retakeCourse
                                    ? report.retakeCourse + '%'
                                    : '-'}
                                </span>
                              </td>
                              <td>
                                <span className='badge badge-soft-grey fs-12 fw-medium'>
                                  {report.avgUserRating}
                                </span>
                              </td>
                              <td>
                                <Link
                                  to={`/courses/report/${report.id}`}
                                  target='_blank'
                                >
                                  <i className='bx bx-chevron-right rounded text-light-purple p-1 cursor-pointer'></i>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className='mx-3 my-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ? query.limit : 10}
                        onChange={limit =>
                          setQuery(prev => ({ ...prev, limit: +limit.label }))
                        }
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                        limit={query.limit}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>

        {exportType && (
          <ExportCourseReport
            exportType={exportType}
            setExportType={setExportType}
            count={data.count}
            query={query}
          />
        )}
      </div>
    </Fragment>
  )
}

export default CourseReport
