import React, { useEffect, useMemo } from 'react'
import SimpleBar from 'simplebar-react'

//Import Components
import VerticalLayout from './VerticalLayouts/index'
import { Button, Container } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { switchBackToMasteredPortal } from '../helpers/company'
import { handleError } from '../helpers/toast_helper'
import { CREDENTIALS_TYPE } from '../sharedTypes'

const Sidebar = () => {
  const { user, switchedToPortal } = useAppSelector(state => ({
    user: state.User.user,
    switchedToPortal: state.User.switchedToPortal,
  }))

  useEffect(() => {
    const verticalOverlay = document.getElementsByClassName('vertical-overlay')
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener('click', function () {
        document.body.classList.remove('vertical-sidebar-enable')
      })
    }
  })

  const email = useMemo(
    () =>
      user?.credentials?.find(i => i.credentialType === CREDENTIALS_TYPE.EMAIL)
        ?.credential || '',
    [user],
  )

  const dispatch = useAppDispatch()

  const onSwitchBack = () => {
    switchBackToMasteredPortal(email, dispatch).catch(handleError)
  }

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover'
    ) {
      document.documentElement.setAttribute(
        'data-sidebar-size',
        'sm-hover-active',
      )
    } else if (
      document.documentElement.getAttribute('data-sidebar-size') ===
      'sm-hover-active'
    ) {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover')
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover')
    }
  }
  return (
    <React.Fragment>
      <div className='app-menu navbar-menu'>
        <div className='navbar-brand-box'>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type='button'
            className='btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover'
            id='vertical-hover'
          >
            <i className='ri-record-circle-line'></i>
          </button>
        </div>
        <React.Fragment>
          <SimpleBar id='scrollbar' className='h-100'>
            <Container fluid>
              <div id='two-column-menu'></div>
              <div
                className='d-flex flex-column justify-content-between'
                style={{ height: '87vh' }}
              >
                <ul className='navbar-nav' id='navbar-nav'>
                  <VerticalLayout />
                </ul>

                {switchedToPortal && (
                  <Button color='primary px-2 mx-3' onClick={onSwitchBack}>
                    <i className='ri-arrow-left-line label-icon align-middle me-2'></i>
                    Back to Mastered Portal
                  </Button>
                )}
              </div>
            </Container>
          </SimpleBar>
          <div className='sidebar-background'></div>
        </React.Fragment>
      </div>
      <div className='vertical-overlay'></div>
    </React.Fragment>
  )
}

export default Sidebar
