import { PUSH_NOTIFICATIONS } from '../url_helper'
import { API } from '../api_helper'
import {
  CreatePushNotificationDTO,
  GetPushNotificationsDTO,
} from '../../sharedTypes/api/pushNotifications'
import {
  TPushNotification,
  TPushNotificationData,
} from '../../sharedTypes/models/pushNotifications'

export const getPushNotifications = (
  params: GetPushNotificationsDTO.Request,
) => {
  return API.get<GetPushNotificationsDTO.Response>(PUSH_NOTIFICATIONS, {
    params,
  })
}

export const getPushNotificationById = (id: number) => {
  return API.get<TPushNotificationData>(`${PUSH_NOTIFICATIONS}/${id}`)
}

export const postPushNotification = (
  body: CreatePushNotificationDTO.Request,
) => {
  return API.post<CreatePushNotificationDTO.Response>(
    PUSH_NOTIFICATIONS,
    body,
  ).then(d => d.data)
}

export const patchPushNotification = (
  id: number,
  data: Partial<CreatePushNotificationDTO.Request>,
) => {
  return API.patch<CreatePushNotificationDTO.Response>(
    `${PUSH_NOTIFICATIONS}/${id}`,
    data,
  )
}

export const duplicatePushNotification = (id: number) => {
  return API.post<TPushNotification>(
    `${PUSH_NOTIFICATIONS}/duplicate/${id}`,
  ).then(d => d.data)
}

export const sendPushNotification = (id: number) => {
  return API.post<TPushNotification>(`${PUSH_NOTIFICATIONS}/send/${id}`).then(
    d => d.data,
  )
}

export const deletePushNotification = (id: number) => {
  return API.delete(`${PUSH_NOTIFICATIONS}/${id}`)
}
