import { createSlice } from '@reduxjs/toolkit'
import { TLanguage } from '../../sharedTypes'
import { find, get } from 'lodash'

export const initialState: LanguagesState = {
  list: [],
  defaultLanguageId: 1,
}

const reducerSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setLanguages(state, { payload }: { payload: TLanguage[] }) {
      state.list = payload
      state.defaultLanguageId = get(find(payload, { code: 'en' }), 'id', 1)
    },
  },
})

export const { setLanguages } = reducerSlice.actions

export type LanguagesState = {
  list: TLanguage[]
  defaultLanguageId: number
}

export default reducerSlice.reducer
