import React, { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Form,
  Col,
  Card,
  Row,
  Input,
  Label,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import Dropzone from 'react-dropzone'

import { uploadWrittenTest } from '../../../helpers/api_helper'
import formatBytes from '../../../utils/formatBytes'

import { successToastOptions, handleError } from '../../../helpers/toast_helper'
import { UploadWrittenTestDTO } from '../../../sharedTypes'

interface FileForm {
  file?: File
  score?: number
  attestationAccepted: boolean
}

interface UploadWrittenTestProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (res: UploadWrittenTestDTO.Response) => void
  assignmentId: number
}

const maxFileSize = 30 * 1024 * 1024

const UploadWrittenTest = ({
  onClose,
  onSubmit,
  isOpen,
  assignmentId,
}: UploadWrittenTestProps) => {
  const form = useFormik<FileForm>({
    enableReinitialize: true,
    initialValues: {
      file: undefined,
      score: undefined,
      attestationAccepted: false,
    },
    validationSchema: Yup.object({
      file: Yup.mixed()
        .nullable()
        .required('Please upload test file')
        .test(
          'valid-size',
          'Max allowed size is 30MB',
          value => value?.size <= maxFileSize,
        ),
      score: Yup.number()
        .integer('Test score must an integer number')
        .typeError('Test score must be a number')
        .required('Please enter test score')
        .min(1, 'Test score must be at least 1')
        .max(100, 'Test score must not exceed 100'),
    }),
    onSubmit: values => {
      if (values.file) {
        form.setSubmitting(true)
        const data = new FormData()
        data.append('file', values.file)
        data.append('score', (values.score as number).toString())
        data.append(
          'attestationAccepted',
          values.attestationAccepted.toString(),
        )
        return uploadWrittenTest(assignmentId, data)
          .then(res => {
            toast(
              'Success - Written test uploaded successfully',
              successToastOptions,
            )
            onSubmit(res)
          })
          .catch(handleError)
          .finally(() => {
            form.setSubmitting(false)
          })
      }
    },
  })

  useEffect(() => {
    if (!isOpen) {
      form.resetForm()
    }
  }, [isOpen])

  const handleAcceptedFiles = (file: File) => {
    form.setFieldValue('file', file)
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Upload Written Test</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div>
            <label htmlFor='score' className='form-label'>
              Test Score*
            </label>
            <Input
              name='score'
              className='form-control'
              id='score'
              disabled={form.isSubmitting}
              placeholder=''
              type='text'
              isDisabled
              invalid={!!(form.touched.score && form.errors.score)}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.score || ''}
            />
          </div>
          {form.touched.score && form.errors.score ? (
            <div className='invalid-feedback d-block'>{form.errors.score}</div>
          ) : null}
          <div className='mt-3'>
            <label htmlFor='testFile' className='form-label'>
              Test File
            </label>
            {!form.values.file ? (
              <>
                <Dropzone
                  maxFiles={1}
                  disabled={form.isSubmitting}
                  multiple={false}
                  accept={{
                    'image/jpeg': [],
                    'image/jpg': [],
                    'image/png': [],
                    'application/pdf': [],
                  }}
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles[0])
                  }}
                  maxSize={maxFileSize}
                >
                  {({ getRootProps }) => (
                    <div className='dropzone dz-clickable cursor-pointer'>
                      <div
                        className='dz-message needsclick'
                        {...getRootProps()}
                      >
                        <div className='mt-2'>
                          <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                        </div>
                        <p className='m-0 text-muted fw-light fs-14'>
                          Drop file here or click to upload<br></br>
                          (pdf, jpg, png)
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {form.touched.file && form.errors.file ? (
                  <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                    {form.errors.file}
                  </p>
                ) : null}
              </>
            ) : (
              <ul className='list-unstyled mb-0' id='dropzone-preview'>
                <Card
                  className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                  key={'-file'}
                >
                  <div className='p-2'>
                    <Row className='align-items-center'>
                      <Col className='col-auto'>
                        {form.values.file.type.includes('image/') ? (
                          <img
                            data-dz-thumbnail=''
                            height='80'
                            className='avatar-sm rounded bg-light'
                            alt={form.values.file.name}
                            src={URL.createObjectURL(form.values.file)}
                          />
                        ) : (
                          <div className='avatar-sm flex-shrink-0'>
                            <span className='avatar-title rounded fs-3 image-container'>
                              <i className='ri-file-list-3-line'></i>
                            </span>
                          </div>
                        )}
                      </Col>
                      <Col>
                        <Link to='#' className='text-muted font-weight-bold'>
                          {form.values.file.name}
                        </Link>
                        <p className='mb-0'>
                          {formatBytes(form.values.file.size)}
                        </p>
                      </Col>
                      <Col className='d-flex justify-content-end align-items-end'>
                        <Button
                          disabled={form.isSubmitting}
                          color='danger'
                          onClick={() => {
                            form.setFieldValue('file', undefined)
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </ul>
            )}
          </div>
          {form.values.file && (
            <div className='form-check mt-3'>
              <Input
                className='form-check-input'
                type='checkbox'
                name='attestationAccepted'
                id='attestationAccepted'
                checked={form.values.attestationAccepted}
                onChange={form.handleChange}
              />
              <Label className='form-check-label' htmlFor='attestationAccepted'>
                User accepted attestation
              </Label>
            </div>
          )}
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={onClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={!(form.isValid && form.dirty) || form.isSubmitting}
            >
              {form.isSubmitting ? <Spinner size={'sm'} /> : 'Upload'}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default UploadWrittenTest
