import {
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import React, { useState } from 'react'
import {
  SurveyStatusTypes,
  TLanguage,
  MultiChoiceAnswerItem,
  Question,
  CommunicationPermissions,
  QuestionTypes,
} from '../../sharedTypes'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks/redux'
import { usePermissions } from '../../hooks/usePermissions'

const SurveyQuestionsBasePreview = ({
  languages,
  questions = [],
  status,
  id,
}: {
  id?: number
  questions?: Question[]
  languages: TLanguage[]
  status?: SurveyStatusTypes
}) => {
  const { defaultLanguageId } = useAppSelector(state => state.Languages)
  const [activeTab, setActiveTab] = useState(defaultLanguageId)
  const navigate = useNavigate()

  const hasPermissionToEdit = usePermissions(
    CommunicationPermissions.EDIT_SURVEY,
  )

  const tabChange = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <>
      <Nav
        className='nav nav-tabs nav-tabs-custom rounded card-header-tabs mt-3 px-3'
        role='tablist'
      >
        {languages.map((language, index) => (
          <NavItem key={index}>
            <NavLink
              to='#'
              className={`${classnames({
                active: activeTab === language.id,
              })} cursor-pointer`}
              onClick={() => {
                tabChange(language.id)
              }}
            >
              {language.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab} className='px-3'>
        {languages.map((language, langIndex: number) => (
          <TabPane tabId={language.id} key={langIndex}>
            <div className='vstack gap-4 mt-3'>
              {questions
                .filter(q => q.languageId === language.id)
                .map((question, qIndex: number) => (
                  <div key={qIndex} className='pb-3 nav-tabs-custom mt-3'>
                    <div>
                      <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-muted fs-14'>
                          Question # {qIndex + 1}
                        </span>
                        <div>
                          {status &&
                          [
                            SurveyStatusTypes.DRAFT,
                            SurveyStatusTypes.SCHEDULED,
                          ].includes(status) &&
                          hasPermissionToEdit &&
                          !qIndex ? (
                            <span
                              className='badge text-capitalize text-light-purple fs-12 py-2 cursor-pointer'
                              onClick={() => navigate(`/surveys/manage/${id}`)}
                            >
                              <i className='ri-edit-box-line mx-1'></i>Edit
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className='hstack'>
                        <span className='fw-medium fs-16'>
                          {question.question || '-'}
                        </span>
                      </div>
                      {question.type === QuestionTypes.MULTIPLE_CHOICE &&
                        (question.answers as MultiChoiceAnswerItem[]).map(
                          (a, i: number) => (
                            <div key={i}>
                              {a.answer ? (
                                <div className='form-check my-2 fs-14'>
                                  <Input
                                    className='form-check-input'
                                    type='radio'
                                    name='certificateRequired'
                                    checked={false}
                                    disabled={true}
                                    id={`answer-${i}`}
                                  />
                                  <Label
                                    className='form-check-label fw-medium'
                                    htmlFor={`answer-${i}`}
                                  >
                                    {a.answer}
                                  </Label>
                                </div>
                              ) : null}
                            </div>
                          ),
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </TabPane>
        ))}
      </TabContent>
    </>
  )
}

export default SurveyQuestionsBasePreview
