import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { TScheduledCourse } from '../../../sharedTypes'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { getScheduledCourseById } from '../../../helpers/api_helper'

import axios from 'axios'
import EventInfo from './EventInfo'
import EventUsers from './EventUsers'

interface ManageCourseProps extends WithRouterProps {
  fromCalendar?: boolean
}

const ManageCourse = ({ router }: ManageCourseProps) => {
  document.title = 'Manage Course | Mastered - Admin & Dashboard'

  const [course, setCourse] = useState<TScheduledCourse | null>(null)

  useEffect(() => {
    if (
      !router.params.eventId ||
      Number.isNaN(+router.params.eventId) ||
      typeof +router.params.eventId !== 'number'
    ) {
      return router.navigate('/404')
    }

    getScheduledCourseById(parseInt(router.params.eventId))
      .then(res => {
        setCourse(res.data)
      })
      .catch(error => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          router.navigate('/404')
        }
      })
  }, [router.params.userId])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Manage Course'
            items={[
              {
                linkTo: '/',
                title: 'Courses',
              },
              {
                linkTo: '/courses/scheduled-courses',
                title: 'Scheduled Courses',
              },
              {
                active: true,
                title: 'Manage',
              },
            ]}
          />
          {course && (
            <Row>
              <EventInfo course={course} />
              <EventUsers course={course} />
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ManageCourse)
