import { useEffect, useRef } from 'react'

export const usePrevious = (servings: any) => {
  const ref = useRef(servings)

  useEffect(() => {
    ref.current = servings
  }, [servings])

  return ref.current
}
