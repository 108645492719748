import React, { useCallback, useState } from 'react'
import { OnlineCourseFormatsEnum } from '../../sharedTypes'
import { Col, Row, Button } from 'reactstrap'
import CourseActionModal from '../Modals/CourseActionModal'

interface IActionListItem {
  action: string
  modalTitle: string
  icon?: string
  modalMessage: string
  format: OnlineCourseFormatsEnum
  exludeWhenCompetency: boolean
  disabled?: boolean
}

const actionList: IActionListItem[] = [
  {
    action: 'Add Knowledge Check',
    modalTitle: 'Add Knowledge Check',
    modalMessage:
      'This option allows you to set a pre-course mini-test that is not graded',
    format: OnlineCourseFormatsEnum.KNOWLEDGE_CHECK,
    exludeWhenCompetency: false,
    disabled: true,
  },
  {
    action: 'Add Digital Course',
    modalTitle: 'Add Digital Course',
    modalMessage:
      'This option allows you to set the parameters of the course with digital materials',
    format: OnlineCourseFormatsEnum.DIGITAL,
    exludeWhenCompetency: false,
    icon: 'ri-film-line',
  },
  {
    action: 'Add Live Course',
    format: OnlineCourseFormatsEnum.LIVE,
    modalTitle: 'Add Live Course',
    modalMessage:
      'This option allows you to set the parameters of the course with live broadcasting',
    exludeWhenCompetency: false,
    icon: 'ri-play-line',
  },
]

export const GeneralAddFormat = ({
  formats,
  onAdd,
}: {
  formats: OnlineCourseFormatsEnum[]
  onAdd: (format: OnlineCourseFormatsEnum) => void
}) => {
  const [selectedAction, setSelectedAction] = useState<
    IActionListItem | null | undefined
  >(null)

  const onBeforeAdd = useCallback((format: OnlineCourseFormatsEnum) => {
    setSelectedAction(actionList.find(a => a.format === format))
  }, [])

  const onSubmit = useCallback(() => {
    if (selectedAction) {
      onAdd(selectedAction.format)
      setSelectedAction(null)
    }
  }, [selectedAction])

  return (
    <>
      <Row>
        <Col sm='7'></Col>
        <Col sm='5' className='d-flex justify-content-end gap-3'>
          {actionList
            .filter(
              b =>
                !b.disabled &&
                !(
                  b?.exludeWhenCompetency &&
                  formats.includes(OnlineCourseFormatsEnum.COMPETENCY)
                ) &&
                !formats.includes(b.format),
            )
            .map(b => (
              <Button
                key={b.format}
                color='ghost-primary'
                className='text-light-purple'
                onClick={() => {
                  onBeforeAdd(b.format)
                }}
              >
                <i className='ri-add-line me-1 fs-16'></i>
                {b.action}
              </Button>
            ))}
        </Col>
      </Row>
      {selectedAction && (
        <CourseActionModal
          confirmText='Add'
          icon={selectedAction.icon || 'ri-film-line'}
          title={selectedAction.modalTitle}
          message={selectedAction.modalMessage}
          isOpen={!!selectedAction}
          onSubmit={onSubmit}
          onClose={() => {
            setSelectedAction(null)
          }}
        />
      )}
    </>
  )
}
