import { COURSE_TYPES } from '../lib/types'
import { TInstructor } from './instructor'
import { notificationOption } from '../forms'
import {
  COURSE_FILTER_FORMATS,
  CoursesTabs,
  OnlineCourseItem,
} from './onlineCourse'
import { TCoursePackage } from './coursePackage'

export enum SCHEDULED_COURSE_FORMATS {
  'IN_PERSON' = 'in-person',
  'WEBINAR' = 'webinar',
}

export enum SCHEDULED_COURSE_STATUSES {
  'PUBLISHED' = 'published',
  'DRAFT' = 'draft',
  'INACTIVE' = 'inactive',
}

export type TScheduledCourseCategory = {
  id: number
  name: string
  description: string
}

export type TScheduledCourse = {
  id: number
  categoryId: number
  category: TScheduledCourseCategory | null
  isDraft: boolean
  description: null | string
  format: SCHEDULED_COURSE_FORMATS
  link: null | string
  linkType: null
  location: null | string
  maximumAttendance: null | number
  name: string
  registrationDeadline: string | null
  relevantLinks: null | string[]
  startTime: string
  startDate: string
  endTime: string
  endDate: string
  type: COURSE_TYPES
  instructor: TInstructor | null
  createdAt: string
  updatedAt: string
  status: SCHEDULED_COURSE_STATUSES
  notificationsEnabled: boolean
  notifications: { value: number; type: notificationOption }[] | null
}

export interface SelectedCourse {
  [CoursesTabs.COURSES]: {
    course: OnlineCourseItem
    format?: COURSE_FILTER_FORMATS
  }[]
  [CoursesTabs.PACKAGES]: TCoursePackage[]
  [CoursesTabs.TRAINING_DAYS]: TCoursePackage[]
}
