import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { SingleValue } from 'react-select'
import {
  TCompany,
  CreateGroupDTO,
  CreateFacilityDTO,
  TFacility,
} from '../../../sharedTypes'
import { GroupSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import {
  postGroup,
  postFacility,
  patchFacility,
} from '../../../helpers/api_helper'
import {
  getCompanyGroupOptions,
  getParentOption,
  Option,
} from '../../../helpers/facility'
import { toast } from 'react-toastify'
import { successToastOptions, handleError } from '../../../helpers/toast_helper'
import { SignatureUpload } from '../../../Components/Common/SignatureUpload'
import handleAcceptedFile from '../../../utils/handleAcceptedFile'
import { TAttachment } from '../../../sharedTypes/models/attachments'

interface CreateNewFacilityModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  toggle: ModalProps['toggles']
  companies: TCompany[]
  onSubmit: () => void
  editFacility?: TFacility
  hasPermissionToAddGroup: boolean
}

interface IForm {
  name: string
  groupName?: string
  parent: Option
  signature: TAttachment | Blob | undefined
}

const CreateNewFacilityModal = ({
  onClose,
  toggle,
  isOpen,
  companies,
  onSubmit,
  editFacility,
  hasPermissionToAddGroup,
}: CreateNewFacilityModalProps) => {
  const [showAddNewGroup, setShowAddNewGroup] = useState(false)

  const groupOptions = useMemo(() => {
    return getCompanyGroupOptions(companies)
  }, [companies])

  const createFacility = (facilityData: CreateFacilityDTO.Request) => {
    const action = editFacility
      ? patchFacility(editFacility.id, facilityData)
      : postFacility(facilityData)

    return action.then(() => {
      editFacility
        ? toast('Success - Facility has been updated', successToastOptions)
        : toast('Success - New Facility has been created', successToastOptions)
      onSubmit()
    })
  }

  const _onSubmit = ({ name, parent, groupName, signature }: IForm) => {
    if (showAddNewGroup && groupName) {
      const data: CreateGroupDTO.Request = {
        name: groupName,
        companyId: parent.companyId,
      }
      const [type, id] = parent.value.split(':')
      if (type === 'group' && id) {
        data.parentGroupId = +id
      }

      return postGroup(data)
        .then(({ data }) => {
          const facilityData: CreateFacilityDTO.Request = {
            name,
            companyId: data.companyId,
            groupId: data.id,
            signature,
          }
          return createFacility(facilityData)
        })
        .catch(handleError)
        .finally(() => {
          form.setSubmitting(false)
        })
    }

    const facilityData: CreateFacilityDTO.Request = {
      name,
      companyId: parent.companyId,
      signature,
    }
    const [type, id] = parent.value.split(':')
    if (type === 'group' && id) {
      facilityData.groupId = +id
    } else if (editFacility) {
      facilityData.groupId = null
    }

    createFacility(facilityData)
      .catch(handleError)
      .finally(() => {
        form.setSubmitting(false)
      })
  }

  useEffect(() => {
    if (!isOpen) {
      form.resetForm()
      setShowAddNewGroup(false)
    }
  }, [isOpen])

  const form = useFormik<IForm>({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: editFacility?.name || '',
      parent: getParentOption(groupOptions, editFacility),
      groupName: undefined,
      signature: (editFacility as any)?.signature || undefined,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Facility Name'),
      parent: Yup.object().required('Please Select Group'),
      groupName: Yup.string().when([], {
        is: () => showAddNewGroup,
        then: Yup.string().required('Please Enter Group Name'),
        otherwise: Yup.string().notRequired(),
      }),
      signature: Yup.mixed()
        .nullable()
        .required('Required')
        .test(
          'valid-size',
          'Max allowed size is 1mb',
          value => value?.size <= 1000000,
        ),
    }),
    onSubmit: _onSubmit,
  })

  useEffect(() => {
    if (!showAddNewGroup) {
      form.setFieldValue('groupName', undefined)
    }
  }, [showAddNewGroup])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>
            {editFacility ? 'Edit Facility' : 'Add New Facility'}
          </h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={toggle}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='FacilityManagement#'
        >
          <div className='vstack gap-3'>
            <div>
              <label htmlFor='groupName' className='form-label'>
                Facility Name*
              </label>
              <Input
                name='name'
                className='form-control'
                id='groupName'
                placeholder='Enter facility name'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.name || ''}
                invalid={!!(form.touched.name && form.errors.name)}
              />
              {form.touched.name && form.errors.name ? (
                <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
              ) : null}
            </div>
            <div>
              <label htmlFor='parent' className='form-label'>
                {showAddNewGroup ? 'Parent Group*' : 'Group'}
              </label>
              <GroupSelectWithSearch<Option>
                name='parent'
                id='parent'
                onChange={option => {
                  const singleOption = option as SingleValue<Option>
                  form.setFieldValue('parent', singleOption)
                }}
                onBlur={form.handleBlur}
                value={form.values.parent}
                isMulti={false}
                isClearable={false}
                isSearchable={true}
                placeholder={''}
                options={groupOptions}
              />
            </div>
            {showAddNewGroup && (
              <div>
                <label htmlFor='groupName' className='form-label'>
                  Group
                </label>
                <div className='form-icon right'>
                  <Input
                    name='groupName'
                    className='form-control form-control-icon'
                    id='groupName'
                    placeholder='Enter group name'
                    type='text'
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.groupName || ''}
                    invalid={
                      !!(form.touched.groupName && form.errors.groupName)
                    }
                  />
                  <i
                    className='ri-delete-bin-2-line text-danger cursor-pointer fs-20'
                    onClick={() => setShowAddNewGroup(false)}
                  ></i>
                </div>
                {form.touched.groupName && form.errors.groupName ? (
                  <FormFeedback
                    type='invalid'
                    htmlFor='groupName'
                    className='d-block'
                  >
                    {form.errors.groupName}
                  </FormFeedback>
                ) : null}
              </div>
            )}
            {hasPermissionToAddGroup && (
              <div>
                <Button
                  color='soft-primary'
                  className='fs-12'
                  disabled={showAddNewGroup}
                  onClick={() => setShowAddNewGroup(true)}
                >
                  <i className='ri-add-line me-1'></i>Add New Group
                </Button>
              </div>
            )}
          </div>

          <div>
            <SignatureUpload
              error={form.errors.signature}
              signature={form.values.signature}
              isSubmitting={form.isSubmitting}
              onDrop={acceptedFiles => {
                handleAcceptedFile(
                  acceptedFiles,
                  'signature',
                  form.setFieldValue,
                )
              }}
              onDelete={() => {
                form.setFieldValue('signature', null)
              }}
            />
          </div>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={onClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={!(form.isValid && form.dirty) || form.isSubmitting}
            >
              Submit
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default CreateNewFacilityModal
