import React, {
  InputHTMLAttributes,
  ReactNode,
  FC,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'

export type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'style'
> & {
  suffix?: ReactNode
}

const inputPadding = 15 as const

export const InputWithSuffix: FC<InputProps> = ({
  value,
  placeholder,
  className,
  suffix,
  ...props
}) => {
  const suffixRef = useRef<HTMLSpanElement>(null)

  const [inputRightPadding, setInputRightPadding] = useState<number>(0)

  useLayoutEffect(() => {
    const suffixWidth = suffixRef.current?.offsetWidth
    setInputRightPadding(
      suffix && suffixWidth ? suffixWidth + inputPadding : inputPadding,
    )
  }, [suffix])

  return (
    <div className={clsx('position-relative d-flex flex-1', className)}>
      <input
        className='form-control'
        value={value}
        style={{
          paddingRight: inputRightPadding,
        }}
        placeholder={placeholder}
        {...props}
      />
      <div className='form-control suffix'>
        <span className='overflow-hidden'>
          {value?.toString().replace(/ /g, '\u00a0') || placeholder}
        </span>
        <span
          className='text-muted visible h-100 d-flex align-items-center'
          ref={suffixRef}
        >
          {suffix}
        </span>
      </div>
    </div>
  )
}
