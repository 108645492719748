import { Card, CardBody, Col, Row } from 'reactstrap'
import React from 'react'
import { AnalyticsData } from '../types'

const Statistics = ({ analytics }: { analytics: AnalyticsData[] }) => {
  return (
    <>
      <Row>
        {analytics.map((item, i) => (
          <Col md={4} key={i}>
            <Card className='card-animate'>
              <CardBody>
                <div className='d-flex justify-content-between'>
                  <div>
                    <p className='fw-medium text-muted mb-0'>{item.label}</p>
                    <h2 className='mt-4 ff-secondary'>
                      <span className='counter-value'>{item.value}</span>
                    </h2>
                  </div>
                  <div>
                    <div className='avatar-sm flex-shrink-0'>
                      <span className='avatar-title rounded-circle fs-2 text-light-purple'>
                        <i className={`${item.icon} fs-3`} />
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Statistics
