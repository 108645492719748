import React, { Fragment, useMemo } from 'react'
import { DateRangeData, SelectedFilters } from './types'
import moment from 'moment'
import masteredLogo from '../../../assets/images/logo.png'
import { Table } from 'reactstrap'
import {
  ASSIGNMENT_STATUS,
  COURSE_TYPES,
  TAssignment,
} from '../../../sharedTypes'
import { BadgeColorByStatus } from '../../../helpers/course_history'
import {
  getAssignmentStatus,
  getTestAttempts,
} from '../../../helpers/assignments'
import {
  getUserDisplayName,
  truncateTextToFitWidth,
} from '../../../helpers/user'

interface AssignmentsSelectedFilters extends SelectedFilters {
  completedDate?: Date[]
  courseStatus?: ASSIGNMENT_STATUS[]
  courseType?: COURSE_TYPES[]
}

interface CourseHistoryReportProps {
  dateRange: DateRangeData | undefined
  assignments: TAssignment[]
  page: number
  selectedFilters: AssignmentsSelectedFilters
  forSummary?: boolean
}

const Columns = [
  {
    name: 'User',
    style: { width: 300 },
  },
  {
    name: 'Department',
  },
  {
    name: 'Code',
  },
  {
    name: 'Course',
  },
]

const CourseHistoryReport = ({
  dateRange,
  assignments,
  page,
  selectedFilters,
  forSummary = false,
}: CourseHistoryReportProps) => {
  const filtersData = useMemo(() => {
    const data = [
      {
        label: 'Position',
        data: selectedFilters.positions,
        key: 'position',
        render: true,
      },
      {
        label: 'Course',
        data: selectedFilters.courses,
        key: 'course',
        render: true,
      },
      {
        label: 'Department',
        data: selectedFilters.departments,
        key: 'department',
        render: true,
      },
      {
        label: 'Date Completed',
        data: selectedFilters.completedDate || [],
        key: 'completedDate',
        render: true,
      },
      {
        label: 'Course Status',
        data: selectedFilters.courseStatus || [],
        key: 'courseStatus',
        render: !forSummary,
      },
      {
        label: 'Course Type',
        data: selectedFilters.courseType || [],
        key: 'courseType',
        render: forSummary,
      },
    ]

    return data.filter(d => d.render)
  }, [selectedFilters, forSummary])

  const columns = useMemo(() => {
    const changedColumns = [...Columns]

    if (forSummary) {
      return [
        ...changedColumns,
        {
          name: 'Course Type',
        },
        {
          name: 'Completion Date',
        },
      ]
    }

    return [
      ...changedColumns,
      {
        name: 'Progress',
      },
      {
        name: 'Completed',
        style: { width: 150 },
      },
      {
        name: 'Status',
      },
      {
        name: 'Days overdue',
        style: { width: 150 },
      },
      {
        name: 'Test attempts',
        style: { width: 150 },
      },
    ]
  }, [forSummary])

  const shouldRenderFilters = useMemo(() => {
    return (
      selectedFilters.positions.length ||
      selectedFilters.courses.length ||
      selectedFilters.departments.length ||
      (selectedFilters.courseType && selectedFilters.courseType.length > 1) ||
      (selectedFilters.completedDate &&
        selectedFilters.completedDate.length > 1) ||
      (selectedFilters.courseStatus &&
        selectedFilters.courseStatus.length &&
        !forSummary)
    )
  }, [selectedFilters, forSummary])

  return (
    <div id='course-history-report-pdf w-100'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-3 align-items-center'>
          <h1 className='text-body'>Course History Report</h1>
          <h2 className='text-muted fw-normal'>
            {dateRange?.startDate && dateRange?.endDate
              ? `${moment(dateRange.startDate).format('MM/DD/YYYY')} - ${moment(
                  dateRange.endDate,
                ).format('MM/DD/YYYY')}`
              : ''}
          </h2>
          <h2 className='text-body'>(Page {page})</h2>
        </div>
        <div>
          <img
            className='card-img-top img-fluid w-100'
            src={masteredLogo}
            alt='Mastered'
          />
        </div>
      </div>
      <div className='mt-2'>
        <h2>{(selectedFilters.facilities || ['All']).join(', ')}</h2>
      </div>
      {page === 1 && (
        <>
          {shouldRenderFilters ? (
            <div className='mt-3'>
              <h2 className='fw-normal text-muted'>Filters Selected</h2>
            </div>
          ) : (
            ''
          )}

          <div className='d-flex flex-wrap gap-2 mb-2 mt-1'>
            {filtersData.map(filterItem => (
              <Fragment key={filterItem.key}>
                {filterItem.data.length > 0 && (
                  <>
                    {filterItem.key === 'completedDate' &&
                    filterItem.data.length > 1 ? (
                      <>
                        <h3 className='fw-normal text-muted'>
                          Date Completed:
                        </h3>
                        <h3 className='text-body'>
                          {moment(filterItem.data[0]).format('MM/DD/YYYY')}
                        </h3>
                        {filterItem.data[1] && (
                          <h3 className='text-body'>
                            {' '}
                            - {moment(filterItem.data[1]).format('MM/DD/YYYY')}
                          </h3>
                        )}
                      </>
                    ) : (
                      <>
                        <h3 className='fw-normal text-muted'>
                          {filterItem.label}:
                        </h3>
                        <h3 className='text-body'>
                          {filterItem.data.join(', ')}
                        </h3>
                      </>
                    )}
                    <h3 className='text-muted filter-separator'>|</h3>
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}
      <div className='table-card mt-5 courses-table'>
        <Table className='align-middle mb-0' id='course-history-section'>
          <thead className='table-light'>
            <tr className='text-muted fs-3'>
              {columns.map(column => (
                <th
                  scope='col'
                  className='fw-light align-middle'
                  style={column.style}
                  key={column.name}
                >
                  {forSummary ? (
                    column.name
                  ) : (
                    <div className='d-flex align-items-center'>
                      <div className='d-flex align-items-center justify-content-center'>
                        <span className='header-name'>{column.name}</span>
                      </div>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {assignments.map((assignment, i) => (
              <tr key={i} className='fs-14 fw-light'>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='flex-grow-1 ms-2'>
                      <p
                        className='card-title mb-0 fs-4'
                        style={{ overflow: 'hidden' }}
                      >
                        <span>{getUserDisplayName(assignment.user)}</span>
                        <p className='text-truncate user-info fs-4 text-muted'>
                          {assignment.user &&
                            truncateTextToFitWidth(
                              assignment.user.position?.name ?? '',
                              forSummary ? 500 : 100,
                            )}
                        </p>
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {assignment.user.departments?.[0]?.name || ''}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>{assignment.code}</span>
                </td>
                <td>
                  <span className='fw-normal text-muted fs-4'>
                    {assignment.course?.translations[0].content.name || '-'}
                  </span>
                </td>
                {!forSummary && (
                  <td>
                    <div className='course-progress d-flex gap-1 align-items-center'>
                      <span className='fw-light text-muted fs-4'>
                        {assignment.progress + '%'}
                      </span>
                    </div>
                  </td>
                )}

                {forSummary && (
                  <td>
                    <div className='course-progress d-flex gap-1 align-items-center'>
                      <span className='fw-light text-muted fs-4'>
                        {assignment.course.type}
                      </span>
                    </div>
                  </td>
                )}

                <td>
                  <span className='text-muted fs-4'>
                    {assignment.status === ASSIGNMENT_STATUS.COMPLETED
                      ? moment(assignment.endDate).format('MM/DD/YYYY, hh:MM A')
                      : '-'}
                  </span>
                </td>
                {!forSummary && (
                  <>
                    <td>
                      <span
                        className={`badge badge-${
                          BadgeColorByStatus[assignment.status]
                        } fs-4 fw-normal text-capitalize`}
                      >
                        {getAssignmentStatus(assignment.status)}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`fs-4 fw-normal text-capitalize text-muted`}
                      >
                        {assignment.daysOverdue}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`fs-4 fw-normal text-capitalize text-muted`}
                      >
                        {getTestAttempts(assignment)}
                      </span>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default CourseHistoryReport
