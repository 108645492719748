import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { API, setAuthorization } from '../helpers/api_helper'
import { useAppDispatch } from '../hooks/redux'
import axios from 'axios'
import { useProfile } from '../Components/Hooks/UserHooks'
import { clearUser } from '../slices/auth/user/reducer'
import Error500 from '../pages/AuthenticationInner/Errors/Error500'
import Error403 from '../pages/AuthenticationInner/Errors/Error403'
import { usePermissions } from '../hooks/usePermissions'
import {
  CalendarPermissions,
  CommunicationPermissions,
  CoursesPermissions,
  FacilityManagementPermissions,
  ReportingPermissions,
  SettingsPermissions,
  SupportPermissions,
  UserManagementPermissions,
} from '../sharedTypes'

const AuthProtected = (props: {
  children: React.ReactNode
  permission?:
    | SettingsPermissions
    | CalendarPermissions
    | CoursesPermissions
    | FacilityManagementPermissions
    | SupportPermissions
    | UserManagementPermissions
    | ReportingPermissions
    | CommunicationPermissions
    | undefined
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [path, setPath] = useState<string>('/')
  const [prevPath, setPrevPath] = useState<string>('/')
  const [error, setError] = useState<number | null>(null)
  const [interceptorsSet, setInterceptorsSet] = useState(false)
  const { userProfile, token } = useProfile()
  const hasPermission = usePermissions(props.permission)

  useEffect(() => {
    if (path !== location.pathname) {
      setPrevPath(path)
      setPath(location.pathname)
    }
  }, [location.pathname])

  useEffect(() => {
    const interceptors = API.interceptors.response.use(
      r => r,
      error => {
        if (axios.isAxiosError(error)) {
          const response = error.response
          if (response) {
            const statusCode = response.status
            if ([500, 403].includes(statusCode)) {
              setError(statusCode)
            }
          }
        }
        return Promise.reject(error)
      },
    )

    setInterceptorsSet(true)

    return () => {
      API.interceptors.response.eject(interceptors)
    }
  }, [])

  useEffect(() => {
    if (userProfile && token) {
      setAuthorization(token)
    } else if (!userProfile && !token) {
      dispatch(clearUser())
    }
  }, [token, userProfile])

  /*
    Navigate is un-auth access protected routes via url
    */

  if (!interceptorsSet) {
    return null
  }

  if (!userProfile && !token) {
    return <Navigate to={{ pathname: '/login' }} />
  }

  if (error === 500) {
    return <Error500 />
  } else if (error === 403 || !hasPermission) {
    return (
      <Error403
        onGoBack={() => {
          window.location.href = prevPath as string
        }}
      />
    )
  } else {
    return <>{props.children}</>
  }
}

export { AuthProtected }
