import React from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { useState } from 'react'
import { TCompany } from '../../sharedTypes'

interface ActionsDropDownDropDownProps {
  company: TCompany
  onEdit: () => void
  onSwitch: () => void
  permissions: {
    editCompany: boolean
    switchToPortal: boolean
  }
}

export const ActionsDropDown = ({
  company,
  onEdit,
  onSwitch,
  permissions,
}: ActionsDropDownDropDownProps) => {
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)

  return (
    <Dropdown
      isOpen={actionDropdownOpen}
      toggle={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setActionDropdownOpen(prevState => !prevState)
      }}
    >
      <DropdownToggle
        className={`badge btn ${
          actionDropdownOpen
            ? 'btn-primary'
            : 'text-light-purple btn-soft-primary'
        }`}
        tag='button'
      >
        <i className='mdi mdi-dots-horizontal align-middle' />
      </DropdownToggle>
      <DropdownMenu container={'body'}>
        {permissions.editCompany && (
          <DropdownItem
            className={`cursor-pointer d-flex gap-2`}
            onClick={onEdit}
          >
            <i className={`ri-pencil-line`} id={`editCompany${company.id}`} />
            Edit
          </DropdownItem>
        )}

        {permissions.switchToPortal && (
          <DropdownItem
            className={`cursor-pointer d-flex gap-2`}
            onClick={onSwitch}
          >
            <i
              className={`ri-external-link-line`}
              id={`switchToCompany${company.id}`}
            />
            Switch to
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
