import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row } from 'reactstrap'

import { SearchInput } from '../../Common/SearchInput'
import Filters from '../../Course/Filters'
import { Step2Filters } from '../AssignCourseModal/types'
import { COURSE_FILTER_FORMATS } from '../../../sharedTypes'

export interface FiltersProps {
  filters: Step2Filters
  onFiltersChange: Dispatch<SetStateAction<Step2Filters>>
  filterOptions?: {
    value: COURSE_FILTER_FORMATS
    label: COURSE_FILTER_FORMATS
  }[]
}

const FiltersBlock = ({
  filters,
  onFiltersChange,
  filterOptions,
}: FiltersProps) => {
  return (
    <div className={`p-3 border-bottom assign-courses`}>
      <Row className='gap-4 mb-3 mt-0 mx-0'>
        <Col sm='3' className='mt-0 p-0 '>
          <label htmlFor='format' className='form-label'>
            Search
          </label>
          <SearchInput
            onChange={key => {
              onFiltersChange(prev => ({ ...prev, key, page: 1 }))
            }}
            value={filters.key}
          />
        </Col>
        <Filters<Step2Filters>
          availableFilters={['format', 'type']}
          onFilterChange={onFiltersChange}
          filterOptions={filterOptions}
        />
      </Row>
    </div>
  )
}

export default FiltersBlock
