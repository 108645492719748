import React, { useEffect, useMemo, useState } from 'react'
import SimpleBar from 'simplebar-react'
import _ from 'lodash'

import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  TCompany,
  TFacility,
  TFacilityStatus,
  TGroup,
} from '../../../sharedTypes'
import Highlighter from 'react-highlight-words'

type SearchGroup = Omit<TGroup, 'subGroups'> & {
  isSearch?: boolean
  subGroups: SearchGroup[]
}

export interface SelectedEntity {
  companyId: number
  id: number
  type: 'group' | 'facility'
}

interface SearchProps {
  status: TFacilityStatus | undefined
  companies: TCompany[]
  setSelectedItem: (item: SelectedEntity | null) => void
  dnone?: boolean
}

const filterFacilities = (facilities: TFacility[], search: string) => {
  return facilities.filter(facility =>
    facility.name.toLowerCase().includes(search.toLowerCase()),
  )
}

const filterGroups = (group: TGroup, search: string): SearchGroup | null => {
  if (group.name.toLowerCase().includes(search.toLowerCase())) {
    return {
      ...group,
      isSearch: true,
      facilities: filterFacilities(group.facilities, search),
      subGroups: group.subGroups
        .map(subGroup => filterGroups(subGroup, search))
        .filter(subGroup => subGroup !== null) as SearchGroup[],
    }
  }

  const _facilities = filterFacilities(group.facilities, search)
  const _subGroups = group.subGroups
    .map(subGroup => filterGroups(subGroup, search))
    .filter(subGroup => subGroup !== null) as SearchGroup[]

  if (_.isEmpty(_facilities) && _.isEmpty(_subGroups)) {
    return null
  }

  return {
    ...group,
    facilities: _facilities,
    subGroups: _subGroups,
  }
}

const renderSearchItem = (
  parentStr: string,
  group: SearchGroup,
  searchValue: string,
  level = 1,
  onClick: SearchProps['setSelectedItem'],
) => {
  return (
    <div key={parentStr + level.toString() + group.id}>
      {group.isSearch && (
        <div
          className='dropdown-item notify-item cursor-pointer'
          key={'group' + group.id}
          onClick={() => {
            onClick({
              id: group.id,
              type: 'group',
              companyId: group.companyId,
            })
          }}
        >
          <span className='fs-14'>
            {parentStr} {'> '}
            <Highlighter
              highlightClassName='text-highlight-primary'
              searchWords={[searchValue]}
              highlightTag={'span'}
              autoEscape={true}
              textToHighlight={group.name}
            />
          </span>
        </div>
      )}
      {group.facilities.map(facility => (
        <div
          className='dropdown-item notify-item cursor-pointer'
          key={'facility' + facility.id}
          onClick={() => {
            onClick({
              id: facility.id,
              type: 'facility',
              companyId: facility.companyId,
            })
          }}
        >
          <span className='fs-14'>
            {parentStr} {'>'} {group.name} {'> '}
            <Highlighter
              highlightClassName='text-highlight-primary'
              searchWords={[searchValue]}
              highlightTag={'span'}
              autoEscape={true}
              textToHighlight={facility.name}
            />
          </span>
        </div>
      ))}
      {group.subGroups.map(subGroup =>
        renderSearchItem(
          parentStr + ' > ' + group.name,
          subGroup,
          searchValue,
          level + 1,
          onClick,
        ),
      )}
    </div>
  )
}

const Search = ({ companies, setSelectedItem, dnone = true }: SearchProps) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (!value) {
      setSelectedItem(null)
    }
  }, [value])
  const searchResult = useMemo(() => {
    if (!value) {
      return []
    }
    return companies.map(company => {
      return {
        ...company,
        facilities: filterFacilities(company.facilities, value),
        groups: company.groups
          .map(group => filterGroups(group, value))
          .filter(group => group !== null) as SearchGroup[],
      }
    })
  }, [value])

  useEffect(() => {
    const dropdown = document.getElementById('facility-search-dropdown')
    const searchInput = document.getElementById(
      'facility-search-options',
    ) as HTMLInputElement

    searchInput.addEventListener('keyup', function () {
      const inputLength: number = searchInput?.value?.length
      if (inputLength > 0) {
        dropdown?.classList.add('show')
      } else {
        dropdown?.classList.remove('show')
      }
    })

    document.body.addEventListener('click', function (e: any) {
      if (
        e.target?.getAttribute('id') !== 'search-options' &&
        e.target?.getAttribute('id') !== 'facility-search-options'
      ) {
        dropdown?.classList.remove('show')
      }
    })
  }, [])

  return (
    <React.Fragment>
      <form
        className={`app-search ${dnone ? 'd-none' : ''} d-md-block p-0`}
        noValidate={true}
      >
        <SearchInput
          onChange={setValue}
          value={value}
          style={{ maxWidth: 400, position: 'relative' }}
          inputProps={{
            id: 'facility-search-options',
            onFocus: () => {
              document
                .getElementById('facility-search-dropdown')
                ?.classList.add('show')
            },
          }}
        />
        <div
          className={
            'dropdown-menu dropdown-menu-lg' +
            (value.length > 0 ? ' show' : ' d-none')
          }
          id='facility-search-dropdown'
        >
          <SimpleBar style={{ maxHeight: '320px' }}>
            {searchResult.map(company => (
              <div key={'company' + company.id}>
                {company.facilities.map(facility => (
                  <div
                    className='dropdown-item notify-item cursor-pointer'
                    key={'facility' + facility.id}
                    onClick={() => {
                      setSelectedItem({
                        id: facility.id,
                        type: 'facility',
                        companyId: company.id,
                      })
                    }}
                  >
                    <span className='fs-14'>
                      {company.name} {'> '}
                      <Highlighter
                        highlightClassName='text-highlight-primary'
                        searchWords={[value]}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={facility.name}
                      />
                    </span>
                  </div>
                ))}
                {company.groups.map(group =>
                  renderSearchItem(
                    company.name,
                    group,
                    value,
                    1,
                    setSelectedItem,
                  ),
                )}
              </div>
            ))}
          </SimpleBar>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Search
