import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { TRole } from '../../../sharedTypes'

const DragHandle = SortableHandle(() => (
  <i className='ri-draggable me-1 fs-16 cursor-pointer'></i>
))

interface SortableColumnItemProps {
  role: TRole
  isEdit: boolean
  editRole: TRole | null
  onChange: (key: string, value: any) => void
  onSaveChanges: () => void
  onCancelEdit: () => void
  setEditRole: (role: TRole) => void
  setAssignRole: (role: TRole) => void
  onClone: (role: TRole) => void
  onDeleteRole: (role: TRole) => void
  permissions: {
    add: boolean
    editName: boolean
    editPermissions: boolean
    assign: boolean
    delete: boolean
  }
}
export const SortableColumnItem = SortableElement<SortableColumnItemProps>(
  ({
    role,
    isEdit,
    editRole,
    onChange,
    onSaveChanges,
    onCancelEdit,
    setEditRole,
    setAssignRole,
    onClone,
    onDeleteRole,
    permissions,
  }: SortableColumnItemProps) => (
    <th
      key={role.id}
      className='align-middle fw-light'
      style={{
        padding: '0.5rem 0.6rem',
        minWidth: 217,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {isEdit ? (
        <span className='hstack'>
          {permissions.editPermissions && <DragHandle />}
          <input
            className='form-control p-1 d-inline-block me-2'
            value={editRole?.name}
            onChange={event => {
              onChange('name', event.target.value)
            }}
          />
          <i
            className='fs-16 ri-check-line text-success cursor-pointer'
            onClick={onSaveChanges}
          ></i>
          <i
            className='fs-16 ri-close-line text-danger cursor-pointer'
            onClick={onCancelEdit}
          ></i>
        </span>
      ) : (
        <>
          {permissions.editPermissions && <DragHandle />}
          {role.name}
          <span
            className='text-primary fs-16'
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              position: 'absolute',
              right: '0.6rem',
              height: '100%',
              top: 0,
              zIndex: 10,
            }}
          >
            {permissions.editName && (
              <>
                <UncontrolledTooltip
                  placement='top'
                  target={'editRole' + role.id}
                >
                  Edit Role
                </UncontrolledTooltip>
                <i
                  className='ri-pencil-line cursor-pointer'
                  id={'editRole' + role.id}
                  onClick={() => setEditRole(role)}
                ></i>
              </>
            )}
            {permissions.assign && (
              <>
                <UncontrolledTooltip
                  placement='top'
                  target={'assignRole' + role.id}
                >
                  Assign Role
                </UncontrolledTooltip>
                <i
                  className='ri-group-line cursor-pointer'
                  id={'assignRole' + role.id}
                  onClick={() => setAssignRole(role)}
                ></i>
              </>
            )}
            {permissions.add && (
              <>
                <UncontrolledTooltip
                  placement='top'
                  target={'cloneRole' + role.id}
                >
                  Clone Role
                </UncontrolledTooltip>
                <i
                  className='bx bx-duplicate cursor-pointer'
                  id={'cloneRole' + role.id}
                  onClick={() => onClone(role)}
                ></i>
              </>
            )}
            {permissions.delete && (
              <>
                <UncontrolledTooltip
                  placement='top'
                  target={'deleteRole' + role.id}
                >
                  Delete Role
                </UncontrolledTooltip>
                <i
                  className='ri-delete-bin-2-line text-danger cursor-pointer'
                  id={'deleteRole' + role.id}
                  onClick={() => onDeleteRole(role)}
                ></i>
              </>
            )}
          </span>
        </>
      )}
    </th>
  ),
)

interface SortableColumnContainerProps {
  children: React.ReactNode
}
export const SortableColumnContainer =
  SortableContainer<SortableColumnContainerProps>(
    ({ children }: SortableColumnContainerProps) => (
      <thead className='table-light'>
        <tr className='text-muted fs-14'>
          <th className='align-middle' style={{ minWidth: 200 }}>
            Actions
          </th>
          {children}
        </tr>
      </thead>
    ),
  )
