import React from 'react'
import {
  BooleanAnswerItem,
  CoursesPermissions,
  ImageAnswerItem,
  MultiChoiceAnswerItem,
  OnlineCourseItem,
  QuestionableTypes,
  QuestionTypes,
} from '../../../../sharedTypes'
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap'
import { FormikProps } from 'formik'
import { useQuestions } from '../../../../hooks/course/useQuestions'
import { IForm } from './types'
import _ from 'lodash'

interface CompetencyTestProps {
  course: OnlineCourseItem
  form: FormikProps<IForm>
}

const CompetencyTest = ({ course, form }: CompetencyTestProps) => {
  const { data } = useQuestions(
    course.id,
    QuestionableTypes.COMPETENCY_ONLY,
    true,
    1,
    50,
    CoursesPermissions.VIEW_TRANSLATIONS,
  )

  return (
    <Card>
      {_.isEmpty(data.questions) ? (
        <div className='d-flex flex-column align-items-center py-5'>
          <h5 className='text-center fw-semibold text-body'>
            Sorry, no questions found
          </h5>
        </div>
      ) : (
        <CardBody>
          {data.questions.map((questionItem, i) => (
            <div className='border-bottom py-3' key={questionItem.id}>
              <Row className='mb-2'>
                <Col>
                  <div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <span className='text-muted fs-14'>
                        Question # {i + 1}
                      </span>
                    </div>
                    <div className='hstack mb-2'>
                      <span className='fw-medium fs-16 text-start text-body'>
                        {questionItem.question || '-'}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='border-bottom' key={questionItem.id}>
                    <div className='d-flex justify-content-between'>
                      <label className='form-check-label fs-14 fw-medium'>
                        Question # {i + 1}
                      </label>

                      <div className='form-check form-switch mb-2'>
                        <Input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          id={`approve`}
                          checked={
                            form.values.content.questions[questionItem.groupId]
                              ?.approved
                          }
                          onChange={e => {
                            form.setFieldValue(
                              `content.questions.${questionItem.groupId}.approved`,
                              e.target.checked,
                            )
                          }}
                        />
                        <Label className='form-check-label' htmlFor={`approve`}>
                          Approve
                        </Label>
                      </div>
                    </div>
                    <div>
                      <Input
                        className='form-control'
                        name={`content.questions[${questionItem.groupId}].question`}
                        id='question'
                        placeholder='Enter question'
                        type='textarea'
                        rows='3'
                        value={
                          form.values.content.questions[questionItem.groupId]
                            ?.question
                        }
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {questionItem.type === QuestionTypes.MULTIPLE_CHOICE && (
                <>
                  {(
                    (questionItem.answers as MultiChoiceAnswerItem[]) || []
                  ).map((answerItem, i) => (
                    <Row key={i} className='pb-2'>
                      <Col>
                        {answerItem.correct ? (
                          <div className='text-green-500 d-flex gap-3 align-items-center'>
                            <CorrectAnswerIcon />
                            <span className='fs-14'>{answerItem.answer}</span>
                          </div>
                        ) : (
                          <div className='text-danger d-flex gap-3 align-items-center'>
                            <WrongAnswerIcon />
                            <span className='fs-14'>{answerItem.answer}</span>
                          </div>
                        )}
                      </Col>
                      <Col>
                        <div className='d-flex gap-2'>
                          <Input
                            className='form-control'
                            name={`content.questions[${questionItem.groupId}].answers[${i}].answer`}
                            id='answer'
                            placeholder='Enter answer'
                            type='text'
                            value={
                              form.values.content.questions[
                                questionItem.groupId
                              ]?.answers?.[i]?.['answer']
                            }
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />

                          {answerItem.correct ? (
                            <div className='text-green-500 d-flex gap-3 align-items-center'>
                              <div className='d-flex align-items-center h-100'>
                                <i
                                  className='ri-check-fill fs-5 bg-green-100 border-green-500
                                 rounded fw-bold px-3 d-flex align-items-center h-100'
                                ></i>
                              </div>
                            </div>
                          ) : (
                            <div className='text-danger d-flex gap-3 align-items-center'>
                              <div className='d-flex align-items-center h-100'>
                                <i
                                  className='ri-close-line fs-5 bg-soft-danger border-danger
                                 rounded fw-bold px-3 d-flex align-items-center h-100'
                                ></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </>
              )}

              {questionItem.type === QuestionTypes.TRUE_FALSE && (
                <Row key={i} className='pb-2'>
                  <div className='vstack gap-4'>
                    <div className='text-green-500 d-flex gap-3 align-items-center'>
                      <CorrectAnswerIcon />
                      <span className='fs-14'>
                        {(questionItem.answers as BooleanAnswerItem)?.correct
                          ? 'True'
                          : 'False'}
                      </span>
                    </div>

                    <div className='text-danger d-flex gap-3 align-items-center'>
                      <WrongAnswerIcon />
                      <span className='fs-14'>
                        {(questionItem.answers as BooleanAnswerItem)?.correct
                          ? 'False'
                          : 'True'}
                      </span>
                    </div>
                  </div>
                </Row>
              )}

              {questionItem.type === QuestionTypes.IMAGE_CHOICE && (
                <div className='d-flex gap-2 flex-wrap image-answers-container'>
                  {(questionItem.answers as ImageAnswerItem[]).map(
                    (answerItem, i) => (
                      <div key={i} className='position-relative'>
                        <div className='d-flex justify-content-center align-items-center image-container'>
                          <img
                            src={answerItem.path}
                            className='w-100 h-100 rounded'
                            alt='No Image'
                          />
                        </div>
                        <div className='position-absolute top-0 right-0 p-2'>
                          {answerItem.correct ? (
                            <CorrectAnswerIcon />
                          ) : (
                            <WrongAnswerIcon />
                          )}
                        </div>
                      </div>
                    ),
                  )}
                </div>
              )}
            </div>
          ))}
        </CardBody>
      )}
    </Card>
  )
}

const CorrectAnswerIcon = () => {
  return (
    <div className='d-flex align-items-center answer-icon text-green-500'>
      <i className='ri-check-fill fs-14 bg-green-100 rounded-circle fw-bold px-1'></i>
    </div>
  )
}

const WrongAnswerIcon = () => {
  return (
    <div className='d-flex align-items-center answer-icon text-danger'>
      <i className='ri-close-line fs-14 bg-soft-danger rounded-circle fw-bold px-1'></i>
    </div>
  )
}

export default CompetencyTest
