import {
  useLocation,
  useNavigate,
  useParams,
  Location,
  NavigateFunction,
  Params,
} from 'react-router-dom'
import React from 'react'

export interface WithRouterProps {
  router: {
    location: Location
    navigate: NavigateFunction
    params: Readonly<Params>
  }
}

const WithRouterHOC = <P extends object>(Component: React.ComponentType<P>) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = Component.displayName || Component.name || 'Component'
  const ComponentWithRouter = (props: Omit<P, keyof WithRouterProps>) => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    return (
      <Component {...(props as P)} router={{ location, navigate, params }} />
    )
  }

  ComponentWithRouter.displayName = `withRouter(${displayName})`

  return ComponentWithRouter
}

export default WithRouterHOC
