import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import statisticsImg from '../../../assets/images/dashboard/statistics.png'
import { NewUsersStatuses } from '../types'
import { getUserStatusAnalytics } from '../../../helpers/api/users'
import { GetUserStatusAnalyticsDTO } from '../../../sharedTypes'
import _ from 'lodash'
import { handleError } from '../../../helpers/toast_helper'
import { useAppSelector } from '../../../hooks/redux'
import DetailsInfoModal from '../../../Components/Modals/DetailsInfoModal'
import { v4 as uuidv4 } from 'uuid'

const userStatuses = [
  {
    label: 'Not Registered',
    key: NewUsersStatuses.NOT_REGISTERED,
    color: '#F25959',
    info: 'User has not registered on the Mastered App.',
  },
  {
    label: 'Not Started Onboarding',
    key: NewUsersStatuses.NOT_STARTED_ONBOARDING,
    color: '#FFD643',
    info: 'User has registered but has not started their first course course.',
  },
  {
    label: 'Onboarding in Progress',
    key: NewUsersStatuses.ONBOARDING_IN_PROGRESS,
    color: '#0039C7',
    info: 'User has completed their first course.',
  },
  {
    label: 'Onboarding Complete',
    key: NewUsersStatuses.ONBOARDING_COMPLETE,
    color: '#1AC1BD',
  },
]
const NewUsers = () => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )

  const [data, setData] = useState<GetUserStatusAnalyticsDTO.Response>({
    [NewUsersStatuses.NOT_REGISTERED]: {
      count: 0,
      percent: 0,
    },
    [NewUsersStatuses.NOT_STARTED_ONBOARDING]: {
      count: 0,
      percent: 0,
    },
    [NewUsersStatuses.ONBOARDING_IN_PROGRESS]: {
      count: 0,
      percent: 0,
    },
    [NewUsersStatuses.ONBOARDING_COMPLETE]: {
      count: 0,
      percent: 0,
    },
    totalUsersCount: 0,
  })

  useEffect(() => {
    const facilityId = selectedFacilityId ?? undefined
    const groupId = selectedGroupId ?? undefined
    getUserStatusAnalytics({ facilityId, groupId })
      .then(res => {
        setData(res)
      })
      .catch(handleError)
  }, [selectedFacilityId, selectedGroupId])

  const memoizedUserStatuses = useMemo(() => {
    return userStatuses.map(item => ({
      ...item,
      id: uuidv4(),
    }))
  }, [])

  return (
    <Card className='mt-3 new-users-container'>
      <CardHeader className='align-items-center d-flex'>
        <h4 className='card-title mb-0 flex-grow-1'>New Users</h4>
      </CardHeader>

      <CardBody>
        <Row className='align-items-center'>
          <Col xs={8}>
            <h4 className='fs- mb-0'>{data.totalUsersCount}</h4>
            <p className='mb-0 mt-2 text-muted'>in last 90 days</p>
          </Col>
          <Col xs={4}>
            <div className='text-center'>
              <img src={statisticsImg} className='img-fluid' alt='' />
            </div>
          </Col>
        </Row>
        <div className='mt-3 pt-2'>
          <div className='progress progress-lg rounded-pill'>
            {userStatuses.map(item => (
              <>
                <div
                  key={item.key}
                  className='progress-bar'
                  role='progressbar'
                  style={{
                    width: `${_.get(data[item.key], 'percent', 0)}%`,
                    background: item.color,
                  }}
                  aria-valuemin={0}
                  aria-valuenow={_.get(data[item.key], 'percent', 0)}
                  aria-valuemax={100}
                ></div>
              </>
            ))}
          </div>
        </div>

        <div className='mt-3 pt-2'>
          {memoizedUserStatuses.map(item => (
            <div className='d-flex mb-3' key={item.key}>
              <div className='flex-grow-1'>
                <p className='text-truncate text-muted fs-14 mb-0'>
                  <i
                    className='mdi mdi-circle align-middle pe-2'
                    style={{ color: item.color }}
                  ></i>
                  {item.label}
                  <DetailsInfoModal
                    id={item.id}
                    name={'new-users-break-down'}
                    info={item.info}
                  />
                </p>
              </div>
              <div>
                <span>{_.get(data[item.key], 'percent', '')}%</span>
                <span
                  className='fw-semibold ms-3 text-end'
                  style={{ minWidth: 40, display: 'inline-block' }}
                >
                  {_.get(data[item.key], 'count', '')}
                </span>
              </div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default NewUsers
