import React, { Fragment } from 'react'
import { Col, Row } from 'reactstrap'

import { PackageDetailInfoProps } from './types'
import moment from 'moment'

const PackageDetailInfo = ({ coursePackage }: PackageDetailInfoProps) => {
  return (
    <Fragment>
      <div className='border-bottom pb-3'>
        <Row>
          <Col className='d-flex flex-column align-items-start justify-content-center gap-2'>
            <span className='text-muted fs-14'>{coursePackage.code}</span>
          </Col>
          <Col className='d-flex justify-content-end align-items-start'>
            <span
              className={`badge badge-soft-grey fs-12 fw-normal text-capitalize mt-1`}
            >
              {coursePackage?.coursesCount} Course
              {(coursePackage.coursesCount ?? 0) > 1 ? 's' : ''}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className='text-start'>
            <span className='fs-14 text-body fw-medium'>
              {coursePackage.name}
            </span>
          </Col>
        </Row>
      </div>
      <div className='vstack gap-2 pt-3 text-start'>
        <Row>
          <Col md={4}>
            <span className='fw-medium text-body fs-14'>Publish Date: </span>
          </Col>
          <Col md={8}>
            <span className='text-muted fs-14'>
              {moment(coursePackage.publishedDate).format('MM/DD/YYYY')}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span className='fw-medium text-body fs-14'>Description: </span>
          </Col>
          <Col md={8}>
            <span className='text-muted fs-14'>
              {coursePackage.description}
            </span>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default PackageDetailInfo
