import {
  CreateCompanyNewsDTO,
  GetCompanyNewsDTO,
  TCompanyNews,
} from '../../sharedTypes'
import { COMPANY_NEWS } from '../url_helper'
import { API } from '../api_helper'

export const getCompanyNews = (params: GetCompanyNewsDTO.Request) => {
  return API.get<GetCompanyNewsDTO.Response>(COMPANY_NEWS, {
    params,
  })
}

export const putCompanyNewsImages = (
  newsId: number,
  attachmentId: number,
  type: 'media' | 'cover',
  body: FormData,
) => {
  return API.post<any>(
    `${COMPANY_NEWS}/${attachmentId}/${type}/${newsId}/images`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}

export const getCompanyNewsById = (id: number) => {
  return API.get<TCompanyNews>(`${COMPANY_NEWS}/${id}`)
}

export const deleteCompanyNews = (id: number) => {
  return API.delete(COMPANY_NEWS + `/${id}`)
}

export const postCompanyNews = (data: CreateCompanyNewsDTO.Request) => {
  return API.post<CreateCompanyNewsDTO.Response>(COMPANY_NEWS, data, {}).then(
    d => d.data,
  )
}

export const patchCompanyNews = (
  id: number,
  data: CreateCompanyNewsDTO.Request,
) => {
  return API.patch<CreateCompanyNewsDTO.Response>(`${COMPANY_NEWS}/${id}`, data)
}
