import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import Search, { SelectedEntity } from './Search'
import FilterTabs from './Tabs'
import {
  OrderType,
  CompaniesFacilitiesSortBy,
  TFacilityStatus,
  TCompany,
  GetCompaniesFacilitiesDTO,
  TGroup,
  TFacility,
  FacilityManagementPermissions,
  GetCompanyFacilitiesPermissions,
} from '../../../sharedTypes'
import {
  activateFacility,
  deactivateFacility,
  getCompaniesHierarchy,
} from '../../../helpers/api_helper'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import FacilityItem from './FacilityItem'
import GroupItem from './GroupItem'
import { publish } from '../../../utils/events'
import CreateNewGroupModal from './CreateNewGroupModal'
import CreateNewFacilityModal from './CreateNewFacilityModal'
import { usePermissions } from '../../../hooks/usePermissions'
import ActivationModal from '../../../Components/Modals/PrimaryConfirmationModal'
import DeactivationModal from '../../../Components/Modals/DeleteConfirmation'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../helpers/toast_helper'
import { ReactComponent as FlashLightOff } from '../../../assets/images/svg/flashOff.svg'
import SignatoryModal from '../../../Components/Modals/SignatoryModal'

const Columns = [
  {
    name: 'Group',
    sortBy: CompaniesFacilitiesSortBy.GROUP_NAME,
  },
  {
    name: 'Facility',
    sortBy: CompaniesFacilitiesSortBy.FACILITY_NAME,
  },
  {
    name: 'Status',
    sortBy: CompaniesFacilitiesSortBy.STATUS,
  },
  {
    name: 'Activation Date',
    sortBy: CompaniesFacilitiesSortBy.ACTIVATION_DATE,
  },
  {
    name: 'Signatory',
  },
]

export interface SelectedFacility {
  isOpen: boolean
  facility: TFacility | null
}

const findEntity = (
  group: TGroup,
  selectedItem: SelectedEntity,
): TGroup | null => {
  if (selectedItem.type === 'group') {
    if (group.id === selectedItem.id) {
      return group
    }
  } else {
    const selectFacility = group.facilities.find(
      facility => facility.id === selectedItem.id,
    )
    if (selectFacility) {
      return {
        ...group,
        facilities: [selectFacility],
        subGroups: [],
      }
    }
  }

  let selectedGroup = undefined
  group.subGroups.forEach(subGroup => {
    const select = findEntity(subGroup, selectedItem)
    if (select) {
      selectedGroup = select
    }
  })

  if (selectedGroup) {
    return {
      ...group,
      facilities: [],
      subGroups: [selectedGroup],
    }
  }
  return null
}

const getSelectItem = (groups: TGroup[], selectedItem: SelectedEntity) => {
  let selectedGroup = undefined
  groups.forEach(group => {
    const select = findEntity(group, selectedItem)
    if (select) {
      selectedGroup = select
    }
  })

  if (selectedGroup) {
    return [selectedGroup]
  }
  return []
}

const FacilityManagement = () => {
  document.title = 'Facility Management | Mastered - Admin & Dashboard'
  const [showSignatoryModal, setShowSignatoryModal] =
    useState<TFacility | null>(null)
  const [companies, setCompanies] = useState<TCompany[]>([])
  const [sortedColumn, setSortedColumn] =
    useState<CompaniesFacilitiesSortBy | null>(null)
  const [sortOrder, setSortOrder] = useState<OrderType>(OrderType.ASC)
  const [status, setStatus] = useState<TFacilityStatus | undefined>(undefined)
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [editGroup, setEditGroup] = useState<TGroup | undefined>(undefined)
  const [showFacilityModal, setShowFacilityModal] = useState(false)
  const [editFacility, setEditFacility] = useState<TFacility | undefined>(
    undefined,
  )
  const [selectedItem, setSelectedItem] = useState<SelectedEntity | null>(null)
  const [confirmActivation, setConfirmActivation] = useState<SelectedFacility>({
    isOpen: false,
    facility: null,
  })

  const permissions = {
    addFacility: usePermissions(FacilityManagementPermissions.ADD_NEW_FACILITY),
    viewFacility: usePermissions(
      FacilityManagementPermissions.VIEW_FACILITY_DETAILS,
    ),
    editFacility: usePermissions(FacilityManagementPermissions.EDIT_FACILITY),
    activateFacility: usePermissions(
      FacilityManagementPermissions.ACTIVATE_FACILITY,
    ),
    deactivateFacility: usePermissions(
      FacilityManagementPermissions.DEACTIVATE_FACILITY,
    ),
    addGroup: usePermissions(FacilityManagementPermissions.ADD_NEW_GROUP),
    editGroup: usePermissions(FacilityManagementPermissions.EDIT_GROUP),
    deleteGroup: usePermissions(FacilityManagementPermissions.DELETE_GROUP),
  }

  const collapseAll = () => {
    publish('collapseAll')
  }
  const expandAll = () => {
    publish('expandAll')
  }

  const _companies = useMemo(() => {
    if (!selectedItem) {
      return companies
    }
    expandAll()
    return companies
      .filter(company => company.id === selectedItem.companyId)
      .map(company => {
        return {
          ...company,
          facilities:
            selectedItem.type === 'facility'
              ? company.facilities.filter(
                  facility => facility.id === selectedItem.id,
                )
              : [],
          groups: getSelectItem(company.groups, selectedItem),
        }
      })
  }, [selectedItem, companies])

  const statusToggle = (_status?: TFacilityStatus) => {
    if (status !== _status) {
      setStatus(_status)
    }
  }

  const handleSort = (column: CompaniesFacilitiesSortBy) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === OrderType.ASC ? OrderType.DESC : OrderType.ASC)
    } else {
      setSortedColumn(column)
      setSortOrder(OrderType.ASC)
    }
  }

  const onCloseGroupModal = () => {
    setEditGroup(undefined)
    setShowGroupModal(false)
  }

  const onCloseFacilityModal = () => {
    setEditFacility(undefined)
    setShowFacilityModal(false)
  }

  const onSubmitCreating = () => {
    onCloseGroupModal()
    onCloseFacilityModal()
    fetchGroups()
  }

  const onEditGroup = (group: TGroup) => {
    setEditGroup(group)
    setShowGroupModal(true)
  }

  const onEditFacility = (facility: TFacility) => {
    setEditFacility(facility)
    setShowFacilityModal(true)
  }

  //Changed order or status need to re-fetch users, keep same page
  useEffect(() => {
    fetchGroups()
  }, [sortedColumn, sortOrder, status])

  const fetchGroups = () => {
    const params: GetCompaniesFacilitiesDTO.Request = {
      status,
      permission: GetCompanyFacilitiesPermissions.VIEW_FACILITY_MANAGEMENT,
    }

    if (sortedColumn) {
      params.sortBy = sortedColumn
      params.orderBy = sortOrder
    }

    getCompaniesHierarchy(params)
      .then(res => {
        setCompanies(res.data)
      })
      .catch(() => {})
  }

  const updateStatusToActive = (data: TGroup[] | TCompany[]) => {
    data.forEach(item => {
      if (item.facilities) {
        item.facilities.forEach(facility => {
          if (confirmActivation.facility?.id === facility.id) {
            facility.status =
              facility.status === TFacilityStatus.ACTIVE
                ? TFacilityStatus.INACTIVE
                : TFacilityStatus.ACTIVE
          }
        })
      }
      if ((item as TCompany).groups) {
        updateStatusToActive((item as TCompany).groups)
      }
      if ((item as TGroup).subGroups) {
        updateStatusToActive((item as TGroup).subGroups)
      }
    })
  }

  const handleFacilityActivation = () => {
    if (confirmActivation.facility) {
      const action =
        confirmActivation.facility.status === TFacilityStatus.INACTIVE
          ? activateFacility(confirmActivation.facility.id)
          : deactivateFacility(confirmActivation.facility.id)

      return action.then(() => {
        confirmActivation.facility?.status === TFacilityStatus.INACTIVE
          ? toast(
              `Facility ${confirmActivation.facility?.name} successfully activated`,
              successToastOptions,
            )
          : toast(
              `Facility ${confirmActivation.facility?.name} successfully deactivated`,
              successToastOptions,
            )
        setConfirmActivation({
          isOpen: false,
          facility: null,
        })

        updateStatusToActive(companies)
      })
    }
  }

  return (
    <React.Fragment>
      {showSignatoryModal && (
        <SignatoryModal
          isOpen={!!showSignatoryModal}
          title={
            showSignatoryModal?.signatory ? 'Edit Signatory' : '+ Add Signatory'
          }
          facility={showSignatoryModal}
          onSubmit={() => {
            fetchGroups()
            setShowSignatoryModal(null)
          }}
          onClose={() => {
            setShowSignatoryModal(null)
          }}
        />
      )}
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Facility management'
            items={[
              {
                active: true,
                title: 'Facility Management',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Row className='align-items gap-2'>
                    <Col className='flex-1'>
                      <Search
                        status={status}
                        companies={companies}
                        setSelectedItem={setSelectedItem}
                        dnone={false}
                      />
                    </Col>
                    <Col className='d-flex facility-filter-middle justify-content-center'>
                      <Button
                        className='btn btn-ghost-secondary align-middle text-secondary'
                        onClick={collapseAll}
                      >
                        <i className='ri-arrow-up-line me-1 fs-16'></i>Collapse
                        All
                      </Button>
                      <Button
                        className='btn btn-ghost-secondary align-middle text-secondary me-2'
                        onClick={expandAll}
                      >
                        <i className='ri-arrow-down-line me-1 fs-16'></i>Expand
                        All
                      </Button>
                      <FilterTabs navTab={status} navToggle={statusToggle} />
                    </Col>
                    <Col>
                      <div className='d-flex facility-filter-right gap-3 justify-content-end align-items-end align-content-end'>
                        {permissions.addGroup && (
                          <Button
                            color={'primary'}
                            className='btn btn-secondary align-middle facility-button'
                            onClick={() => setShowGroupModal(true)}
                          >
                            <i className='ri-add-line me-1 fs-16'></i>
                            <span className='group-text' />
                          </Button>
                        )}
                        {permissions.addFacility && (
                          <Button
                            color={'primary'}
                            className='btn btn-primary align-middle facility-button'
                            onClick={() => setShowFacilityModal(true)}
                          >
                            <i className='ri-add-line me-1 fs-16'></i>
                            <span className='facility-text' />
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className='table-card'>
                    <Table
                      className='align-middle table-nowrap mb-0'
                      style={{ tableLayout: 'fixed' }}
                    >
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {Columns.map(column => (
                            <th
                              scope='col'
                              className='align-middle'
                              key={column.name}
                            >
                              {column.name}
                              {!!column.sortBy && (
                                <ColumnSortIcon<CompaniesFacilitiesSortBy>
                                  sortOrder={sortOrder}
                                  sortedColumn={sortedColumn}
                                  column={column.sortBy}
                                  handleSort={handleSort}
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className='mb-0 pb-0'>
                        {_companies.map(company => (
                          <React.Fragment key={company.key}>
                            <tr className='bg-light'>
                              <td colSpan={5}>{company.name}</td>
                            </tr>
                            {company.facilities.map(facility => (
                              <FacilityItem
                                onSignatoryChange={setShowSignatoryModal}
                                facility={facility}
                                key={facility.id}
                                onEdit={onEditFacility}
                                onToggleActivation={() =>
                                  setConfirmActivation({
                                    isOpen: true,
                                    facility,
                                  })
                                }
                                hasPermissionToView={permissions.viewFacility}
                                hasPermissionToEdit={permissions.editFacility}
                                hasPermissionToActivate={
                                  permissions.activateFacility
                                }
                                hasPermissionToDeactivate={
                                  permissions.deactivateFacility
                                }
                              />
                            ))}
                            {company.groups.map(group => (
                              <GroupItem
                                onSignatoryChange={setShowSignatoryModal}
                                group={group}
                                key={group.id}
                                onEdit={onEditGroup}
                                onEditFacility={onEditFacility}
                                permissions={permissions}
                                handleToggleActivation={(facility: TFacility) =>
                                  setConfirmActivation({
                                    isOpen: true,
                                    facility,
                                  })
                                }
                              />
                            ))}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {permissions.addGroup && (
        <CreateNewGroupModal
          isOpen={showGroupModal}
          companies={companies}
          editGroup={editGroup}
          toggle={onCloseGroupModal}
          onClose={onCloseGroupModal}
          onSubmit={onSubmitCreating}
        />
      )}

      {permissions.addFacility && (
        <CreateNewFacilityModal
          isOpen={showFacilityModal}
          companies={companies}
          toggle={onCloseFacilityModal}
          onClose={onCloseFacilityModal}
          onSubmit={onSubmitCreating}
          editFacility={editFacility}
          hasPermissionToAddGroup={permissions.addGroup}
        />
      )}

      <ActivationModal
        onConfirm={handleFacilityActivation}
        onClose={() =>
          setConfirmActivation({
            isOpen: false,
            facility: null,
          })
        }
        isOpen={
          confirmActivation.isOpen &&
          confirmActivation.facility?.status === TFacilityStatus.INACTIVE
        }
        message={`Please note: Activating the facility will send an email to
        all staff members instructing them to download the app.`}
        title={`Activate ${confirmActivation.facility?.name ?? ''} ?`}
        confirmButtonLabel={'Activate'}
        icon={'ri-flashlight-line'}
      />

      <DeactivationModal
        isOpen={
          confirmActivation.isOpen &&
          confirmActivation.facility?.status === TFacilityStatus.ACTIVE
        }
        title={`Deactivate ${confirmActivation.facility?.name ?? ''} ?`}
        message={`Warning: Users will no longer be able to login to the app.`}
        confirmLabel={'Deactivate'}
        onDelete={handleFacilityActivation}
        onClose={() =>
          setConfirmActivation({
            isOpen: false,
            facility: null,
          })
        }
        customImage={<FlashLightOff className='flash-light-off-danger' />}
      />
    </React.Fragment>
  )
}

export default FacilityManagement
