import { TInstructor, TUser } from '../sharedTypes'

export const getUserDisplayName = (
  user?:
    | TUser
    | TInstructor
    | Pick<TUser, 'id' | 'firstName' | 'lastName'>
    | Partial<TUser>
    | null,
  displayLastNameFirst = false,
) => {
  if (!user) {
    return ''
  }
  return displayLastNameFirst
    ? `${(user.lastName || '').trim()} ${(user.firstName || '').trim()}`
    : `${(user.firstName || '').trim()} ${(user.lastName || '').trim()}`
}

export const getUserPhotoDisplayName = (user?: TUser) => {
  if (!user) {
    return null
  }
  return `${user.firstName[0]} ${user.lastName[0]}`.trim()
}

export const truncateTextToFitWidth = (text: string, maxWidth = 100) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) {
    return text
  }

  const textWidth = context.measureText(text).width
  if (textWidth <= maxWidth) {
    return text
  }

  let truncatedText = ''
  let width = 0
  for (let i = 0; i < text.length && width <= maxWidth; i++) {
    const charWidth = context.measureText(text[i]).width
    if (width + charWidth <= maxWidth) {
      truncatedText += text[i]
      width += charWidth
    } else {
      break
    }
  }

  truncatedText += '...'
  return truncatedText
}

export const getUserInitials = (user: TUser) => {
  return user.firstName.trim()[0] + ' ' + user.lastName.trim()[0]
}
