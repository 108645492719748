import React, { useCallback, useMemo, useState } from 'react'
import { Card, Col, Button, CardHeader } from 'reactstrap'
import _ from 'lodash'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FeatherIcon from 'feather-icons-react'

import {
  GetUsersDTO,
  OrderType,
  TScheduledCourse,
  UsersSearchSortBy,
  UsersSortBy,
} from '../../../sharedTypes'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import UsersTable, {
  Departments,
  IColumn,
  UserInfo,
} from '../../../Components/Common/UsersTable'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { SearchInput } from '../../../Components/Common/SearchInput'

const Columns: IColumn[] = [
  {
    title: 'User',
    id: 'user',
    component: UserInfo,
    sortBy: UsersSortBy.USER,
  },
  {
    title: 'Facility',
    id: 'facility',
    sortBy: UsersSortBy.FACILITY_NAME,
    selector: 'facility.name',
  },
  {
    title: 'Department',
    id: 'department',
    component: Departments,
  },
  {
    title: 'Position',
    id: 'position',
    sortBy: UsersSortBy.POSITION_NAME,
    selector: 'position.name',
  },
]

interface EventInfoProps extends WithRouterProps {
  course: TScheduledCourse
}

const EventUsers = ({ course, router }: EventInfoProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<GetUsersDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    users: [],
  })

  const [query, setQuery] = useState<GetUsersDTO.Request>({
    page: 1,
    limit: 10,
  })

  const isFiltering = useMemo<boolean>(
    () =>
      _.size(query.positionIds) > 0 ||
      _.size(query.departmentIds) > 0 ||
      _.size(query.facilityIds) > 0 ||
      !!query.courseStatus ||
      !!query.key,
    [query],
  )

  const handleSort = useCallback((column: UsersSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  return (
    <Col>
      <Card>
        <CardHeader>
          <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
            <SearchInput
              style={{ maxWidth: 300 }}
              onChange={key => {
                setQuery(prev => ({ ...prev, key, page: 1 }))
              }}
              value={query.key}
            />

            <div className='d-flex flex-shrink-0 gap-3'>
              <Button className='btn-soft-primary align-middle'>
                <i className='ri-file-list-3-line me-1 fs-16'></i>
                Generate report
              </Button>
              <Button
                color={'primary'}
                className='btn btn-primary align-middle d-flex align-items-center'
              >
                <FeatherIcon
                  icon='user-plus'
                  className='me-1'
                  style={{ width: 16, height: 16 }}
                />
                Add users
              </Button>
            </div>
          </div>
        </CardHeader>
        <NoResultTableWrapper
          isLoading={isLoading}
          isFiltering={isFiltering}
          pages={data.pages}
        >
          <UsersTable
            columns={Columns}
            users={data.users}
            sortedColumn={query.sortBy}
            page={data.page - 1}
            sortOrder={query.orderBy}
            totalPages={data.pages}
            onPageChanged={page => {
              setQuery(prev => ({ ...prev, page: ++page }))
            }}
            onLimitChange={limit => {
              setQuery(prev => ({ ...prev, limit }))
            }}
            limit={query.limit}
            itemsPerPage={query.limit ? query.limit : 10}
            handleSort={
              handleSort as (column: UsersSortBy | UsersSearchSortBy) => void
            }
            totalUsers={data.count}
            globalSearch={query.key || ''}
          />
        </NoResultTableWrapper>
      </Card>
    </Col>
  )
}

export default withRouter(EventUsers)
