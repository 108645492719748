//TUTORIALS
import { CreateTutorialDTO, GetTutorialsDTO } from '../../sharedTypes'
import {
  DELETE_TUTORIALS,
  GET_TUTORIALS,
  POST_TUTORIALS,
  PUT_TUTORIALS,
} from '../url_helper'
import { API } from '../api_helper'

export const getTutorials = (params: GetTutorialsDTO.Request) => {
  return API.get<GetTutorialsDTO.Response>(GET_TUTORIALS, { params }).then(
    d => d.data,
  )
}

export const postTutorial = (body: FormData) => {
  return API.post<CreateTutorialDTO.Response>(POST_TUTORIALS, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(d => d.data)
}

export const putTutorial = (id: number, body: FormData) => {
  return API.put<CreateTutorialDTO.Response>(PUT_TUTORIALS + `/${id}`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(d => d.data)
}

export const deleteTutorial = (id: number) => {
  return API.delete<void>(DELETE_TUTORIALS + `/${id}`)
}
