import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { deleteCompanyNews, getCompanyNews } from '../../../helpers/api_helper'
import moment from 'moment'
import defaultImg from '../../../assets/images/logo.png'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../helpers/toast_helper'
import { Pagination } from '../../../Components/Common/Pagination'
import { GetCompanyNewsDTO } from '../../../sharedTypes/api/companyNews'
import { useNavigate } from 'react-router-dom'
import { getStatusColor } from '../../../helpers/common'
import { usePermissions } from '../../../hooks/usePermissions'
import { CommunicationPermissions } from '../../../sharedTypes'
import { useAppSelector } from '../../../hooks/redux'
import ShowMoreWithPopover from '../../../Components/Common/ShowMoreWithPopover'
import _, { split } from 'lodash'

const CompanyNews = () => {
  document.title = 'Company News | Mastered - Admin & Dashboard'

  const user = useAppSelector(state => state.User.user)
  const { selectedFacilityId, selectedGroupId, groupOptions } = useAppSelector(
    state => state.FacilityOptions,
  )

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [fetchData, setFetchData] = useState(false)
  const [deleteId, setDeleteId] = useState<null | number>()
  const [query, setQuery] = useState<GetCompanyNewsDTO.Request>({
    page: 1,
    limit: 10,
  })
  const [data, setData] = useState<GetCompanyNewsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    companyNews: [],
  })

  const hasPermissionToAdd = usePermissions(
    CommunicationPermissions.ADD_COMPANY_NEWS,
  )
  const hasPermissionToEdit = usePermissions(
    CommunicationPermissions.VIEW_COMPANY_NEWS,
  )
  const hasPermissionToDelete = usePermissions(
    CommunicationPermissions.DELETE_COMPANY_NEWS,
  )

  useEffect(() => {
    if (fetchData) {
      getCompanyNewsData()
    }
  }, [query, fetchData])

  useEffect(() => {
    const facilityId = selectedFacilityId ?? undefined
    const groupId = selectedGroupId ?? undefined
    if (selectedFacilityId && selectedFacilityId !== query.facilityId) {
      setQuery(q => ({ ...q, facilityId, groupId: undefined }))
    }
    if (selectedGroupId && selectedGroupId !== query.groupId) {
      setQuery(q => ({ ...q, facilityId: undefined, groupId }))
    }

    if (!selectedGroupId && !selectedFacilityId) {
      setQuery(q => ({ ...q, facilityId, groupId }))
    }

    setFetchData(true)
  }, [selectedFacilityId, selectedGroupId])

  const getCompanyNewsData = () => {
    setIsLoading(true)
    getCompanyNews(query)
      .then(res => {
        setData(res.data)
        setIsLoading(false)
      })
      .catch(() => {})
  }

  const onDelete = useCallback(async () => {
    try {
      await deleteCompanyNews(deleteId as number)
      setQuery(prev => ({ ...prev, page: 1 }))
      setDeleteId(null)
      toast('Success - News successfully deleted', successToastOptions)
    } catch (e) {}
  }, [data.page, deleteId])

  const getRelatedFacilitiesNames = (
    facilityNames?: string | null,
    groupNames?: string | null,
  ) => {
    const _facilityNames = facilityNames ? split(facilityNames, ',') : []
    const _groupNames = groupNames ? split(groupNames, ',') : []

    if (_.isEmpty(facilityNames) && _.isEmpty(groupNames)) {
      const company = groupOptions.find(option => !option.level)
      return company && company.originalName ? [company.originalName] : ['']
    }

    return [..._facilityNames, ..._groupNames]
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Company News'
          items={[
            {
              title: 'Settings',
              linkTo: '/',
            },
            {
              title: 'Company News',
              active: true,
            },
          ]}
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className='d-flex justify-content-between align-items-center gap-3'>
                  <h6>Company News</h6>
                  {hasPermissionToAdd && (
                    <Button
                      color={'primary'}
                      onClick={() => {
                        navigate('/company-news/manage')
                      }}
                      className='btn btn-primary align-middle d-flex align-items-center'
                    >
                      <i className='ri-add-line me-1 fs-16'></i>Add New
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='news-card'>
          {data.companyNews.length ? (
            data.companyNews.map((news, index) => (
              <Col className='item-col' key={index}>
                <Card>
                  <div
                    className={`${
                      news.newsCover
                        ? 'news-image-container'
                        : 'default-image-container'
                    }`}
                  >
                    <img
                      className='card-img-top img-fluid w-100'
                      src={news.newsCover ? news.newsCover.path : defaultImg}
                      alt='Card cap'
                    />
                    <div className='actions'>
                      {hasPermissionToEdit && (
                        <i
                          className='ri-pencil-line text-light-purple fs-3'
                          onClick={() => {
                            navigate(`/company-news/manage/${news.id}`)
                          }}
                        ></i>
                      )}
                      {hasPermissionToDelete && (
                        <i
                          className='ri-delete-bin-2-line badge-soft-danger fs-3 ms-1'
                          onClick={() => setDeleteId(news.id)}
                        ></i>
                      )}
                    </div>
                  </div>
                  <CardBody>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className='card-title mb-2'>
                        {news.translations[0].title}
                      </h4>
                      <span
                        className={`${getStatusColor(
                          news.status,
                        )} badge text-capitalize`}
                      >
                        {news.status}
                      </span>
                    </div>
                    <h4 className='card-title mb-2'>
                      {!news.isDraft &&
                        `Date: ${moment(news.publishedDate).format(
                          'MM/DD/YYYY',
                        )}`}
                    </h4>
                    <p className='card-text text-muted'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: news.translations[0].content,
                        }}
                      ></div>
                    </p>

                    {(!user?.isFacilityAdmin ||
                      user?.hasAccessToMultipleFacilities) && (
                      <ShowMoreWithPopover
                        limit={2}
                        data={getRelatedFacilitiesNames(
                          news.facilityNames,
                          news.groupNames,
                        )}
                      />
                    )}

                    {!user?.companyId && (
                      <ShowMoreWithPopover
                        limit={2}
                        data={
                          news.companyNames
                            ? split(news.companyNames, ',')
                            : ['All']
                        }
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <NoResultTableWrapper
              isLoading={isLoading}
              isFiltering={false}
              pages={data.pages}
            />
          )}
        </Row>
        <Card>
          <CardBody>
            <Pagination
              currentPage={data.page - 1}
              totalPages={data.pages}
              totalRecords={data.count}
              setPage={page => {
                setQuery(prev => ({ ...prev, page: ++page }))
              }}
            />
          </CardBody>
        </Card>

        <DeleteConfirmation
          isOpen={!!deleteId}
          title='Delete news?'
          message={`Are you sure you want to delete "${data.companyNews.find(
            news => news.id === deleteId,
          )?.translations[0].title}" ?`}
          onDelete={onDelete}
          onClose={() => {
            setDeleteId(null)
          }}
        />
      </Container>
    </div>
  )
}

export default CompanyNews
