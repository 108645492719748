import React, { useState } from 'react'
import { Input, FormFeedback, InputProps } from 'reactstrap'

interface PasswordInputProps extends Omit<InputProps, 'type'> {
  error?: string | null
}

const PasswordInput = (props: PasswordInputProps) => {
  const [pasword, showPassword] = useState<boolean>(true)

  return (
    <div>
      <div className='form-icon right is-invalid'>
        <Input
          className='form-control form-control-icon'
          type={pasword ? 'password' : 'text'}
          {...props}
        />
        <i
          className={`cursor-pointer ${
            pasword ? `ri-eye-off-line` : `ri-eye-line`
          }`}
          onClick={() => {
            showPassword(prev => !prev)
          }}
        ></i>
      </div>
      {props.error ? (
        <FormFeedback type='invalid'>{props.error as string}</FormFeedback>
      ) : null}
    </div>
  )
}

export default PasswordInput
