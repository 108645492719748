//SCHEDULED COURSES
import {
  GetScheduledCoursesDTO,
  GetSchedulesByFacilityDTO,
  PostScheduledCoursesDTO,
  ScheduledCourseItem,
  ScheduledEvent,
  TScheduledCourse,
  TUser,
} from '../../sharedTypes'
import {
  GET_SCHEDULED_COURSES,
  GET_SCHEDULED_EVENTS,
  GET_SCHEDULED_INSTRUCTORS,
  SCHEDULED_COURSES,
} from '../url_helper'
import { API } from '../api_helper'

export const postScheduledCourse = (data: PostScheduledCoursesDTO.Request) => {
  return API.post<PostScheduledCoursesDTO.Response>(SCHEDULED_COURSES, data)
}
export const getScheduledCourse = (params: GetScheduledCoursesDTO.Request) => {
  return API.get<GetScheduledCoursesDTO.Response>(SCHEDULED_COURSES, {
    params,
  })
}
export const getScheduledCourseInstructors = () => {
  return API.get<Pick<TUser, 'id' | 'firstName' | 'lastName'>[]>(
    GET_SCHEDULED_INSTRUCTORS,
  )
}
export const getScheduledCourseById = (id: number) => {
  return API.get<TScheduledCourse>(SCHEDULED_COURSES + `/${id}`)
}

export const updateScheduledCourseById = (
  id: number,
  data: Partial<PostScheduledCoursesDTO.Request>,
) => {
  return API.patch<TScheduledCourse>(SCHEDULED_COURSES + `/${id}`, data)
}

export const getSchedulesByFacility = (
  params: GetSchedulesByFacilityDTO.Request,
) => {
  return API.get<ScheduledEvent[]>(GET_SCHEDULED_EVENTS, {
    params,
  }).then(d => d.data)
}

export const getScheduleById = (id: number) => {
  return API.get<ScheduledCourseItem>(`${GET_SCHEDULED_COURSES}/${id}`).then(
    d => d.data,
  )
}
