import React, { useEffect, useState, useCallback } from 'react'
import {
  COURSE_FILTER_FORMATS,
  FilterOption,
  OrderType,
} from '../../../../sharedTypes'
import _ from 'lodash'
import { handleError } from '../../../../helpers/toast_helper'
import { getCourseFeedback } from '../../../../helpers/api_helper'
import ColumnSortIcon from '../../../../Components/Common/ColumnSortIcon'
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import moment from 'moment/moment'
import { Pagination } from '../../../../Components/Common/Pagination'
import Select from 'react-select'
import {
  StatisticsData,
  StatisticsProps,
} from '../../../Courses/OnlineCourses/types'
import NoResultTableWrapper from '../../../../Components/Common/NoResultTableWrapper'
import UserCell from '../../../../Components/Common/UserCellWithDetails'
import { REPORT_TYPE_FILTERS } from '../index'
import { SearchInput } from '../../../../Components/Common/SearchInput'
import Highlighter from 'react-highlight-words'
import {
  CourseFeedbackSortBy,
  GetCourseFeedbackDTO,
} from '../../../../sharedTypes/api/courseReport'
import MultiSelect from '../../../../Components/Common/MultiSelect'
import { AVG_USER_RATING, avgUserRating } from '../types'

const Columns = [
  {
    name: 'Name',
    sortBy: CourseFeedbackSortBy.NAME,
    style: { width: '15%' },
    overflow: false,
  },
  {
    name: 'Overall',
    sortBy: CourseFeedbackSortBy.OVERALL_RATING,
    overflow: true,
  },
  {
    name: 'Material',
    sortBy: CourseFeedbackSortBy.COURSE_MATERIAL,
    overflow: true,
  },
  {
    name: 'Presentation',
    sortBy: CourseFeedbackSortBy.COURSE_PRESENTATION,
    overflow: true,
  },
  {
    name: 'Learned',
    sortBy: CourseFeedbackSortBy.HOW_MUCH_LEARNED,
    overflow: true,
  },
  {
    name: 'Engaging',
    sortBy: CourseFeedbackSortBy.HOW_ENGAGING,
    overflow: true,
  },
  {
    name: 'Date',
    sortBy: CourseFeedbackSortBy.DATE,
    style: { width: 120 },
    overflow: false,
  },
  {
    name: 'Comment',
    sortBy: CourseFeedbackSortBy.COMMENT,
    style: { width: '30%' },
    overflow: false,
  },
]

const statistics: StatisticsData[] = [
  {
    label: 'Overall Rating',
    iconClass: 'ri-graduation-cap-line',
    key: 'overallRatingAvg',
  },
  {
    label: 'Course Material',
    iconClass: 'ri-file-list-3-line',
    key: 'courseMaterialAvg',
  },
  {
    label: 'Course Presentation',
    iconClass: 'bx bx-image-alt',
    key: 'coursePresentationAvg',
  },
  {
    label: 'How Much I learned',
    iconClass: 'bx bx-badge-check',
    key: 'howMuchLearnedAvg',
  },
  {
    label: 'How Engaging It Was',
    iconClass: 'ri-flashlight-line',
    key: 'howEngagingAvg',
  },
]

const reportFilters = [
  {
    label: 'This Month',
    value: REPORT_TYPE_FILTERS.THIS_MONTH,
  },
  {
    label: 'Past Month',
    value: REPORT_TYPE_FILTERS.PAST_MONTH,
  },
  {
    label: 'Past 12 Months',
    value: REPORT_TYPE_FILTERS.PAST_YEAR,
  },
]

interface FeedbackProps {
  courseFormat: COURSE_FILTER_FORMATS
  courseId: number
}

const Statistics = ({ statistics, results }: StatisticsProps) => {
  return (
    <div className='d-flex gap-3 flex-wrap'>
      {statistics.map(statisticsItem => (
        <div key={statisticsItem.key} className='feedback-statistics-item'>
          <div className='d-flex justify-content-between rating-total-value rounded p-3'>
            <div>
              <p className='text-muted mb-0'>{statisticsItem.label}</p>
              <h2 className='mt-4 ff-secondary'>
                <span className='counter-value'>
                  {_.round(results[statisticsItem.key], 2)}
                </span>
              </h2>
            </div>
            <div>
              <div className='avatar-sm flex-shrink-0'>
                <span className='avatar-title rounded-circle fs-3 text-light-purple'>
                  <i className={statisticsItem.iconClass}></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const Feedback = ({ courseFormat, courseId }: FeedbackProps) => {
  const [query, setQuery] = useState<GetCourseFeedbackDTO.Request>({
    page: 1,
    limit: 10,
    courseFormat,
    type: REPORT_TYPE_FILTERS.THIS_MONTH,
  })

  const [data, setData] = useState<GetCourseFeedbackDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    feedback: [],
    statistics: {
      courseMaterialAvg: 0,
      coursePresentationAvg: 0,
      howMuchLearnedAvg: 0,
      howEngagingAvg: 0,
      instructorAvg: 0,
      overallRatingAvg: 0,
    },
  })

  useEffect(() => {
    if (courseId) {
      getCourseFeedback(courseId, query)
        .then(res => {
          setData(res)
        })
        .catch(e => {
          handleError(e)
        })
    }
  }, [query, courseId])

  useEffect(() => {
    setQuery(prev => ({ ...prev, courseFormat }))
  }, [courseFormat])

  const handleSort = useCallback((column: CourseFeedbackSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  return (
    <>
      <Card>
        <CardHeader>
          <Statistics statistics={statistics} results={data.statistics} />
        </CardHeader>
        <CardBody>
          <div className='hstack gap-3 px-3 mx-n3 d-flex justify-content-between flex-wrap'>
            <div className='d-flex align-items-center gap-2'>
              <SearchInput
                style={{ maxWidth: 400 }}
                onChange={key => {
                  setQuery(prev => ({ ...prev, key, page: 1 }))
                }}
                value={query.key || ''}
              />
              <div className='fs-14'>
                Respondents:
                <span className='ms-2'>{data.count}</span>
              </div>
            </div>

            <div className='d-flex flex-shrink-0 gap-3 align-items-center'>
              <div className='fw-medium'>Filter by Rating:</div>

              <MultiSelect
                name='rating'
                id='rating'
                isMulti
                className='filter-by-rating-select'
                options={avgUserRating}
                isSearchable={true}
                placeholder='Select rating'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option: FilterOption[]) => {
                  setQuery(prev => ({
                    ...prev,
                    rating: _.isEmpty(option)
                      ? []
                      : (_.map(option, 'value') as AVG_USER_RATING[]),
                  }))
                }}
                defaultValue={avgUserRating.filter(score =>
                  (query.rating || []).includes(score.value),
                )}
              />
              <Select
                isSearchable={false}
                onChange={option => {
                  if (option) {
                    setQuery(prev => ({ ...prev, type: option.value }))
                  }
                }}
                value={reportFilters.find(
                  filter => filter.value === query.type,
                )}
                options={reportFilters}
                className='select2-container course-feedback-type'
                classNamePrefix='select2-selection form-select'
              />
            </div>
          </div>

          <NoResultTableWrapper
            isLoading={false}
            isFiltering={false}
            pages={data.pages}
            className='px-0'
          >
            <div className='overflow-auto'>
              <Table className='align-middle mb-0'>
                <thead className='table-light'>
                  <tr className='text-muted fs-14'>
                    {Columns.map((column, i) => (
                      <th
                        scope='col'
                        className='align-middle'
                        style={column.style}
                        key={column.name}
                      >
                        <div className='d-flex align-items-center gap-1'>
                          <span
                            className='text-truncate-two-lines'
                            id={`question-${i}`}
                          >
                            {column.name}
                          </span>
                          {!!column.sortBy && (
                            <ColumnSortIcon<CourseFeedbackSortBy>
                              sortOrder={query.orderBy}
                              sortedColumn={query.sortBy}
                              column={column.sortBy}
                              handleSort={handleSort}
                            />
                          )}
                          {column.overflow && (
                            <UncontrolledTooltip
                              placement='top'
                              target={`question-${i}`}
                            >
                              {column.name}
                            </UncontrolledTooltip>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.feedback.map((feedback, i) => (
                    <tr key={i} className='fs-14'>
                      <UserCell
                        user={feedback.user}
                        globalSearch={query.key || ''}
                      />
                      <td>{feedback.overallRating}</td>
                      <td>{feedback.courseMaterial}</td>
                      <td>{feedback.coursePresentation}</td>
                      <td>{feedback.howMuchLearned}</td>
                      <td>{feedback.howEngaging}</td>
                      <td>{moment(feedback.createdAt).format('MM/DD/YYYY')}</td>
                      <td>
                        <Highlighter
                          highlightClassName='text-highlight'
                          className='text-truncate-two-lines'
                          id={`comment-${i}`}
                          searchWords={[query.key || '']}
                          highlightTag={'span'}
                          autoEscape={true}
                          textToHighlight={feedback.comment}
                        />
                        <UncontrolledTooltip
                          placement='top'
                          className='comment--tooltip'
                          target={`comment-${i}`}
                        >
                          {feedback.comment}
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className='my-3'>
              <Pagination
                currentPage={data.page - 1}
                totalPages={data.pages}
                totalRecords={data.count}
                setPage={page => {
                  setQuery(prev => ({ ...prev, page: ++page }))
                }}
              />
            </div>
          </NoResultTableWrapper>
        </CardBody>
      </Card>
    </>
  )
}

export default Feedback
