import { Card, CardBody } from 'reactstrap'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { OrderType } from '../../../sharedTypes'
import React, { useCallback, useEffect, useState } from 'react'
import { handleError } from '../../../helpers/toast_helper'
import PackageCoursesTableView from '../../Courses/Packages/PackageCoursesTableView'
import {
  GetPackageCoursesDTO,
  PackageCoursesSortBy,
} from '../../../sharedTypes/api/coursePackages'
import { getPackageCourses } from '../../../helpers/api/coursePackages'
import { Pagination } from '../../../Components/Common/Pagination'

interface TrainingDayCoursesProps {
  trainingDayId: number
  onReleaseTest: (courseId: number) => void
  disableReleaseBtn: boolean
}

const TrainingDayCourses = ({
  trainingDayId,
  onReleaseTest,
  disableReleaseBtn,
}: TrainingDayCoursesProps) => {
  const [data, setData] = useState<GetPackageCoursesDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    packageCourses: [],
  })

  const [query, setQuery] = useState<GetPackageCoursesDTO.Request>({
    page: 1,
    limit: 10,
  })

  const handleSort = useCallback((column: PackageCoursesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  useEffect(() => {
    getPackageCourses(query, trainingDayId)
      .then(res => {
        setData(res)
      })
      .catch(e => {
        handleError(e)
      })
  }, [trainingDayId, query])

  return (
    <Card>
      <CardBody className='p-0 py-2'>
        <div className='hstack gap-3 mx-n3 justify-content-between px-4 py-1 mb-2 flex-wrap'>
          <SearchInput
            style={{ maxWidth: 300 }}
            onChange={key => {
              setQuery(prev => ({ ...prev, key, page: 1 }))
            }}
            value={query.key || ''}
          />
        </div>

        <PackageCoursesTableView
          packageCourses={data.packageCourses}
          query={query}
          handleSort={handleSort}
          handleDelete={() => {}}
          viewOnly={false}
          fromFacilitatorView={true}
          onReleaseTest={onReleaseTest}
          disableReleaseBtn={disableReleaseBtn}
        />

        <div className='mx-3 my-3'>
          <Pagination
            currentPage={data.page - 1}
            totalPages={data.pages}
            totalRecords={data.count}
            setPage={page => {
              setQuery(prev => ({ ...prev, page: ++page }))
            }}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default TrainingDayCourses
