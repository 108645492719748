import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import bell from '../../../assets/images/svg/bell.svg'
import { Pagination } from '../../../Components/Common/Pagination'
import { GetNotificationsDTO } from '../../../sharedTypes/api/notifications'
import { handleError } from '../../../helpers/toast_helper'
import {
  getNotifications,
  markNotificationAsRead,
} from '../../../helpers/api/notifications'
import moment from 'moment/moment'

const NotificationsList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetNotificationsDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    notifications: [],
    newNotificationsCount: 0,
  })

  const [query, setQuery] = useState<GetNotificationsDTO.Request>({
    page: 1,
    limit: 5,
  })

  useEffect(() => {
    setIsLoading(true)
    getNotifications(query)
      .then(res => {
        setData(res)
      })
      .catch(e => {
        handleError(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const onPageChanged = (page: number) => {
    setQuery(prev => ({ ...prev, page: ++page }))
  }

  const formatNotificationTime = (notificationTime: string) => {
    const now = moment()
    const notificationDateTime = moment(notificationTime)
    const duration = moment.duration(now.diff(notificationDateTime))

    const secondsAgo = Math.floor(duration.asSeconds())
    if (secondsAgo < 60) {
      return `Just ${secondsAgo} seconds ago`
    }

    return notificationDateTime.fromNow()
  }

  const markAsRead = (id: number) => {
    markNotificationAsRead(id)
      .then(() => {
        const updatedNotifications = data.notifications.map(notification => {
          if (notification.id === id) {
            return { ...notification, isRead: true }
          }

          return notification
        })

        setData(prevData => ({
          ...prevData,
          notifications: updatedNotifications,
          newNotificationsCount: data.newNotificationsCount
            ? data.newNotificationsCount - 1
            : 0,
        }))
      })
      .catch(handleError)
  }

  return (
    <div
      className='dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 d-inline-block position-relative w-100
    notifications-list'
      aria-labelledby='page-header-notifications-dropdown'
    >
      <div>
        <div className='dropdown-head bg-primary bg-pattern rounded-top'>
          <div className='p-3'>
            <Row className='align-items-center'>
              <Col>
                <h6 className='m-0 fs-16 fw-semibold text-white'>
                  {' '}
                  Notifications{' '}
                </h6>
              </Col>
              <div className='col-auto dropdown-tabs'>
                <span className='badge badge-soft-light fs-13'>
                  {' '}
                  {data.newNotificationsCount} New
                </span>
              </div>
            </Row>
          </div>
        </div>
        {!data.notifications.length && !isLoading ? (
          <Fragment>
            <div className='w-50 pt-3 mx-auto'>
              <img src={bell} className='img-fluid' alt='user-pic' />
            </div>
            <div className='text-center pb-5 mt-2'>
              <h6 className='fs-18 fw-semibold lh-base'>
                Hey! You have no any notifications{' '}
              </h6>
            </div>
          </Fragment>
        ) : (
          <div className='pe-2'>
            <div className='notifications-container'>
              {data.notifications.map(notification => (
                <div
                  className='text-reset notification-item d-block dropdown-item'
                  key={notification.id}
                >
                  <div className='d-flex'>
                    <div className='flex-shrink-0 avatar-xs me-3'>
                      {notification.relatedUser?.photo ? (
                        <img
                          src={notification.relatedUser.photo}
                          alt=''
                          className='avatar-xs rounded-circle'
                        />
                      ) : (
                        <div className='avatar-xs'>
                          <div className='avatar-title rounded-circle bg-soft-primary text-primary'>
                            {notification.relatedUser?.firstName[0]}{' '}
                            {notification.relatedUser?.lastName[0]}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='flex-1'>
                      <div
                        className={`d-flex justify-content-between ${
                          !notification.isRead ? 'cursor-pointer' : ''
                        }`}
                        onClick={
                          !notification.isRead
                            ? () => markAsRead(notification.id)
                            : undefined
                        }
                      >
                        <h6 className='mt-0 mb-2 lh-base'>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: notification.description,
                            }}
                          ></div>
                        </h6>
                        {!notification.isRead && (
                          <i className='bx bxs-circle text-primary ms-1 fs-10'></i>
                        )}
                      </div>

                      <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                        <span>
                          <i className='mdi mdi-clock-outline me-1'></i>
                          {formatNotificationTime(notification.createdAt)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='pb-3'>
              <Pagination
                currentPage={data.page - 1}
                totalPages={data.pages}
                totalRecords={data.count}
                setPage={onPageChanged}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationsList
