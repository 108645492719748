import { useSearchParams } from 'react-router-dom'

export function useUrlParams() {
  const [searchParams] = useSearchParams()

  const updateUrlParams = (key: string, value: string): string => {
    const newParams = new URLSearchParams(searchParams.toString())
    newParams.set(key, value)
    return newParams.toString()
  }

  const getUrlParamsAsArray = <T>(key: string): T[] => {
    const param = searchParams.get(key)
    return param ? (param.split(',') as unknown as T[]) : []
  }

  const getFacilityUrlParamsAsArray = (key: string): string[] => {
    const param = searchParams.get(key)
    return param ? JSON.parse(param) : []
  }

  const getDataUrlParamsAsArray = (key: string): Date[] => {
    const param = searchParams.get(key)
    return param ? param.split(' ').map(dateStr => new Date(dateStr)) : []
  }

  return {
    updateUrlParams,
    getUrlParamsAsArray,
    getFacilityUrlParamsAsArray,
    getDataUrlParamsAsArray,
  }
}
