import BreadCrumb from '../../../../Components/Common/BreadCrumb'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap'
import { SearchInput } from '../../../../Components/Common/SearchInput'
import {
  ASSIGNMENT_STATUS,
  OrderType,
  ReportingPermissions,
} from '../../../../sharedTypes'
import {
  CoursesAssignmentsSortBy,
  GetCoursesAssignmentsDTO,
} from '../../../../sharedTypes/api/courseHistory'
import ColumnSortIcon from '../../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../../Components/Common/NoResultTableWrapper'
import { getCoursesAssignments } from '../../../../helpers/api/assignments'
import { handleError } from '../../../../helpers/toast_helper'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { Pagination } from '../../../../Components/Common/Pagination'
import UserCell from '../../../../Components/Common/UserCellWithDetails'
import Filters from '../Filters'
import { EXPORT_TYPES, SelectedFilters } from '../types'
import ExportAssignmentsModal, {
  REPORT_TYPES,
} from '../../../../Components/Modals/ExportAssignmentsModal'
import { onPrint } from '../../../../helpers/common'
import _ from 'lodash'
import { useAppSelector } from '../../../../hooks/redux'
import UserAssociations from '../../../../Components/Common/UserAssociations'
import ItemsPerPageDropdown from '../../../../Components/Common/ItemsPerPageDropdown'

const Columns = [
  {
    name: 'User',
    sortBy: CoursesAssignmentsSortBy.USER,
  },
  {
    name: 'Department',
    style: { width: 245 },
  },
  {
    name: 'Agency',
    sortBy: CoursesAssignmentsSortBy.AGENCY_NAME,
    style: { width: 245 },
  },
  {
    name: 'Code',
    sortBy: CoursesAssignmentsSortBy.CODE,
  },
  {
    name: 'Course',
    sortBy: CoursesAssignmentsSortBy.COURSE,
  },
  {
    name: 'Course Type',
    sortBy: CoursesAssignmentsSortBy.COURSE,
  },
  {
    name: 'Completion Date',
    sortBy: CoursesAssignmentsSortBy.COMPLETED,
    style: { width: 170 },
  },
  {
    name: 'Attestation Statement',
    style: { width: 100 },
  },
]

const CourseHistorySummary = () => {
  document.title = 'Course History Summary | Mastered - Admin & Dashboard'

  const admin = useAppSelector(state => state.User.user)

  const [showFilters, setShowFilters] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const [exportModal, setExportModal] = useState<EXPORT_TYPES | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    agencies: [],
    positions: [],
    departments: [],
    courses: [],
  })

  const [query, setQuery] = useState<GetCoursesAssignmentsDTO.Request>({
    page: 1,
    limit: 10,
    sortBy: CoursesAssignmentsSortBy.USER,
    orderBy: OrderType.ASC,
    status: [ASSIGNMENT_STATUS.COMPLETED],
    permission: ReportingPermissions.VIEW_COURSE_HISTORY_SUMMARY_REPORT,
    facilityIds: [],
    agencyIds: [],
  })

  const [data, setData] = useState<GetCoursesAssignmentsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    assignments: [],
  })

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      const _query = _.omitBy(query, _.isNil)
      if (_query.completedDate && !_.isEmpty(_query.completedDate)) {
        _query.completedDate = [
          moment(_query.completedDate[0]).format('YYYY-MM-DD'),
          moment(_query.completedDate[1]).format('YYYY-MM-DD'),
        ]
      }
      getCoursesAssignments(_query as GetCoursesAssignmentsDTO.Request)
        .then(d => d.data)
        .then(res => {
          setData(res)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  const handleSort = useCallback((column: CoursesAssignmentsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'Completed Courses Report'}
            items={[
              {
                active: true,
                title: 'Reporting',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      <Dropdown
                        isOpen={exportDropdownOpen}
                        toggle={() => {
                          setExportDropdownOpen(prevState => !prevState)
                        }}
                      >
                        <DropdownToggle
                          className=' btn btn-secondary align-middle'
                          tag='button'
                        >
                          <i className='mdi mdi-file-export-outline me-1 fs-16'></i>
                          Export
                        </DropdownToggle>
                        <DropdownMenu end>
                          {Object.values(EXPORT_TYPES).map(type => (
                            <DropdownItem key={type}>
                              <span
                                className={`mx-2 ${
                                  type === EXPORT_TYPES.PDF
                                    ? 'text-green-500'
                                    : ''
                                }`}
                                onClick={() => setExportModal(type)}
                              >
                                Export as {type}
                              </span>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => onPrint('course-history-summary')}
                      >
                        <i className='ri-printer-line me-1 fs-16'></i>
                      </Button>
                    </div>
                  </div>

                  <Row
                    className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}
                  >
                    <Filters
                      visible={showFilters}
                      setFilters={data => {
                        setQuery(prev => ({
                          ...prev,
                          facilityIds: data.facility,
                          agencyIds: data.agencies,
                          groupIds: data.group,
                          positionIds: data.position,
                          departmentIds: data.department,
                          courseIds: data.course,
                          completedDate: data.completedDate,
                          courseType: data.courseTypes,
                          page: 1,
                        }))
                        if (!fetchData) {
                          setFetchData(true)
                        }
                      }}
                      filterBy={{
                        status: false,
                        courseType: true,
                      }}
                      setSelectedFilters={setSelectedFilters}
                    />
                  </Row>
                </CardHeader>

                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card overflow-auto'>
                    <Table
                      className='align-middle mb-0'
                      id='course-history-summary'
                    >
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {Columns.map(column => (
                            <th
                              scope='col'
                              className='align-middle'
                              style={column.style}
                              key={column.name}
                            >
                              <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center justify-content-center'>
                                  <span className='header-name'>
                                    {column.name}
                                  </span>
                                  {!!column.sortBy && (
                                    <ColumnSortIcon<CoursesAssignmentsSortBy>
                                      sortOrder={query.orderBy}
                                      sortedColumn={query.sortBy}
                                      column={column.sortBy}
                                      handleSort={handleSort}
                                    />
                                  )}{' '}
                                </div>
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.assignments.map((assignment, i) => (
                          <tr key={i} className='fs-14'>
                            <UserCell
                              user={assignment.user}
                              globalSearch={query.key || ''}
                              className={'summary-user-info'}
                              displayFacility={
                                !admin?.isFacilityAdmin ||
                                admin?.hasAccessToMultipleFacilities
                              }
                            />

                            <UserAssociations
                              data={assignment.user.departments || []}
                              key={query.key || ''}
                            />

                            <UserAssociations
                              data={
                                assignment.user.agency
                                  ? [assignment.user.agency]
                                  : []
                              }
                              key={query.key || ''}
                            />

                            <td>
                              <Highlighter
                                highlightClassName='text-highlight'
                                searchWords={[query.key || '']}
                                className={'text-muted'}
                                highlightTag={'span'}
                                autoEscape={true}
                                textToHighlight={assignment.code}
                              />
                            </td>
                            <td>
                              <span>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={
                                    assignment.course.translations[0].content
                                      .name || '-'
                                  }
                                />
                              </span>
                            </td>
                            <td>
                              <span className='text-body'>
                                {assignment.course.type}
                              </span>
                            </td>
                            <td>
                              <div className='vstack'>
                                <span className='text-muted'>
                                  {moment(assignment.endDate).format(
                                    'MM/DD/YYYY',
                                  )}
                                </span>
                                <span className='text-muted'>
                                  {moment(assignment.endDate).format('hh:MM A')}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className='text-center'>
                                {assignment.attestationAccepted ? (
                                  <i className='ri-check-line text-green-500'></i>
                                ) : (
                                  <i className='ri-close-line text-danger'></i>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className='mx-3 my-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ? query.limit : 10}
                        onChange={limit =>
                          setQuery(prev => ({ ...prev, limit: +limit.label }))
                        }
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                        limit={query.limit}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
        {exportModal && (
          <ExportAssignmentsModal
            onClose={() => setExportModal(null)}
            isOpen={!!exportModal}
            exportType={exportModal}
            query={query}
            selectedFilters={selectedFilters}
            reportType={REPORT_TYPES.COURSE_HISTORY_SUMMARY}
          />
        )}
      </div>
    </Fragment>
  )
}

export default CourseHistorySummary
