import { FormikErrors } from 'formik'
import formatBytes from './formatBytes'

export default function <T>(
  files: File[],
  field: string,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<T>>,
) {
  files.map((file: File) =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    }),
  )
  setFieldValue(field, files[0])
}
