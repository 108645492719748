import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalProps,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from 'reactstrap'
import moment from 'moment'
import _ from 'lodash'
import { toast } from 'react-toastify'

import {
  AttachmentsAccessEnum,
  AttachmentsTypesEnum,
  BadgeColorByAttachmentAccess,
  BadgeColorByAttachmentType,
  CoursesPermissions,
  TAttachment,
} from '../../sharedTypes'
import MIcon from '../../assets/images/svg/M.svg'
import { getUserDisplayName } from '../../helpers/user'
import formatBytes from '../../utils/formatBytes'
import { copyToClipboard } from '../../utils/clipboard'
import { InputWithSuffix } from '../Common/InputWithSuffix'
import {
  getAttachmentMentions,
  updateAttachment,
} from '../../helpers/api/attachments'
import { handleError, successToastOptions } from '../../helpers/toast_helper'
import { usePermissions } from '../../hooks/usePermissions'

interface FileDetailsProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (attachment: TAttachment) => void
  attachment: TAttachment
  setDeleteMedia: (attachment: TAttachment) => void
}

const FileDetailsModal = ({
  onClose,
  isOpen,
  attachment,
  setDeleteMedia,
  onSubmit,
}: FileDetailsProps) => {
  const [mentions, setMentions] = useState<string[]>([])
  const [isEdit, setIsEdit] = useState(false)
  const [fileName, setFileName] = useState('')
  const [meta, setMeta] = useState<any>({})

  const hasPermissionToDelete = usePermissions(CoursesPermissions.DELETE_MEDIA)
  const hasPermissionToEdit = usePermissions(CoursesPermissions.EDIT_MEDIA)

  const ext = useMemo(() => {
    const index = attachment.name.lastIndexOf('.')
    if (index >= 0) {
      return attachment.name.substr(index)
    }
    return ''
  }, [attachment])

  const originalName = useMemo(() => {
    const index = attachment.name.lastIndexOf('.')
    if (index >= 0) {
      return attachment.name.substr(0, index)
    }
    return attachment.name
  }, [attachment])

  const onEdit = () => {
    setIsEdit(true)
    setFileName(originalName)
  }

  //Fetch mentions
  useEffect(() => {
    getAttachmentMentions(attachment.id)
      .then(res => {
        if (res.data.news) {
          setMentions(
            res.data.news?.map(
              news => news.translations[0].title.toString() + ' News',
            ),
          )
        }
      })
      .catch(() => {})
  }, [attachment])

  const submitEdit = () => {
    if (!_.trim(fileName)) {
      return
    }
    updateAttachment(attachment.id, { name: _.trim(fileName) + ext })
      .then(res => {
        toast('File name successfully updated', successToastOptions)
        setFileName('')
        setIsEdit(false)
        onSubmit(res.data)
      })
      .catch(handleError)
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const [visibleItems, setVisibleItems] = useState<string[]>([])
  const [hiddenItemCount, setHiddenItemCount] = useState(0)

  useEffect(() => {
    const calculateVisibleItems = () => {
      const container = containerRef.current
      if (container) {
        const containerWidth = container.clientWidth - 25

        let visibleWidth = 0
        let itemCount = 0
        const _visibleItems: string[] = []

        mentions.every((item, index) => {
          const itemElems = document.createElement('span')
          itemElems.innerText = index !== 0 ? `, ${item}` : item
          // Append the element to the document or any specific container before measuring its width
          document.body.appendChild(itemElems)
          const itemWidth = itemElems.offsetWidth

          // Remove the element if you no longer need it
          itemElems.remove()
          if (visibleWidth + itemWidth <= containerWidth) {
            visibleWidth += itemWidth
            itemCount++
            _visibleItems.push(item)
            return true
          } else {
            return false
          }
        })

        setVisibleItems(_visibleItems)

        if (itemCount >= mentions.length) {
          setHiddenItemCount(0)
        } else {
          setHiddenItemCount(mentions.length - itemCount)
        }
      }
    }

    window.addEventListener('resize', calculateVisibleItems)
    calculateVisibleItems()

    return () => {
      window.removeEventListener('resize', calculateVisibleItems)
    }
  }, [mentions])

  const renderDimensions = useCallback(() => {
    return (
      <Col>
        <span className='fw-medium'>Dimensions</span>
        <div>{meta.width ? meta.width + '×' + meta.height : '-'}</div>
      </Col>
    )
  }, [meta])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered size='xl'>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>File Details</h5>
          <div className='hstack gap-2'>
            {attachment.canDelete && hasPermissionToDelete && (
              <span
                className='badge badge-red fw-normal p-1 d-flex align-items-center flex-0 flex-grow-0'
                id={'accessAttachment' + attachment.id}
              >
                <UncontrolledTooltip
                  placement='top'
                  target={'deleteModalAttachment' + attachment.id}
                >
                  Delete
                </UncontrolledTooltip>
                <i
                  className='ri-delete-bin-2-line cursor-pointer text-danger fs-16'
                  id={'deleteModalAttachment' + attachment.id}
                  onClick={() => setDeleteMedia(attachment)}
                ></i>
              </span>
            )}
            <i
              className='ri-close-line fs-24 cursor-pointer text-muted'
              onClick={onClose}
            ></i>
          </div>
        </div>
        <Row>
          <Col className='w-50 vstack'>
            {attachment.type === AttachmentsTypesEnum.DOCUMENT && (
              <iframe
                className='w-100'
                style={{ aspectRatio: 0.8 }}
                src={
                  'https://docs.google.com/gview?embedded=true&url=' +
                  attachment.path
                }
              ></iframe>
            )}
            {attachment.type === AttachmentsTypesEnum.IMAGE && (
              <img
                className='card-img-top img-fluid'
                src={attachment.path}
                alt={attachment.name}
                onLoad={e => {
                  setMeta({
                    width: _.get(e, 'target.naturalWidth'),
                    height: _.get(e, 'target.naturalHeight'),
                  })
                }}
              />
            )}
            {attachment.type === AttachmentsTypesEnum.VIDEO && (
              <video
                controls
                className='w-100'
                onLoadedMetadata={event => {
                  setMeta({
                    width: _.get(event, 'target.videoWidth'),
                    height: _.get(event, 'target.videoHeight'),
                  })
                }}
                src={attachment.path}
              />
            )}
          </Col>
          <Col className='w-50 vstack gap-3'>
            <Row>
              <Col>
                <span className='fw-medium'>Title</span>
                <div className='hstack'>
                  {!isEdit ? (
                    <>
                      <span className='text-truncate'>{originalName}</span>
                      <span className='text-muted'>{ext}</span>
                    </>
                  ) : (
                    <InputWithSuffix
                      value={fileName}
                      suffix={ext}
                      onChange={event => {
                        setFileName(_.trimStart(event.target.value))
                      }}
                      className='my-2'
                    />
                  )}
                  {!isEdit && attachment.canEdit && hasPermissionToEdit && (
                    <div className='ms-auto'>
                      <UncontrolledTooltip
                        placement='top'
                        target={'editTitle' + attachment.id}
                      >
                        Edit
                      </UncontrolledTooltip>
                      <i
                        className='ps-2 ri-pencil-line cursor-pointer'
                        id={'editTitle' + attachment.id}
                        onClick={onEdit}
                      ></i>
                    </div>
                  )}
                </div>
                {isEdit && (
                  <div className='hstack gap-2 justify-content-end'>
                    <Button
                      className='btn-soft-primary'
                      onClick={() => {
                        setIsEdit(false)
                      }}
                      active={false}
                    >
                      Cancel
                    </Button>
                    <Button
                      color='success'
                      disabled={!_.trim(fileName)}
                      onClick={submitEdit}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='fw-medium'>File Type</span>
                <div>
                  <span
                    className={`badge badge-${
                      BadgeColorByAttachmentType[attachment.type]
                    } fs-12 fw-normal text-capitalize`}
                  >
                    {attachment.type}
                  </span>
                </div>
              </Col>
              <Col>
                <span className='fw-medium'>Access</span>
                <div>
                  <div className='d-inline-block'>
                    <span
                      className={`badge badge-${
                        BadgeColorByAttachmentAccess[attachment.access]
                      } fw-normal p-1 d-flex align-items-center flex-0 flex-grow-0 gap-1`}
                      id={'accessAttachment' + attachment.id}
                    >
                      {attachment.access === AttachmentsAccessEnum.MASTERED ? (
                        <img src={MIcon} className='img-fluid' alt='' />
                      ) : (
                        <i className='ri-shield-keyhole-line fs-16'></i>
                      )}{' '}
                      <span className='fs-12 fw-normal'>
                        {attachment.access}
                      </span>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='fw-medium'>File size</span>
                <div>{formatBytes(attachment.size)}</div>
              </Col>
              {attachment.type === AttachmentsTypesEnum.IMAGE &&
                renderDimensions()}
              {attachment.type === AttachmentsTypesEnum.VIDEO && (
                <Col>
                  <span className='fw-medium'>Resolution</span>
                  <div>-</div>
                </Col>
              )}
            </Row>
            {attachment.type === AttachmentsTypesEnum.VIDEO && (
              <Row>{renderDimensions()}</Row>
            )}
            <Row>
              <Col>
                <span className='fw-medium'>Mentions</span>
                <div
                  style={{
                    width: '100%',
                  }}
                  ref={containerRef}
                >
                  {!_.isEmpty(visibleItems) ? visibleItems.join(', ') : '-'}
                  {hiddenItemCount > 0 && (
                    <>
                      <UncontrolledTooltip
                        placement='top'
                        target='moreMentions'
                      >
                        {mentions.slice(-hiddenItemCount).join(', ')}
                      </UncontrolledTooltip>
                      <span
                        className='ms-2 text-primary'
                        id='moreMentions'
                      >{`+${hiddenItemCount}`}</span>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='fw-medium'>Uploaded by</span>
                <div>{getUserDisplayName(attachment.uploadedBy)}</div>
              </Col>
              <Col>
                <span className='fw-medium'>Uploaded on</span>
                <div>{moment(attachment.createdAt).format('MM/DD/YYYY')}</div>
              </Col>
            </Row>
            <Row className='position-relative'>
              <Col>
                <span className='fw-medium'>Original file URL</span>
                <div className='hstack gap-2'>
                  <span className='text-truncate'>{attachment.path}</span>
                  <span className='d-flex gap-2 text-muted justify-content-end'>
                    <UncontrolledTooltip
                      placement='top'
                      target={'downloadAttachment' + attachment.id}
                    >
                      Download
                    </UncontrolledTooltip>
                    <i
                      className='ri-download-2-fill cursor-pointer'
                      id={'downloadAttachment' + attachment.id}
                      onClick={() => {
                        const link = document.createElement('a')
                        link.href = attachment.path
                        link.download = attachment.name
                        link.click()
                      }}
                    ></i>
                    {attachment.canEdit && (
                      <UncontrolledTooltip
                        placement='top'
                        target={'copyAttachment' + attachment.id}
                      >
                        Copy URL
                      </UncontrolledTooltip>
                    )}
                    <i
                      className='ri-file-copy-line cursor-pointer'
                      id={'copyAttachment' + attachment.id}
                      onClick={() => {
                        copyToClipboard(attachment.path)
                      }}
                    ></i>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default FileDetailsModal
