import {
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { generalCompetencySchema } from '../../schemas'
import { putCompetencyGeneralCourse } from '../../helpers/api_helper'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../helpers/toast_helper'
import { OnlineCourseItem, ViewResultReportEnum } from '../../sharedTypes'
import { useFormik, FormikProps } from 'formik'
import _, { get } from 'lodash'
import { useShouldGoTo, IGoTo } from '../../hooks/course/useShouldGoTo'

interface ICompetencyMessage {
  success: string
  fail: string
  retake: string
  languageId: number
}

interface IForm {
  quizQuestionsNumber: number
  randomizeQuestions: boolean
  quizAttempts: number
  viewResultReport: ViewResultReportEnum
  messages: ICompetencyMessage[]
}

export interface IUseCompetencyStep {
  setSelectedLanguage: Dispatch<SetStateAction<number | null>>
  selectedLanguage: null | number
  form: FormikProps<IForm>
  hasUnsavedData: boolean
  onPreHandleSubmit: (props: IGoTo) => void
  onDiscardChanges: () => void
  onHandleUpdate: (values: IForm, publish?: boolean) => void
}

export const useCompetencyStep = ({
  course,
  onBack,
}: {
  course: OnlineCourseItem | null
  onBack: () => void
}) => {
  const { setShouldGoTo, onSuccess } = useShouldGoTo({
    goNext: () => {},
    onBack,
  })
  const [initialValue, setInitialValue] = useState<IForm | null>()
  const [selectedLanguage, setSelectedLanguage] = useState<number | null>(null)

  useEffect(() => {
    setInitialFormValues()
  }, [course])

  const onDiscardChanges = useCallback(() => {
    setInitialFormValues()
  }, [course])

  const setInitialFormValues = useCallback(() => {
    if (course) {
      const values: Partial<IForm> = {}
      setSelectedLanguage(
        get(course, 'competencyTest.messages[0].languageId', null),
      )
      values.quizQuestionsNumber = course.competencyTest.quizQuestionsNumber
      values.randomizeQuestions = course.competencyTest.randomizeQuestions
      values.viewResultReport = course?.competencyTest.viewResultReport
      values.quizAttempts = course?.competencyTest.quizAttempts
      values.messages = course?.competencyTest.messages.map(m => ({
        success: m.content.success,
        fail: m.content.fail,
        retake: m.content.retake,
        languageId: m.languageId,
      }))
      form.setValues(values as IForm, true)
      setInitialValue(values as IForm)
    }
  }, [course])

  const onSubmit = useCallback(
    async (values: IForm) => {
      try {
        await onHandleUpdate(values)
      } catch (e) {}
    },
    [course, onSuccess],
  )

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      quizAttempts: 1,
      quizQuestionsNumber: 1,
      randomizeQuestions: false,
      viewResultReport: ViewResultReportEnum.NO,
      messages: [],
    },
    validationSchema: generalCompetencySchema,
    onSubmit,
  })

  const onHandleUpdate = useCallback(
    async (values: IForm, publish?: boolean) => {
      const { messages, ...rest } = values
      const parsedMessages = messages.map(({ languageId, ...content }) => ({
        content,
        languageId,
      }))
      await putCompetencyGeneralCourse(Number(course?.id), {
        ...rest,
        messages: parsedMessages,
      })
      onSuccess()
      if (publish) {
      } else {
        toast(
          'Success - Competency general information successfully updated',
          successToastOptions,
        )
      }
    },
    [form.values, course, onSuccess],
  )

  const hasUnsavedData = useMemo(
    () => JSON.stringify(form.values) !== JSON.stringify(initialValue),
    [initialValue, form.values],
  )

  const onPreHandleSubmit = useCallback(
    (props: IGoTo) => {
      setShouldGoTo(props)
      form.handleSubmit()
    },
    [form],
  )

  return {
    form,
    selectedLanguage,
    setSelectedLanguage,
    onPreHandleSubmit,
    hasUnsavedData,
    onDiscardChanges,
    onHandleUpdate,
  }
}
