import React from 'react'

export const NotCorrectAnswerIcon = ({
  className = 'fs-20',
}: {
  className?: string
}) => {
  return (
    <div className='rounded-circle'>
      <i className={`bx bx-circle ${className} px-1 text-muted`}></i>
    </div>
  )
}

export const CorrectAnswerIcon = ({
  className = 'fs-16',
}: {
  className?: string
}) => {
  return (
    <div className='bg-soft-info rounded-circle me-1'>
      <i className={`ri-check-fill ${className} text-green-500 px-1`}></i>
    </div>
  )
}
