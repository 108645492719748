import React, { useCallback, useState } from 'react'
import { handleError, successToastOptions } from '../../helpers/toast_helper'
import { toast } from 'react-toastify'
import { createCourseRevision } from '../../helpers/api_helper'
import CourseRevisionModal from '../../Components/Modals/CourseRevisionModal'
import { CreateCourseRevisionDTO } from '../../sharedTypes'
import moment from 'moment/moment'

export const useCourseRevision = (afterSubmit?: (courseId: number) => void) => {
  const [revisionCourseId, setRevisionCourseId] = useState<number | null>(null)

  const onCourseRevisionCreate = useCallback(
    async (values: CreateCourseRevisionDTO.Request) => {
      if (revisionCourseId) {
        try {
          setRevisionCourseId(null)
          const res = await createCourseRevision(revisionCourseId, {
            expirationDate: moment(values.expirationDate).format('YYYY-MM-DD'),
          })
          toast(
            'Success - Course revision has been created',
            successToastOptions,
          )
          if (afterSubmit) {
            afterSubmit(res.id)
          }
        } catch (e) {
          handleError(e)
        }
      }
    },
    [revisionCourseId, afterSubmit],
  )

  const renderCourseRevisionModal = useCallback(() => {
    return (
      <CourseRevisionModal
        onClose={() => setRevisionCourseId(null)}
        isOpen={!!revisionCourseId}
        onCourseRevisionCreate={onCourseRevisionCreate}
      />
    )
  }, [revisionCourseId, onCourseRevisionCreate])

  return { renderCourseRevisionModal, setRevisionCourseId }
}
