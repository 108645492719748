import { ScheduledCourseTypes } from '../../sharedTypes'

export enum CategoryTypes {
  'INFO' = 'info',
  'WARNING' = 'warning',
  'PRIMARY' = 'primary',
}

export const scheduledEventTypes = [
  {
    title: 'Virtual Conference',
    value: ScheduledCourseTypes.WEBINAR,
    type: CategoryTypes.PRIMARY,
  },
  {
    title: 'In-Person',
    value: ScheduledCourseTypes.IN_PERSON,
    type: CategoryTypes.INFO,
  },
]
