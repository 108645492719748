import { Table, UncontrolledTooltip } from 'reactstrap'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import NoResults from '../../../../assets/images/no-result.png'
import { getSurveyResponses } from '../../../../helpers/api/surveys'
import {
  GetSurveyResponsesDTO,
  OrderType,
  SurveyAnswersSortBy,
  QuestionItem,
  TUser,
} from '../../../../sharedTypes'
import NoResultTableWrapper from '../../../../Components/Common/NoResultTableWrapper'
import { SearchInput } from '../../../../Components/Common/SearchInput'
import ColumnSortIcon from '../../../../Components/Common/ColumnSortIcon'
import Highlighter from 'react-highlight-words'
import { getUserDisplayName } from '../../../../helpers/user'
import { Pagination } from '../../../../Components/Common/Pagination'
import _ from 'lodash'
import moment from 'moment/moment'
import { useAppSelector } from '../../../../hooks/redux'

interface ResponsesProps {
  surveyId: number
  questions: QuestionItem[]
}

interface ColumnInterface {
  name: string | JSX.Element
  sortBy: SurveyAnswersSortBy
  style?: object
}

const initialColumns: ColumnInterface[] = [
  {
    name: 'Name',
    sortBy: SurveyAnswersSortBy.USER,
  },
  {
    name: 'Date',
    sortBy: SurveyAnswersSortBy.CREATED_AT,
  },
]

const Responses = ({ surveyId, questions }: ResponsesProps) => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [fetchData, setFetchData] = useState(false)
  const [query, setQuery] = useState<GetSurveyResponsesDTO.Request>({
    surveyId,
    page: 1,
    limit: 10,
  })

  const responseColumns = useMemo(() => {
    return questions
      .filter(f => f.languageId === 1)
      .map(q => ({
        name: q.question,
        sortBy: `responses.${q.id}`,
      }))
  }, [questions])

  const [data, setData] = useState<GetSurveyResponsesDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    responses: [],
  })

  useEffect(() => {
    if (fetchData) {
      getSurveyResponses(query)
        .then(setData)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  useEffect(() => {
    const facilityId = selectedFacilityId ?? undefined
    const groupId = selectedGroupId ?? undefined
    if (selectedFacilityId && selectedFacilityId !== query.facilityId) {
      setQuery(q => ({ ...q, facilityId, groupId: undefined }))
    }
    if (selectedGroupId && selectedGroupId !== query.groupId) {
      setQuery(q => ({ ...q, facilityId: undefined, groupId }))
    }

    if (!selectedGroupId && !selectedFacilityId) {
      setQuery(q => ({ ...q, facilityId, groupId }))
    }

    setFetchData(true)
  }, [selectedFacilityId, selectedGroupId])

  useEffect(() => {
    if (selectedFacilityId && selectedFacilityId !== query.facilityId) {
      setQuery(q => ({ ...q, facilityId: selectedFacilityId }))
    }
  }, [selectedFacilityId, query])

  const handleSort = useCallback((sortBy: SurveyAnswersSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  return (
    <NoResultTableWrapper
      noDataErrorMessage={'There are no responses to this survey yet.'}
      noDataErrorMessageImage={NoResults}
      isLoading={isLoading}
      isFiltering={!!query.key}
      pages={data.pages}
    >
      <div className='mt-3'>
        <SearchInput
          style={{ maxWidth: 400 }}
          onChange={key => {
            setQuery(prev => ({ ...prev, key, page: 1 }))
          }}
          value={query.key}
        />
        <div className='table-card mt-3'>
          <div className='overflow-x-auto'>
            <Table className='align-middle mb-0 table-nowrap surveys-table survey-responses'>
              <thead className='table-light'>
                <tr className='text-muted fs-14'>
                  {initialColumns.map((column: any, index) => (
                    <th
                      scope='col'
                      className='align-middle'
                      key={index}
                      style={column.style || {}}
                    >
                      {column.name}
                      {!!column.sortBy && (
                        <ColumnSortIcon<SurveyAnswersSortBy>
                          sortOrder={query.orderBy}
                          sortedColumn={query.sortBy}
                          column={column.sortBy}
                          handleSort={handleSort}
                        />
                      )}
                    </th>
                  ))}
                  {responseColumns.map((column: any, index) => (
                    <th
                      scope='col'
                      className='align-middle'
                      key={index}
                      style={column.style || {}}
                    >
                      <div className='d-flex align-items-center gap-1'>
                        <span
                          className='text-truncate-two-lines'
                          id={`survey-question-${index}`}
                        >
                          {column.name}
                        </span>
                        {!!column.sortBy && (
                          <ColumnSortIcon<SurveyAnswersSortBy>
                            sortOrder={query.orderBy}
                            sortedColumn={query.sortBy}
                            column={column.sortBy}
                            handleSort={handleSort}
                          />
                        )}
                        <UncontrolledTooltip
                          placement='top'
                          target={`survey-question-${index}`}
                        >
                          {column.name}
                        </UncontrolledTooltip>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.responses.map((response, i) => (
                  <tr key={i} className='fs-14'>
                    <td>
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={
                          response.isAnonymous
                            ? 'Anonymous'
                            : getUserDisplayName(
                                response.user as Partial<TUser>,
                              )
                        }
                      />
                      {!response.isAnonymous && (
                        <UncontrolledTooltip
                          placement='top'
                          target={`user-details-${i}`}
                        >
                          {response.user?.position?.name || ''}
                        </UncontrolledTooltip>
                      )}
                      {response.user && (
                        <div
                          className='text-muted fs-12 user-details-overflow'
                          id={`user-details-${i}`}
                        >
                          {response.user?.position?.name || '-'}
                        </div>
                      )}
                    </td>
                    <td>{moment(response.createdAt).format('MM.DD.YYYY')}</td>
                    {responseColumns.map((col, index) => (
                      <td key={index}>
                        <Highlighter
                          highlightClassName='text-highlight'
                          searchWords={[query.key || '']}
                          highlightTag={'span'}
                          autoEscape={true}
                          textToHighlight={_.get(response, col.sortBy) || ''}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className='mx-3 mt-3'>
            <Pagination
              limit={query.limit}
              currentPage={data.page - 1}
              totalPages={data.pages}
              totalRecords={data.count}
              setPage={page => {
                setQuery(prev => ({ ...prev, page: ++page }))
              }}
            />
          </div>
        </div>
      </div>
    </NoResultTableWrapper>
  )
}

export default Responses
