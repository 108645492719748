import { TUser } from './user'

export enum LOG_TYPES {
  'ERROR' = 'error',
  'NEW' = 'new',
  'UPDATED' = 'updated',
}

export enum LOG_ERROR_STATUS {
  'OPEN' = 'open',
  'CLOSED' = 'closed',
}

export type TLog = {
  id: number
  userId: number | null
  employeeId: number
  createdAt: string
  updatedAt: string
  user: TUser | null
  rawUser: any
  type: LOG_TYPES
  notes: string
  status: LOG_ERROR_STATUS
}
