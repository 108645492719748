import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { SearchInput } from '../../../Components/Common/SearchInput'
import FilterTabs from '../../../Components/Common/FilterTabs'
import {
  OrderType,
  ScheduledCoursesSortBy,
  TScheduledCourse,
  GetScheduledCoursesDTO,
} from '../../../sharedTypes'
import UserCell from '../../../Components/Common/UserItemCell'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { SCHEDULED_COURSE_FORMATS } from '../../../sharedTypes'
import { getScheduledCourse } from '../../../helpers/api_helper'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import Highlighter from 'react-highlight-words'
import { Pagination } from '../../../Components/Common/Pagination'
import { Link } from 'react-router-dom'
import moment from 'moment'
import EventModal from '../../../Components/Modals/EventModal'
import { BadgeColorByStatus, BadgeColorByFormat } from '../../../helpers/course'
import Filters from './Filters'
import _ from 'lodash'

const tabs = [
  { label: 'All', value: undefined },
  { label: 'In-Person', value: SCHEDULED_COURSE_FORMATS.IN_PERSON },
  { label: 'Webinar', value: SCHEDULED_COURSE_FORMATS.WEBINAR },
]

const Columns = [
  {
    name: 'ID',
    sortBy: ScheduledCoursesSortBy.ID,
    style: { width: 120 },
  },
  {
    name: 'Course',
    sortBy: ScheduledCoursesSortBy.NAME,
  },
  {
    name: 'Instructor',
    sortBy: ScheduledCoursesSortBy.INSTRUCTOR,
  },
  {
    name: 'Format',
    sortBy: ScheduledCoursesSortBy.FORMAT,
  },
  {
    name: 'Category',
    sortBy: ScheduledCoursesSortBy.CATEGORY,
  },
  {
    name: 'Status',
    sortBy: ScheduledCoursesSortBy.STATUS,
  },
  {
    name: 'Date',
    sortBy: ScheduledCoursesSortBy.DATE,
  },
  {
    name: 'Start Time',
    sortBy: ScheduledCoursesSortBy.TIME,
  },
  {
    name: 'End Time',
    sortBy: ScheduledCoursesSortBy.DURATION,
  },
  {
    name: 'Attendees',
  },
  {
    name: 'Actions',
    style: { width: 150 },
  },
]

const ScheduledCourses = ({ router }: WithRouterProps) => {
  document.title = 'Scheduled Courses | Mastered - Admin & Dashboard'

  const [isLoading, setIsLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [data, setData] = useState<GetScheduledCoursesDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    courses: [],
  })

  const [query, setQuery] = useState<GetScheduledCoursesDTO.Request>({
    page: 1,
    limit: 10,
    status: undefined,
    type: undefined,
    instructorIds: undefined,
    categoryIds: undefined,
    format: undefined,
  })

  const isFiltering = useMemo<boolean>(
    () =>
      !_.isEmpty(query.status) ||
      !_.isEmpty(query.type) ||
      !_.isEmpty(query.instructorIds) ||
      !_.isEmpty(query.categoryIds) ||
      !!query.format ||
      !!query.key,
    [query],
  )

  const [showEventModal, setEventToShow] = useState<TScheduledCourse | null>(
    null,
  )

  const handleSort = useCallback((column: ScheduledCoursesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onUpdateCourse = (_course: TScheduledCourse) => {
    setData(data => ({
      ...data,
      courses: data.courses.map(course => {
        if (course.id === _course.id) {
          return _course
        }
        return course
      }),
    }))
  }

  //Fetch data
  useEffect(() => {
    setIsLoading(true)
  }, [query])

  const navigateToCourse = (id: number | string) => {
    router.navigate('/courses/scheduled-courses/manage/' + id)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Scheduled Courses'
            items={[
              {
                active: true,
                title: 'Scheduled Courses',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <FilterTabs<SCHEDULED_COURSE_FORMATS>
                      tabs={tabs}
                      navTab={query.format}
                      navToggle={format => {
                        setQuery(prev => ({ ...prev, format, page: 1 }))
                      }}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      <Button
                        color={'primary'}
                        className='btn btn-primary align-middle'
                        onClick={() => navigateToCourse('new')}
                      >
                        <i className='ri-add-line me-1 fs-16'></i>Add New Event
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={isFiltering}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <Table className='align-middle table-nowrap mb-0'>
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {Columns.map(column => (
                            <th
                              scope='col'
                              className='fw-light align-middle'
                              style={column.style}
                              key={column.name}
                            >
                              {column.name}
                              {!!column.sortBy && (
                                <ColumnSortIcon<ScheduledCoursesSortBy>
                                  sortOrder={query.orderBy}
                                  sortedColumn={query.sortBy}
                                  column={column.sortBy}
                                  handleSort={handleSort}
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.courses.map((course, i) => (
                          <tr key={i} className='fs-14 fw-light'>
                            <th scope='row'>
                              <Link
                                to={'/courses/scheduled-courses/' + course.id}
                                className='fw-normal'
                              >
                                {course.id}
                              </Link>
                            </th>
                            <td>
                              <Highlighter
                                highlightClassName='text-highlight'
                                searchWords={[query.key || '']}
                                highlightTag={'span'}
                                autoEscape={true}
                                textToHighlight={course.name}
                              />
                            </td>
                            <UserCell
                              user={course.instructor}
                              globalSearch={query.key}
                            />
                            <td>
                              <span
                                className={`badge badge-soft-${
                                  BadgeColorByFormat[course.format]
                                } fs-12 fw-normal text-capitalize`}
                              >
                                {course.format}
                              </span>
                            </td>
                            <td>{course.category?.name}</td>
                            <td>
                              <span
                                className={`badge badge-soft-${
                                  BadgeColorByStatus[course.status]
                                } fs-12 fw-normal text-capitalize`}
                              >
                                {course.status}
                              </span>
                            </td>
                            <td>
                              {course.startDate
                                ? moment(course.startDate).format('MM/DD/YYYY')
                                : '-'}
                            </td>
                            <td>
                              {course.startTime
                                ? moment(course.startTime, 'h:mm A').format(
                                    'h:mm A',
                                  )
                                : '-'}
                            </td>
                            <td>
                              {course.endTime
                                ? moment(course.endTime, 'h:mm A').format(
                                    'h:mm A',
                                  )
                                : '-'}
                            </td>
                            <td>
                              {course.maximumAttendance ? (
                                <span
                                  className={`badge badge-soft-grey fs-12 fw-normal`}
                                >
                                  {course.maximumAttendance}
                                </span>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              <div className='d-flex justify-content-between'>
                                <span className='d-flex gap-2 text-muted justify-content-end'>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={'viewCourse' + course.id}
                                  >
                                    View
                                  </UncontrolledTooltip>
                                  <i
                                    className='ri-eye-line cursor-pointer'
                                    id={'viewCourse' + course.id}
                                    onClick={() => setEventToShow(course)}
                                  ></i>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={'editCourse' + course.id}
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  <i
                                    className='ri-pencil-line cursor-pointer'
                                    id={'editCourse' + course.id}
                                    onClick={() => navigateToCourse(course.id)}
                                  ></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className='mx-3 mt-3'>
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
            <Filters
              setShowFilters={setShowFilters}
              visible={showFilters}
              setFilters={data => {
                setQuery(prev => ({
                  ...prev,
                  status: data.status,
                  type: data.type,
                  instructorIds: data.instructors,
                  categoryIds: data.category,
                  page: 1,
                }))
              }}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ScheduledCourses)
