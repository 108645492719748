import { useAppSelector } from '../../hooks/redux'

const useProfile = () => {
  const { userProfile, token, masteredToken, switched } = useAppSelector(
    state => ({
      userProfile: state.User.user,
      token: state.User.accessToken,
      masteredToken: state.User.masteredToken,
      switched: state.User.switched,
    }),
  )

  return { userProfile, token, masteredToken, switched }
}

export { useProfile }
