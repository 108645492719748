import { UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { ShowMoreWithPopoverProps } from '../Modals/AssignCourseModal/types'

export const ShowMoreWithPopover = ({
  limit = 5,
  data,
  className = 'text-primary',
  id = 0,
}: ShowMoreWithPopoverProps) => {
  return (
    <div className='hstack'>
      <span className='text-body'>
        {data.slice(0, limit).join(', ')}

        {data.length > limit && (
          <>
            <UncontrolledTooltip placement='top' target={`viewMore${id}`}>
              {data.slice(limit, data.length).join(', ')}
            </UncontrolledTooltip>

            <span
              className={`${className} cursor-pointer ps-1`}
              id={`viewMore${id}`}
            >
              +{data.length - limit}
            </span>
          </>
        )}
      </span>
    </div>
  )
}

export default ShowMoreWithPopover
