import React, { useEffect, useMemo, useState } from 'react'
import { TCourseRawTranslation } from '../../../../sharedTypes'
import AdditionalCourseInfo from './AdditionalCourseInfo'
import { Button, Col, Row } from 'reactstrap'
import GeneralInformation from './GeneralInformation'
import {
  getTranslationsByLanguage,
  submitTranslation,
  updateTranslation,
} from '../../../../helpers/api/translations'
import {
  errorToastOptions,
  handleError,
  successToastOptions,
} from '../../../../helpers/toast_helper'
import { useFormik } from 'formik'
import * as yup from 'yup'
import CompetencyTest from './CompetencyTest'
import { toast } from 'react-toastify'
import Preview from './Preview'
import UnsavedDataModal from '../../../../Components/Course/UnsavedDataModal'
import { useNavigate } from 'react-router-dom'
import {
  ALL_FIELDS_APPROVED_MESSAGE,
  GeneralInformationProps,
  IForm,
  propertiesToRender,
  TRANSLATION_TABS,
} from './types'
import _ from 'lodash'

const TranslationsSchema = yup.object().shape({
  content: yup.object().shape({
    name: yup.object().shape({
      value: yup.string().max(100, 'must be at most 100 characters').nullable(),
      approved: yup.boolean(),
    }),
    description: yup.object().shape({
      value: yup
        .string()
        .max(2000, 'must be at most 2000 characters')
        .nullable(),
      approved: yup.boolean(),
    }),
    objective: yup.object().shape({
      value: yup
        .string()
        .max(1000, 'must be at most 1000 characters')
        .nullable(),
      approved: yup.boolean(),
    }),
  }),
})

const LanguageTabContent = ({
  course,
  activeTab,
  activeLanguage,
  isLive,
  fetchData,
  setFetchData,
  changeTabs,
}: GeneralInformationProps) => {
  const navigate = useNavigate()

  const [rawTranslation, setRawTranslation] =
    useState<TCourseRawTranslation | null>(null)

  const [unsavedDataModal, setUnsavedDataModal] = useState<boolean>(false)

  const onSubmit = () => {
    if (rawTranslation && course) {
      const questions = form.values.content.questions
      const tabsWithErrors: TRANSLATION_TABS[] = []

      if (
        questions &&
        Object.values(questions).some(question => !question.approved)
      ) {
        form.setFieldError('questions', ALL_FIELDS_APPROVED_MESSAGE)
        tabsWithErrors.push(TRANSLATION_TABS.COMPETENCY_TEST)
      }

      if (
        propertiesToRender
          .filter(property => property.contentKey !== 'resources')
          .some(
            property =>
              !_.get(form.values, `content[${property.contentKey}].approved`),
          )
      ) {
        tabsWithErrors.push(TRANSLATION_TABS.GENERAL_INFORMATION)
      }

      if (!_.isEmpty(tabsWithErrors)) {
        toast(ALL_FIELDS_APPROVED_MESSAGE, errorToastOptions)
        changeTabs(tabsWithErrors)
        return
      }

      submitTranslation(rawTranslation.id as number, {
        ...form.values.content,
        courseId: course.id,
      })
        .then(() => {
          toast('Success! Translation has been submitted!', successToastOptions)
          changeTabs([])
          setFetchData(true)
        })
        .catch(handleError)
    }
  }

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      content: {
        name: {
          value: '',
          approved: false,
        },
        description: {
          value: '',
          approved: false,
        },
        objective: {
          value: '',
          approved: false,
        },
        questions: {},
      },
    },
    validationSchema: TranslationsSchema,
    onSubmit,
  })

  useEffect(() => {
    if (course && activeTab !== TRANSLATION_TABS.PREVIEW) {
      getTranslationsByLanguage(course.id, activeLanguage.id)
        .then(res => {
          setRawTranslation(res)
          form.setValues(res)
        })
        .catch(e => {
          if (e.response.status === 404) {
            navigate('/404')
          } else {
            handleError(e)
          }
        })
    }
  }, [course])

  const hasUnsavedData = useMemo(
    () => JSON.stringify(form.values) !== JSON.stringify(rawTranslation),
    [rawTranslation, form.values],
  )

  const renderTab = () => {
    if (course) {
      switch (activeTab) {
        case TRANSLATION_TABS.GENERAL_INFORMATION:
          return (
            <GeneralInformation
              course={course}
              form={form}
              activeLanguage={activeLanguage}
            />
          )
        case TRANSLATION_TABS.COMPETENCY_TEST:
          return <CompetencyTest course={course} form={form} />
        case TRANSLATION_TABS.PREVIEW:
          return <Preview course={course} activeLanguage={activeLanguage} />
      }
    }
  }

  const onSave = () => {
    if (rawTranslation && course) {
      updateTranslation(rawTranslation.id as number, {
        ...form.values.content,
        courseId: course.id,
      })
        .then(() => {
          toast('Translations are successfully updated', successToastOptions)
          if (isLive) {
            setFetchData(!fetchData)
          }
        })
        .catch(handleError)
    }
  }

  const onClose = () => {
    if (hasUnsavedData) {
      setUnsavedDataModal(true)
    } else {
      navigateToCourseListing()
    }
  }

  const navigateToCourseListing = () => {
    navigate('/courses')
  }

  return (
    <>
      <Row>
        <Col md={3}>{course && <AdditionalCourseInfo course={course} />}</Col>
        <Col>{renderTab()}</Col>
      </Row>
      <Row className='sticky-row course-navigation'>
        <div className='d-flex justify-content-between'>
          <Button color='light' onClick={onClose}>
            Close
          </Button>
          {activeTab !== TRANSLATION_TABS.PREVIEW && (
            <div className='hstack gap-3'>
              <Button
                color='ghost-primary'
                className='text-light-purple'
                onClick={onSave}
              >
                Save
              </Button>
              {!isLive && (
                <Button
                  color='primary'
                  onClick={() => form.handleSubmit()}
                  className='btn-label right px-3'
                >
                  Submit
                </Button>
              )}
            </div>
          )}
        </div>
      </Row>

      <UnsavedDataModal
        isOpen={unsavedDataModal}
        onClose={() => {
          setUnsavedDataModal(false)
        }}
        onConfirm={navigateToCourseListing}
        message='You have unsaved changes.  Press Cancel to remain on the page, press OK to go back to the Course listing and lose unsaved changes.'
        confirmBtnLabel='OK'
        cancelBtnLabel='Cancel'
        confirmBtnColor='primary'
      />
    </>
  )
}

export default LanguageTabContent
