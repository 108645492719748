import { TDepartment } from '../models'
import { PaginationRequest } from './pagination'

export namespace GetDepartmentsDTO {
  export interface Request extends PaginationRequest {
    key?: string
    facilityIds?: number[]
  }
  export interface Response {
    count: number
    departments: TDepartment[]
    page: number
    pages: number
  }
}
