import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { StatusTypes } from '../../../sharedTypes/models/pushNotifications'

interface FilterTabsProps {
  navTab?: StatusTypes
  navToggle: (tab?: StatusTypes) => void
}
const tabs = [
  { label: 'All', value: undefined },
  { label: 'Draft', value: StatusTypes.DRAFT },
  { label: 'Scheduled', value: StatusTypes.SCHEDULED },
  { label: 'Sent', value: StatusTypes.SENT },
]

const FilterTabs = ({ navTab, navToggle }: FilterTabsProps) => {
  return (
    <Nav className='nav-customs-bordered'>
      {tabs.map((tab: any) => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
