import { Option } from '../../helpers/facility'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FILTERS } from '../../pages/Reporting/CourseHistory/types'
import MultiSelect from './MultiSelect'

interface AgenciesMultiSelectDropdownProps {
  selectedAgencyOptions: Option[]
  setSelectedAgencyOptions: Dispatch<SetStateAction<Option[]>>
  agencyOptions: Option[]
  label?: string
  updateUrlParams?: (key: string, value: string) => string
}

const AgenciesMultiSelectDropdown = ({
  selectedAgencyOptions,
  setSelectedAgencyOptions,
  agencyOptions,
  label = 'Filter by Agency',
  updateUrlParams,
}: AgenciesMultiSelectDropdownProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const agenciesParam = searchParams.get(FILTERS.AGENCY)

  useEffect(() => {
    setSelectedAgencyOptions(
      agencyOptions.filter(item => agenciesParam?.includes(item.label)),
    )
  }, [])

  const onAgencySelect = (selectedOptions: Option[]) => {
    const inHouseSelected = selectedOptions.find(
      item => item.label === 'In House',
    )
    if (
      selectedAgencyOptions.length === 1 &&
      selectedAgencyOptions.find(
        (item: { label: string }) => item.label === 'In House',
      )
    ) {
      const filteredOptions = selectedOptions.filter(
        item => item.label !== 'In House',
      )
      setSelectedAgencyOptions(filteredOptions)
      if (updateUrlParams) {
        navigate(
          `?${updateUrlParams(
            FILTERS.AGENCY,
            JSON.stringify(
              filteredOptions.map((item: { label: string }) => item.label),
            ),
          )}`,
        )
      }
    } else if (inHouseSelected) {
      setSelectedAgencyOptions([inHouseSelected])
      if (updateUrlParams) {
        navigate(
          `?${updateUrlParams(
            FILTERS.AGENCY,
            JSON.stringify(
              [inHouseSelected].map((item: { label: string }) => item.label),
            ),
          )}`,
        )
      }
    } else {
      setSelectedAgencyOptions(selectedOptions)
      if (updateUrlParams) {
        navigate(
          `?${updateUrlParams(
            FILTERS.AGENCY,
            JSON.stringify(
              selectedOptions.map((item: { label: string }) => item.label),
            ),
          )}`,
        )
      }
    }
  }

  return (
    <div>
      <div>
        <label htmlFor='position' className='form-label'>
          {label}
        </label>

        <MultiSelect
          name='agency'
          id='agency'
          isMulti
          options={agencyOptions}
          isSearchable
          placeholder='Select agency'
          isClearable={false}
          backspaceRemovesValue={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          value={selectedAgencyOptions}
          onChange={(selectedOptions: Option[]) =>
            onAgencySelect(selectedOptions)
          }
        />
      </div>
    </div>
  )
}

export default AgenciesMultiSelectDropdown
