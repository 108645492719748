import React, { useEffect, useMemo, useState } from 'react'
import {
  Card,
  Col,
  CardHeader,
  UncontrolledCollapse,
  UncontrolledAccordion,
  AccordionItem,
} from 'reactstrap'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import _ from 'lodash'

import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  COURSE_TYPES,
  SCHEDULED_COURSE_STATUSES,
  TInstructor,
  TScheduledCourseCategory,
} from '../../../sharedTypes'
import {
  getScheduledCourseInstructors,
  getUsedScheduledCategory,
} from '../../../helpers/api_helper'
import { getUserDisplayName } from '../../../helpers/user'

export interface IFilters {
  status: SCHEDULED_COURSE_STATUSES[]
  type: COURSE_TYPES[]
  category: number[]
  instructors: number[]
}

interface FiltersProps {
  setShowFilters: (v: boolean) => void
  visible: boolean
  setFilters: (filter: IFilters) => void
}

const Filters = ({ setShowFilters, visible, setFilters }: FiltersProps) => {
  const [categories, setCategories] = useState<TScheduledCourseCategory[]>([])
  const [instructors, setInstructors] = useState<
    Pick<TInstructor, 'id' | 'firstName' | 'lastName'>[]
  >([])

  const [openStatus, setOpenStatus] = useState(true)
  const [openType, setOpentype] = useState(true)
  const [openCategory, setOpenCategory] = useState(false)
  const [openInstructors, setOpenInstructors] = useState(false)

  const [showAllCategories, setShowAllCategories] = useState(false)
  const [showAllInstructors, setShowAllInstructors] = useState(false)

  const [selectedStatuses, setSelectedStatuses] = useState<
    SCHEDULED_COURSE_STATUSES[]
  >([])
  const [selectedTypes, setSelectedTypes] = useState<COURSE_TYPES[]>([])
  const [selectedCategories, setSelectedCategories] = useState<number[]>([])
  const [selectedInstructors, setSelectedInstructors] = useState<number[]>([])

  const [tSearch, setTSearch] = useState<string>('')
  const [cSearch, setCSearch] = useState<string>('')
  const [iSearch, setISearch] = useState<string>('')

  //Fetch data
  useEffect(() => {
    getScheduledCourseInstructors()
      .then(res => {
        setInstructors(res.data)
      })
      .catch(() => {})
    getUsedScheduledCategory()
      .then(data => {
        setCategories(data)
      })
      .catch(() => {})
  }, [])

  const onSelectStatus =
    (status: SCHEDULED_COURSE_STATUSES) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked

      setSelectedStatuses(statuses =>
        isChecked
          ? _.uniq([...statuses, status])
          : _.pull([...statuses], status),
      )
    }

  const onSelectType =
    (type: COURSE_TYPES) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked

      setSelectedTypes(types =>
        isChecked ? _.uniq([...types, type]) : _.pull([...types], type),
      )
    }
  const onSelectInstructor =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked

      setSelectedInstructors(selected =>
        isChecked ? _.uniq([...selected, id]) : _.pull([...selected], id),
      )
    }
  const onSelectCategory =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked

      setSelectedCategories(selected =>
        isChecked ? _.uniq([...selected, id]) : _.pull([...selected], id),
      )
    }

  useEffect(() => {
    setFilters({
      status: selectedStatuses,
      type: selectedTypes,
      category: selectedCategories,
      instructors: selectedInstructors,
    })
  }, [selectedStatuses, selectedTypes, selectedCategories, selectedInstructors])

  const types = useMemo(() => {
    return tSearch
      ? Object.values(COURSE_TYPES).filter(
          _type => _type.toLowerCase().indexOf(tSearch.toLowerCase()) >= 0,
        )
      : Object.values(COURSE_TYPES)
  }, [tSearch])

  const _instructors = useMemo(() => {
    return iSearch
      ? instructors.filter(
          instructor =>
            getUserDisplayName(instructor)
              .toLowerCase()
              .indexOf(iSearch.toLowerCase()) >= 0,
        )
      : instructors
  }, [iSearch, instructors])

  const _categories = useMemo(() => {
    return cSearch
      ? categories.filter(
          category =>
            category.name.toLowerCase().indexOf(cSearch.toLowerCase()) >= 0,
        )
      : categories
  }, [cSearch, categories])

  return (
    <Col xl={3} lg={4} className={`col ${visible ? 'd-flex' : 'd-none'} `}>
      <Card
        className='flex-1'
        style={{
          maxHeight: 'calc(100vh - 250px)',
          minHeight: 'calc(100vh - 250px)',
          overflowY: 'scroll',
        }}
      >
        <CardHeader>
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
              <p className='fs-16 mb-0 fw-light' style={{ color: '#495057' }}>
                Filters
              </p>
            </div>
            <div className='flex-shrink-0'>
              <i
                className='ri-close-line fs-24 cursor-pointer fw-light'
                onClick={() => setShowFilters(false)}
              ></i>
            </div>
          </div>
        </CardHeader>

        <UncontrolledAccordion flush>
          <AccordionItem>
            <h2 className='accordion-header'>
              <button
                className={classnames(
                  'accordion-button bg-transparent shadow-none',
                  {
                    collapsed: !openStatus,
                  },
                )}
                type='button'
                id='flush-headingFacility'
                onClick={() => setOpenStatus(v => !v)}
              >
                <span className='text-muted text-uppercase fs-12 fw-light'>
                  Status
                </span>
              </button>
            </h2>
            <UncontrolledCollapse
              toggler='#flush-headingFacility'
              defaultOpen={openStatus}
            >
              <div
                id='flush-collapseFacility'
                className='accordion-collapse collapse show'
                aria-labelledby='flush-headingFacility'
              >
                <div className='accordion-body text-body pt-0'>
                  <div className='d-flex flex-column gap-2 mt-3'>
                    {Object.keys(SCHEDULED_COURSE_STATUSES).map(statusKey => {
                      return (
                        <div className='form-check' key={statusKey}>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={'status' + statusKey}
                            defaultChecked={selectedStatuses.includes(
                              SCHEDULED_COURSE_STATUSES[
                                statusKey as keyof typeof SCHEDULED_COURSE_STATUSES
                              ],
                            )}
                            onChange={onSelectStatus(
                              SCHEDULED_COURSE_STATUSES[
                                statusKey as keyof typeof SCHEDULED_COURSE_STATUSES
                              ],
                            )}
                          />
                          <label
                            className='form-check-label fw-normal text-capitalize'
                            htmlFor={'status' + statusKey}
                          >
                            {
                              SCHEDULED_COURSE_STATUSES[
                                statusKey as keyof typeof SCHEDULED_COURSE_STATUSES
                              ]
                            }
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </UncontrolledCollapse>
          </AccordionItem>
          {types.length > 0 && (
            <AccordionItem>
              <h2 className='accordion-header'>
                <button
                  className={classnames(
                    'accordion-button bg-transparent shadow-none',
                    {
                      collapsed: !openType,
                    },
                  )}
                  type='button'
                  id='flush-headingFacility'
                  onClick={() => setOpentype(v => !v)}
                >
                  <span className='text-muted text-uppercase fs-12 fw-light'>
                    Type
                  </span>
                </button>
              </h2>
              <UncontrolledCollapse
                toggler='#flush-headingFacility'
                defaultOpen={openType}
              >
                <div
                  id='flush-collapseFacility'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingFacility'
                >
                  <div className='accordion-body text-body pt-0'>
                    <SearchInput
                      placeholder='Search Type...'
                      onChange={setTSearch}
                      value={tSearch}
                    />
                    <div className='d-flex flex-column gap-2 mt-3'>
                      {types.map(type => {
                        return (
                          <div className='form-check' key={type}>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={'type' + type}
                              defaultChecked={selectedTypes.includes(type)}
                              onChange={onSelectType(type)}
                            />
                            <label
                              className='form-check-label fw-normal'
                              htmlFor={'type' + type}
                            >
                              <Highlighter
                                highlightClassName='text-highlight'
                                searchWords={[tSearch]}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={type}
                              />
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </UncontrolledCollapse>
            </AccordionItem>
          )}
          {categories.length > 0 && (
            <AccordionItem>
              <h2 className='accordion-header'>
                <button
                  className={classnames(
                    'accordion-button bg-transparent shadow-none',
                    {
                      collapsed: !openCategory,
                    },
                  )}
                  type='button'
                  id='flush-headingFacility'
                  onClick={() => setOpenCategory(v => !v)}
                >
                  <span className='text-muted text-uppercase fs-12 fw-light'>
                    Category
                  </span>
                </button>
              </h2>
              <UncontrolledCollapse
                toggler='#flush-headingFacility'
                defaultOpen={openCategory}
              >
                <div
                  id='flush-collapseFacility'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingFacility'
                >
                  <div className='accordion-body text-body pt-0'>
                    <SearchInput
                      placeholder='Search Category...'
                      onChange={setCSearch}
                      value={cSearch}
                    />
                    <div className='d-flex flex-column gap-2 mt-3'>
                      {(showAllCategories
                        ? _categories
                        : _categories.slice(0, 4)
                      ).map(category => {
                        return (
                          <div className='form-check' key={category.id}>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={'category' + category.id}
                              defaultChecked={selectedCategories.includes(
                                category.id,
                              )}
                              onChange={onSelectCategory(category.id)}
                            />
                            <label
                              className='form-check-label fw-normal'
                              htmlFor={'category' + category.id}
                            >
                              <Highlighter
                                highlightClassName='text-highlight'
                                searchWords={[iSearch]}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={category.name}
                              />
                            </label>
                          </div>
                        )
                      })}
                      {_categories.length > 4 && (
                        <div>
                          <button
                            type='button'
                            className='btn btn-link text-decoration-none text-uppercase fw-medium p-0'
                            onClick={() => setShowAllInstructors(v => !v)}
                          >
                            {showAllCategories
                              ? 'Show Less'
                              : `${_categories.slice(4).length} More`}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </UncontrolledCollapse>
            </AccordionItem>
          )}
          {instructors.length > 0 && (
            <AccordionItem>
              <h2 className='accordion-header'>
                <button
                  className={classnames(
                    'accordion-button bg-transparent shadow-none',
                    {
                      collapsed: !openInstructors,
                    },
                  )}
                  type='button'
                  id='flush-headingFacility'
                  onClick={() => setOpenInstructors(v => !v)}
                >
                  <span className='text-muted text-uppercase fs-12 fw-light'>
                    Instructor
                  </span>
                </button>
              </h2>
              <UncontrolledCollapse
                toggler='#flush-headingFacility'
                defaultOpen={openInstructors}
              >
                <div
                  id='flush-collapseFacility'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingFacility'
                >
                  <div className='accordion-body text-body pt-0'>
                    <SearchInput
                      placeholder='Search Instructor...'
                      onChange={setISearch}
                      value={iSearch}
                    />
                    <div className='d-flex flex-column gap-2 mt-3'>
                      {(showAllInstructors
                        ? _instructors
                        : _instructors.slice(0, 4)
                      ).map(instructor => {
                        return (
                          <div className='form-check' key={instructor.id}>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={'instructor' + instructor.id}
                              defaultChecked={selectedInstructors.includes(
                                instructor.id,
                              )}
                              onChange={onSelectInstructor(instructor.id)}
                            />
                            <label
                              className='form-check-label fw-normal'
                              htmlFor={'instructor' + instructor.id}
                            >
                              <Highlighter
                                highlightClassName='text-highlight'
                                searchWords={[iSearch]}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={getUserDisplayName(instructor)}
                              />
                            </label>
                          </div>
                        )
                      })}
                      {_instructors.length > 4 && (
                        <div>
                          <button
                            type='button'
                            className='btn btn-link text-decoration-none text-uppercase fw-medium p-0'
                            onClick={() => setShowAllInstructors(v => !v)}
                          >
                            {showAllInstructors
                              ? 'Show Less'
                              : `${_instructors.slice(4).length} More`}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </UncontrolledCollapse>
            </AccordionItem>
          )}
        </UncontrolledAccordion>
      </Card>
    </Col>
  )
}

export default Filters
