import _ from 'lodash'
import { useAppSelector } from './redux'

export const usePermissions = (
  permission: string | undefined,
  value: string | undefined = undefined,
) => {
  const { user } = useAppSelector(state => ({
    user: state.User.permissions,
  }))

  const permissionKey = !value ? `${permission}` : `${permission}.${value}`
  const result = _.get(user, permissionKey)
  return result === undefined ? true : result
}

export const usePermissionsToDisplay = (item: any) => {
  const { user } = useAppSelector(state => ({
    user: state.User.permissions,
  }))

  return !item.subItems.every(
    (subItem: { id: string }) =>
      _.get(user, `${item.id}.${subItem.id}`) === false,
  )
}
