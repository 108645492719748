import React, { Fragment, useMemo } from 'react'
import masteredLogo from '../../../assets/images/logo.png'
import { Table } from 'reactstrap'
import { TCourseReport } from '../../../sharedTypes/models/courseReport'
import _ from 'lodash'
import { BadgeColorByAllFormats } from '../../../helpers/course'
import { GetCourseReportDTO } from '../../../sharedTypes/api/courseReport'
import {
  avgDaysToComplete,
  avgTestScore,
  avgUserRating,
  retakeCourse,
  retakeTest,
} from './types'

export interface IColumn {
  name: string
  style?: React.HTMLProps<HTMLTableColElement>['style']
}

const Columns: IColumn[] = [
  {
    name: 'Code',
  },
  {
    name: 'Name',
  },
  {
    name: 'Type',
  },
  {
    name: 'Format',
  },
  {
    name: 'Number of \n Completions',
    style: { width: 70 },
  },
  {
    name: 'Avg. Test Score \n (1st attempt)',
    style: { whiteSpace: 'pre-line' },
  },
  {
    name: 'Avg. Days Taken to \n Complete the Course',
    style: { whiteSpace: 'pre-line' },
  },
  {
    name: 'Retake \n Test',
    style: { whiteSpace: 'pre-line' },
  },
  {
    name: 'Retake \n Course',
    style: { whiteSpace: 'pre-line' },
  },
  {
    name: 'Avg. User \n Rating',
    style: { whiteSpace: 'pre-line' },
  },
]

interface ExportCourseReportProps {
  courseReport: TCourseReport[]
  page: number
  query: GetCourseReportDTO.Request
}

const ExportUserReportPdf = ({
  courseReport,
  page,
  query,
}: ExportCourseReportProps) => {
  const filtersData = useMemo(() => {
    return [
      {
        label: 'Filter by Type',
        data: query.courseType || [],
        key: 'courseType',
      },
      {
        label: 'Filter by Format',
        data: query.courseFormat || [],
        key: 'courseFormat',
      },
      {
        label: 'Avg. Test Score',
        data: avgTestScore
          .filter(score => (query.avgTestScore || []).includes(score.value))
          .map(score => score.label),
        key: 'avgTestScore',
      },
      {
        label: 'Avg. Days Taken to Complete',
        data: avgDaysToComplete
          .filter(score =>
            (query.avgDaysToComplete || []).includes(score.value),
          )
          .map(score => score.label),
        key: 'avgDaysToComplete',
      },
      {
        label: 'Retake Test',
        data: retakeTest
          .filter(score => (query.retakeTest || []).includes(score.value))
          .map(score => score.label),
        key: 'retakeTest',
      },
      {
        label: 'Retake Course',
        data: retakeCourse
          .filter(score => (query.retakeCourse || []).includes(score.value))
          .map(score => score.label),
        key: 'retakeCourse',
      },
      {
        label: 'Avg. User Rating',
        data: avgUserRating
          .filter(score => (query.avgUserRating || []).includes(score.value))
          .map(score => score.label),
        key: 'avgUserRating',
      },
    ]
  }, [query])

  const shouldRenderFilters = useMemo(() => {
    return Object.values(filtersData).some(filter => {
      if (Array.isArray(filter)) {
        return filter.length > 0
      }
      return true
    })
  }, [filtersData])

  return (
    <div id='user-report-pdf w-100'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-3 align-items-center'>
          <h1 className='text-body'>Course Report</h1>
          <h2 className='text-body'>(Page {page})</h2>
        </div>
        <div>
          <img
            className='card-img-top img-fluid w-100'
            src={masteredLogo}
            alt='Mastered'
          />
        </div>
      </div>
      {page === 1 && (
        <>
          {shouldRenderFilters ? (
            <div className='mt-3'>
              <h2 className='fw-normal text-muted'>Filters Selected</h2>
            </div>
          ) : (
            ''
          )}

          <div className='d-flex flex-wrap gap-2 mb-2 mt-1'>
            {filtersData.map(filterItem => (
              <Fragment key={filterItem.key}>
                {filterItem.data.length > 0 && (
                  <>
                    <h3 className='fw-normal text-muted text-capitalize'>
                      {filterItem.label}:
                    </h3>
                    <h3 className='text-body'>{filterItem.data.join(', ')}</h3>
                    <h3 className='text-muted filter-separator'>|</h3>
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}
      <div className='table-card mt-5'>
        <Table className='align-middle mb-0' id={'user-report'}>
          <thead className='table-light'>
            <tr className='text-muted fs-3'>
              {Columns.map(column => (
                <th
                  scope='col'
                  className='fw-light align-middle'
                  style={column.style}
                  key={column.name}
                >
                  <div className='d-flex align-items-center gap-1'>
                    <span>{column.name}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {courseReport.map(report => (
              <tr key={report.id} className='fs-14 fw-light'>
                <td>
                  <span className='fs-4 text-muted'>{report.code}</span>
                </td>
                <td>
                  <div className='fs-4 text-muted'>
                    {_.get(report.course, 'translations[0].content.name', '-')}
                  </div>
                </td>
                <td>
                  <span className='fs-4 text-muted'>{report.course.type}</span>
                </td>
                <td>
                  <span
                    className={`fs-4 badge badge-${
                      BadgeColorByAllFormats[report.courseFormat]
                    } fw-normal text-capitalize`}
                  >
                    {report.courseFormat}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>{report.completions}</span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {report.avgScoreFirstAttempt + '%'}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {report.avgDaysToComplete}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {report.retakeTest + '%'}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {report.retakeCourse ? report.retakeCourse + '%' : '-'}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {report.avgUserRating}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ExportUserReportPdf
