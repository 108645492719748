import React, { ChangeEvent, useCallback } from 'react'

interface SearchInputProps {
  placeholder?: string
  style?: React.HTMLAttributes<HTMLDivElement>['style']
  onChange?: (v: string) => void
  value?: string
  background?: string
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  showClearIcon?: boolean
}
export const SearchInput = ({
  placeholder,
  style,
  onChange,
  value,
  background = '#EBF0F8',
  inputProps = {},
  showClearIcon = true,
}: SearchInputProps) => {
  const _onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value)
      }
    },
    [onChange],
  )

  const onClear = () => {
    if (onChange) {
      onChange('')
    }
  }

  return (
    <div className='search-box flex-1' style={style}>
      <input
        type='text'
        className='form-control border-0 text-body fw-semibold'
        placeholder={placeholder || 'Search'}
        value={value}
        style={{
          background,
        }}
        autoComplete='off'
        onChange={_onChange}
        {...inputProps}
      />
      <i className='ri-search-2-line search-icon'></i>
      {showClearIcon && value && (
        <i className='ri-close-line clear-icon' onClick={onClear}></i>
      )}
    </div>
  )
}
