import { Button, Card, CardBody, Col } from 'reactstrap'
import {
  CalendarPermissions,
  COURSE_FILTER_FORMATS,
  ScheduledCourseItem,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
} from '../../../sharedTypes'
import moment from 'moment'
import ShowMore from '../../../Components/Common/ShowMore'
import { copyToClipboard } from '../../../utils/clipboard'
import React from 'react'
import { getBadge, getCourseFormatPrefix } from '../../../helpers/course'
import { usePermissions } from '../../../hooks/usePermissions'

interface GeneralInfoProps {
  schedule: ScheduledCourseItem
  start: string
  onReleaseTest: (courseId: number) => void
  getEntityDetail: (value: 'name' | 'desc') => string
  disableReleaseBtn: boolean
}

const GeneralInfo = ({
  schedule,
  start,
  onReleaseTest,
  getEntityDetail,
  disableReleaseBtn = false,
}: GeneralInfoProps) => {
  const hasPermissionToReleaseTest = usePermissions(
    CalendarPermissions.RELEASE_TEST,
  )

  const getCode = () => {
    const type =
      schedule.type === ScheduledCourseTypes.IN_PERSON
        ? COURSE_FILTER_FORMATS.IN_PERSON
        : COURSE_FILTER_FORMATS.WEBINAR

    const entityCode = schedule.liveCourse
      ? schedule.liveCourse.course.code
      : schedule.trainingDay.code

    return `${getCourseFormatPrefix(type)}-${entityCode}`
  }

  return (
    <Col sm={5} xl={3}>
      <Card className='m-0'>
        <CardBody className='general-info'>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <span className='ml-1 fs-16 text-muted'>General Info</span>
          </div>
          {schedule.entityType === ScheduledEntitiesTypes.COURSE &&
            schedule.liveCourse.course.cover?.path && (
              <div className='text-center course-cover'>
                <img
                  src={schedule.liveCourse.course.cover?.path}
                  className='card-img-top img-fluid rounded w-100 h-100'
                  alt='Cover Image'
                  style={{
                    aspectRatio: 1,
                    objectFit: 'cover',
                  }}
                />
              </div>
            )}
          <p className='text-center m-3 fs-16 entity-title'>
            {getEntityDetail('name')}
          </p>
          <ul className='mx-auto list-unstyled overflow-auto'>
            <li className='mb-4 gap-4 d-flex'>
              <span className='title fw-medium text-body fs-14'>Code:</span>
              <span className='text-muted fs-14'>{getCode()}</span>
            </li>
            <li className='mb-4 gap-4 d-flex'>
              <span className='title fw-medium text-body fs-14'>
                Date and Time:
              </span>
              <span className='text-muted fs-14'>
                {moment(start).format('MM/DD/YYYY, hh:mm')}
              </span>
            </li>
            {schedule.entityType === ScheduledEntitiesTypes.COURSE && (
              <>
                <li className='mb-4 gap-4 d-flex'>
                  <span className='title fw-medium text-body fs-14'>
                    Location:
                  </span>
                  <span className='text-muted fs-14'>
                    {schedule.location || '-'}
                  </span>
                </li>
                <li className='mb-4 gap-4 d-flex'>
                  <span className='title fw-medium text-body fs-14'>
                    Facilitator:
                  </span>
                  <span className='text-muted fs-14'>
                    {schedule.facilitator
                      ? `${schedule.facilitator.firstName} ${schedule.facilitator.lastName}`
                      : '-'}
                  </span>
                </li>
              </>
            )}
            <li className='mb-4 gap-4 d-flex'>
              <span className='title fw-medium text-body fs-14'>
                Description:
              </span>
              <span className='text-muted fs-14'>
                <ShowMore content={getEntityDetail('desc')} />
              </span>
            </li>
            <li className='mb-4 gap-4 d-flex'>
              <span className='title fw-medium text-body fs-14'>
                Meeting ID:
              </span>
              <span className='text-secondary fw-medium zoom-meeting-link'>
                {schedule.meetingLink}
              </span>
              <i
                className='ri-file-copy-line cursor-pointer text-primary'
                onClick={() => {
                  copyToClipboard(schedule.meetingLink)
                }}
              ></i>
            </li>

            {schedule.entityType === ScheduledEntitiesTypes.COURSE && (
              <>
                {schedule.liveCourse.course.translations.map(t => {
                  const medialList = schedule.liveCourse?.media.filter(
                    m => m.languageId === t.languageId,
                  )
                  return (
                    <li key={t.id} className='mb-4 gap-4 d-flex'>
                      <span className='title fw-medium text-body fs-14'>
                        Media ({t.language.name}):{' '}
                      </span>{' '}
                      <span className='text-muted fs-14 d-flex gap-2 flex-wrap'>
                        {medialList?.length
                          ? medialList?.map(m => getBadge(m))
                          : '-'}
                      </span>
                    </li>
                  )
                })}
              </>
            )}
          </ul>
        </CardBody>
      </Card>

      {schedule.entityType === ScheduledEntitiesTypes.COURSE && (
        <Card className='m-0 mt-3'>
          <CardBody className='general-info'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <span className='ml-1 fs-16 text-muted'>Test Info</span>
            </div>

            <ul className='mx-auto list-unstyled'>
              <li className='mb-4 gap-4 d-flex'>
                <span className='title fw-medium text-body fs-14'>
                  Total Number of Questions:
                </span>
                <span className='text-muted fs-14'>
                  {schedule.liveCourse.course.competencyTest.questions.length}
                </span>
              </li>
              <li className='mb-4 gap-4 d-flex'>
                <span className='title fw-medium text-body fs-14'>
                  Questions to display:
                </span>
                <span className='text-muted fs-14'>
                  {
                    schedule.liveCourse.course.competencyTest
                      .quizQuestionsNumber
                  }
                </span>
              </li>
              {hasPermissionToReleaseTest && (
                <Button
                  size='md'
                  disabled={disableReleaseBtn}
                  className={`${
                    !disableReleaseBtn ? 'btn-soft-primary' : 'btn-primary'
                  }
                align-middle fs-14 w-100 fw-medium release-test`}
                  onClick={() => onReleaseTest(schedule.liveCourse.courseId)}
                >
                  <i className='mdi mdi-file-export-outline me-1 fs-16'></i>
                  Release Test
                </Button>
              )}
            </ul>
          </CardBody>
        </Card>
      )}
    </Col>
  )
}

export default GeneralInfo
