//ROLE&PERMISSIONS
import {
  AssignRoleToUsersDTO,
  CreateRoleDTO,
  GetRolesDTO,
  UpdateRoleOrdersDTO,
} from '../../sharedTypes'
import {
  ASSIGN_ROLE,
  DELETE_ROLE,
  GET_ROLES,
  PATCH_ROLE,
  POST_ROLE_ORDER,
  POST_ROLES,
} from '../url_helper'
import { API } from '../api_helper'

export const getRoles = (params: GetRolesDTO.Request) => {
  return API.get<GetRolesDTO.Response>(GET_ROLES, { params })
}
export const postRole = (data: CreateRoleDTO.Request) => {
  return API.post<CreateRoleDTO.Response>(POST_ROLES, data)
}
export const deleteRole = (id: number) => {
  return API.delete(DELETE_ROLE + `/${id}`)
}

export const patchRole = (id: number, data: Partial<CreateRoleDTO.Request>) => {
  return API.patch(PATCH_ROLE + `/${id}`, data)
}

export const postRoleOrder = (data: UpdateRoleOrdersDTO.Request) => {
  return API.post(POST_ROLE_ORDER, data)
}

export const assignRoleToUsers = (
  id: number,
  data: AssignRoleToUsersDTO.Request,
) => {
  return API.patch(`${ASSIGN_ROLE}/${id}`, data)
}
