import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  AccordionItem,
  UncontrolledCollapse,
} from 'reactstrap'
import { CreateQuestion } from '../../../Components/Course/Questions/CreateQuestion'
import { QuestionTypes } from '../../../sharedTypes'
import classnames from 'classnames'
import _ from 'lodash'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  Responders,
} from 'react-beautiful-dnd'
import { IQuestionForm, TLanguage } from '../../../sharedTypes'

interface SurveyQuestionsProps {
  questions: IQuestionForm[]
  languages: TLanguage[]
  onChange: (i: number, data: IQuestionForm) => void
  onAdd: () => void
  onDelete: (i: number) => void
  triggerValidation: null | string
  onReOrder: Responders['onDragEnd']
  forceUpdate: boolean
}

interface SurveyQuestionItemProps {
  index: number
  question: IQuestionForm
  languages: TLanguage[]
  onChange: (data: IQuestionForm) => void
  onAdd: () => void
  onDelete: () => void
  triggerValidation: null | string
  dragHandleProps: DroppableProvided[]
}

const SurveyQuestionItem = ({
  index,
  languages,
  question,
  onChange,
  onAdd,
  onDelete,
  dragHandleProps,
  triggerValidation,
}: SurveyQuestionItemProps) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      <Card>
        <CardBody className='p-0'>
          <AccordionItem>
            <h2 className='m-0'>
              <div
                className={classnames('accordion-button bg-light shadow-none', {
                  collapsed: !isOpen,
                })}
                style={{ padding: 10 }}
                id={`question-heading-${index}`}
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                <i
                  className='ri-draggable text-muted fs-5  drag-icon'
                  {...dragHandleProps}
                ></i>
                <div className='d-flex justify-content-between align-items-center w-100'>
                  <span className='text-muted fw-light ms-2'>
                    Question #{index + 1}
                  </span>
                  <i
                    className='ri-delete-bin-2-line text-danger fs-5 me-3'
                    onClick={() => onDelete()}
                  ></i>
                </div>
              </div>
            </h2>
            <UncontrolledCollapse
              toggler={`#question-heading-${index}`}
              defaultOpen={isOpen}
            >
              <div
                id={`question-1`}
                className='accordion-collapse collapse show'
                aria-labelledby={`question-heading-1`}
              >
                <div
                  style={{ padding: 20 }}
                  className='accordion-body text-body pt-0'
                >
                  <CreateQuestion
                    languages={languages}
                    initialValues={question}
                    hideBottomAtions
                    hideCorrectAnswer
                    maxMultiChoiceQuestions={5}
                    triggerValidation={triggerValidation}
                    defaultType={QuestionTypes.MULTIPLE_CHOICE}
                    allowedTypes={[
                      QuestionTypes.MULTIPLE_CHOICE,
                      QuestionTypes.SHORT_ANSWER,
                    ]}
                    onCancel={() => {}}
                    onChange={onChange}
                    loading={false}
                    onSubmit={() => {}}
                  />
                </div>
              </div>
            </UncontrolledCollapse>
          </AccordionItem>
        </CardBody>
      </Card>
    </>
  )
}

const SurveyQuestions = ({
  questions,
  languages,
  onChange,
  onAdd,
  onDelete,
  triggerValidation,
  onReOrder,
  forceUpdate,
}: SurveyQuestionsProps) => {
  function getStyle(style: any, snapshot: any) {
    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      transitionDuration: `0.001s`,
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={onReOrder}>
        <Droppable
          droppableId='droppable-questions'
          type='questions'
          direction='vertical'
        >
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {provided.placeholder}
              {questions.map((question, qIndex: number) => (
                <Draggable
                  draggableId={`q-${qIndex}`}
                  index={qIndex}
                  key={qIndex}
                >
                  {(provided: any, snapshot) => (
                    <div
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getStyle(provided.draggableProps.style, snapshot)}
                    >
                      <div>
                        <SurveyQuestionItem
                          key={Number(forceUpdate)}
                          index={qIndex}
                          triggerValidation={triggerValidation}
                          dragHandleProps={provided.dragHandleProps}
                          languages={languages}
                          question={question}
                          onAdd={() => onAdd()}
                          onDelete={() => onDelete(qIndex)}
                          onChange={value => onChange(qIndex, value)}
                        />
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        color='primary'
        className='d-flex align-items-center py-1'
        // disabled={_.size(form.values.questions) === QUESTIONS_LIMIT}
        onClick={() => onAdd()}
      >
        <span className='ms-1 me-2 fs-4 fw-normal'>+</span> Add New Question
      </Button>
    </>
  )
}

export default SurveyQuestions
