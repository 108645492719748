import { Button, Card, CardBody, Table, UncontrolledTooltip } from 'reactstrap'
import { SearchInput } from '../../../Components/Common/SearchInput'
import FilterTabs from '../../../Components/Common/FilterTabs'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import {
  ASSIGNMENT_STATUS,
  CalendarPermissions,
  COURSE_FILTER_FORMATS,
  CreateAssignmentDTO,
  GetScheduleAssignmentsDTO,
  IDefaultCourseSettings,
  OrderType,
  ScheduleAssignmentsSortBy,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
  TAssignment,
  UploadWrittenTestDTO,
} from '../../../sharedTypes'
import UserCell from '../../../Components/Common/UserItemCell'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { BadgeColorByStatus } from '../../../helpers/course_history'
import { Pagination } from '../../../Components/Common/Pagination'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  checkinUserAssignment,
  createAssignment,
  deleteUserAssignment,
  getScheduleAssignments,
  overrideUserAssignmentScore,
  undoUserAssignment,
} from '../../../helpers/api/assignments'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import { ReactComponent as CheckinIcon } from '../../../assets/images/svg/checkin-in.svg'
import { toast } from 'react-toastify'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import AssignUserModal from '../../../Components/Modals/AssignUserModal'
import axios from 'axios'
import OverrideConfirmationModal from '../../../Components/Modals/OverrideConfirmationModal'
import { getDefaultCourseSettings } from '../../../helpers/api_helper'
import { getAssignmentStatus } from '../../../helpers/assignments'
import ActionsDropdownMenu from './ActionsDropdownMenu'
import UploadWrittenTest from '../../../Components/Modals/Assignments/UploadWrittenTest'
import TestPreview from '../../../Components/Modals/Assignments/TestPreview'
import {
  ASSIGNMENT_ACTIONS,
  AssignmentsListProps,
  SelectedAssignment,
} from './types'
import CompetencyTestPreviewModal from '../../../Components/Modals/CourseQuestions/PreviewModal'
import { usePermissions } from '../../../hooks/usePermissions'
import UserAssociations from '../../../Components/Common/UserAssociations'

interface Column {
  name: string
  style?: {
    width: string | number
  }
  sortBy?: ScheduleAssignmentsSortBy
}

const initialTabs = [
  { label: 'All', value: undefined },
  { label: 'Attended', value: ASSIGNMENT_STATUS.ATTENDED },
  { label: 'Absent', value: ASSIGNMENT_STATUS.ABSENT },
]

const Columns: Column[] = [
  {
    name: '',
    style: { width: 100 },
  },
  {
    sortBy: ScheduleAssignmentsSortBy.USER,
    name: 'Name',
    style: { width: 225 },
  },
  {
    sortBy: ScheduleAssignmentsSortBy.FACILITY,
    name: 'Facility',
    style: { width: 100 },
  },
  {
    name: 'Department',
    style: { width: 150 },
  },
  {
    sortBy: ScheduleAssignmentsSortBy.POSITION,
    name: 'Position',
    style: { width: 150 },
  },
  {
    sortBy: ScheduleAssignmentsSortBy.SIGN_IN_TIME,
    name: 'Sign In Time',
    style: { width: 110 },
  },
]

const AssignmentsList = ({
  schedule,
  start,
  entityName,
  code,
  fetchAssignmentsList,
  router,
}: AssignmentsListProps) => {
  const [query, setQuery] = useState<GetScheduleAssignmentsDTO.Request>({
    page: 1,
    limit: 10,
    status: undefined,
    sortBy: ScheduleAssignmentsSortBy.ID,
    orderBy: OrderType.ASC,
    startDate: '',
    scheduleId: 0,
  })

  const [data, setData] = useState<GetScheduleAssignmentsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    assignments: [],
  })
  const [passingScore, setPassingScore] = useState<number>(0)

  const [selectedAssignment, setSelectedAssignment] =
    useState<SelectedAssignment>({
      data: null,
      type: ASSIGNMENT_ACTIONS.DELETE,
    })

  const [showAddUsersModal, setShowAddUsersModal] = useState<boolean>(false)

  const permissions = {
    addParticipants: usePermissions(CalendarPermissions.ADD_PARTICIPANTS),
    checkIn: usePermissions(CalendarPermissions.CHECK_PARTICIPANT_IN),
    removeParticipant: usePermissions(CalendarPermissions.REMOVE_PARTICIPANTS),
    previewTest: usePermissions(CalendarPermissions.PREVIEW_TEST),
    overrideScore: usePermissions(CalendarPermissions.OVERRIDE_TEST_SCORE),
  }

  const isScheduleForCourse = useMemo(() => {
    return schedule?.entityType === ScheduledEntitiesTypes.COURSE
  }, [schedule?.entityType])

  const columns = useMemo(() => {
    let changedColumns = [
      ...Columns,
      {
        sortBy: isScheduleForCourse
          ? ScheduleAssignmentsSortBy.TEST_SCORE
          : ScheduleAssignmentsSortBy.COMPLETED,
        name: isScheduleForCourse ? 'Test score' : 'Completed',
        style: { width: 100 },
      },
      {
        sortBy: ScheduleAssignmentsSortBy.STATUS,
        name: 'Status',
        style: { width: 100 },
      },
      {
        name: 'Action',
        style: { width: 70 },
      },
    ]

    if (!isScheduleForCourse) {
      if (!permissions.removeParticipant) {
        changedColumns = changedColumns.filter(
          column => column.name !== 'Action',
        )
      }
    }

    if (!permissions.checkIn) {
      changedColumns = changedColumns.filter(column => column.name !== '')
    }

    return changedColumns
  }, [isScheduleForCourse, permissions])

  const tabs = useMemo(() => {
    let changedTabs = [...initialTabs]

    if (isScheduleForCourse) {
      changedTabs = [
        ...changedTabs,
        { label: 'Test Pending', value: ASSIGNMENT_STATUS.TEST_PENDING },
        { label: 'Complete', value: ASSIGNMENT_STATUS.COMPLETED },
      ]
    }

    return changedTabs
  }, [isScheduleForCourse])

  const handleSort = useCallback((column: ScheduleAssignmentsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  useEffect(() => {
    if (start && (schedule.id || fetchAssignmentsList)) {
      getScheduleAssignments({
        ...query,
        scheduleId: +schedule.id,
        startDate: start,
      })
        .then(res => {
          setData(res)
        })
        .catch(e => {
          if (axios.isAxiosError(e) && e.response?.status === 404) {
            router.navigate('/404')
          } else {
            handleError(e)
          }
        })
    }
  }, [start, schedule.id, query, fetchAssignmentsList])

  useEffect(() => {
    getDefaultCourseSettings({
      permission: CalendarPermissions.VIEW_FACILITATOR_COURSE_VIEW,
    }).then((settings: IDefaultCourseSettings) => {
      setPassingScore(settings.passingScore)
    })
  }, [])

  const isScheduleStarted = useMemo(() => {
    return moment(start).isBefore(moment(), 'minute')
  }, [start])

  const isScheduleEnded = useMemo(() => {
    const end = moment(start).set({
      hour: moment.utc(schedule.endTime, 'HH:mm:ss').local().hour(),
      minute: moment.utc(schedule.endTime, 'HH:mm:ss').local().minute(),
    })

    return end.isBefore(moment(), 'minute')
  }, [start, schedule.endTime])

  const toggleAttendance = (assignment: TAssignment) => {
    const userAssignmentPromise = [
      ASSIGNMENT_STATUS.ABSENT,
      ASSIGNMENT_STATUS.ASSIGNED,
    ].includes(assignment.status)
      ? checkinUserAssignment(assignment.id)
      : undoUserAssignment(assignment.id)

    userAssignmentPromise
      .then(res => {
        setData(prevData => ({
          ...prevData,
          assignments: prevData.assignments.map(prevAssignment => ({
            ...prevAssignment,
            status:
              prevAssignment.id === assignment.id
                ? res.status
                : prevAssignment.status,
            startDate:
              prevAssignment.id === assignment.id
                ? res.startDate
                : prevAssignment.startDate,
          })),
        }))
      })
      .catch(e => {
        handleError(e)
      })
  }

  const getPage = () => {
    if (data.assignments.length <= 1 && data.page > 1) {
      return data.page - 1
    }

    return data.page
  }

  const onDelete = useCallback(async () => {
    if (
      selectedAssignment.data &&
      selectedAssignment.data.status !== ASSIGNMENT_STATUS.COMPLETED
    ) {
      try {
        await deleteUserAssignment(selectedAssignment.data.id, {
          permission: CalendarPermissions.REMOVE_PARTICIPANTS,
        })
        setQuery(prev => ({ ...prev, page: getPage() }))
        setSelectedAssignment({ ...selectedAssignment, data: null })
        toast('Success - Assignment successfully deleted', successToastOptions)
      } catch (e) {
        handleError(e)
      }
    }
  }, [data.page, selectedAssignment.data])

  const addParticipants = (userIds: number[]) => {
    if (start && !isScheduleEnded) {
      const request: CreateAssignmentDTO.Request = {
        userIds,
        allowDuplicates: false,
        permission: CalendarPermissions.ADD_PARTICIPANTS,
        itemsToAssign: [
          {
            courseId: isScheduleForCourse
              ? schedule.liveCourse.course.id
              : null,
            packageId: isScheduleForCourse ? undefined : schedule.entityId,
            format:
              schedule.type === ScheduledCourseTypes.WEBINAR
                ? COURSE_FILTER_FORMATS.WEBINAR
                : COURSE_FILTER_FORMATS.IN_PERSON,
            dateAvailable: moment().toDate(),
            dueDate: moment(start).toDate(),
            scheduleId: schedule.id,
            code,
          },
        ],
      }

      createAssignment(request)
        .then(() => {
          setQuery(prev => ({ ...prev, page: getPage() }))
          setShowAddUsersModal(false)
          toast(
            `${entityName} successfully assigned to ${userIds.length} users`,
            successToastOptions,
          )
        })
        .catch(e => {
          handleError(e)
        })
    }
  }

  const getCompletedCoursesNames = (assignment: TAssignment) => {
    return (assignment.courseAssignments as TAssignment[])
      .flatMap(item =>
        item.course.translations
          .filter(translation => translation.language.code === 'en')
          .map(translation => translation.content.name),
      )
      .join(', ')
  }

  const onOverride = useCallback(
    async (notes: string) => {
      try {
        if (
          selectedAssignment.data &&
          selectedAssignment.data.status !== ASSIGNMENT_STATUS.COMPLETED
        ) {
          await overrideUserAssignmentScore(
            selectedAssignment.data.id as number,
            { notes },
          )
          const updatedAssignments = data.assignments.map(assignment =>
            assignment.id === selectedAssignment.data?.id
              ? {
                  ...assignment,
                  competencyTestScore: passingScore,
                  status: ASSIGNMENT_STATUS.COMPLETED,
                }
              : assignment,
          )
          setData({ ...data, assignments: updatedAssignments })
          setSelectedAssignment({ ...selectedAssignment, data: null })
          toast(
            'Success - Test score successfully overridden',
            successToastOptions,
          )
        }
      } catch (e) {
        handleError(e)
      }
    },
    [data.page, selectedAssignment.data?.id],
  )

  const onAddTestFile = (res: UploadWrittenTestDTO.Response) => {
    const { assignment: changedAssignment, testFile } = res
    const updatedAssignments = data.assignments.map(assignment =>
      assignment.id === selectedAssignment.data?.id
        ? {
            ...assignment,
            competencyTestScore: changedAssignment.competencyTestScore,
            status: changedAssignment.status,
            endDate: changedAssignment.endDate,
            certificate: changedAssignment.certificate,
            testFile,
            testFileId: changedAssignment.testFileId,
          }
        : assignment,
    )
    setData({ ...data, assignments: updatedAssignments })

    setSelectedAssignment({ ...selectedAssignment, data: null })
  }

  const getTestScoreBadgeColor = (assignment: TAssignment) =>
    assignment.competencyTestScore &&
    assignment.competencyTestScore < passingScore
      ? 'red'
      : 'soft-grey'

  return (
    <Card>
      <CardBody className='p-0 py-2'>
        <div className='hstack gap-3 mx-n3 justify-content-between px-4 py-1 mb-2 flex-wrap'>
          <SearchInput
            style={{ maxWidth: 300 }}
            onChange={key => {
              setQuery(prev => ({ ...prev, key, page: 1 }))
            }}
            value={query.key || ''}
          />

          <FilterTabs
            tabs={tabs}
            navTab={query.status}
            navToggle={status => {
              setQuery(prev => ({ ...prev, status, page: 1 }))
            }}
          />
          {permissions.addParticipants && (
            <Button
              color={'primary'}
              className='btn btn-primary align-middle'
              disabled={isScheduleEnded}
              onClick={
                !isScheduleEnded
                  ? () => {
                      setShowAddUsersModal(true)
                    }
                  : undefined
              }
            >
              <i className='bx ri-edit-box-line me-1 fs-16'></i>Add Participants
            </Button>
          )}
        </div>
        <NoResultTableWrapper
          isLoading={false}
          isFiltering={!!query.key}
          pages={data.pages}
        >
          <div className='table-card assignments-table'>
            <Table className='gridjs-table align-middle table-nowrap mb-0'>
              <thead className='table-light'>
                <tr className='text-muted fs-14'>
                  {columns.map(column => (
                    <th
                      scope='col'
                      className='align-middle'
                      key={column.name}
                      style={column.style}
                    >
                      {column.name}
                      {!!column.sortBy && (
                        <ColumnSortIcon<ScheduleAssignmentsSortBy>
                          sortOrder={query.orderBy}
                          sortedColumn={query.sortBy}
                          column={column.sortBy}
                          handleSort={handleSort}
                        />
                      )}{' '}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.assignments.map((assignment, i) => (
                  <tr key={i} className='fs-14 fw-light'>
                    {permissions.checkIn && (
                      <td>
                        <span
                          className={`${
                            isScheduleStarted &&
                            assignment.status !== ASSIGNMENT_STATUS.COMPLETED &&
                            !isScheduleEnded &&
                            (assignment.completedCoursesCount as number) < 1
                              ? 'text-primary cursor-pointer'
                              : 'text-muted pe-none'
                          }
                  d-flex gap-2 align-items-center fw-normal`}
                          onClick={
                            assignment.status !== ASSIGNMENT_STATUS.COMPLETED
                              ? () => toggleAttendance(assignment)
                              : undefined
                          }
                        >
                          {[
                            ASSIGNMENT_STATUS.ABSENT,
                            ASSIGNMENT_STATUS.ASSIGNED,
                          ].includes(assignment.status) ? (
                            <>
                              <CheckinIcon
                                style={{ width: 14, height: 14 }}
                                className={`${
                                  !isScheduleStarted ||
                                  assignment.status ===
                                    ASSIGNMENT_STATUS.COMPLETED ||
                                  isScheduleEnded
                                    ? 'checkin-in-disabled'
                                    : ''
                                }`}
                              />
                              Check In
                            </>
                          ) : (
                            <>
                              <i className='ri-reply-fill'></i>
                              Undo
                            </>
                          )}
                        </span>
                      </td>
                    )}
                    <UserCell
                      user={assignment.user}
                      globalSearch={query.key || ''}
                    />
                    <td>
                      <Highlighter
                        highlightClassName='text-highlight'
                        className='detail-overflow text-truncate'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={assignment.user.facility?.name || ''}
                      />
                    </td>

                    <UserAssociations
                      data={assignment.user.departments || []}
                      key={query.key || ''}
                    />

                    <td>
                      <Highlighter
                        highlightClassName='text-highlight'
                        className='detail-overflow text-truncate'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={assignment.user.position?.name || ''}
                      />
                    </td>
                    <td>
                      {assignment.startDate
                        ? moment(assignment.startDate).format('hh:mm A')
                        : '-'}
                    </td>
                    <td>
                      {isScheduleForCourse ? (
                        <>
                          <span
                            className={`badge badge-${getTestScoreBadgeColor(
                              assignment,
                            )} fs-12 fw-normal`}
                          >
                            {assignment.competencyTestScore
                              ? assignment.competencyTestScore + '%'
                              : '-'}
                          </span>
                          {assignment.testFileId && (
                            <i
                              className='ri-edit-box-line ms-1 fs-12 text-primary cursor-pointer'
                              onClick={() => {
                                setSelectedAssignment({
                                  type: ASSIGNMENT_ACTIONS.TEST_PREVIEW,
                                  data: assignment,
                                })
                              }}
                            ></i>
                          )}
                        </>
                      ) : assignment.status === ASSIGNMENT_STATUS.ASSIGNED ? (
                        <span
                          className={`badge badge-soft-grey fs-12 fw-normal`}
                        >
                          -
                        </span>
                      ) : (
                        <span
                          className={`badge badge-soft-grey fs-12 fw-normal`}
                        >
                          {(assignment.completedCoursesCount as number) > 0 && (
                            <UncontrolledTooltip
                              placement='top'
                              target={`completed-courses-${assignment.id}`}
                            >
                              {getCompletedCoursesNames(assignment)}
                            </UncontrolledTooltip>
                          )}

                          <span id={`completed-courses-${assignment.id}`}>
                            {assignment.completedCoursesCount}/
                            {assignment.coursesCount}
                          </span>
                        </span>
                      )}
                    </td>
                    <td>
                      {assignment.status === ASSIGNMENT_STATUS.ASSIGNED ? (
                        <span
                          className={`badge badge-soft-grey fs-12 fw-normal`}
                        >
                          -
                        </span>
                      ) : (
                        <span
                          className={`badge badge-${
                            BadgeColorByStatus[assignment.status]
                          } fs-12 fw-normal text-capitalize`}
                        >
                          {getAssignmentStatus(assignment.status)}
                        </span>
                      )}
                    </td>
                    {(isScheduleForCourse ||
                      (!isScheduleForCourse &&
                        permissions.removeParticipant)) && (
                      <td>
                        <div className='d-flex gap-2 justify-content-center'>
                          {isScheduleForCourse ? (
                            <ActionsDropdownMenu
                              isScheduleStarted={isScheduleStarted}
                              assignment={assignment}
                              setSelectedAssignment={setSelectedAssignment}
                              permissions={permissions}
                            />
                          ) : (
                            <>
                              <UncontrolledTooltip
                                placement='top'
                                target={`deleteAssignment${assignment.id}`}
                              >
                                Delete
                              </UncontrolledTooltip>
                              <i
                                className={`${
                                  assignment.status ===
                                  ASSIGNMENT_STATUS.COMPLETED
                                    ? 'pe-none'
                                    : 'cursor-pointer'
                                } text-danger ri-delete-bin-2-line`}
                                id={`deleteAssignment${assignment.id}`}
                                onClick={
                                  assignment.status !==
                                  ASSIGNMENT_STATUS.COMPLETED
                                    ? () =>
                                        setSelectedAssignment({
                                          type: ASSIGNMENT_ACTIONS.DELETE,
                                          data: assignment,
                                        })
                                    : undefined
                                }
                              ></i>
                            </>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className='mx-3 my-3'>
              <Pagination
                currentPage={data.page - 1}
                totalPages={data.pages}
                totalRecords={data.count}
                setPage={page => {
                  setQuery(prev => ({ ...prev, page: ++page }))
                }}
              />
            </div>
          </div>
        </NoResultTableWrapper>
      </CardBody>

      {permissions.removeParticipant && (
        <DeleteConfirmation
          isOpen={
            !!(
              selectedAssignment.data?.id &&
              selectedAssignment.type === 'delete'
            )
          }
          title={`Unassign user from ${entityName}`}
          message={`Are you sure you want to unassign ${selectedAssignment.data?.user.firstName} ${selectedAssignment.data?.user.lastName} from ${entityName}?`}
          onDelete={onDelete}
          confirmLabel={'Unassign'}
          onClose={() => {
            setSelectedAssignment({ ...selectedAssignment, data: null })
          }}
        />
      )}
      {start && permissions.addParticipants && (
        <AssignUserModal
          onClose={() => {
            setShowAddUsersModal(false)
          }}
          isOpen={showAddUsersModal}
          entityName={entityName}
          start={start}
          location={schedule.location}
          facilityId={schedule.facilityId}
          handleAdd={addParticipants}
        />
      )}

      {permissions.overrideScore && (
        <OverrideConfirmationModal
          isOpen={
            !!(
              selectedAssignment.data?.id &&
              selectedAssignment.type === ASSIGNMENT_ACTIONS.OVERRIDE
            )
          }
          title='Override Test Score'
          message={`Please explain the need to override ${selectedAssignment.data?.user.firstName}
        ${selectedAssignment.data?.user.lastName}'s test score to a pass?`}
          onOverride={onOverride}
          onClose={() => {
            setSelectedAssignment({ ...selectedAssignment, data: null })
          }}
        />
      )}
      {selectedAssignment.data?.id && (
        <UploadWrittenTest
          isOpen={
            !!(
              selectedAssignment.data.id &&
              selectedAssignment.type === ASSIGNMENT_ACTIONS.UPLOAD_TEST
            )
          }
          assignmentId={selectedAssignment.data.id}
          onSubmit={onAddTestFile}
          onClose={() => {
            setSelectedAssignment({ ...selectedAssignment, data: null })
          }}
        />
      )}

      {selectedAssignment.data?.testFile && (
        <TestPreview
          isOpen={
            !!(
              selectedAssignment.data.id &&
              selectedAssignment.type === ASSIGNMENT_ACTIONS.TEST_PREVIEW
            )
          }
          testFile={selectedAssignment.data.testFile}
          onClose={() => {
            setSelectedAssignment({ ...selectedAssignment, data: null })
          }}
        />
      )}

      {selectedAssignment.data?.id && permissions.previewTest && (
        <CompetencyTestPreviewModal
          isOpen={
            !!(
              selectedAssignment.data.id &&
              selectedAssignment.type === ASSIGNMENT_ACTIONS.GENERATE_TEST
            )
          }
          onClose={() => {
            setSelectedAssignment({ ...selectedAssignment, data: null })
          }}
          courseName={entityName}
          assignment={selectedAssignment.data}
          languages={selectedAssignment.data.course.translations.map(
            t => t.language,
          )}
        />
      )}
    </Card>
  )
}

export default AssignmentsList
