import React, { useMemo, useRef } from 'react'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import Highlighter from 'react-highlight-words'
import { CardBody, Table, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment'
import { PackageCoursesSortBy } from '../../../sharedTypes/api/coursePackages'
import _ from 'lodash'
import { PackageCoursesTableViewProps } from './types'
import { Link } from 'react-router-dom'
import { PackageCourse } from '../../../sharedTypes/models/coursePackage'
import {
  CalendarPermissions,
  CoursesPermissions,
  OnlineCourseFormatsEnum,
  OnlineCourseItem,
} from '../../../sharedTypes'
import { usePermissions } from '../../../hooks/usePermissions'
import CourseDetailInfo from '../../../Components/Course/CoursesTable/CourseDetailInfo'
import { formattedDuration } from '../../../helpers/course'

export interface IColumn {
  name: string
  sortBy?: PackageCoursesSortBy
  style?: React.HTMLProps<HTMLTableColElement>['style']
}

const Columns: IColumn[] = [
  {
    name: 'Code',
    sortBy: PackageCoursesSortBy.CODE,
  },
  {
    name: 'Name',
    sortBy: PackageCoursesSortBy.NAME,
    style: { width: '20%' },
  },
  {
    name: 'Type',
    sortBy: PackageCoursesSortBy.TYPE,
  },
  {
    name: 'Description',
    sortBy: PackageCoursesSortBy.DESCRIPTION,
    style: { width: '40%' },
  },
]

interface ActionsProps {
  packageCourse: PackageCourse
  viewOnly: boolean
  handleDelete: (course: OnlineCourseItem) => void
  onReleaseTest: (courseId: number) => void
  disableReleaseBtn: boolean
  hasPermissionToDeleteCourse: boolean
  hasPermissionToReleaseTest: boolean
  hasPermissionToEditCourse: boolean
}

export const TrainingDayPageActions = ({
  packageCourse,
  viewOnly,
  handleDelete,
  hasPermissionToDeleteCourse,
  hasPermissionToEditCourse,
}: Omit<
  ActionsProps,
  'onReleaseTest' | 'disableReleaseBtn' | 'hasPermissionToReleaseTest'
>) => {
  return (
    <>
      {hasPermissionToEditCourse && (
        <>
          <UncontrolledTooltip
            placement='top'
            target={`edit-course-${packageCourse.courseId}`}
          >
            Edit
          </UncontrolledTooltip>
          <Link
            to={`/courses/general/${packageCourse.courseId}`}
            className={`${viewOnly ? 'pe-none' : ''} text-muted`}
            target='_blank'
          >
            <i
              className='ri-pencil-line cursor-pointer'
              id={`edit-course-${packageCourse.courseId}`}
            ></i>
          </Link>
        </>
      )}

      {hasPermissionToDeleteCourse && (
        <>
          <UncontrolledTooltip
            placement='top'
            target={`delete-course-${packageCourse.courseId}`}
          >
            Delete
          </UncontrolledTooltip>
          <i
            className={`${
              viewOnly ? 'pe-none' : 'cursor-pointer text-danger'
            } ri-delete-bin-2-line`}
            id={`delete-course-${packageCourse.courseId}`}
            onClick={() => handleDelete(packageCourse.course)}
          ></i>
        </>
      )}
    </>
  )
}

export const FacilitatorPageActions = ({
  packageCourse,
  onReleaseTest,
  disableReleaseBtn,
  hasPermissionToReleaseTest,
  hasPermissionToEditCourse,
}: Omit<
  ActionsProps,
  'viewOnly' | 'handleDelete' | 'hasPermissionToDeleteCourse'
>) => {
  return (
    <>
      {hasPermissionToReleaseTest && (
        <>
          <UncontrolledTooltip
            placement='top'
            target={`release-test-${packageCourse.courseId}`}
          >
            Release Test
          </UncontrolledTooltip>
          <i
            className={`${
              disableReleaseBtn ? 'pe-none' : ''
            } mdi mdi-file-export-outline cursor-pointer`}
            id={`release-test-${packageCourse.courseId}`}
            onClick={() => {
              onReleaseTest(packageCourse.courseId)
            }}
          ></i>
        </>
      )}
      {hasPermissionToEditCourse && (
        <>
          <UncontrolledTooltip
            placement='top'
            target={`edit-course-${packageCourse.courseId}`}
          >
            View
          </UncontrolledTooltip>
          <Link
            to={`/courses/general/${packageCourse.courseId}`}
            className={'text-muted'}
            target='_blank'
          >
            <i
              className='ri-eye-line cursor-pointer'
              id={`edit-course-${packageCourse.courseId}`}
            ></i>
          </Link>
        </>
      )}
    </>
  )
}

const PackageCoursesTableView = ({
  packageCourses,
  query,
  handleSort,
  handleDelete,
  viewOnly,
  onReleaseTest = () => {},
  fromFacilitatorView = false,
  disableReleaseBtn = false,
}: PackageCoursesTableViewProps) => {
  const tooltipRefs = useRef<{ [key: string]: HTMLElement | null }>({})

  const permissions = {
    editCourse: usePermissions(CoursesPermissions.ADD_EDIT_COURSE),
    removeCourseFromPackage: usePermissions(
      CoursesPermissions.REMOVE_COURSES_FROM_PACKAGE,
    ),
    releaseTest: usePermissions(CalendarPermissions.RELEASE_TEST),
  }

  const permissionToViewActions = useMemo(() => {
    return (
      (fromFacilitatorView &&
        permissions.releaseTest &&
        permissions.editCourse) ||
      (!fromFacilitatorView &&
        permissions.removeCourseFromPackage &&
        permissions.editCourse)
    )
  }, [fromFacilitatorView, permissions])

  const columns = useMemo(() => {
    let changedColumns: IColumn[]

    if (!fromFacilitatorView) {
      changedColumns = [
        ...Columns,
        {
          name: 'Duration',
          sortBy: PackageCoursesSortBy.CREDIT_HOURS,
        },
        {
          name: 'Publish Date',
          sortBy: PackageCoursesSortBy.DATE_PUBLISHED,
        },
        {
          name: 'Actions',
          style: { width: 150 },
        },
      ]
    } else {
      changedColumns = [
        ...Columns,
        {
          name: 'Actions',
          style: { width: 100 },
        },
      ]
    }

    if (!permissionToViewActions) {
      return changedColumns.filter(column => column.name !== 'Actions')
    }

    return changedColumns
  }, [fromFacilitatorView, permissionToViewActions])

  return (
    <CardBody className='p-0'>
      <Table className='align-middle table-nowrap mb-0 package-courses'>
        <thead className='table-light'>
          <tr className='text-muted fs-14'>
            {columns.map(column => (
              <th
                scope='col'
                className='align-middle'
                style={column.style}
                key={column.name}
              >
                {column.name}
                {!!column.sortBy && (
                  <ColumnSortIcon<PackageCoursesSortBy>
                    sortOrder={query.orderBy}
                    sortedColumn={query.sortBy}
                    column={column.sortBy}
                    handleSort={handleSort}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {packageCourses.map((packageCourse, i) => (
            <tr key={i} className='fs-14'>
              <td className='text-primary fw-normal'>
                <Highlighter
                  highlightClassName='text-highlight'
                  searchWords={[query.key || '']}
                  highlightTag={'span'}
                  autoEscape={true}
                  textToHighlight={packageCourse.courseCode}
                />
              </td>
              <td>
                <div className='d-flex gap-2 align-items-center'>
                  <Highlighter
                    highlightClassName='text-highlight'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={_.get(
                      packageCourse,
                      'course.translations[0].content.name',
                      '-',
                    )}
                  />
                  <div>
                    <i
                      className='ri-information-line cursor-pointer fs-16 text-muted'
                      id={`course-info-${packageCourse.courseId}`}
                      ref={ref =>
                        (tooltipRefs.current[
                          `course-${packageCourse.courseId}`
                        ] = ref)
                      }
                    ></i>
                    <UncontrolledTooltip
                      className='course-details--tooltip'
                      placement='right'
                      target={`course-info-${packageCourse.courseId}`}
                      trigger='click'
                    >
                      <CourseDetailInfo
                        course={packageCourse.course as OnlineCourseItem}
                        index={i}
                        tooltipRefs={tooltipRefs}
                      />
                    </UncontrolledTooltip>
                  </div>
                </div>
              </td>
              <td>{packageCourse.course.type}</td>
              <td className='text-muted'>
                <Highlighter
                  highlightClassName='text-highlight'
                  className='text-truncate-two-lines course-description'
                  searchWords={[query.key || '']}
                  highlightTag={'span'}
                  autoEscape={true}
                  textToHighlight={_.get(
                    packageCourse,
                    'course.translations[0].content.description',
                    '-',
                  )}
                />
              </td>
              {!fromFacilitatorView && (
                <>
                  <td>
                    <span className='badge badge-soft-grey fs-12 fw-normal'>
                      {!packageCourse.course.formats.includes(
                        OnlineCourseFormatsEnum.COMPETENCY,
                      )
                        ? formattedDuration(packageCourse.course.duration)
                        : '-'}
                    </span>
                  </td>
                  <td>
                    <span className='badge badge-soft-grey fs-12 fw-normal text-capitalize'>
                      {packageCourse.course.publishedDate
                        ? moment(packageCourse.course.publishedDate).format(
                            'MM/DD/YYYY',
                          )
                        : '-'}
                    </span>
                  </td>
                </>
              )}
              {permissionToViewActions && (
                <td>
                  <div className='d-flex justify-content-between'>
                    <span className='d-flex gap-2 text-muted justify-content-end'>
                      {fromFacilitatorView ? (
                        <FacilitatorPageActions
                          packageCourse={packageCourse}
                          onReleaseTest={onReleaseTest}
                          disableReleaseBtn={disableReleaseBtn}
                          hasPermissionToReleaseTest={permissions.releaseTest}
                          hasPermissionToEditCourse={permissions.editCourse}
                        />
                      ) : (
                        <TrainingDayPageActions
                          packageCourse={packageCourse}
                          viewOnly={viewOnly}
                          handleDelete={handleDelete}
                          hasPermissionToDeleteCourse={
                            permissions.removeCourseFromPackage
                          }
                          hasPermissionToEditCourse={permissions.editCourse}
                        />
                      )}
                    </span>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  )
}

export default PackageCoursesTableView
