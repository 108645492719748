import { useMemo } from 'react'
import { useAppSelector } from './redux'

const useIsMasteredAdmin = () => {
  const user = useAppSelector(state => state.User.user)

  return useMemo(() => !user?.companyId, [user])
}

export default useIsMasteredAdmin
