import { API } from '../api_helper'
import { SURVEYS } from '../url_helper'
import {
  CreateSurveyDTO,
  DuplicateSurveyDTO,
  GetSurveysDTO,
  SendSurveyDto,
  GetSurveyResponsesDTO,
  TSurveyData,
  GetSurveyStatisticsDTO,
} from '../../sharedTypes'

export const getSurveys = (params: GetSurveysDTO.Request) => {
  return API.get<GetSurveysDTO.Response>(SURVEYS, {
    params,
  }).then(d => d.data)
}

export const postSurvey = (body: CreateSurveyDTO.Request) => {
  return API.post<CreateSurveyDTO.Response>(SURVEYS, body).then(d => d.data)
}

export const getSurveyById = (id: number) => {
  return API.get<TSurveyData>(`${SURVEYS}/${id}`).then(d => d.data)
}

export const duplicateSurvey = (id: number) => {
  return API.post<DuplicateSurveyDTO.Response>(
    `${SURVEYS}/duplicate/${id}`,
  ).then(d => d.data)
}

export const sendSurvey = (id: number, data: SendSurveyDto.Request) => {
  return API.post<SendSurveyDto.Response>(`${SURVEYS}/send/${id}`, data).then(
    d => d.data,
  )
}

export const getSurveyStatistics = (
  surveyId: number,
  params: GetSurveyStatisticsDTO.Request,
) => {
  return API.get<GetSurveyStatisticsDTO.Response>(
    `${SURVEYS}/${surveyId}/statistics`,
    {
      params,
    },
  ).then(d => d.data)
}

export const getSurveyResponses = (params: GetSurveyResponsesDTO.Request) => {
  return API.get<GetSurveyResponsesDTO.Response>(`${SURVEYS}/responses`, {
    params,
  }).then(d => d.data)
}

export const putSurvey = (
  id: number,
  data: Partial<CreateSurveyDTO.Request>,
) => {
  return API.put<CreateSurveyDTO.Response>(`${SURVEYS}/${id}`, data)
}

export const deleteSurvey = (id: number) => {
  return API.delete(`${SURVEYS}/${id}`)
}
