import { Col, Row, Spinner } from 'reactstrap'
import { getStatusColor } from '../../../../helpers/common'
import {
  SurveyStatusTypes,
  TSurvey,
  AssignToOptions,
} from '../../../../sharedTypes'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { IRecipientsData } from '../../../../Components/Modals/PushNotificationModal'
import { getAveragePercent } from '../../../../utils/users'

interface GeneralInfoProps {
  survey: TSurvey | undefined
  recipientsData: IRecipientsData[]
}

const GeneralInfo = ({ survey, recipientsData }: GeneralInfoProps) => {
  const RECIPIENTS_SHOW_LIMIT = 5
  const [showMore, setShowMore] = useState<boolean>(false)

  return (
    <>
      {survey ? (
        <div className='vstack gap-4'>
          <Row>
            <Col md={4}>
              <span className='fw-medium'>Name: </span>
            </Col>
            <Col md={8}>
              <span className='text-muted'>
                {survey.translations?.[0]?.content.name || '-'}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium'>Description: </span>
            </Col>
            <Col md={8}>
              <span className='text-muted'>
                {survey.translations?.[0]?.content.description || '-'}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium'>Status: </span>
            </Col>
            <Col md={8}>
              <span
                className={`${getStatusColor(
                  survey.status,
                )} badge text-capitalize`}
              >
                {survey.status}
              </span>
            </Col>
          </Row>
          {[SurveyStatusTypes.DRAFT, SurveyStatusTypes.SCHEDULED].includes(
            survey.status,
          ) && (
            <Row>
              <Col md={4}>
                <span className='fw-medium'>Scheduled Date: </span>
              </Col>
              <Col md={8}>
                <span className='text-muted'>
                  {survey.sendDate
                    ? moment(survey.sendDate).format('MM/DD/YYYY, HH:mm')
                    : ''}
                </span>
              </Col>
            </Row>
          )}
          {[SurveyStatusTypes.SENT, SurveyStatusTypes.COMPLETED].includes(
            survey.status,
          ) && (
            <Row>
              <Col md={4}>
                <span className='fw-medium'>Date sent: </span>
              </Col>
              <Col md={8}>
                <span className='text-muted'>
                  {moment(survey.updatedAt).format('MM/DD/YYYY, HH:mm')}
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={4}>
              <span className='fw-medium'>Deadline: </span>
            </Col>
            <Col md={8}>
              <span className='text-muted'>
                {survey.deadline
                  ? moment(survey.deadline, 'YYYY-MM-DD').format('MM/DD/YYYY')
                  : '-'}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium'>Notification Message: </span>
            </Col>
            <Col md={8}>
              <span className='text-muted'>
                {survey.translations?.[0]?.content?.message || '-'}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium'>Recipients: </span>
            </Col>
            <Col md={8}>
              <div className='text-muted'>
                {AssignToOptions.find(o => o.value === survey.sendTo)?.label ||
                  ''}
              </div>
              <div className='text-muted'>
                {showMore
                  ? recipientsData.join(' , ')
                  : recipientsData.slice(0, RECIPIENTS_SHOW_LIMIT).join(' , ')}

                {recipientsData.length > RECIPIENTS_SHOW_LIMIT && (
                  <span
                    className='text-primary cursor-pointer ps-1'
                    onClick={() => {
                      setShowMore(!showMore)
                    }}
                  >
                    Show {showMore ? 'Less' : 'More'}
                  </span>
                )}
              </div>
            </Col>
          </Row>
          {[SurveyStatusTypes.SENT, SurveyStatusTypes.COMPLETED].includes(
            survey.status,
          ) && (
            <>
              <Row>
                <Col md={4}>
                  <span className='fw-medium'>
                    Num of <br /> Recipients:{' '}
                  </span>
                </Col>
                <Col md={8} className='d-flex align-items-center'>
                  <span className='text-muted'>{survey.recipientsCount}</span>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <span className='fw-medium'>Num completed: </span>
                </Col>
                <Col md={8}>
                  <span className='text-muted'>
                    <span className='mr-1'>{survey.completedUsersCount} </span>(
                    {getAveragePercent(
                      survey.completedUsersCount || 0,
                      survey.recipientsCount || 0,
                    )}
                    %)
                  </span>
                </Col>
              </Row>
            </>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default GeneralInfo
