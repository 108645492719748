import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react'
import {
  getCourseLiveSettings,
  addLiveCourseMedia,
  deleteLiveCourseMedia,
} from '../../../../helpers/api_helper'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { CourseLayout } from '../../../../Components/Course'
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Label,
  TabContent,
  TabPane,
} from 'reactstrap'
import {
  OnlineCourseFormatsEnum,
  TLanguage,
  LiveCourseMedia,
  OnlineCourseItem,
} from '../../../../sharedTypes'
import ScheduledCourse from './ScheduledCourse'
import classnames from 'classnames'
import MediaLiveCourse from '../../../../Components/Course/MediaLiveCourse'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../../helpers/toast_helper'
import { handleError } from '../../../../helpers/toast_helper'
import { CourseContext } from '../../../../context/CourseContext'

const LiveCourse = ({ router }: WithRouterProps) => {
  const {
    course,
    setCourse,
    setCourseId,
    setLoading,
    onRemoveFormat,
    tabs,
    onBack,
    deleteFormatModal,
    setDeleteFormatModal,
    isPublished,
    isPublishing,
    goNext,
    onNavigate,
    scheduled,
  } = useContext(CourseContext)
  const [mediaList, setMediaList] = useState<LiveCourseMedia[]>([])
  const [activeTab, setActiveTab] = useState<number>(0)
  const mediaLanguages = useMemo<TLanguage[]>(
    () => (course ? course.translations.map(m => m.language) : []),
    [course],
  )

  const onMediaAdd = useCallback(
    async (languageId: number, attachmentId: number) => {
      addLiveCourseMedia(Number(router.params.courseId), {
        languageId,
        attachmentId,
      })
        .then(response => {
          updateMediaState([...mediaList, response])
          toast('Success - Media successfully added', successToastOptions)
        })
        .catch(handleError)
    },
    [mediaList],
  )

  const updateMediaState = useCallback(
    (media: LiveCourseMedia[]) => {
      setCourse((c: any) => {
        const course = c as OnlineCourseItem
        return { ...c, liveCourse: { ...course.liveCourse, media } }
      })
      setMediaList(media)
    },
    [course],
  )

  const onMediaDelete = useCallback(
    async (languageId: number, attachmentId: number) => {
      const removeMedia = _.find(mediaList, { languageId, attachmentId })
      if (removeMedia) {
        await await deleteLiveCourseMedia(
          Number(router.params.courseId),
          removeMedia.id,
        )
          .then(() => {
            updateMediaState(mediaList.filter(f => f.id !== removeMedia.id))
            toast('Success - Media successfully deleted', successToastOptions)
          })
          .catch(handleError)
      }
    },
    [mediaList],
  )

  useEffect(() => {
    getCourseLiveSettings(Number(router.params.courseId)).then(data => {
      setMediaList(data.media)
    })
  }, [router.params.courseId])

  useEffect(() => {
    setCourseId(Number(router.params.courseId))
  }, [router.params.courseId])

  return (
    <CourseLayout
      backText='Back'
      classes={{ cardBody: 'pt-0' }}
      leftSideBar={true}
      router={router}
      course={course}
      tabs={tabs}
      viewOnly={isPublished}
      onRemoveFormat={onRemoveFormat}
      setDeleteFormatModal={setDeleteFormatModal}
      deleteFormatModal={deleteFormatModal}
      onNavigate={path => {
        onNavigate(path)
      }}
      onBack={() => {
        onBack()
      }}
      onNext={() => {
        goNext()
      }}
      onSaveAsDraft={() => {}}
    >
      <div className='w-100'>
        <Col sm='12' className='d-flex mb-3'>
          <div style={{ flex: 1 }}>
            <Nav
              tabs
              className='nav justify-content-start mx-0 nav-tabs-custom rounded card-header-tabs border-bottom-1 mb-1'
            >
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: activeTab === 0,
                  })}
                  onClick={() => {
                    setActiveTab(0)
                  }}
                >
                  <div className='gap-2 d-flex'>
                    <Label
                      className='form-check-label'
                      chekc
                      htmlFor='flexSwitchCheckDefault'
                    >
                      Schedule
                    </Label>
                  </div>
                </NavLink>
              </NavItem>
              {mediaLanguages.map(l => (
                <NavItem key={l.id}>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      active: l.id === activeTab,
                    })}
                    onClick={() => {
                      setActiveTab(l.id)
                    }}
                  >
                    <div className='gap-2 d-flex'>
                      <Label
                        className='form-check-label'
                        chekc
                        htmlFor='flexSwitchCheckDefault'
                      >
                        Media - {l.name}
                      </Label>
                    </div>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
          <Nav
            tabs
            className='nav justify-content-start mx-0 nav-tabs-custom rounded card-header-tabs border-bottom-1 mb-1'
          >
            <NavItem>
              <NavLink style={{ cursor: 'pointer' }} onClick={() => {}}>
                {!isPublished && (
                  <span
                    className='text-danger cursor-pointer d-flex gap-2 justify-content-end'
                    onClick={() => {
                      setDeleteFormatModal({
                        title: 'Delete Live Settings',
                        message:
                          'Are you sure you want to delete Live Settings?',
                        format: OnlineCourseFormatsEnum.LIVE,
                      })
                    }}
                  >
                    <b className='mb-2'>Remove Live Settings</b>{' '}
                    <i className='bx bx-trash text-danger cursor-pointer	'></i>
                  </span>
                )}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>

        <Col sm='12'>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={0} key={0}>
              <ScheduledCourse
                {...scheduled}
                viewOnly={isPublished}
                isPublishing={isPublishing}
              />
            </TabPane>
            {mediaLanguages.map(m => (
              <TabPane tabId={m.id} key={m.id}>
                <MediaLiveCourse
                  viewOnly={!!isPublished}
                  onAdd={attachmentId => onMediaAdd(m.id, attachmentId)}
                  onDelete={attachmentId => onMediaDelete(m.id, attachmentId)}
                  attachments={_.map(
                    _.filter(mediaList, { languageId: m.id }),
                    'attachment',
                  )}
                />
              </TabPane>
            ))}
          </TabContent>
        </Col>
      </div>
    </CourseLayout>
  )
}
export default withRouter(LiveCourse)
