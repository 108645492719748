import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { calculateAspectRatioFit } from '../pages/Calendar'
import formatBytes from '../utils/formatBytes'
import { TAttachment, DocumentFile } from '../sharedTypes'

export const getStatusColor = (status: string) => {
  let statusColor = ''
  switch (status) {
    case 'published':
    case 'sent':
      statusColor = 'badge-success'
      break
    case 'draft':
      statusColor = 'badge-soft-dark'
      break
    case 'scheduled':
      statusColor = 'text-light-purple'
      break
    case 'completed':
      statusColor = 'badge-soft-secondary'
      break
  }

  return statusColor
}

export const onPrint = async (elementId: string) => {
  const section = document.getElementById(elementId)

  if (section) {
    html2canvas(section).then(canvas => {
      const pdf = new jsPDF({
        unit: 'mm',
        format: 'a4',
        orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
      })
      const { width, height } = calculateAspectRatioFit(
        canvas.width,
        canvas.height,
        pdf.internal.pageSize.getWidth() - 20,
        pdf.internal.pageSize.getHeight() - 20,
      )

      pdf.addImage({
        imageData: canvas,
        x: 10,
        y: 10,
        width: width,
        height: height,
        compression: 'NONE',
      })

      const pdfBlob = new Blob([pdf.output('blob')], {
        type: 'application/pdf',
      })
      const pdfUrl = URL.createObjectURL(pdfBlob)
      const pdfWindow = window.open(pdfUrl, '_blank')
      if (pdfWindow) {
        pdfWindow.onload = () => {
          pdfWindow.print()
        }
      }
    })
  }
}

export const getMediaDetails = (attachment: TAttachment): DocumentFile => {
  return {
    attachmentId: attachment.id,
    name: attachment.name,
    preview: attachment.path,
    formattedSize: formatBytes(attachment.size),
    type: attachment.type.toLowerCase(),
  }
}

export const ENGLISH_LANGUAGE_CODE = 'en'
export const SPANISH_LANGUAGE_CODE = 'es'
export const CREOLE_LANGUAGE_CODE = 'ht'
