import React, { useState, Dispatch, SetStateAction } from 'react'
import { useCallback } from 'react'
import Dropzone from 'react-dropzone'
import formatBytes from '../../../utils/formatBytes'
import { Col, Row, Card, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { DocumentFile, AttachmentsMimeTypeEnum } from '../../../sharedTypes'
import { postNewAttachment } from '../../../helpers/api_helper'

interface MediaUploaderProps {
  onUploadedEvent: () => void
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  mimetypes?: AttachmentsMimeTypeEnum[]
}
const MediaUploader = ({
  onUploadedEvent,
  setIsLoading,
  isLoading,
  mimetypes,
}: MediaUploaderProps) => {
  const [file, setFile] = useState<DocumentFile | null>()

  const onDrop = useCallback((files: any) => {
    const parsedFiles = files.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    )
    setFile(parsedFiles[0])
  }, [])

  const onUpload = useCallback(async () => {
    try {
      setIsLoading(true)
      const data = new FormData()
      data.append('file', file as any)
      await postNewAttachment(data)
      setIsLoading(false)
      onUploadedEvent()
    } catch (e) {}
  }, [file])

  const accept: any = {}

  if (mimetypes) {
    mimetypes.forEach(type => {
      accept[type] = []
    })
  }

  return (
    <div className='cursor-pointer'>
      <Dropzone
        maxFiles={1}
        disabled={isLoading}
        accept={accept}
        onDrop={onDrop}
      >
        {({ getRootProps }) => (
          <div className='dropzone dz-clickable'>
            <div className='dz-message needsclick' {...getRootProps()}>
              <div className='mt-2'>
                <i className='display-7 text-muted ri-upload-cloud-2-fill' />
              </div>
              <p className='m-0 text-muted fs-16'>
                Drop files here or click to upload.
                <br></br>
                {mimetypes?.map(t => t.split('/')[1]).join(',')}
              </p>
            </div>
          </div>
        )}
      </Dropzone>
      {file && (
        <ul className='list-unstyled mb-0' id='dropzone-preview'>
          <Card
            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
            key={'-file'}
          >
            <div className='p-2'>
              <Row className='align-items-center'>
                <Col className='col-auto'>
                  <img
                    data-dz-thumbnail=''
                    height='80'
                    className='avatar-sm rounded bg-light'
                    alt={'NO IMAGE'}
                    src={file.preview}
                  />
                </Col>
                <Col>
                  <Link to='#' className='text-muted font-weight-bold'>
                    {file.name}
                  </Link>
                  <p className='mb-0'>
                    <strong>{file.formattedSize}</strong>
                  </p>
                </Col>

                <Col className='d-flex justify-content-end align-items-end'>
                  <Button
                    disabled={isLoading}
                    color='danger'
                    onClick={() => {
                      setFile(null)
                    }}
                  >
                    {' '}
                    Delete{' '}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </ul>
      )}
      <div className='mt-3 d-flex justify-content-end'>
        <Button color='success' onClick={onUpload} disabled={isLoading}>
          Upload
        </Button>
      </div>
    </div>
  )
}

export default MediaUploader
