import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap'
import React, { useMemo } from 'react'
import {
  OnlineCourseItem,
  TLanguage,
  TOnlineCourseTranslationItem,
} from '../../../../sharedTypes'
import { ENGLISH_LANGUAGE_CODE } from '../../../../helpers/common'
import { FormikProps } from 'formik'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { IForm, propertiesToRender } from './types'

interface GeneralInformationProps {
  course: OnlineCourseItem
  form: FormikProps<IForm>
  activeLanguage: TLanguage
}

const renderCoursePropertyTranslationItem = (
  label: string,
  contentKey: keyof Omit<typeof form.values.content, 'questions'>,
  englishTranslation: TOnlineCourseTranslationItem,
  form: FormikProps<IForm>,
  languageName: string,
  showEditor = false,
  isTextarea = false,
) => {
  return (
    <Card className='mb-2'>
      <CardBody>
        <Row>
          <Col>
            {contentKey === 'name' && <p className='fs-18 mb-4'>English</p>}
            <label className='form-check-label'>{label}</label>
            <div className='fw-normal mt-2'>
              <div
                dangerouslySetInnerHTML={{
                  __html: englishTranslation?.content[contentKey] || '',
                }}
              ></div>
            </div>
          </Col>
          <Col>
            {contentKey === 'name' && (
              <p className='fs-18 mb-4 text-capitalize'>{languageName}</p>
            )}
            <div className='d-flex justify-content-between'>
              <label className='form-check-label'>{label}</label>

              <div className='form-check form-switch mb-2'>
                <Input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id={`approve-${contentKey}`}
                  checked={form.values.content[contentKey]?.approved}
                  onChange={e => {
                    form.setFieldValue(
                      `content.${contentKey}.approved`,
                      e.target.checked,
                    )
                  }}
                />
                <Label
                  className='form-check-label'
                  htmlFor={`approve-${contentKey}`}
                >
                  Approve
                </Label>
              </div>
            </div>
            {showEditor ? (
              <div className='is-invalid'>
                <CKEditor
                  editor={ClassicEditor}
                  data={form.values.content?.[contentKey]?.value || ''}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    form.setFieldValue(`content.${contentKey}.value`, data)
                  }}
                  onBlur={() => form.handleBlur(`content.${contentKey}.value`)}
                />
              </div>
            ) : (
              <Input
                name={`content.${contentKey}.value`}
                className='form-control'
                id='title'
                placeholder={`Enter ${contentKey}`}
                type={isTextarea ? 'textarea' : 'text'}
                rows={isTextarea ? 6 : undefined}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.content?.[contentKey]?.value || ''}
                invalid={
                  !!(
                    form.touched.content?.[contentKey]?.value &&
                    form.errors.content?.[contentKey]?.value
                  )
                }
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const GeneralInformation = ({
  course,
  form,
  activeLanguage,
}: GeneralInformationProps) => {
  const englishTranslation = useMemo(
    () =>
      course?.translations.find(
        translation => translation.language.code === ENGLISH_LANGUAGE_CODE,
      ),
    [course],
  )

  return (
    <>
      {propertiesToRender.map(({ label, contentKey, showEditor, isTextarea }) =>
        renderCoursePropertyTranslationItem(
          label,
          contentKey as keyof typeof englishTranslation,
          englishTranslation as TOnlineCourseTranslationItem,
          form,
          activeLanguage.name,
          showEditor,
          isTextarea,
        ),
      )}
    </>
  )
}

export default GeneralInformation
