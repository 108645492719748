import React, { Fragment, useMemo } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { WithRouterProps } from '../../../Components/Common/withRouter'
import {
  CoursePackageFormatsEnum,
  TCoursePackage,
} from '../../../sharedTypes/models/coursePackage'
import { PackageHeader } from './PackageHeader'
import { BottomNavigation, BottomNavigationProps } from './BottomNavigation'

interface CoursePackageLayoutProps
  extends Omit<BottomNavigationProps, 'onSubmit' | 'onBack'>,
    WithRouterProps {
  children: React.ReactElement
  coursePackage: TCoursePackage | null
  loading?: boolean
  onPublish: () => void
  coursesCount: number
  trainingDaysCount: number
  fetchCoursePackage: () => void
  tabChange?: () => void
}

export const PackageLayout = (props: CoursePackageLayoutProps) => {
  document.title = 'Manage Package | Mastered - Admin & Dashboard'

  const isDisabled = useMemo(() => {
    if (!props.coursePackage || !props.coursePackage.isDraft) {
      return true
    }

    if (props.coursePackage.format === CoursePackageFormatsEnum.PACKAGE) {
      return !props.coursesCount
    }

    return !props.coursesCount || !props.trainingDaysCount
  }, [props.coursePackage, props.coursesCount, props.trainingDaysCount])

  const title = props.coursePackage?.publishedDate
    ? 'Edit Course Package'
    : 'Create New Course Package'

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={title}
            items={[
              {
                title: 'Courses',
                linkTo: '/courses',
              },
              {
                title: 'Course Packages',
                linkTo: '/courses',
              },
              {
                title,
                active: true,
              },
            ]}
          />
          <Row>
            <Col sm='12'>
              <Card className='mb-0 shadow-none'>
                <PackageHeader
                  coursePackage={props.coursePackage}
                  coursesCount={props.coursesCount}
                  fetchCoursePackage={props.fetchCoursePackage}
                />
              </Card>
              {props.loading && !props.coursePackage ? (
                <CardBody className='d-flex justify-content-center align-items-center'>
                  <h2>Loading...</h2>
                </CardBody>
              ) : (
                props.coursePackage && <>{props.children}</>
              )}
            </Col>
            <Col sm={'12'}>
              <BottomNavigation
                backText={props.backText}
                onBack={() => props.router.navigate('/courses')}
                onSaveAsDraft={props.onSaveAsDraft}
                onSubmit={props.onPublish}
                isDisabledPublish={isDisabled}
                isDisabledSaveAsDraft={!props.coursePackage?.isDraft}
                tabChange={props.tabChange}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
