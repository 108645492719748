import React from 'react'
import { Button, Modal, ModalBody, ModalHeader, ModalProps } from 'reactstrap'

interface ConfirmationModalProps {
  onClose: () => void
  onConfirm: () => void
  isOpen: ModalProps['isOpen']
  title: string
  message: string
  confirmationBtnText: string
}

const ConfirmationModal = ({
  onClose,
  onConfirm,
  isOpen,
  title,
  message,
  confirmationBtnText = 'Yes, sure',
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalHeader toggle={onClose}></ModalHeader>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div className='avatar-xl mx-auto'>
            <div className='avatar-title rounded bg-soft-danger text-danger'>
              <i className='ri-alert-line fs-48'></i>
            </div>
          </div>

          <h3 className='mt-4'>{title}</h3>
          <span className='text-muted fw-light fs-20'>{message}</span>
        </div>
        <div className='hstack gap-2 justify-content-center mt-4'>
          <Button color='danger' onClick={onConfirm}>
            {confirmationBtnText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal
