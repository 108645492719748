import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col } from 'reactstrap'
import _ from 'lodash'

import {
  TDepartment,
  TGroup,
  TGrouped,
  USER_REGISTERED_STATUSES,
  USER_STATUSES,
} from '../../sharedTypes'
import {
  getAgencies,
  getDepartments,
  getGroups,
  getPositions,
} from '../../helpers/api_helper'
import MultiSelect from '../../Components/Common/MultiSelect'
import { FilterOption } from '../../sharedTypes'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'
import { FILTERS, SelectedFilters } from '../Reporting/UserReport/types'
import FacilitiesMultiSelectDropdown from '../../Components/Common/FacilitiesMultiSelectDropdown'
import { useAppSelector } from '../../hooks/redux'
import { Option } from '../../helpers/facility'
import AgenciesMultiSelectDropdown from '../../Components/Common/AgenciesMultiSelectDropdown'
import { TAgency } from '../../sharedTypes/models/agency'
import { useNavigate } from 'react-router-dom'
import { useUrlParams } from '../../utils/urlParamsParser'

export interface IFilters {
  facility?: number[]
  department: number[]
  agencies?: number[]
  position: number[]
  groups?: number[]
  status: USER_STATUSES[]
  registeredStatuses: USER_REGISTERED_STATUSES[]
  searchDate?: Date[]
  hireDate?: Date[]
  signupDate?: Date[]
}

interface IRawFilters
  extends Omit<IFilters, 'department' | 'position' | 'agencies'> {
  agencies: string[]
  department: string[]
  position: string[]
}

interface FiltersProps {
  setFilters: (filter: IFilters) => void
  filterBy: {
    departments: boolean
    agencies: boolean
    positions: boolean
    groups: boolean
    status: boolean
    registered: boolean
    searchDate?: boolean
    hireDate?: boolean
    signupDate?: boolean
  }
  initialValues?: Partial<IRawFilters>
  setSelectedFilters?: (filters: SelectedFilters) => void
  visible: boolean
}

// Custom function to format the selected date range
export const formatRange = (dates: Date[]) => () => {
  if (dates.length === 2) {
    const [startDate, endDate] = dates
    return `${moment(startDate).format('MM/DD/YYYY')} - ${moment(
      endDate,
    ).format('MM/DD/YYYY')}`
  }
  if (dates.length === 1) {
    const [startDate] = dates
    return `${moment(startDate).format('MM/DD/YYYY')}`
  }
  return ''
}

// DateTimePickerProps.render?: ((props: Omit<DateTimePickerProps, "options" | "render">, ref: (node: (HTMLInputElement | null)) => void)
interface DataPickerInputProps extends React.HTMLProps<HTMLInputElement> {
  onClear: () => void
  inputRef: (node: HTMLInputElement | null) => void
}
const CustomInput = ({
  value,
  inputRef,
  onClear,
  ...props
}: DataPickerInputProps) => {
  return (
    <div className='search-box flex-1'>
      <input
        type='text'
        className='form-control fw-light'
        style={{ paddingLeft: 10 }}
        {...props}
        value={value as string}
        ref={inputRef}
      />
      {!_.isEmpty(value) && (
        <i className='ri-close-line clear-icon' onClick={onClear}></i>
      )}
    </div>
  )
}

const Filters = ({
  visible,
  setFilters,
  filterBy,
  setSelectedFilters,
  initialValues,
}: FiltersProps) => {
  const { groupOptions, selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))
  const navigate = useNavigate()
  const { updateUrlParams } = useUrlParams()

  const selectedValues = useMemo(() => {
    if (user?.groupId && !selectedFacilityId && !selectedGroupId) {
      return groupOptions.filter(
        option => option.value === `group:${user?.groupId}`,
      )
    }

    if (!selectedFacilityId && !selectedGroupId) {
      return groupOptions.filter(option => option.level === 0)
    }

    if (selectedFacilityId) {
      return groupOptions.filter(
        option => option.value === `facility:${selectedFacilityId}`,
      )
    }

    return groupOptions.filter(
      option => option.value === `group:${selectedGroupId}`,
    )
  }, [selectedFacilityId, selectedGroupId, user, groupOptions])

  const [departments, setDepartments] = useState<TGrouped[]>([])
  const [positions, setPositions] = useState<TGrouped[]>([])
  const [groups, setGroups] = useState<TGroup[]>([])
  const datePickerRef = useRef<Flatpickr>(null)
  const signupDatePickerRef = useRef<Flatpickr>(null)

  const [selectedOptions, setSelectedOptions] =
    useState<Option[]>(selectedValues)

  const [selectedAgencyOptions, setSelectedAgencyOptions] = useState<Option[]>(
    [],
  )
  const [agencyOptions, setAgencyOptions] = useState<Option[]>([])
  const [filters, setRawFilters] = useState<IRawFilters>({
    department: initialValues?.department || [],
    agencies: initialValues?.agencies || [],
    position: initialValues?.position || [],
    groups: initialValues?.groups || [],
    status: initialValues?.status || [USER_STATUSES.ACTIVE],
    registeredStatuses: initialValues?.registeredStatuses || [],
    searchDate: initialValues?.searchDate || [],
    hireDate: initialValues?.hireDate || undefined,
    signupDate: initialValues?.signupDate || undefined,
  })

  //Fetch data
  useEffect(() => {
    filterBy.departments &&
      getDepartments({})
        .then(res => {
          const grouped: TGrouped[] = _.map(
            _.groupBy(res.data.departments, 'name'),
            (group: TDepartment[], key) => {
              return {
                name: key,
                values: group.map(g => g.id),
              }
            },
          )
          setDepartments(grouped)
        })
        .catch(() => {})
    filterBy.positions &&
      getPositions()
        .then(res => {
          const grouped: TGrouped[] = _.map(
            _.groupBy(res.data.positions, 'name'),
            (group: TDepartment[], key) => {
              return {
                name: key,
                values: group.map(g => g.id),
              }
            },
          )
          setPositions(grouped)
        })
        .catch(() => {})
    filterBy.groups &&
      getGroups()
        .then(res => {
          setGroups(res.data)
        })
        .catch(() => {})
    filterBy.agencies &&
      getAgencies({})
        .then(res => {
          setAgencyOptions([
            {
              id: -2,
              label: 'In House',
              value: '2',
              level: 0,
              hasChildren: false,
              disabled: false,
              companyId: user?.companyId as number,
            },
            ...res.data.agencies.map(agency => ({
              id: agency.id,
              label: agency.name,
              value: agency.name,
              level: 1,
              hasChildren: false,
              disabled: true,
              companyId: user?.companyId as number,
            })),
          ])
        })
        .catch(() => {})
  }, [])

  const onSelectGroupItem = (
    name: 'department' | 'position',
    groupNames: string[],
  ) => {
    if (name === 'department') {
      setRawFilters(_filters => ({ ..._filters, department: groupNames }))
    }
    if (name === 'position') {
      setRawFilters(_filters => ({ ..._filters, position: groupNames }))
    }
  }

  useEffect(() => {
    const _selectedOptions = selectedOptions.map(option => {
      const [type, id] = option.value.split(':')
      return { type, id: +id, label: option.label }
    })

    const _selectedAgencyOptions = selectedAgencyOptions
      .filter(option => option.id !== -1)
      .map(option => {
        return { id: option.id ? +option.id : 0, label: option.label }
      })

    setFilters({
      ...filters,
      department: _.flatten(
        departments
          .filter(d => filters.department.includes(d.name))
          .map(d => d.values),
      ),
      position: _.flatten(
        positions
          .filter(p => filters.position.includes(p.name))
          .map(p => p.values),
      ),
      agencies: _selectedAgencyOptions.map(option => option.id),
      facility: _selectedOptions
        .filter(option => option.type === 'facility')
        .map(option => option.id),
      groups: _selectedOptions
        .filter(option => option.type === 'group')
        .map(option => option.id),
    })

    if (setSelectedFilters) {
      setSelectedFilters({
        departments: _.flatten(
          departments
            .filter(d => filters.department.includes(d.name))
            .map(d => d.name),
        ),
        positions: _.flatten(
          positions
            .filter(p => filters.position.includes(p.name))
            .map(p => p.name),
        ),
        agencies: _selectedAgencyOptions.map(option => option.label),
        facilities: _selectedOptions.map(option => option.label),
      })
    }
  }, [filters, selectedOptions, selectedAgencyOptions])

  if (!visible) {
    return null
  }

  return (
    <>
      {(!user?.isFacilityAdmin || user?.hasAccessToMultipleFacilities) && (
        <Col className='mt-0 p-0'>
          <FacilitiesMultiSelectDropdown
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            updateUrlParams={updateUrlParams}
          />
        </Col>
      )}

      {filterBy.agencies && !user?.agencyId && (
        <Col className='mt-0 p-0'>
          <AgenciesMultiSelectDropdown
            selectedAgencyOptions={selectedAgencyOptions}
            setSelectedAgencyOptions={setSelectedAgencyOptions}
            agencyOptions={agencyOptions}
            updateUrlParams={updateUrlParams}
          />
        </Col>
      )}

      {departments.length > 0 && (
        <Col className='mt-0 p-0'>
          <div>
            <label htmlFor='department' className='form-label'>
              Filter by Department
            </label>

            <MultiSelect
              name='department'
              id='department'
              isMulti
              options={departments.map(obj => ({
                value: obj.values,
                label: obj.name,
              }))}
              isSearchable={true}
              placeholder='Select department'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'department',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={departments
                .filter(obj => filters.department.includes(obj.name))
                .map(obj => ({
                  value: obj.values,
                  label: obj.name,
                }))}
            />
          </div>
        </Col>
      )}
      {positions.length > 0 && (
        <Col className='mt-0 p-0'>
          <div>
            <label htmlFor='position' className='form-label'>
              Filter by Position
            </label>

            <MultiSelect
              name='position'
              id='position'
              isMulti
              options={positions.map(obj => ({
                value: obj.values,
                label: obj.name,
              }))}
              isSearchable={true}
              placeholder='Select position'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'position',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={positions
                .filter(obj => filters.position.includes(obj.name))
                .map(obj => ({
                  value: obj.values,
                  label: obj.name,
                }))}
            />
          </div>
        </Col>
      )}

      {filterBy.hireDate && (
        <Col className='mt-0 p-0'>
          <div className='w-100 form-icon right filter-by-date'>
            <label htmlFor='type' className='form-label'>
              Filter by Hire Date
            </label>

            <Flatpickr
              className='form-control fs-12'
              placeholder='--/--/---- - --/--/---'
              onChange={dates => {
                if (dates.length === 2) {
                  setRawFilters(f => ({
                    ...f,
                    hireDate: dates,
                  }))
                  navigate(
                    `?${updateUrlParams(
                      FILTERS.HIRE_DATE,
                      String(dates[0]).split(' ').slice(0, 4).join('-') +
                        ' ' +
                        String(dates[1]).split(' ').slice(0, 4).join('-'),
                    )}`,
                  )
                }
              }}
              ref={datePickerRef}
              value={filters.hireDate}
              options={{
                mode: 'range',
                dateFormat: 'm/d/Y',
                locale: {
                  rangeSeparator: ' - ',
                },
              }}
            />
            <i
              className='ri-close-line fs-16 text-danger'
              onClick={() => {
                datePickerRef.current?.flatpickr.clear()
                setRawFilters(f => ({
                  ...f,
                  hireDate: [],
                }))
              }}
            ></i>
            <i
              className='ri-calendar-2-line fs-20'
              onClick={() => {
                datePickerRef.current?.flatpickr.open()
              }}
            ></i>
          </div>
        </Col>
      )}

      {filterBy.signupDate && (
        <Col className='mt-0 p-0'>
          <div className='w-100 form-icon right filter-by-date'>
            <label htmlFor='type' className='form-label'>
              Filter by Registration Date
            </label>

            <Flatpickr
              className='form-control fs-12'
              placeholder='--/--/---- - --/--/---'
              onChange={dates => {
                if (dates.length === 2) {
                  setRawFilters(f => ({
                    ...f,
                    signupDate: dates,
                  }))
                  navigate(
                    `?${updateUrlParams(
                      FILTERS.REGISTRATION_DATE,
                      String(dates[0]).split(' ').slice(0, 4).join('-') +
                        ' ' +
                        String(dates[1]).split(' ').slice(0, 4).join('-'),
                    )}`,
                  )
                }
              }}
              ref={signupDatePickerRef}
              value={filters.signupDate}
              options={{
                mode: 'range',
                dateFormat: 'm/d/Y',
                locale: {
                  rangeSeparator: ' - ',
                },
              }}
            />
            <i
              className='ri-close-line fs-16 text-danger'
              onClick={() => {
                signupDatePickerRef.current?.flatpickr.clear()
                setRawFilters(f => ({
                  ...f,
                  signupDate: [],
                }))
              }}
            ></i>
            <i
              className='ri-calendar-2-line fs-20'
              onClick={() => {
                signupDatePickerRef.current?.flatpickr.open()
              }}
            ></i>
          </div>
        </Col>
      )}

      {filterBy.status && (
        <Col className='mt-0 p-0'>
          <div>
            <label htmlFor='group' className='form-label'>
              Filter by Active Status
            </label>

            <MultiSelect
              name='status'
              id='userStatus'
              isMulti
              options={Object.values(USER_STATUSES).map(status => ({
                value: status,
                label: _.capitalize(status),
              }))}
              isSearchable={false}
              placeholder='Select status'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              defaultValue={filters.status.map(status => ({
                value: status,
                label: _.capitalize(status),
              }))}
              onChange={(selectedOptions: FilterOption[]) => {
                setRawFilters(f => ({
                  ...f,
                  status: selectedOptions.map(
                    option => option.value,
                  ) as USER_STATUSES[],
                }))
              }}
            />
          </div>
        </Col>
      )}
      {filterBy.registered && (
        <Col className='mt-0 p-0'>
          <div>
            <label htmlFor='group' className='form-label'>
              Filter by Registered Status
            </label>

            <MultiSelect
              name='status'
              id='userRegisteredStatus'
              isMulti
              options={Object.values(USER_REGISTERED_STATUSES).map(status => ({
                value: status,
                label: _.capitalize(status),
              }))}
              isSearchable={false}
              placeholder='Select status'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                setRawFilters(f => ({
                  ...f,
                  registeredStatuses: selectedOptions.map(
                    option => option.value,
                  ) as USER_REGISTERED_STATUSES[],
                }))
              }}
            />
          </div>
        </Col>
      )}
      {filterBy.searchDate && (
        <Col className='mt-0 p-0'>
          <div>
            <label htmlFor='group' className='form-label'>
              Filter by Date
            </label>
            <div className='input-group w-auto' style={{ minWidth: 280 }}>
              <Flatpickr
                placeholder='Search by date'
                render={({ value, placeholder }, ref) => {
                  return (
                    <CustomInput
                      inputRef={ref}
                      onClear={() => {
                        setRawFilters(f => ({
                          ...f,
                          searchDate: [],
                        }))
                        datePickerRef.current?.flatpickr.clear()
                      }}
                      value={value as string}
                      placeholder={placeholder}
                    />
                  )
                }}
                onClose={dates => {
                  if (dates.length !== 2) {
                    datePickerRef.current?.flatpickr.clear()
                    setRawFilters(f => ({
                      ...f,
                      searchDate: [],
                    }))
                  }
                }}
                onChange={dates => {
                  if (dates.length === 2) {
                    setRawFilters(f => ({
                      ...f,
                      searchDate: dates,
                    }))
                  }
                }}
                ref={datePickerRef}
                value={filters.searchDate}
                options={{
                  enableTime: false,
                  mode: 'range',
                  dateFormat: 'd-m-Y',
                  formatDate: formatRange(filters.searchDate || []),
                }}
              />
              <span className='input-group-text bg-primary'>
                <i className='ri-calendar-event-line text-white'></i>
              </span>
            </div>
          </div>
        </Col>
      )}
    </>
  )
}

export default Filters
