import React, { useCallback, useState } from 'react'
import { Button, Modal, ModalBody, Form, Col, Alert } from 'reactstrap'
import { useFormik } from 'formik'
import PasswordInput from '../../../Components/Common/PasswordInput'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { changePassword } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../helpers/toast_helper'

interface ChangePasswordModalProps {
  isOpen: boolean
  onClose: () => void
}

const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps) => {
  const [isError, setError] = useState<string | null>(null)

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .nullable()
        .min(8, 'Your password is too short.')
        .test(
          'email-matchsa',
          'Current password is required',
          function (value) {
            if ((value || '').length === 0) {
              return (this.parent.newPassword || '').length === 0
            } else {
              return true
            }
          },
        ),
      newPassword: Yup.string()
        .nullable()
        .min(8, 'Your password is too short.')
        .test('email-match', 'Required', function (value) {
          if (
            (value || '').length === 0 &&
            (this.parent.currentPassword || '').length > 0
          ) {
            return false
          } else {
            return true
          }
        }),
      confirmPassword: Yup.string()
        .nullable()
        .min(8, 'Your password is too short.')
        .test('email-match', 'Does not match new password', function (value) {
          return this.parent.newPassword === value
        }),
    }),
    onSubmit: async (values, formik) => {
      formik.setSubmitting(true)
      await changePassword(values)
        .then(() => {
          setError(null)
          toast('Successfully changed password', successToastOptions)
          onBeforeClose()
        })
        .catch(e => {
          setError(e.response.data.message)
        })
        .finally(() => {
          formik.setSubmitting(false)
        })
    },
  })

  const onBeforeClose = useCallback(() => {
    setError(null)
    form.resetForm()
    onClose()
  }, [])

  return (
    <Modal isOpen={isOpen} toggle={onBeforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Change Password</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onBeforeClose}
          ></i>
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          {isError ? (
            <Alert color='danger alert-borderless mx-2 text-center'>
              {isError}
            </Alert>
          ) : null}
          <div className='row g-3'>
            <Col xxl={12}>
              <label htmlFor='currentPassword' className='form-label'>
                Current Password
              </label>
              <PasswordInput
                name='currentPassword'
                disabled={form.isSubmitting}
                placeholder=''
                id='currentPassword'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.currentPassword || ''}
                invalid={
                  !!(
                    form.touched.currentPassword && form.errors.currentPassword
                  )
                }
                error={
                  form.touched.currentPassword && form.errors.currentPassword
                    ? (form.errors.currentPassword as string)
                    : null
                }
              />
            </Col>

            <Col xxl={12}>
              <label htmlFor='newPassword' className='form-label'>
                New Password
              </label>
              <PasswordInput
                name='newPassword'
                className='form-control'
                id='newPassword'
                disabled={form.isSubmitting}
                placeholder=''
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.newPassword || ''}
                invalid={
                  !!(form.touched.newPassword && form.errors.newPassword)
                }
                error={
                  form.touched.newPassword && form.errors.newPassword
                    ? (form.errors.newPassword as string)
                    : null
                }
              />
            </Col>

            <Col xxl={12}>
              <label htmlFor='confirmPassword' className='form-label'>
                Confirm New Password
              </label>
              <PasswordInput
                name='confirmPassword'
                className='form-control'
                id='confirmPassword'
                disabled={form.isSubmitting}
                placeholder=''
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.confirmPassword || ''}
                invalid={
                  !!(
                    form.touched.confirmPassword && form.errors.confirmPassword
                  )
                }
                error={
                  form.touched.confirmPassword && form.errors.confirmPassword
                    ? (form.errors.confirmPassword as string)
                    : null
                }
              />
            </Col>
          </div>

          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={onBeforeClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={!form.isValid || !form.dirty || form.isSubmitting}
            >
              {form.isSubmitting ? <Spinner size={'sm'} /> : 'Change Password'}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ChangePasswordModal
