import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import ParticlesAuth from '../ParticlesAuth'
import { Button } from 'reactstrap'

// Import Images
import error from '../../../assets/images/500-error.png'

import ParticlesAuthHeader from '../../../Components/Common/ParticlesAuthHeader'

const Error500 = () => {
  const onRefresh = () => {
    window.location.reload()
  }

  return (
    <div className='auth-page-wrapper'>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <ParticlesAuthHeader />
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <img
                        src={error}
                        alt=''
                        className='error-basic-img'
                        height={210}
                      />
                      <div className='mt-n4 pt-5'>
                        <h2 className='error-message mb-2 fw-light'>
                          We're currently offline
                        </h2>
                        <p className='error-message text-muted mb-4 px-2 fw-light'>
                          We can’t load the webpage because our server is
                          currently offline. Please, try refreshing the page by
                          clicking the button below or come back later.
                        </p>
                        <Button
                          color={'primary'}
                          className='btn btn-primary'
                          onClick={onRefresh}
                        >
                          <span className='d-flex'>
                            <i className='ri-refresh-line me-1'></i>
                            <span>Refresh</span>
                          </span>
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </div>
  )
}

export default Error500
