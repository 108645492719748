export enum AVG_TEST_SCORE {
  LESS_THAN_EIGHTY = '80-',
  BETWEEN_EIGHTY_AND_NINETY = '80-89.9',
  EQUAL_OR_MORE_THAN_NINETY = '90+',
}

export enum AVG_DAYS_TO_COMPLETE {
  THREE_DAYS_OR_LESS = '<=3',
  BETWEEN_FOUR_AND_SIX = '4-6',
  BETWEEN_SEVEN_AND_NINE = '7-9',
  EQUAL_OR_MORE_THAN_TEN = '10+',
}

export enum RETAKE_TEST {
  LESS_THAN_FIVE = '5-',
  BETWEEN_FIVE_AND_ELEVEN = '5-10.9',
  BETWEEN_ELEVEN_AND_SIXTEEN = '11-15.9',
  BETWEEN_SIXTEEN_AND_TWENTY_ONE = '16-20.9',
  BETWEEN_TWENTY_ONE_AND_TWENTY_SIX = '21-25.9',
  EQUAL_OR_MORE_THAN_TWENTY_SIX = '26+',
}

export enum RETAKE_COURSE {
  LESS_THAN_ONE = '1-',
  BETWEEN_ONE_AND_THREE = '1-2.9',
  BETWEEN_THREE_AND_FIVE = '3-4.9',
  EQUAL_OR_MORE_THAN_FIVE = '5+',
}

export enum AVG_USER_RATING {
  LESS_THAN_THREE = '3-',
  BETWEEN_THREE_AND_THREE_POINT_FOUR = '3-3.4',
  BETWEEN_THREE_POINT_FIVE_AND_FOUR = '3.5-3.9',
  BETWEEN_FOUR_AND_FOUR_POINT_FIVE = '4-4.5',
  BETWEEN_FOUR_POINT_FIVE_AND_FIVE = '4.6-5',
}

export const avgTestScore = [
  { value: AVG_TEST_SCORE.LESS_THAN_EIGHTY, label: 'Less than 80%' },
  { value: AVG_TEST_SCORE.BETWEEN_EIGHTY_AND_NINETY, label: '80-89.9%' },
  { value: AVG_TEST_SCORE.EQUAL_OR_MORE_THAN_NINETY, label: '90% and above' },
]

export const avgDaysToComplete = [
  { value: AVG_DAYS_TO_COMPLETE.THREE_DAYS_OR_LESS, label: '3 days or less' },
  { value: AVG_DAYS_TO_COMPLETE.BETWEEN_FOUR_AND_SIX, label: '4-6 days' },
  { value: AVG_DAYS_TO_COMPLETE.BETWEEN_SEVEN_AND_NINE, label: '7-9 days' },
  {
    value: AVG_DAYS_TO_COMPLETE.EQUAL_OR_MORE_THAN_TEN,
    label: '10 days or more',
  },
]

export const retakeTest = [
  { value: RETAKE_TEST.LESS_THAN_FIVE, label: 'Less than 5%' },
  { value: RETAKE_TEST.BETWEEN_FIVE_AND_ELEVEN, label: '5-10.9%' },
  { value: RETAKE_TEST.BETWEEN_ELEVEN_AND_SIXTEEN, label: '11-15.9%' },
  { value: RETAKE_TEST.BETWEEN_SIXTEEN_AND_TWENTY_ONE, label: '16-20.9%' },
  { value: RETAKE_TEST.BETWEEN_TWENTY_ONE_AND_TWENTY_SIX, label: '21-25.9%' },
  { value: RETAKE_TEST.EQUAL_OR_MORE_THAN_TWENTY_SIX, label: '26% and above' },
]

export const retakeCourse = [
  { value: RETAKE_COURSE.LESS_THAN_ONE, label: 'Less than 1%' },
  { value: RETAKE_COURSE.BETWEEN_ONE_AND_THREE, label: '1-2.9%' },
  { value: RETAKE_COURSE.BETWEEN_THREE_AND_FIVE, label: '3-4.9%' },
  { value: RETAKE_COURSE.EQUAL_OR_MORE_THAN_FIVE, label: '5% and above' },
]

export const avgUserRating = [
  { value: AVG_USER_RATING.LESS_THAN_THREE, label: 'Less than 3%' },
  {
    value: AVG_USER_RATING.BETWEEN_THREE_AND_THREE_POINT_FOUR,
    label: '3-3.4%',
  },
  {
    value: AVG_USER_RATING.BETWEEN_THREE_POINT_FIVE_AND_FOUR,
    label: '3.5-3.9%',
  },
  { value: AVG_USER_RATING.BETWEEN_FOUR_AND_FOUR_POINT_FIVE, label: '4-4.5%' },
  { value: AVG_USER_RATING.BETWEEN_FOUR_POINT_FIVE_AND_FIVE, label: '4.6-5%' },
]
