import React from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'

import { TUser } from '../../sharedTypes'
import { getUserDisplayName } from '../../helpers/user'

interface CreateNewRoleModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  user: TUser
  onDeactivate: () => void
  isActivate?: boolean
}

const DeactivateModal = ({
  onClose,
  isOpen,
  user,
  onDeactivate,
  isActivate,
}: CreateNewRoleModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-danger text-danger'>
              <i
                className='ri-account-circle-line'
                style={{ fontSize: 62 }}
              ></i>
            </div>
          </div>

          <h3 className='mt-4'>{isActivate ? 'Activate' : 'Deactivate'}</h3>
          <span className='text-muted fw-light'>
            Are you sure you want to {isActivate ? 'activate' : 'deactivate'}
            <br /> {getUserDisplayName(user)} Account?
          </span>
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-primary w-100'
            onClick={onClose}
            active={false}
          >
            Cancel
          </Button>
          <Button
            color={isActivate ? 'success' : 'danger'}
            className='w-100'
            onClick={onDeactivate}
          >
            {isActivate ? 'Activate' : 'Deactivate'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeactivateModal
