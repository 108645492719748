// SUPPORT
import {
  CreateSupportCategoryDTO,
  EditSupportCategoryDTO,
  GetSupportCategoriesDTO,
  GetSupportCategoryNamesDTO,
  TSupportCategory,
} from '../../sharedTypes'
import {
  DELETE_SUPPORT_CATEGORY,
  GET_SUPPORT_CATEGORIES,
  GET_SUPPORT_CATEGORY,
  POST_SUPPORT_CATEGORY,
  PUT_SUPPORT_CATEGORY,
} from '../url_helper'
import { API } from '../api_helper'

export const getSupportCategories = (
  params: GetSupportCategoriesDTO.Request,
) => {
  return API.get<GetSupportCategoriesDTO.Response>(GET_SUPPORT_CATEGORY, {
    params,
  }).then(d => d.data)
}

export const getSupportCategoriesNames = (
  params: GetSupportCategoryNamesDTO.Request,
) => {
  return API.get<GetSupportCategoryNamesDTO.Response>(
    `${GET_SUPPORT_CATEGORY}/list`,
    {
      params,
    },
  ).then(d => d.data)
}

export const getSupportCategory = (id: number) => {
  return API.get<TSupportCategory>(GET_SUPPORT_CATEGORIES + `/${id}`).then(
    d => d.data,
  )
}

export const postSupportCategory = (body: FormData) => {
  return API.post<CreateSupportCategoryDTO.Response>(
    POST_SUPPORT_CATEGORY,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}

export const putSupportCategory = (id: number, body: FormData) => {
  return API.put<EditSupportCategoryDTO.Response>(
    PUT_SUPPORT_CATEGORY + `/${id}`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}

export const deleteSupportCategory = (id: number) => {
  return API.delete<TSupportCategory>(DELETE_SUPPORT_CATEGORY + `/${id}`)
}
