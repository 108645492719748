import React, { useMemo } from 'react'
import { Routes, Route } from 'react-router-dom'

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout'
import VerticalLayout from '../Layouts/index'
import { CourseContextProvider } from '../context/CourseContext'

//routes
import {
  authProtectedRoutes,
  publicRoutes,
  courseStepsRoutes,
} from './allRoutes'
import { AuthProtected } from './AuthProtected'
import { useAppSelector } from '../hooks/redux'
import useIsMasteredAdmin from '../hooks/useIsMasteredAdmin'

const Index = () => {
  const switchedToPortal = useAppSelector(state => state.User.switchedToPortal)

  const isMasteredAdmin = useIsMasteredAdmin()

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthLayout
                  forceLogin={
                    typeof route.forceLogin === 'boolean'
                      ? route.forceLogin
                      : true
                  }
                >
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              // exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map(
            (route, idx) =>
              (route.render && !switchedToPortal
                ? route.render(isMasteredAdmin as boolean)
                : true) && (
                <Route
                  path={route.path}
                  element={
                    <AuthProtected>
                      <VerticalLayout>
                        {typeof route.component === 'function'
                          ? route.component(isMasteredAdmin as boolean)
                          : route.component}
                      </VerticalLayout>
                    </AuthProtected>
                  }
                  key={idx}
                />
              ),
          )}
        </Route>
        <Route element={<CourseContextProvider />}>
          {courseStepsRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected permission={route.permission}>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AuthProtected>
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  )
}

export default Index
