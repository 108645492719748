import { TFacility } from '../models'
import { PaginationRequest } from './pagination'
import { TAttachment } from '../models/attachments'
import { StatesEnum } from '../lib/locations'

export enum FacilitiesSortBy {
  FACILITY_NAME = 'facility.name',
  DEPARTMENT_NAME = 'department.name',
  POSITION_NAME = 'position.name',
  EMPLOYEE_ID = 'employeeId',
  STATUS = 'status',
}

export namespace GetFacilitiesDTO {
  export interface Request extends PaginationRequest {
    sortBy?: FacilitiesSortBy
  }
  export interface Response {
    count: number
    facilities: TFacility[]
    page: number
    pages: number
  }
}

export namespace CreateFacilityDTO {
  export interface Request {
    name: string
    companyId: number
    groupId?: number | null
    address?: string
    phone?: string
    state?: StatesEnum
    signature?: TAttachment | Blob | undefined
  }
  export type Response = TFacility
}

export namespace PostFacilitySignatoryDTO {
  export interface Request {
    facilityId: number
    signatoryId: number
    signature?: TAttachment | Blob | undefined
  }
  export type Response = TFacility
}
