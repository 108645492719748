import React, { useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { SurveyStatusTypes, TSurvey } from '../../sharedTypes'

export interface ISurveyPermissions {
  add: boolean
  edit: boolean
  view: boolean
  send: boolean
  delete: boolean
}

interface SurveyActionDropDownProps {
  survey: TSurvey
  permissions: ISurveyPermissions
  onDelete: () => void
  onDuplicate: () => void
  onEdit: () => void
  onSend: () => void
  onView: () => void
}

export const SurveyActionDropDown = ({
  survey,
  permissions,
  onView,
  onSend,
  onEdit,
  onDuplicate,
  onDelete,
}: SurveyActionDropDownProps) => {
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)
  const isSurveySent = [
    SurveyStatusTypes.SENT,
    SurveyStatusTypes.COMPLETED,
  ].includes(survey.status)
  const disabledClasses = 'text-disabled pe-none'

  return (
    <Dropdown
      isOpen={actionDropdownOpen}
      toggle={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setActionDropdownOpen(prevState => !prevState)
      }}
    >
      <DropdownToggle
        className={`badge btn ${
          actionDropdownOpen
            ? 'btn-primary'
            : 'text-light-purple btn-soft-primary'
        }`}
        tag='button'
      >
        <i className='mdi mdi-dots-horizontal align-middle' />
      </DropdownToggle>
      <DropdownMenu container={'body'}>
        {permissions.view && (
          <DropdownItem onClick={onView}>
            <i className='ri-eye-line cursor-pointer'></i>
            <span className='mx-2'>View</span>
          </DropdownItem>
        )}

        {permissions.send && (
          <DropdownItem
            className={`${
              survey.status !== SurveyStatusTypes.SCHEDULED
                ? disabledClasses
                : 'cursor-pointer'
            }`}
            onClick={onSend}
          >
            <i
              className={`ri-external-link-line`}
              id={`sendSurvey${survey.id}`}
            ></i>
            <span className='mx-2'>Send</span>
          </DropdownItem>
        )}

        {permissions.edit && (
          <DropdownItem
            className={isSurveySent ? disabledClasses : 'cursor-pointer'}
            onClick={onEdit}
          >
            <i className={`ri-pencil-line`} id={`editSurvey${survey.id}`}></i>
            <span className='mx-2'>Edit</span>
          </DropdownItem>
        )}

        {permissions.add && (
          <DropdownItem onClick={onDuplicate}>
            <i
              className='bx bx-duplicate cursor-pointer'
              id={`duplicateSurvey${survey.id}`}
            ></i>
            <span className='mx-2'>Duplicate</span>
          </DropdownItem>
        )}

        {permissions.delete && (
          <DropdownItem
            className={`${
              isSurveySent ? disabledClasses : 'cursor-pointer text-danger'
            }`}
            onClick={onDelete}
          >
            <i
              className={`ri-delete-bin-2-line`}
              id={`deleteSurvey${survey.id}`}
            ></i>
            <span className='mx-2'>Delete</span>
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
