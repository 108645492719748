import React, { useMemo } from 'react'
import {
  CoursesPermissions,
  ImageAnswerItem,
  MultiChoiceAnswerItem,
  OnlineCourseItem,
  QuestionableTypes,
  QuestionTypes,
  TLanguage,
} from '../../../../sharedTypes'
import { Card, CardBody, Col, Label, Row } from 'reactstrap'
import { useQuestions } from '../../../../hooks/course/useQuestions'
import {
  CorrectAnswerIcon,
  NotCorrectAnswerIcon,
} from '../../../../Components/Course/Questions/AnswerIcons'
import NoResults from '../../../../assets/images/no-result.png'
import { booleanAnswers } from './types'

interface CompetencyTestProps {
  course: OnlineCourseItem
  activeLanguage: TLanguage
}

const propertiesToRender = [
  { label: 'Name', contentKey: 'name' },
  { label: 'Description', contentKey: 'description' },
  { label: 'Objectives', contentKey: 'objective' },
  { label: 'Resources', contentKey: 'resources' },
]

const Preview = ({ course, activeLanguage }: CompetencyTestProps) => {
  const { data } = useQuestions(
    course.id,
    QuestionableTypes.COMPETENCY_ONLY,
    true,
    activeLanguage.id,
    50,
    CoursesPermissions.VIEW_TRANSLATIONS,
  )

  const translation = useMemo(
    () =>
      course?.translations.find(
        translation => translation.language.code === activeLanguage.code,
      ),
    [course],
  )

  return (
    <Card>
      <CardBody>
        {translation ? (
          <Row>
            <Col>
              {propertiesToRender.map((property, i) => (
                <div key={i} className='mb-4'>
                  <label className='fw-semibold'>{property.label}</label>
                  <div className='fw-normal'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          translation?.content[
                            property.contentKey as keyof typeof translation.content
                          ] || '',
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </Col>
            <Col>
              {data.questions.map((questionItem, i) => (
                <div className='border-bottom mb-3' key={questionItem.id}>
                  <div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <span className='text-muted fs-14'>
                        Question # {i + 1}
                      </span>
                    </div>
                    <div className='hstack mb-2'>
                      <span className='fw-medium fs-16 text-start text-body'>
                        {questionItem.question || '-'}
                      </span>
                    </div>
                  </div>

                  {questionItem.type === QuestionTypes.MULTIPLE_CHOICE &&
                    (
                      (questionItem.answers as MultiChoiceAnswerItem[]) || []
                    ).map((answerData, aIndex) => (
                      <div key={aIndex}>
                        <div className='form-check my-2 fs-14 mb-2 px-0 d-flex align-items-center gap-2 preview-icon'>
                          {answerData.correct ? (
                            <CorrectAnswerIcon className='' />
                          ) : (
                            <NotCorrectAnswerIcon className='ps-0 fs-24' />
                          )}

                          <Label className='form-check-label fw-medium text-muted'>
                            {'answer' in answerData && answerData?.answer}
                          </Label>
                        </div>
                      </div>
                    ))}

                  {questionItem.type === QuestionTypes.IMAGE_CHOICE && (
                    <div className='image-answers-container d-flex flex-wrap gap-2 preview'>
                      {((questionItem.answers as ImageAnswerItem[]) || []).map(
                        (answerData, aIndex) => (
                          <div
                            className='form-check my-2 fs-14 mb-2 px-0 d-flex align-items-center gap-2 preview-icon'
                            key={aIndex}
                          >
                            <div key={i} className='position-relative'>
                              <div className='d-flex justify-content-center align-items-center image-container'>
                                <img
                                  src={answerData.path}
                                  className='w-100 h-100 rounded'
                                  alt='No Image'
                                />
                              </div>
                              <div className='position-absolute top-0 right-0 p-2'>
                                {answerData.correct ? (
                                  <CorrectAnswerIcon />
                                ) : (
                                  <NotCorrectAnswerIcon className='fs-14' />
                                )}
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  )}

                  {questionItem.type === QuestionTypes.TRUE_FALSE && (
                    <div className='preview-icon'>
                      <div className='text-green-500 d-flex gap-3 align-items-center'>
                        <CorrectAnswerIcon className='' />
                        <span className='fs-14'>
                          {questionItem.answer?.correct
                            ? booleanAnswers[activeLanguage.code].true
                            : booleanAnswers[activeLanguage.code].false}
                        </span>
                      </div>

                      <div className='text-muted d-flex gap-3 align-items-center'>
                        <NotCorrectAnswerIcon className='ps-0 fs-24' />
                        <span className='fs-14'>
                          {questionItem.answer?.correct
                            ? booleanAnswers[activeLanguage.code].false
                            : booleanAnswers[activeLanguage.code].true}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </Col>
          </Row>
        ) : (
          <div className='d-flex flex-column align-items-center py-5'>
            <img src={NoResults} className='mb-5' alt='search-pic' />
            <p>There are no translations yet.</p>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default Preview
