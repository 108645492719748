import { CreateCompanyDTO, GetCompaniesDTO, TCompany } from '../../sharedTypes'
import { API } from '../api_helper'
import { COMPANIES } from '../url_helper'

export const getCompanies = (params: GetCompaniesDTO.Request) => {
  return API.get<GetCompaniesDTO.Response>(COMPANIES, {
    params,
  }).then(d => d.data)
}

export const getCompanyById = (id: number) => {
  return API.get<TCompany>(`${COMPANIES}/${id}`)
}

export const createCompany = (body: FormData) => {
  return API.post<CreateCompanyDTO.Response>(COMPANIES, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(d => d.data)
}

export const updateCompany = (id: number, data: FormData) => {
  return API.patch<CreateCompanyDTO.Response>(`${COMPANIES}/${id}`, data)
}
