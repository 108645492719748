import { COURSE_FILTER_FORMATS, OnlineCourseItem } from './onlineCourse'
import { ScheduledCourseItem } from '../api'
import { TCoursePackage } from './coursePackage'
import { TUser } from './user'
import { TAttachment } from './attachments'

export type TAssignment = {
  id: number
  course: OnlineCourseItem
  format: COURSE_FILTER_FORMATS
  progress: number
  competencyTestScore: number
  competencyTestTries: number
  code: string
  dueDate: string
  status: ASSIGNMENT_STATUS
  courseId: number
  startDate: string | null
  endDate: string
  nextTrainingDate: string
  parentId: number
  packageId: number
  package: TCoursePackage
  scheduleId: number
  userId: number
  assignmentById: number
  certificate: string
  dateAvailable: string
  courseRetries: number
  createdAt: string
  updatedAt: string
  schedule: ScheduledCourseItem | undefined
  user: TUser
  completedCoursesCount?: number
  coursesCount?: number
  testFileId: number | null
  attestationAccepted: boolean
  daysOverdue: number
  competencyTestMaxTries: number
  totalCompetencyTestTries: number
  testFile?: TAttachment
  courseAssignments?: TAssignment[]
}

export enum ASSIGNMENT_STATUS {
  ASSIGNED = 'Assigned',
  IN_PROGRESS = 'In Progress',
  ATTENDED = 'Attended',
  ABSENT = 'Absent',
  TEST_PENDING = 'Test Pending',
  COMPLETED = 'Completed',
}
