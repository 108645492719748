import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Button,
  Form,
  Alert,
} from 'reactstrap'
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'

import withRouter, { WithRouterProps } from '../../Components/Common/withRouter'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import ParticlesAuthHeader from '../../Components/Common/ParticlesAuthHeader'
import { VerifyRegistrationDTO } from '../../sharedTypes'
import { sendCode, verifyRegistration } from '../../helpers/api_helper'
import registrationVerification from '../../assets/images/img-verification.png'
import { Link, useSearchParams } from 'react-router-dom'
import NotAdminBlock from '../../Components/Common/NotAdminBlock'

type RegisterProps = WithRouterProps

const Code = (props: RegisterProps) => {
  const [timeLeft, setTimeLeft] = useState(60)
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>

    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1)
      }, 1000)
    } else if (timeLeft === 0) {
      setIsActive(false)
    }

    return () => clearInterval(timer)
  }, [isActive, timeLeft])

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string>('')

  const [searchParams] = useSearchParams()
  const login = searchParams.get('login')

  const sendVerification = async (data: VerifyRegistrationDTO.Request) => {
    setLoading(true)
    setError('')
    try {
      const verifiedData = await verifyRegistration(data)
      if (verifiedData.data.verifiedToken.token === data.token) {
        props.router.navigate(
          `/create-password/${verifiedData.data.createPassword?.token}`,
        )
      }
    } catch (error: any) {
      setError(
        'Code is not correct. Reenter the code or click Resend code below to get a new one.',
      )
    }
    setLoading(false)
  }

  const resendCode = async () => {
    if (login) {
      setTimeLeft(60)
      setIsActive(true)
      await sendCode(login)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first: '',
      second: '',
      third: '',
      fourth: '',
    },

    validationSchema: Yup.object().shape({
      first: Yup.string()
        .matches(/^\d{1}$/, 'more than 1 digit')
        .required(),
      second: Yup.string()
        .matches(/^\d{1}$/, 'more than 1 digit')
        .required(),
      third: Yup.string()
        .matches(/^\d{1}$/, 'more than 1 digit')
        .required(),
      fourth: Yup.string()
        .matches(/^\d{1}$/, 'more than 1 digit')
        .required(),
    }),

    onSubmit: values =>
      sendVerification({
        token: values.first + values.second + values.third + values.fourth,
        login: login ? login : '',
      }),
  })

  document.title = 'Register | Mastered - Premium Admin & Dashboard Platform'

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-register'>
          <Container>
            <ParticlesAuthHeader marginTop='mt-0' paddingTop={'pt-4'} />

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='d-flex align-items-center justify-content-center pt-2'>
                      <img src={registrationVerification} alt='NO IMAGE' />
                    </div>
                    <div className='text-center mt-4'>
                      <h5 className='text-primary fw-normal'>
                        Enter the 4-digit code
                      </h5>
                      <p className='text-muted fw-light fs-14 ff-base mb-2'>
                        We've sent a verification code to {login}
                      </p>
                    </div>
                    {isError ? (
                      <Alert color='danger alert-borderless mx-2'>
                        {isError}
                      </Alert>
                    ) : null}
                    <div className='p-2'>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        action='#'
                      >
                        <div className='d-flex flex-row justify-content-center gap-4 mb-3'>
                          <Input
                            name='first'
                            className='form-control'
                            style={{ width: '40px' }}
                            placeholder='0'
                            type='number'
                            onChange={value => {
                              validation.setFieldValue(
                                'first',
                                value.target.value.slice(0, 1),
                              )
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.first || ''}
                          />
                          <Input
                            name='second'
                            className='form-control'
                            style={{ width: '40px' }}
                            placeholder='0'
                            type='number'
                            onChange={value => {
                              validation.setFieldValue(
                                'second',
                                value.target.value.slice(0, 1),
                              )
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.second || ''}
                          />
                          <Input
                            name='third'
                            className='form-control'
                            style={{ width: '40px' }}
                            placeholder='0'
                            type='number'
                            onChange={value => {
                              validation.setFieldValue(
                                'third',
                                value.target.value.slice(0, 1),
                              )
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.third || ''}
                          />
                          <Input
                            name='fourth'
                            className='form-control'
                            style={{ width: '40px' }}
                            placeholder='0'
                            type='number'
                            onChange={value => {
                              validation.setFieldValue(
                                'fourth',
                                value.target.value.slice(0, 1),
                              )
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.fourth || ''}
                          />
                        </div>

                        <div className='text-center'>
                          {isActive ? (
                            <p className='fw-normal text-muted'>
                              Resend code after {timeLeft}{' '}
                              {timeLeft > 1 ? 'seconds' : 'second'}
                            </p>
                          ) : (
                            <a
                              className='text-purple mb-2 cursor-pointer'
                              onClick={() => resendCode()}
                            >
                              Resend code
                            </a>
                          )}
                        </div>

                        <div className='mt-4'>
                          <Button
                            color='primary'
                            disabled={
                              !(validation.isValid && validation.dirty) ||
                              isLoading
                            }
                            className='btn btn-primary w-100'
                            type='submit'
                          >
                            Next
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className='text-center'>
                  <p className='fw-normal'>
                    <Link to={'/login'}>
                      <i className='ri-arrow-left-s-line me-1'></i> Back
                    </Link>
                  </p>
                </div>
                <NotAdminBlock />
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Code)
