import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from 'reactstrap'
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'

import { useAppDispatch } from '../../hooks/redux'

import { Link } from 'react-router-dom'
import withRouter, { WithRouterProps } from '../../Components/Common/withRouter'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import ParticlesAuthHeader from '../../Components/Common/ParticlesAuthHeader'
import { LoginUserDTO } from '../../sharedTypes'
import { logInUser, setAuthorization } from '../../helpers/api_helper'
import { setLoginUser } from '../../slices/auth/user/reducer'
import axios from 'axios'

type LoginProps = WithRouterProps

const Login = (props: LoginProps) => {
  const dispatch = useAppDispatch()

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string>('')

  const [passwordShow, setPasswordShow] = useState(false)

  const loginUser = async (payload: LoginUserDTO.Request) => {
    setLoading(true)
    setError('')
    try {
      const { user, access_token, permissionSet } = await logInUser(
        payload,
      ).then(res => res.data)
      const userData = {
        user,
        accessToken: access_token,
        permissions: permissionSet,
      }
      setAuthorization(access_token)
      sessionStorage.setItem('authUser', JSON.stringify(userData))
      dispatch(setLoginUser(userData))
      props.router.navigate('/')
    } catch (error: any) {
      let message = error.message
      if (axios.isAxiosError(error)) {
        message = error.response?.data?.message || message
      }
      setError(message)
    }
    setLoading(false)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: Yup.object({
      login: Yup.string()
        .required('Please Enter Your Email')
        .email('Email not valid'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: loginUser,
  })

  document.title = 'Log In | Mastered - Premium Admin & Dashboard Platform'

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <ParticlesAuthHeader />

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary fw-normal'>Welcome back!</h5>
                      <p className='text-muted fw-light fs-14 ff-base'>
                        Log in to your account
                      </p>
                    </div>
                    {isError ? (
                      <Alert color='danger alert-borderless mx-2'>
                        {isError}
                      </Alert>
                    ) : null}
                    <div className='p-2 mt-4'>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        action='#'
                      >
                        <div className='mb-3'>
                          <Label htmlFor='email' className='form-label'>
                            Email
                          </Label>
                          <Input
                            name='login'
                            className='form-control'
                            placeholder='Enter email'
                            type='email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.login || ''}
                            invalid={
                              !!(
                                validation.touched.login &&
                                validation.errors.login
                              )
                            }
                          />
                          {validation.touched.login &&
                          validation.errors.login ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.login}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <div className='float-end'>
                            <Link
                              to='/forgot-password'
                              className='text-muted fw-light'
                            >
                              Forgot password?
                            </Link>
                          </div>
                          <Label
                            className='form-label'
                            htmlFor='password-input'
                          >
                            Password
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              value={validation.values.password || ''}
                              type={passwordShow ? 'text' : 'password'}
                              className='form-control pe-5'
                              placeholder='Enter password'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                !!(
                                  validation.touched.password &&
                                  validation.errors.password
                                )
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                              type='button'
                              onClick={() => setPasswordShow(!passwordShow)}
                              id='password-addon'
                            >
                              <i
                                className={`${
                                  !passwordShow
                                    ? 'ri-eye-line'
                                    : 'ri-eye-off-line'
                                } align-middle`}
                              ></i>
                            </button>
                          </div>
                        </div>

                        {/*<div className='form-check'>
                          <Input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='auth-remember-check'
                          />
                          <Label
                            className='form-check-label'
                            htmlFor='auth-remember-check'
                          >
                            Remember me
                          </Label>
                        </div>*/}

                        <div className='mt-4'>
                          <Button
                            color='primary'
                            disabled={
                              !(validation.isValid && validation.dirty) ||
                              isLoading
                            }
                            className='btn btn-primary w-100'
                            type='submit'
                          >
                            Log In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Login)
