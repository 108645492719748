import { TGroup } from '../models'

export namespace CreateGroupDTO {
  export interface Request {
    name: string
    companyId: number
    parentGroupId?: number | null
  }
  export type Response = TGroup
}
