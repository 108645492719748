import React, { Fragment } from 'react'
import { Button, Col } from 'reactstrap'

import { Step4Props } from './types'

const Confirmation = ({ onCancel, onNext }: Step4Props) => {
  return (
    <Fragment>
      <p className='text-muted py-2 px-3'>
        Save these assignments and send them to users.
      </p>
      <div className='px-3 row g-3'>
        <Col lg={12} className='my-1'>
          <div className='hstack gap-2 justify-content-between'>
            <Button
              className='btn-soft-primary align-middle'
              color='secondary'
              onClick={onCancel}
            >
              Back
            </Button>
            <Button className='align-middle' color='primary' onClick={onNext}>
              Save and Send
            </Button>
          </div>
        </Col>
      </div>
    </Fragment>
  )
}

export default Confirmation
