import { Table } from 'reactstrap'
import ColumnSortIcon from '../../../../Components/Common/ColumnSortIcon'
import {
  CoursesPermissions,
  GetScheduledCourseDTO,
  RecurringTypes,
  ScheduledCourseItem,
  ScheduledCourseSortBy,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
} from '../../../../sharedTypes'
import Highlighter from 'react-highlight-words'
import moment from 'moment/moment'
import { copyToClipboard } from '../../../../utils/clipboard'
import { Pagination } from '../../../../Components/Common/Pagination'
import NoResultTableWrapper from '../../../../Components/Common/NoResultTableWrapper'
import React, { useCallback } from 'react'
import { usePermissions } from '../../../../hooks/usePermissions'

const Columns = [
  {
    name: 'Format',
    style: { width: '10%' },
    sortBy: ScheduledCourseSortBy.TYPE,
  },
  {
    name: 'Facility',
    style: { width: '20%' },
    sortBy: ScheduledCourseSortBy.FACILITY,
  },
  {
    name: 'Date',
    style: { width: '10%' },
    sortBy: ScheduledCourseSortBy.DATE,
  },
  {
    name: 'Start Time',
    style: { width: '10%' },
    sortBy: ScheduledCourseSortBy.START_TIME,
  },
  {
    name: 'End Time',
    style: { width: '10%' },
    sortBy: ScheduledCourseSortBy.END_TIME,
  },
  {
    name: 'Recurring',
    style: { width: '10%' },
    sortBy: ScheduledCourseSortBy.REPORT_TYPE,
  },
  {
    name: 'Meeting Link',
    style: { width: '10%' },
    sortBy: ScheduledCourseSortBy.MEETING_LINK,
  },
  {
    name: 'Actions',
    style: { width: '7%' },
  },
]

interface ScheduledEntitiesListProps {
  isLoading: boolean
  query: GetScheduledCourseDTO.Request
  setQuery: React.Dispatch<React.SetStateAction<GetScheduledCourseDTO.Request>>
  data: GetScheduledCourseDTO.Response
  handleSort: (column: ScheduledCourseSortBy) => void
  onEdit: (item: ScheduledCourseItem) => void
  onDelete: (id: number) => void
  entityType: ScheduledEntitiesTypes
  noDataErrorMessage?: string
  isPublishing?: boolean
}

const ScheduledEntitiesList = ({
  isLoading,
  query,
  data,
  handleSort,
  onEdit,
  onDelete,
  setQuery,
  entityType,
  noDataErrorMessage,
  isPublishing,
}: ScheduledEntitiesListProps) => {
  const hasPermissionToEditSchedule = usePermissions(
    entityType === ScheduledEntitiesTypes.COURSE
      ? CoursesPermissions.EDIT_COURSE_SCHEDULE
      : CoursesPermissions.EDIT_PACKAGE_SCHEDULE,
  )
  const hasPermissionToDeleteSchedule = usePermissions(
    entityType === ScheduledEntitiesTypes.COURSE
      ? CoursesPermissions.DELETE_COURSE_SCHEDULE
      : CoursesPermissions.DELETE_PACKAGE_SCHEDULE,
  )

  const getColumns = useCallback(() => {
    if (!hasPermissionToEditSchedule && !hasPermissionToDeleteSchedule) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [])

  return (
    <NoResultTableWrapper
      className='p-0'
      noDataErrorMessage={noDataErrorMessage}
      noDataErrorMessageColor={
        isPublishing && data.count === 0 ? '#F25959' : 'black'
      }
      isLoading={isLoading}
      isFiltering={!!query.key}
      pages={data.pages}
    >
      <div className=''>
        <Table className='align-middle table-nowrap mb-0'>
          <thead className='table-light'>
            <tr className='text-muted fs-14'>
              {getColumns().map(column => (
                <th
                  scope='col'
                  className='fw-light align-middle'
                  style={column.style}
                  key={column.name}
                >
                  {column.name}
                  {!!column.sortBy && (
                    <ColumnSortIcon<ScheduledCourseSortBy>
                      sortOrder={query.orderBy}
                      sortedColumn={query.sortBy}
                      column={column.sortBy}
                      handleSort={handleSort}
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.scheduledEntities.map((item: ScheduledCourseItem, i) => (
              <tr key={i} className='fs-14 fw-light'>
                <td>
                  <span
                    className={`badge badge-soft-${
                      item.type === ScheduledCourseTypes.WEBINAR
                        ? 'primary'
                        : 'success'
                    } fs-12 fw-normal`}
                  >
                    {' '}
                    {item.type === ScheduledCourseTypes.IN_PERSON
                      ? 'In Person'
                      : entityType === ScheduledEntitiesTypes.COURSE
                      ? 'Webinar'
                      : 'Zoom only'}
                  </span>
                </td>
                <td>
                  {' '}
                  <Highlighter
                    highlightClassName='text-highlight'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={item?.facility?.name}
                  />
                </td>
                <td>
                  <span className='badge badge-soft-grey fs-12 fw-normal text-capitalize'>
                    {moment(item.startDate).format('MM/DD/YYYY')}
                  </span>
                </td>
                <td>
                  <span className='badge badge-soft-grey fs-12 fw-normal text-capitalize'>
                    {moment.utc(item.startTime, 'h:mm').local().format('LT')}
                  </span>
                </td>
                <td>
                  <span className='badge badge-soft-grey fs-12 fw-normal text-capitalize'>
                    {moment.utc(item.endTime, 'h:mm').local().format('LT')}
                  </span>
                </td>
                <td>
                  {item?.recurring === RecurringTypes.ONE_TIME
                    ? '-'
                    : `Weekly on ${moment(item.startDate).format('dddd')}`}
                </td>
                <td>
                  <div className='d-flex'>
                    <span
                      className='text-truncate text-secondary fw-medium'
                      style={{ maxWidth: 130 }}
                    >
                      {item.meetingLink}
                    </span>
                    <i
                      className='ri-file-copy-line cursor-pointer text-primary'
                      onClick={() => {
                        copyToClipboard(item.meetingLink as string)
                      }}
                    ></i>
                  </div>
                </td>
                {(hasPermissionToEditSchedule ||
                  hasPermissionToDeleteSchedule) && (
                  <td>
                    <span className='d-flex gap-2'>
                      {hasPermissionToEditSchedule && (
                        <i
                          onClick={() => onEdit(item)}
                          className={`bx bx-edit-alt cursor-pointer ${
                            item.isAssigned ? 'pe-none' : ''
                          }`}
                        ></i>
                      )}
                      {hasPermissionToDeleteSchedule && (
                        <i
                          onClick={() => {
                            onDelete(item.id)
                          }}
                          className={`bx bx-trash text-danger cursor-pointer ${
                            item.isAssigned ? 'pe-none' : ''
                          }`}
                        ></i>
                      )}
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <div className='mx-3 m-3'>
          <Pagination
            currentPage={data.page - 1}
            totalPages={data.pages}
            totalRecords={data.count}
            setPage={page => {
              setQuery(prev => ({ ...prev, page: ++page }))
            }}
          />
        </div>
      </div>
    </NoResultTableWrapper>
  )
}

export default ScheduledEntitiesList
