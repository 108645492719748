import { API } from '../api_helper'
import { ADMIN_NOTIFICATIONS, NOTIFICATIONS } from '../url_helper'
import { GetNotificationsDTO } from '../../sharedTypes/api/notifications'

export const getNotifications = (params: GetNotificationsDTO.Request) => {
  return API.get<GetNotificationsDTO.Response>(ADMIN_NOTIFICATIONS, {
    params,
  }).then(d => d.data)
}

export const markNotificationAsRead = (id: number) => {
  return API.post(NOTIFICATIONS + `/${id}`).then(d => d.data)
}
