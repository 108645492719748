import { Form } from 'reactstrap'
import _ from 'lodash'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { CREDENTIALS_TYPE, TUser, TUserCredentials } from '../../../sharedTypes'
import { removeCredential, updateCredential } from '../../../helpers/api/users'
import { handleError } from '../../../helpers/toast_helper'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CredentialItem from './CredentialItem'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'

export interface EditMode {
  [CREDENTIALS_TYPE.EMAIL]: {
    [id: number]: boolean
  }
  [CREDENTIALS_TYPE.PHONE]: {
    [id: number]: boolean
  }
}

interface IForm {
  emails: {
    id: number
    email: string
  }[]
  phones: {
    id: number
    phone: string
  }[]
  newEmail: string
  newPhone: string
}

export interface IAddCredential {
  [CREDENTIALS_TYPE.PHONE]: {
    isOpen: boolean
  }
  [CREDENTIALS_TYPE.EMAIL]: {
    isOpen: boolean
  }
}

interface ManageCredentialsProps {
  hasPermissionToViewUserInfo: boolean
  user: TUser
}

const ManageCredentials = ({
  user,
  hasPermissionToViewUserInfo,
}: ManageCredentialsProps) => {
  const [editMode, setEditMode] = useState<EditMode>({
    [CREDENTIALS_TYPE.EMAIL]: {},
    [CREDENTIALS_TYPE.PHONE]: {},
  })

  const [addCredential, setAddCredential] = useState<IAddCredential>({
    [CREDENTIALS_TYPE.EMAIL]: {
      isOpen: false,
    },
    [CREDENTIALS_TYPE.PHONE]: {
      isOpen: false,
    },
  })

  const [selectedCredential, setSelectedCredential] =
    useState<TUserCredentials | null>(null)

  const credentialName = useMemo(() => {
    if (selectedCredential) {
      return selectedCredential?.credentialType === CREDENTIALS_TYPE.EMAIL
        ? 'email'
        : 'phone number'
    }
    return ''
  }, [selectedCredential])

  const [phoneCredentials, setPhoneCredentials] = useState<TUserCredentials[]>(
    user.credentials?.filter(
      i => i.credentialType === CREDENTIALS_TYPE.PHONE,
    ) || [],
  )

  const [emailCredentials, setEmailCredentials] = useState<TUserCredentials[]>(
    user.credentials?.filter(
      i => i.credentialType === CREDENTIALS_TYPE.EMAIL,
    ) || [],
  )

  const initialPhones = useMemo(() => {
    return (
      user.credentials
        ?.filter(i => i.credentialType === CREDENTIALS_TYPE.PHONE)
        .map(cred => ({ id: cred.id, phone: cred.credential })) || []
    )
  }, [user])

  const initialEmails = useMemo(() => {
    return (
      user.credentials
        ?.filter(i => i.credentialType === CREDENTIALS_TYPE.EMAIL)
        .map(cred => ({ id: cred.id, email: cred.credential })) || []
    )
  }, [user])

  const form = useFormik<IForm>({
    initialValues: {
      emails: initialEmails,
      phones: initialPhones,
      newPhone: '',
      newEmail: '',
    },
    validationSchema: Yup.object({
      emails: Yup.array().of(
        Yup.object({
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        }),
      ),
      phones: Yup.array().of(
        Yup.object({
          phone: Yup.string()
            .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
            .required('Mobile is required'),
        }),
      ),
      newPhone: Yup.string()
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile is required'),
      newEmail: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    }),
    onSubmit: () => {},
  })

  const onUpdate = (id: number, i: number, type: CREDENTIALS_TYPE) => {
    const key = type === CREDENTIALS_TYPE.PHONE ? 'phones' : 'emails'

    form.validateForm().then(errors => {
      if (!_.get(errors[key], i, null)) {
        const data = {
          credentialType: type,
          id,
          [type]:
            type === CREDENTIALS_TYPE.PHONE
              ? form.values.phones[i]?.phone
              : form.values.emails[i]?.email,
        }

        updateCredential(data, user.id)
          .then(res => {
            const updatedCredentials = (
              prevCredentials: TUserCredentials[],
            ) => {
              return prevCredentials.map(prevCred => {
                if (data.id === prevCred.id) {
                  return { ...prevCred, ...res.credential }
                }
                return prevCred
              })
            }

            type === CREDENTIALS_TYPE.PHONE
              ? setPhoneCredentials(prev => updatedCredentials(prev || []))
              : setEmailCredentials(prev => updatedCredentials(prev || []))

            setEditMode(prev => ({
              ...prev,
              [type]: {
                ...prev[type],
                [id]: false,
              },
            }))
          })
          .catch(handleError)
      }
    })
  }

  const onAdd = (type: CREDENTIALS_TYPE) => {
    const key = type === CREDENTIALS_TYPE.PHONE ? 'newPhone' : 'newEmail'

    form.validateForm().then(errors => {
      if (!errors[key]) {
        const data = {
          credentialType: type,
          [type]: form.values[key],
        }
        updateCredential(data, user.id)
          .then(res => {
            const updatedCredentials =
              type === CREDENTIALS_TYPE.PHONE
                ? [...phoneCredentials, res.credential]
                : [...emailCredentials, res.credential]

            type === CREDENTIALS_TYPE.PHONE
              ? setPhoneCredentials(updatedCredentials)
              : setEmailCredentials(updatedCredentials)

            setAddCredential(prev => ({
              ...prev,
              [type]: {
                isOpen: false,
              },
            }))
            form.setFieldValue(key, '')
          })
          .catch(handleError)
      }
    })
  }

  const handleChange = (
    id: number,
    index: number,
    e: ChangeEvent<HTMLInputElement>,
    type: CREDENTIALS_TYPE,
  ) => {
    const newData =
      type === CREDENTIALS_TYPE.PHONE
        ? [...form.values.phones]
        : [...form.values.emails]
    const key = type === CREDENTIALS_TYPE.PHONE ? 'phones' : 'emails'

    if (index === newData.length) {
      let newCredential: any
      if (type === CREDENTIALS_TYPE.PHONE) {
        newCredential = { id, phone: e.target.value }
      } else {
        newCredential = { id, email: e.target.value }
      }
      newData.push(newCredential)
    } else {
      newData[index] =
        type === CREDENTIALS_TYPE.PHONE
          ? { id, phone: e.target.value }
          : { id, email: e.target.value }
    }
    form.setFieldValue(key, newData)
  }

  const handleAdd = (type: CREDENTIALS_TYPE) => {
    setAddCredential(prev => ({
      ...prev,
      [type]: {
        isOpen: true,
      },
    }))
  }

  const onDelete = useCallback(async () => {
    if (selectedCredential) {
      try {
        await removeCredential(selectedCredential.id)

        const updatedCredentials =
          selectedCredential.credentialType === CREDENTIALS_TYPE.PHONE
            ? phoneCredentials.filter(cred => cred.id !== selectedCredential.id)
            : emailCredentials.filter(cred => cred.id !== selectedCredential.id)

        selectedCredential.credentialType === CREDENTIALS_TYPE.PHONE
          ? setPhoneCredentials(updatedCredentials)
          : setEmailCredentials(updatedCredentials)

        const updatedFormValues = {
          ...form.values,
          [selectedCredential.credentialType === CREDENTIALS_TYPE.PHONE
            ? 'phones'
            : 'emails']: updatedCredentials.map(cred => ({
            id: cred.id,
            [selectedCredential.credentialType === CREDENTIALS_TYPE.PHONE
              ? 'phone'
              : 'email']: cred.credential,
          })),
        }

        form.setValues(updatedFormValues)
        setSelectedCredential(null)
      } catch (error) {
        handleError(error)
      }
    }
  }, [selectedCredential, phoneCredentials, emailCredentials, form])

  return (
    <>
      <Form
        onSubmit={e => {
          e.preventDefault()
          form.handleSubmit()
          return false
        }}
        action='#'
      >
        {hasPermissionToViewUserInfo && (
          <div className='d-flex m-0'>
            <div className='ps-0 fs-14 fw-normal text-nowrap user-info-key'>
              Phone:
            </div>

            <CredentialItem
              credentials={phoneCredentials}
              credentialType={CREDENTIALS_TYPE.PHONE}
              editMode={editMode}
              setEditMode={setEditMode}
              form={form}
              handleChange={handleChange}
              onUpdate={onUpdate}
              addCredential={addCredential}
              onAdd={onAdd}
              handleAdd={handleAdd}
              setAddCredential={setAddCredential}
              setSelectedCredential={setSelectedCredential}
            />
          </div>
        )}
        <div className='d-flex m-0'>
          <div className='p-0 fs-14 fw-normal text-nowrap user-info-key'>
            E-mail:
          </div>
          <CredentialItem
            credentials={emailCredentials}
            credentialType={CREDENTIALS_TYPE.EMAIL}
            editMode={editMode}
            setEditMode={setEditMode}
            form={form}
            handleChange={handleChange}
            onUpdate={onUpdate}
            addCredential={addCredential}
            onAdd={onAdd}
            handleAdd={handleAdd}
            setAddCredential={setAddCredential}
            setSelectedCredential={setSelectedCredential}
          />

          <DeleteConfirmation
            isOpen={!!selectedCredential}
            title={`Delete ${credentialName}?`}
            message={`Are you sure you want to delete "${selectedCredential?.credential}" ${credentialName}?`}
            onDelete={onDelete}
            onClose={() => {
              setSelectedCredential(null)
            }}
          />
        </div>
      </Form>
    </>
  )
}

export default ManageCredentials
