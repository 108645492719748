import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { USER_COURSE_STATUSES } from '../../../sharedTypes'
import _ from 'lodash'

interface Props {
  dataColors: string
  series: number[]
}

const Chart = ({ dataColors, series }: Props) => {
  const getChartColorsArray = (colors: string) => {
    return JSON.parse(colors).map(function (value: string) {
      const newValue = value.replace(' ', '')
      if (newValue.indexOf(',') === -1) {
        let color = getComputedStyle(document.documentElement).getPropertyValue(
          newValue,
        )

        if (color.indexOf('#') !== -1) {
          color = color.replace(' ', '')
        }
        if (color) {
          return color
        }

        return newValue
      } else {
        const val = value.split(',')
        if (val.length === 2) {
          let rgbaColor = getComputedStyle(
            document.documentElement,
          ).getPropertyValue(val[0])
          rgbaColor = 'rgba(' + rgbaColor + ',' + val[1] + ')'

          return rgbaColor
        }

        return newValue
      }
    })
  }

  const chartColors = getChartColorsArray(dataColors)

  const options: ApexCharts.ApexOptions = {
    labels: _.map(USER_COURSE_STATUSES, status => _.startCase(status)),
    chart: {
      type: 'donut',
      height: 219,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '76%',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 20,
        height: 6,
        radius: 2,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
    stroke: {
      width: 0,
    },
    yaxis: {
      tickAmount: 4,
      min: 0,
    },
    colors: chartColors,
  }

  return (
    <ReactApexChart
      dir='ltr'
      options={options}
      series={series}
      type='donut'
      height='219'
      width='280'
      className='apex-charts'
    />
  )
}

export default Chart
