import { TUser } from '../../sharedTypes'
import { getUserDisplayName } from '../../helpers/user'
import React from 'react'
import Highlighter from 'react-highlight-words'

interface UserInfoProps {
  user?: TUser | null
  globalSearch?: string
  className?: string
  displayLastNameFirst?: boolean
  displayFacility?: boolean
  hidePhoto?: boolean
}

const UserCell = ({
  user,
  globalSearch,
  className = 'detail-overflow',
  displayLastNameFirst = false,
  displayFacility = true,
  hidePhoto,
}: UserInfoProps) => {
  return (
    <td>
      <div className='d-flex align-items-center'>
        {!hidePhoto && (
          <div className='flex-shrink-0'>
            {user ? (
              <>
                {user.photo ? (
                  <img
                    src={user.photo}
                    alt='NO USER IMAGE'
                    className='avatar-xs rounded-circle'
                  />
                ) : (
                  <div className='avatar-xs'>
                    <div className='avatar-title rounded-circle bg-soft-primary text-primary'>
                      {user.firstName[0]} {user.lastName[0]}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className='avatar-xs'>
                <div className='avatar-title rounded-circle bg-soft-success text-success'>
                  A
                </div>
              </div>
            )}
          </div>
        )}
        <div className={`flex-grow-1 ${hidePhoto ? '' : 'ms-2'}`}>
          <p className='card-title mb-0 fs-14'>
            <Highlighter
              highlightClassName='text-highlight'
              searchWords={[globalSearch || '']}
              highlightTag={'span'}
              autoEscape={true}
              textToHighlight={
                user
                  ? getUserDisplayName(user, displayLastNameFirst)
                  : 'Anonymous'
              }
            />
          </p>
          <p className='text-muted mb-0 fs-12'>
            <Highlighter
              highlightClassName='text-highlight'
              id={user ? `userInfoOverflow-${user.id}` : ''}
              className={`${className} text-truncate user-info`}
              searchWords={[globalSearch || '']}
              highlightTag={'span'}
              autoEscape={true}
              textToHighlight={
                user && (user.facility || user?.position)
                  ? !displayFacility
                    ? user.position?.name ?? ''
                    : `${user.facility?.name ?? ''} | ${
                        user.position?.name ?? ''
                      }`
                  : '- | -'
              }
            />
          </p>
        </div>
      </div>
    </td>
  )
}

export default UserCell
