import React, { useEffect } from 'react'
import { Container } from 'reactstrap'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useNavigate } from 'react-router-dom'

const MasteredDashboard = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/company-management')
  }, [])

  document.title = 'Dashboard | Mastered - Admin & Dashboard'

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Dashboard' items={[{ title: 'Dashboard' }]} />
      </Container>
    </div>
  )
}

export default MasteredDashboard
