import React, { useMemo } from 'react'
import { Card, Col, Button, CardBody, Table } from 'reactstrap'
import { TScheduledCourse } from '../../../sharedTypes'
import moment from 'moment'

import { getUserDisplayName } from '../../../helpers/user'
import { BadgeColorByFormat } from '../../../helpers/course'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'

interface EventInfoProps extends WithRouterProps {
  course: TScheduledCourse
}

const EventInfo = ({ course, router }: EventInfoProps) => {
  const info = useMemo(
    () => [
      {
        label: 'Format',
        value: (
          <span
            className={`badge badge-soft-${
              BadgeColorByFormat[course.format]
            } fs-12 fw-normal text-capitalize`}
          >
            <i
              className={
                'mdi mdi-checkbox-blank-circle me-2 text-' +
                BadgeColorByFormat[course.format]
              }
            ></i>
            {course.format}
          </span>
        ),
      },
      {
        label: 'Instructor',
        value: getUserDisplayName(course.instructor),
      },
      {
        label: 'Type',
        value: course.type,
      },
      {
        label: 'Category',
        value: course.category?.name,
      },
      {
        label: 'Date',
        value: course.startDate
          ? moment(course.startDate).format('MM/DD/YYYY')
          : '-',
      },
      {
        label: 'Start Time',
        value: course.startTime
          ? moment(course.startTime, 'h:mm A').format('h:mm A')
          : '-',
      },
      {
        label: 'End Time',
        value: course.endTime
          ? moment(course.endTime, 'h:mm A').format('h:mm A')
          : '-',
      },
      {
        label: 'Location',
        value: course.location || '-',
      },
      {
        label: 'Notifications',
        value:
          course.notifications
            ?.map(notification => `${notification.value} ${notification.type}`)
            .join(', ') || '-',
      },
      {
        label: 'Number enrolled',
        value: 0,
      },
    ],
    [course],
  )

  const navigateToCourse = () => {
    router.navigate('/courses/scheduled-courses/manage/' + course.id)
  }

  return (
    <Col xl={3} lg={4} className='col'>
      <Card>
        <CardBody className='d-flex gap-4 flex-column'>
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
              <p className='fs-16 mb-0 fw-light' style={{ color: '#7C8193' }}>
                General Info
              </p>
            </div>
            <div className='flex-shrink-0 fs-12'>
              <Button
                color='soft-primary'
                className='fs-12'
                onClick={navigateToCourse}
              >
                <i className='ri-edit-box-line me-1'></i>Edit
              </Button>
            </div>
          </div>
          <div>
            <span className='fs-17' style={{ color: '#495057' }}>
              {course.name}
            </span>
          </div>
          <div className='table-responsive'>
            <Table className='table-borderless mb-0'>
              <tbody>
                {info.map((item, index) => (
                  <tr key={index}>
                    <th
                      className='ps-0 fs-14 fw-normal text-nowrap'
                      scope='row'
                    >
                      {item.label} :
                    </th>
                    <td className='text-muted fw-light'>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default withRouter(EventInfo)
