import React from 'react'
import { Col, Input, Label, Row } from 'reactstrap'

const CoursePackageFormat = ({
  label,
  description,
  checked,
  id,
  value,
  onChange,
}: {
  label: string
  description: string
  value: string
  id: string
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <Row className={'mx-0'}>
      <div
        className={`form-check bg-white border-1 py-2 align-items-center
    d-flex justify-content-center form-control ${checked ? 'active' : ''}`}
      >
        <Col
          sm={1}
          className='d-flex align-items-center justify-content-center'
        >
          <Input
            className='form-check-input'
            type='radio'
            name='format'
            onChange={onChange}
            checked={checked}
            id={id}
            value={value}
          />
        </Col>
        <Col>
          <Label className='form-check-label' htmlFor={id}>
            {label}
            <p className='text-muted fw-normal mb-0'>{description}</p>
          </Label>
        </Col>
      </div>
    </Row>
  )
}

export default CoursePackageFormat
