import React, { CSSProperties } from 'react'
import Select from 'react-select'
import { limitOptions } from '../../common/itemsPerPage'

interface itemsPerPageDropdownProps {
  limit: number
  onChange: (limit: { value: string; label: string }) => void
  width?: CSSProperties['width']
}

const ItemsPerPageDropdown = ({
  limit,
  onChange,
  width = '200px',
}: itemsPerPageDropdownProps) => {
  return (
    <div style={{ maxWidth: width }}>
      <Select<any>
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            borderRadius: '4px 0px 0px 4px',
            minHeight: 39,
          }),
        }}
        value={{
          label: limit.toString(),
          value: limit.toString(),
        }}
        onChange={limit => onChange(limit)}
        placeholder='Items per page'
        options={limitOptions}
      />
    </div>
  )
}

export default ItemsPerPageDropdown
