import { API } from '../api_helper'
import {
  GET_COURSE_FEEDBACK,
  GET_COURSE_REPORT,
  GET_SCHEDULED_COURSES,
} from '../url_helper'
import { GetScheduledCourseOptionsDTO } from '../../sharedTypes'
import {
  GetCourseFeedbackDTO,
  GetCourseReportDTO,
} from '../../sharedTypes/api/courseReport'
import { TCourseReport } from '../../sharedTypes/models/courseReport'

export const getScheduledCourseOptions = (
  entityId: number,
  params: GetScheduledCourseOptionsDTO.Request,
) => {
  return API.get<GetScheduledCourseOptionsDTO.Response>(
    GET_SCHEDULED_COURSES + `/${entityId}/options`,
    { params },
  )
}

export const getCourseReport = (params: GetCourseReportDTO.Request) => {
  return API.get<GetCourseReportDTO.Response>(GET_COURSE_REPORT, {
    params,
  }).then(d => d.data)
}

export const getCourseReportById = (id: number) => {
  return API.get<TCourseReport>(`${GET_COURSE_REPORT}/${id}`).then(d => d.data)
}

export const getCourseFeedback = (
  courseId: number,
  params: GetCourseFeedbackDTO.Request,
) => {
  return API.get<GetCourseFeedbackDTO.Response>(
    `${GET_COURSE_FEEDBACK}/${courseId}`,
    {
      params,
    },
  ).then(d => d.data)
}
