import React from 'react'
import { Button, Input, Modal, ModalBody, ModalProps } from 'reactstrap'
import { Form, Formik } from 'formik'

interface OverrideConfirmationModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  title: string
  message: string | JSX.Element
  onOverride: (notes: string) => void
  confirmLabel?: string
  confirmIcon?: string
}

export interface FormValues {
  notes: string
}

const OverrideConfirmationModal = ({
  onClose,
  isOpen,
  title,
  message,
  onOverride,
  confirmLabel = 'Override',
  confirmIcon = 'ri-indeterminate-circle-line',
}: OverrideConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-danger text-danger'>
              <i className={confirmIcon} style={{ fontSize: 62 }}></i>
            </div>
          </div>

          <h3 className='mt-4'>{title}</h3>
          <span className='text-muted fw-light'>{message}</span>
        </div>

        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={{ notes: '' }}
          onSubmit={() => {}}
        >
          {({
            values,
            handleBlur,
            handleChange,
            isValid,
            dirty,
            isSubmitting,
          }) => (
            <Form>
              <div className='mt-4'>
                <label htmlFor='departments' className='form-label mb-0'>
                  Explanation*
                  <p className='text-muted fw-normal'>
                    Will be sent to an admin to review
                  </p>
                </label>
                <Input
                  className='form-control'
                  id='notes'
                  name={'notes'}
                  type='textarea'
                  rows={3}
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className='hstack gap-2 justify-content-end mt-4'>
                <Button
                  className='btn-soft-primary w-100'
                  onClick={onClose}
                  active={false}
                >
                  Cancel
                </Button>
                <Button
                  color='danger'
                  className='w-100'
                  onClick={() => onOverride(values.notes)}
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {confirmLabel}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default OverrideConfirmationModal
