import React, { useState, useEffect } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

import { TFacility, TGroup } from '../../../sharedTypes'
import FacilityItem from './FacilityItem'
import { subscribe, unsubscribe } from '../../../utils/events'
import AdminsBlock from './AdminsBlock'

interface GroupProps {
  group: TGroup
  level?: number
  onEdit: (group: TGroup) => void
  onEditFacility: (facility: TFacility) => void
  onSignatoryChange: (facility: TFacility) => void
  handleToggleActivation: (facility: TFacility) => void
  permissions: {
    viewFacility: boolean
    editFacility: boolean
    activateFacility: boolean
    deactivateFacility: boolean
    editGroup: boolean
    deleteGroup: boolean
  }
}
const GroupItem = ({
  group,
  level = 1,
  onEdit,
  onEditFacility,
  permissions,
  handleToggleActivation,
  onSignatoryChange,
}: GroupProps) => {
  const [isCollapsed, setCollapsed] = useState(level === 1)
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)

  useEffect(() => {
    subscribe('expandAll', () => setCollapsed(false))
    subscribe('collapseAll', () => setCollapsed(true))

    return () => {
      unsubscribe('expandAll', () => setCollapsed(true))
      unsubscribe('collapseAll', () => setCollapsed(false))
    }
  }, [])

  return (
    <React.Fragment>
      <tr key={group.id} style={{ height: 64 }}>
        <td colSpan={4} style={{ paddingLeft: level * 40 }}>
          <i
            className={`ri-arrow-${
              !isCollapsed ? 'down' : 'right'
            }-s-line mx-3 me-2 fs-16 cursor-pointer`}
            onClick={() => setCollapsed(v => !v)}
          ></i>
          {group.name}
        </td>
        <td>
          <div className='d-flex justify-content-between'>
            <AdminsBlock admins={group.admins} />
            {(permissions.editGroup || permissions.deleteGroup) && (
              <Dropdown
                isOpen={actionDropdownOpen}
                toggle={() => setActionDropdownOpen(prevState => !prevState)}
              >
                <DropdownToggle
                  className={`badge btn ${
                    actionDropdownOpen
                      ? 'btn-primary'
                      : 'text-light-purple btn-soft-primary'
                  }`}
                  tag='button'
                >
                  <i className='mdi mdi-dots-horizontal align-middle' />
                </DropdownToggle>
                <DropdownMenu>
                  {permissions.editGroup && (
                    <DropdownItem>
                      <div onClick={() => onEdit(group)}>
                        <i className='text-muted ri-pencil-line cursor-pointer'></i>
                        <span className='mx-2'> Edit Group</span>
                      </div>
                    </DropdownItem>
                  )}

                  {permissions.deleteGroup && (
                    <DropdownItem>
                      <div>
                        <i className='ri-delete-bin-2-line text-danger'></i>
                        <span className='mx-2'> Delete Group</span>
                      </div>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </td>
      </tr>
      {!isCollapsed && (
        <>
          {group.facilities?.map(facility => {
            return (
              <FacilityItem
                facility={facility}
                key={facility.id}
                onSignatoryChange={onSignatoryChange}
                onEdit={onEditFacility}
                onToggleActivation={() => handleToggleActivation(facility)}
                hasPermissionToView={permissions.viewFacility}
                hasPermissionToEdit={permissions.editFacility}
                hasPermissionToActivate={permissions.activateFacility}
                hasPermissionToDeactivate={permissions.deactivateFacility}
              />
            )
          })}
          {group.subGroups?.map(subGroup => (
            <GroupItem
              onSignatoryChange={onSignatoryChange}
              group={subGroup}
              level={level + 1}
              key={subGroup.id}
              onEdit={onEdit}
              onEditFacility={onEditFacility}
              permissions={permissions}
              handleToggleActivation={handleToggleActivation}
            />
          ))}
        </>
      )}
    </React.Fragment>
  )
}

export default GroupItem
