import { TSupportCategory } from './supportСategory'

interface ResourceCreator {
  firstName: string
  lastName: string
}

type Video = {
  url: string
  info: {
    ext: string
    size: number
    originalname: string
  }
}
export type IResource = {
  id: number
  title: string
  content: string
  categoryId: number | null
  category: TSupportCategory | null
  creator: ResourceCreator | null
  creatorId: number | null
  createdAt: string
  updatedAt: string
}

export enum ResourceType {
  FAQ = 'faq',
  TUTORIAL = 'tutorial',
}

export type ITutorialItem = IResource & {
  video: Video
  type: ResourceType.TUTORIAL
}
