import { PaginatedBaseResponse, PaginationRequest } from './pagination'
import { SurveyStatisticsItem } from '../../sharedTypes'

export enum SurveyAnswersSortBy {
  USER = 'user',
  CREATED_AT = 'createdAt',
}

export namespace GetSurveyResponsesDTO {
  export interface Request extends PaginationRequest {
    page: number
    surveyId: number
    limit: number
    sortBy?: SurveyAnswersSortBy
    key?: string
    facilityId?: number
    groupId?: number
  }
  export interface Response extends PaginatedBaseResponse {
    responses: any[]
  }
}

export namespace GetSurveyStatisticsDTO {
  export interface Response {
    completedSurveys: number
    completionRate: number
    dateSend: string
    reportDate: string
    description: string
    name: string
    data: SurveyStatisticsItem[]
  }
  export interface Request {
    facilityId?: number
    groupId?: number
  }
}
