//FACILITIES
import {
  CreateFacilityDTO,
  GetFacilitiesDTO,
  TFacility,
  PostFacilitySignatoryDTO,
} from '../../sharedTypes'
import {
  GET_FACILITIES,
  PATCH_FACILITIES,
  POST_FACILITIES,
  POST_FACILITY_SIGNATORY,
} from '../url_helper'
import { API } from '../api_helper'
import { keys } from 'lodash'

export const getFacilities = (params: GetFacilitiesDTO.Request) => {
  return API.get<GetFacilitiesDTO.Response>(GET_FACILITIES, { params })
}

export const getFacilityById = (id: number) => {
  return API.get<TFacility>(GET_FACILITIES + `/${id}`)
}

export const postFacility = (data: CreateFacilityDTO.Request) => {
  const body = new FormData()

  keys(data).map(k => {
    body.append(k, (data as any)[k])
  })

  return API.post<CreateFacilityDTO.Response>(POST_FACILITIES, body)
}

export const postFacilitySignatory = (
  data: PostFacilitySignatoryDTO.Request,
) => {
  const body = new FormData()

  keys(data).map(k => {
    body.append(k, (data as any)[k])
  })

  return API.post<PostFacilitySignatoryDTO.Response>(
    POST_FACILITY_SIGNATORY,
    body,
  ).then(d => d.data)
}

export const patchFacility = (
  id: number,
  data: Partial<CreateFacilityDTO.Request>,
) => {
  const body = new FormData()

  keys(data).map(k => {
    body.append(k, (data as any)[k])
  })
  return API.patch<CreateFacilityDTO.Response>(
    PATCH_FACILITIES + `/${id}`,
    body,
  )
}

export const activateFacility = (id: number) => {
  return API.post<CreateFacilityDTO.Response>(
    POST_FACILITIES + `/${id}/activate`,
  )
}

export const deactivateFacility = (id: number) => {
  return API.post<CreateFacilityDTO.Response>(
    POST_FACILITIES + `/${id}/deactivate`,
  )
}
