import React, {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { Input, Col, FormFeedback, Row, Button, CardBody } from 'reactstrap'
import BooksAndStethoscope from '../../../../assets/images/svg/Books_and_stethoscope.svg'
import {
  postDigitalCourseFile,
  putDigitalCourse,
} from '../../../../helpers/api_helper'
import {
  OnlineCourseItem,
  OnlineCourseFormatsEnum,
} from '../../../../sharedTypes'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { handleError } from '../../../../helpers/toast_helper'
import { useFormik } from 'formik'
import { digitalCourseSchema } from '../../../../schemas'
import { CourseLayout } from '../../../../Components/Course'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../../helpers/toast_helper'
import { CourseContext } from '../../../../context/CourseContext'
import { useShouldGoTo } from '../../../../hooks/course/useShouldGoTo'

interface IForm {
  completeDays: number
  quizAttempts: number
}

const ReplaceFile = ({
  name,
  size,
  disabled,
  handleClick,
}: {
  name: string
  size: number
  disabled: boolean
  handleClick: () => void
}) => {
  return (
    <div>
      <section
        style={{
          maxWidth: 166,
          border: '#F0F4F9',
          borderWidth: 1,
          borderStyle: 'solid',
        }}
        className='mx-auto'
      >
        <section
          style={{ background: '#F0F4F9', height: 166 }}
          className='d-flex justify-content-center align-items-center'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='70'
            height='70'
            viewBox='0 0 70 70'
            fill='none'
          >
            <g id='icon/zip'>
              <path
                id='Vector'
                d='M58.3333 64.1654H11.6667C10.8931 64.1654 10.1513 63.8581 9.60427 63.3111C9.05729 62.7641 8.75 62.0222 8.75 61.2487V8.7487C8.75 7.97515 9.05729 7.23328 9.60427 6.6863C10.1513 6.13932 10.8931 5.83203 11.6667 5.83203H58.3333C59.1069 5.83203 59.8488 6.13932 60.3957 6.6863C60.9427 7.23328 61.25 7.97515 61.25 8.7487V61.2487C61.25 62.0222 60.9427 62.7641 60.3957 63.3111C59.8488 63.8581 59.1069 64.1654 58.3333 64.1654ZM55.4167 58.332V11.6654H14.5833V58.332H55.4167ZM40.8333 34.9987V49.582H29.1667V40.832H35V34.9987H40.8333ZM35 11.6654H40.8333V17.4987H35V11.6654ZM29.1667 17.4987H35V23.332H29.1667V17.4987ZM35 23.332H40.8333V29.1654H35V23.332ZM29.1667 29.1654H35V34.9987H29.1667V29.1654Z'
                fill='#85A8FF'
              />
            </g>
          </svg>
        </section>
        <section className='m-2'>
          <p>{name}</p>
          <p>{size} KB </p>
        </section>
      </section>
      <Button
        color={'primary'}
        disabled={disabled}
        className='btn btn-primary mt-3 align-middle mx-auto d-block'
        onClick={() => handleClick()}
      >
        <i className='ri-add-line me-1 fs-16'></i>Replace File
      </Button>
    </div>
  )
}

const DigitalCourse = ({ router }: WithRouterProps) => {
  const {
    course,
    setCourse,
    setCourseId,
    onNavigate,
    setLoading,
    loading,
    onRemoveFormat,
    onBack,
    goNext,
    deleteFormatModal,
    tabs,
    setDeleteFormatModal,
    isPublishing,
    isPublished,
  } = useContext(CourseContext)
  const { setShouldGoTo, onSuccess } = useShouldGoTo({ goNext, onBack })
  const [initialValues, setInitialValues] = useState<null | IForm>(null)
  const hiddenFileInput = useRef<any>(null)

  const allowToDeleteDigitalSettings = useMemo(
    () =>
      !course?.originalCourse ||
      !course.originalCourse?.formats.includes(OnlineCourseFormatsEnum.DIGITAL),
    [course],
  )

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const digital = await putDigitalCourse(
          Number(router.params.courseId),
          values,
        )
        setCourse((c: any) => ({ ...(c as OnlineCourseItem), digital }))
        onSuccess()
        toast(
          'Success - Digital settings successfully updated',
          successToastOptions,
        )
      } catch (e) {}
    },
    [router.params, onSuccess],
  )

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      completeDays: 0,
      quizAttempts: 0,
    },
    validationSchema: digitalCourseSchema,
    onSubmit,
  })

  const hasUnsavedData = useMemo(
    () => JSON.stringify(form.values) !== JSON.stringify(initialValues),
    [initialValues, form.values],
  )

  const handleClick = useCallback(() => {
    hiddenFileInput?.current?.click()
  }, [hiddenFileInput])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      const fileUploaded = event?.target?.files[0]
      onFileUpload(fileUploaded)
    }
  }

  const onFileUpload = useCallback(
    async (file: File) => {
      const form = new FormData()
      setLoading(true)

      form.append('file', file)
      await postDigitalCourseFile(Number(router.params.courseId), form)
        .then(digital => {
          setCourse((c: any) => ({ ...(c as OnlineCourseItem), digital }))
        })
        .catch(handleError)
        .finally(() => {
          setLoading(false)
        })
    },
    [router.params.courseId],
  )

  useEffect(() => {
    setInitialFormValues()
  }, [course])

  const setInitialFormValues = useCallback(() => {
    if (course?.digital) {
      const values: IForm = {
        completeDays: course?.digital?.completeDays,
        quizAttempts: course?.digital?.quizAttempts,
      }
      form.setValues(values, true)
      setInitialValues(values)
    }
  }, [course])

  useEffect(() => {
    setCourseId(Number(router.params.courseId))
  }, [router.params.courseId])

  return (
    <div>
      <CourseLayout
        backText='Back'
        leftSideBar={true}
        showSaveAsDraft
        onNavigate={direction => {
          if (!hasUnsavedData) {
            setInitialFormValues()
            onNavigate(direction)
          } else {
            setShouldGoTo({ direction })
            form.submitForm()
          }
        }}
        router={router}
        tabs={tabs}
        onBack={() => {
          if (!hasUnsavedData) {
            setInitialFormValues()
            onBack()
          } else {
            setShouldGoTo({ direction: 'prev' })
            form.submitForm()
          }
        }}
        onRemoveFormat={onRemoveFormat}
        setDeleteFormatModal={setDeleteFormatModal}
        deleteFormatModal={deleteFormatModal}
        hasUnsavedData={false}
        course={course}
        onNext={() => {
          if (!hasUnsavedData) {
            setInitialFormValues()
            goNext()
          } else {
            setShouldGoTo({ direction: 'next' })
            form.submitForm()
          }
        }}
        onSaveAsDraft={props => {
          setShouldGoTo({ direction: '/courses' })
          form.submitForm()
        }}
      >
        <Row className='w-100'>
          <Row>
            <Col sm='3'>
              {/* <div className='mb-3'>
                <label htmlFor='title' className='form-label'>
                  Number of days to complete course
                </label>
                <Input
                  className='form-control'
                  id='title'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  name='completeDays'
                  value={form.values.completeDays}
                  placeholder=''
                  type='number'
                  invalid={
                    !!(form.touched.completeDays && form.errors.completeDays)
                  }
                />
                {form.touched.completeDays && form.errors.completeDays ? (
                  <FormFeedback type='invalid'>
                    {form.errors.completeDays}
                  </FormFeedback>
                ) : null}
              </div>*/}

              {/*} <div>
                <label htmlFor='quizAttempts' className='form-label'>
                 Test attempts before having to restart the course
                </label>
                <Input
                  className='form-control'
                  id='quizAttempts'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  name='quizAttempts'
                  placeholder=''
                  type='number'
                  value={form.values.quizAttempts}
                  invalid={
                    !!(form.touched.quizAttempts && form.errors.quizAttempts)
                  }
                />
                {form.touched.quizAttempts && form.errors.quizAttempts ? (
                  <FormFeedback type='invalid'>
                    {form.errors.quizAttempts}
                  </FormFeedback>
                ) : null}
              </div>
              */}
            </Col>
            <Col sm='4' className='offset-sm-5'>
              {!isPublished && allowToDeleteDigitalSettings && (
                <span
                  className='text-danger cursor-pointer d-flex gap-2 justify-content-end'
                  onClick={() => {
                    setDeleteFormatModal({
                      title: 'Delete Digital Settings',
                      message:
                        'Are you sure you want to delete Digital Settings?',
                      format: OnlineCourseFormatsEnum.DIGITAL,
                    })
                  }}
                >
                  <b>Remove Digital Settings</b>{' '}
                  <i className='bx bx-trash text-danger cursor-pointer	'></i>
                </span>
              )}
            </Col>
          </Row>

          {!loading ? (
            <Row>
              {!course?.digital?.file ? (
                <div className='py-5'>
                  <img
                    src={BooksAndStethoscope}
                    className='img-fluid mx-auto d-flex'
                    alt=''
                  />

                  <p
                    className='text-center my-3'
                    style={{
                      fontSize: 17,
                      color: isPublishing ? '#F25959' : 'black',
                    }}
                  >
                    No Course Uploaded yet
                  </p>
                  <ul
                    style={{ maxWidth: '425px' }}
                    className='mx-auto list-unstyled'
                  >
                    <li className='badge-soft-grey d-flex p-2 mb-2 gap-2'>
                      <span className='number-badge'>1</span>
                      <span className='ml-1'>
                        Create your course in Storyline 360
                      </span>
                    </li>
                    <li className='badge-soft-grey d-flex p-2 mb-2 gap-2'>
                      <span className='number-badge'>2</span>
                      <span>Publish your Storyline Project</span>
                    </li>
                    <li className='badge-soft-grey d-flex p-2 mb-2 gap-2'>
                      <span className='number-badge'>3</span>
                      <span>
                        When the Publish window appears select the LMS/LRS tab
                        on the left
                      </span>
                    </li>
                    <li className='badge-soft-grey d-flex p-2 mb-2 gap-2'>
                      <span className='number-badge'>4</span>
                      <span>
                        Select Zip which will create a zipped version of your
                        course files in the same location where your course was
                        published
                      </span>
                    </li>
                    <li className='badge-soft-grey d-flex p-2 mb-2 gap-2'>
                      <span className='number-badge'>5</span>
                      <span>Download the zip file</span>
                    </li>
                    <li className='badge-soft-grey d-flex p-2 mb-2 gap-2'>
                      <span className='number-badge'>6</span>
                      <span>Upload the zip file on this page</span>
                    </li>
                  </ul>
                  <Button
                    color={'primary'}
                    className='btn btn-primary align-middle mx-auto d-block'
                    onClick={() => handleClick()}
                  >
                    <i className='ri-add-line me-1 fs-16'></i>Upload Course
                  </Button>
                </div>
              ) : (
                <section>
                  <div className=' mx-auto'>
                    <ReplaceFile
                      disabled={false}
                      name={course?.digital?.file.name}
                      size={course?.digital?.file.size}
                      handleClick={handleClick}
                    />
                  </div>
                </section>
              )}
              <input
                type='file'
                onChange={handleChange}
                accept='.zip'
                ref={hiddenFileInput}
                style={{ display: 'none' }}
              />
            </Row>
          ) : (
            <CardBody>
              <div className='d-flex flex-column align-items-center py-5'>
                <p style={{ fontSize: 20, fontWeight: 600 }}>Uploading...</p>
              </div>
            </CardBody>
          )}
        </Row>
      </CourseLayout>
    </div>
  )
}
export default withRouter(DigitalCourse)
