import { toast } from 'react-toastify'
import React from 'react'

export const copyToClipboard = (text: string, message = 'Copied') => {
  navigator.clipboard.writeText(text).then(() => {
    toast(message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'text-success toast-success',
      icon: <i className='ri-check-double-line'></i>,
      closeButton: <i className='ri-close-line fs-18 fw-light pt-1'></i>,
    })
  })
}
