import { PaginationRequest } from './pagination'
import { TCompanyNews } from '../models/companyNews'

export namespace GetCompanyNewsDTO {
  export interface Request extends PaginationRequest {
    page: number
    limit: number
    facilityId?: number
    groupId?: number
  }
  export interface Response {
    count: number
    companyNews: TCompanyNews[]
    page: number
    pages: number
  }
}

export namespace CreateCompanyNewsDTO {
  export interface Request
    extends Pick<
      TCompanyNews,
      | 'translations'
      | 'isDraft'
      | 'sendDate'
      | 'newsMediaId'
      | 'facilityIds'
      | 'groupIds'
    > {}
  export interface Response extends TCompanyNews {}
}
