import React from 'react'
import { PackageCoursesTableViewProps } from './types'
import { Card, CardBody, Col, Row } from 'reactstrap'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { BadgeColorByAllFormats } from '../../../helpers/course'
import { Link } from 'react-router-dom'
import defaultCourseCover from '../../../assets/images/course-default-cover.png'
import { CoursesPermissions } from '../../../sharedTypes'
import { usePermissions } from '../../../hooks/usePermissions'

const PackageCoursesGridView = ({
  packageCourses,
  query,
  handleDelete,
  viewOnly,
}: Omit<PackageCoursesTableViewProps, 'handleSort'>) => {
  const hasPermissionToDelete = usePermissions(
    CoursesPermissions.REMOVE_COURSES_FROM_PACKAGE,
  )

  return (
    <Row className='d-flex mt-4 flex-wrap position-relative'>
      {packageCourses.map((packageCourse, index) => (
        <Col
          md={6}
          lg={4}
          xl={3}
          className='course-card position-relative'
          key={index}
        >
          <Card style={{ height: '95%' }}>
            <div className='delete-action position-absolute d-flex align-items-start'>
              {hasPermissionToDelete && (
                <div>
                  <i
                    className={`ri-delete-bin-2-line badge-red fs-3 ms-1 p-1 cursor-pointer ${
                      viewOnly ? 'pe-none' : ''
                    }`}
                    onClick={() => handleDelete(packageCourse.course)}
                  ></i>
                </div>
              )}
            </div>
            <span
              className={`position-absolute badge badge-${
                BadgeColorByAllFormats[packageCourse.format]
              }
              fs-12 fw-normal p-1 course-format`}
            >
              {packageCourse.format}
            </span>
            <Link
              className='cursor-pointer'
              to={`/courses/general/${packageCourse.courseId}`}
              target='_blank'
            >
              <div className='course-cover'>
                <img
                  className='card-img-top img-fluid rounded w-100 h-100'
                  src={packageCourse.course.cover?.path ?? defaultCourseCover}
                  alt='NO IMAGE'
                  style={{
                    aspectRatio: 1,
                    objectFit: 'cover',
                  }}
                />
              </div>
            </Link>

            <CardBody className='d-flex'>
              <Col className='d-flex flex-column align-items-start justify-content-center gap-2'>
                <div className='d-flex justify-content-between w-100'>
                  <Link
                    className='text-muted cursor-pointer'
                    to={`/courses/general/${packageCourse.courseId}`}
                    target='_blank'
                  >
                    <span className='text-muted fs-14'>
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={packageCourse.courseCode}
                      />
                    </span>
                  </Link>
                </div>

                <Link
                  className='fs-14 text-body fw-medium cursor-pointer'
                  to={`/courses/general/${packageCourse.courseId}`}
                  target='_blank'
                >
                  <Highlighter
                    highlightClassName='text-highlight'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={_.get(
                      packageCourse,
                      'course.translations[0].content.name',
                      '-',
                    )}
                  />
                </Link>
                <div>
                  <span
                    className={`badge badge-outline-info fs-12 fw-normal text-capitalize`}
                  >
                    {packageCourse.course.type}
                  </span>
                  {/*<span*/}
                  {/*  className={`badge badge-soft-info fs-12 fw-normal text-capitalize`}*/}
                  {/*>*/}
                  {/*  {'Infusion Therapy'}*/}
                  {/*</span>*/}
                </div>

                <Row className='w-100'>
                  <Col>
                    <span className='fw-medium text-body fs-14'>
                      Formats Available:{' '}
                    </span>
                  </Col>
                  <Col className='pe-0'>
                    <span className='text-muted fs-14'>
                      {packageCourse.course.available_formats?.join(', ')}
                    </span>
                  </Col>
                </Row>
              </Col>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default PackageCoursesGridView
