import { COURSE_PACKAGES } from '../url_helper'
import { API } from '../api_helper'
import {
  CreateCoursePackageDTO,
  DuplicatePackageDTO,
  GetCoursePackagesDTO,
  GetPackageCoursesDTO,
  UpdateCoursePackageDTO,
} from '../../sharedTypes/api/coursePackages'

export const getCoursePackages = (params: GetCoursePackagesDTO.Request) => {
  return API.get<GetCoursePackagesDTO.Response>(COURSE_PACKAGES, {
    params,
  }).then(d => d.data)
}

export const createPackage = (body: CreateCoursePackageDTO.Request) => {
  return API.post<CreateCoursePackageDTO.Response>(
    `${COURSE_PACKAGES}`,
    body,
  ).then(d => d.data)
}

export const getCoursePackage = (id: number) => {
  return API.get<CreateCoursePackageDTO.Response>(
    `${COURSE_PACKAGES}/${id}`,
  ).then(d => d.data)
}

export const getPackageCourses = (
  params: GetPackageCoursesDTO.Request,
  packageId: number,
) => {
  return API.get<GetPackageCoursesDTO.Response>(
    `${COURSE_PACKAGES}/${packageId}/courses`,
    {
      params,
    },
  ).then(d => d.data)
}

export const updatePackage = (
  id: number,
  data: UpdateCoursePackageDTO.Request,
) => {
  return API.patch<UpdateCoursePackageDTO.Response>(
    `${COURSE_PACKAGES}/${id}`,
    data,
  )
}

export const changePackageFormat = (id: number) => {
  return API.post<UpdateCoursePackageDTO.Response>(
    `${COURSE_PACKAGES}/change-format/${id}`,
  )
}

export const duplicatePackage = (id: number) => {
  return API.post<DuplicatePackageDTO.Response>(
    `${COURSE_PACKAGES}/duplicate/${id}`,
  ).then(d => d.data)
}

export const deleteCourseFromPackage = (
  packageId: number,
  courseId: number,
) => {
  return API.delete(`${COURSE_PACKAGES}/${packageId}/courses/${courseId}`)
}

export const deleteCoursePackage = (id: number) => {
  return API.delete(`${COURSE_PACKAGES}/${id}`)
}
