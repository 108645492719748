import React, { useCallback, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  Col,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'
import _ from 'lodash-es'

import {
  getRequestEditFields,
  getRequestEditFieldsById,
  postRequestEditFields,
} from '../../../helpers/api/users'
import { GetEditRequestFieldsDto } from '../../../sharedTypes'
import { successToastOptions } from '../../../helpers/toast_helper'
interface Option {
  readonly label: string
  readonly value: string
}

interface EditRequestModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  userId?: number
}

const EditRequestModal = ({
  onClose,
  isOpen,
  userId,
}: EditRequestModalProps) => {
  const [fields, setFields] = React.useState<
    GetEditRequestFieldsDto.Response['fields']
  >([])
  const [options, setOptions] = React.useState<Option[]>([])

  useEffect(() => {
    const getRequestPromise = userId
      ? getRequestEditFieldsById(userId)
      : getRequestEditFields()

    getRequestPromise.then(data => {
      setOptions(
        data.map(f => ({
          value: f.field_name,
          label: _.capitalize(f.field_name),
        })),
      )
      setFields(data)
    })
  }, [])

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      field_name: '',
      field_value: '',
      notes: undefined,
    },
    validationSchema: Yup.object({
      field_value: Yup.string().required(),
    }),
    onSubmit: (values, formik) => {
      formik.setSubmitting(true)
      postRequestEditFields(values, userId)
        .then(() => {
          toast('Successfully requested', successToastOptions)
          form.resetForm()
          onClose()
        })
        .finally(() => {
          formik.setSubmitting(false)
        })
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Edit Request</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <div>
              <label htmlFor='title' className='form-label'>
                Field to Edit*
              </label>
              <Select
                name='field_name'
                id='field_name'
                isSearchable={false}
                onChange={option => {
                  if (option) {
                    form.setFieldValue('field_name', option.value)
                  }
                }}
                onBlur={form.handleBlur}
                value={options.find(o => o.value === form.values.field_name)}
                options={options}
                className='select2-container is-invalid'
                classNamePrefix='select2-selection form-select'
              />
            </div>

            <div className='row g-3'>
              <Col xxl={12}>
                <label htmlFor='certificateId' className='form-label'>
                  Current Value
                </label>
                <Input
                  className='form-control'
                  disabled
                  placeholder=''
                  value={
                    (fields.find(f => f.field_name === form.values.field_name)
                      ?.current_value as string) || ''
                  }
                  type='text'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
              </Col>
            </div>

            <div className='row g-3'>
              <Col xxl={12}>
                <label htmlFor='field_value' className='form-label'>
                  New Value*
                </label>
                {form.values.field_name === 'Birth Date' ? (
                  <Flatpickr
                    name='field_value'
                    className='form-control'
                    id='field_value'
                    disabled={form.isSubmitting}
                    options={{
                      mode: 'single',
                      dateFormat: 'YYYY-MM-DD',
                      formatDate: date => moment(date).format('MM/DD/YYYY'),
                    }}
                    placeholder='Select Date'
                    onChange={date =>
                      form.setFieldValue(
                        'field_value',
                        moment(date[0]).format('MM/DD/YYYY'),
                      )
                    }
                  />
                ) : (
                  <Input
                    name='field_value'
                    className='form-control'
                    id='field_value'
                    disabled={form.isSubmitting}
                    placeholder=''
                    type='text'
                    isDisabled
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.field_value || ''}
                  />
                )}
              </Col>
            </div>

            <div className='row g-3'>
              <Col xxl={12}>
                <label htmlFor='notes' className='form-label'>
                  Notes
                </label>
                <Input
                  name='notes'
                  className='form-control'
                  id='notes'
                  disabled={form.isSubmitting}
                  placeholder=''
                  type='textarea'
                  rows='6'
                  isDisabled
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.notes || ''}
                />
              </Col>
            </div>

            <div className='hstack gap-2 justify-content-end mt-4'>
              <Button
                className='btn-soft-primary'
                onClick={beforeClose}
                active={false}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                color='success'
                type='submit'
                disabled={!form.isValid || !form.dirty || form.isSubmitting}
              >
                {form.isSubmitting ? <Spinner size={'sm'} /> : 'Submit'}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default EditRequestModal
