import React, { KeyboardEventHandler, useCallback, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import CreatableSelect from 'react-select/creatable'
import { SignatureUpload } from '../Common/SignatureUpload'

import {
  getQualificationOptions,
  postInstructor,
  putInstructor,
} from '../../helpers/api_helper'
import {
  InstructorModalForm,
  SelectOption,
  TInstructor,
} from '../../sharedTypes'
import handleAcceptedFile from '../../utils/handleAcceptedFile'

import {
  errorToastOptions,
  successToastOptions,
} from '../../helpers/toast_helper'
import AvatarUploader from '../Common/AvaterUploader'

const components = {
  DropdownIndicator: null,
}

interface Option {
  readonly label: string
  readonly value: string
}

const createOption = (label: string) => ({
  label,
  value: label,
})

interface InstructorModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (instructor: TInstructor) => void
  title: string
  initialValues: InstructorModalForm | null
}

const InstructorModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
}: InstructorModalProps) => {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly Option[]>([])

  useEffect(() => {
    getQualificationOptions().then(list => {
      setOptions(list.qualifications.map(d => ({ label: d, value: d })))
    })
  }, [])

  const onCreate = useCallback(async (values: InstructorModalForm) => {
    const { name, qualification, signature, bio, photo, email } = values
    try {
      const [firstName, lastName] = name.split(' ')
      const qualificationList = qualification.map((o: SelectOption) => o.value)

      const formData = new FormData()
      formData.append('firstName', firstName)
      formData.append('lastName', lastName)
      formData.append('email', email)
      formData.append('bio', bio)

      if (photo) {
        formData.append('avatar', photo)
      }

      if (signature) {
        formData.append('signature', signature as Blob)
      }

      qualificationList.forEach(v => {
        formData.append('qualification[]', String(v))
      })
      const instructor = await postInstructor(formData)
      form.resetForm()
      toast('Successfully added', successToastOptions)
      onSubmit(instructor)
    } catch (e: any) {
      toast(e.response.data.message, errorToastOptions)
    }
  }, [])

  const onEdit = useCallback(async (values: InstructorModalForm) => {
    const {
      id,
      name,
      qualification,
      signature,
      bio,
      email,
      photo: avatar,
    } = values
    try {
      const [firstName, lastName] = name.split(' ')
      const qualificationList = qualification.map((o: SelectOption) => o.value)

      const formData = new FormData()
      formData.append('firstName', firstName)
      formData.append('lastName', lastName)
      formData.append('email', email)
      formData.append('bio', bio)

      if (avatar && typeof avatar === 'object') {
        formData.append('avatar', avatar)
      }
      if (signature && typeof signature === 'object') {
        formData.append('signature', signature as Blob)
      }
      qualificationList.forEach(v => {
        formData.append('qualification[]', String(v))
      })

      const instructor = await putInstructor(id, formData)
      form.resetForm()
      onSubmit(instructor)
    } catch (e: any) {
      toast(e.response.data.message, errorToastOptions)
    }
  }, [])

  const _onSubmit = async (values: InstructorModalForm) => {
    if (values.id > 0) {
      await onEdit(values)
    } else {
      await onCreate(values)
    }
  }

  const form = useFormik<InstructorModalForm>({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: -1,
      name: '',
      email: '',
      bio: '',
      photo: undefined,
      signature: undefined,
      qualification: [],
    },
    validationSchema: Yup.object({
      photo: Yup.mixed()
        .nullable()
        .test('is-valid-size', 'Max allowed size is 3mb', value =>
          value instanceof File ? value.size <= 3000000 : true,
        ),
      name: Yup.string()
        .required('Please enter your name')
        .matches(
          /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/,
          'First Name and Last Name is required',
        ),
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      bio: Yup.string()
        .max(2000, 'Can be only up to 2000 characters')
        .required('Bio is required'),
      signature: Yup.mixed()
        .nullable()
        .required('Signature is required')
        .test(
          'valid-size',
          'Max allowed size is 1mb',
          value => value?.size <= 1000000,
        ),
      qualification: Yup.array().min(1, 'At least one credential is required'),
    }),
    onSubmit: _onSubmit,
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  const handleKeyDown: KeyboardEventHandler = useCallback(
    event => {
      if (!inputValue) {
        return
      }
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          form.setFieldValue('qualification', [
            ...form.values.qualification,
            createOption(inputValue),
          ])
          setInputValue('')
          event.preventDefault()
      }
    },
    [form.values],
  )

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <section className='m-auto'>
              <AvatarUploader
                file={form.values.photo}
                onChange={(f: File) => {
                  form.setFieldValue('photo', f)
                }}
              />
              {form.errors.photo ? (
                <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                  {(form.errors.photo as string) || ''}
                </p>
              ) : null}
            </section>
          </div>

          <div className='vstack gap-3'>
            <div>
              <label htmlFor='name' className='form-label'>
                Name
              </label>
              <Input
                name='name'
                className='form-control'
                id='name'
                placeholder='Enter name'
                type='text'
                onChange={form.handleChange}
                disabled={form.isSubmitting}
                onBlur={form.handleBlur}
                value={form.values.name || ''}
                invalid={!!(form.touched.name && form.errors.name)}
              />
              {form.touched.name && form.errors.name ? (
                <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
              ) : null}
            </div>

            <div>
              <label htmlFor='email' className='form-label'>
                Email
              </label>
              <Input
                name='email'
                className='form-control'
                id='name'
                placeholder='Enter email'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                disabled={form.isSubmitting}
                value={form.values.email || ''}
                invalid={!!(form.touched.email && form.errors.email)}
              />
              {form.touched.email && form.errors.email ? (
                <FormFeedback type='invalid'>
                  {form.errors.email as string}
                </FormFeedback>
              ) : null}
            </div>

            <div>
              <label htmlFor='bio' className='form-label'>
                Bio
              </label>
              <Input
                name='bio'
                className='form-control'
                id='bio'
                placeholder=''
                type='textarea'
                rows='6'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                disabled={form.isSubmitting}
                value={form.values.bio || ''}
                invalid={!!(form.touched.bio && form.errors.bio)}
              />
              {form.touched.bio && form.errors.bio ? (
                <FormFeedback type='invalid'>
                  {form.errors.bio as string}
                </FormFeedback>
              ) : null}
            </div>

            <div>
              <label htmlFor='bio' className='form-label'>
                Credentials
              </label>
              <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isDisabled={form.isSubmitting}
                isMulti
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    borderColor:
                      form.touched.qualification && form.errors.qualification
                        ? '#F06548'
                        : '#ced4da',
                  }),
                }}
                onBlur={() => {
                  form.setFieldTouched('qualification')
                }}
                isSearchable
                options={options}
                onChange={newValue =>
                  form.setFieldValue('qualification', newValue)
                }
                className='select2-container is-invalid'
                classNamePrefix='select2-selection form-select'
                onInputChange={newValue => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder='Add Credentials'
                value={form.values.qualification}
              />
              {form.touched.qualification && form.errors.qualification ? (
                <FormFeedback type='invalid'>
                  {form.errors.qualification as string}
                </FormFeedback>
              ) : null}
            </div>
            <SignatureUpload
              error={form.errors.signature}
              signature={form.values.signature}
              isSubmitting={form.isSubmitting}
              onDrop={acceptedFiles => {
                handleAcceptedFile(
                  acceptedFiles,
                  'signature',
                  form.setFieldValue,
                )
              }}
              onDelete={() => {
                form.setFieldValue('signature', null)
              }}
            ></SignatureUpload>
          </div>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={beforeClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={form.isSubmitting || !form.dirty}
            >
              {form.isSubmitting ? <Spinner size={'sm'} /> : 'Submit'}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default InstructorModal
