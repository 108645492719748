import { Dispatch, SetStateAction } from 'react'
import { ScheduledCourseInitialValues } from '../../sharedTypes'

export enum NewUsersStatuses {
  NOT_REGISTERED = 'notRegistered',
  NOT_STARTED_ONBOARDING = 'notStartedOnboarding',
  ONBOARDING_IN_PROGRESS = 'onboardingInProgress',
  ONBOARDING_COMPLETE = 'onboardingComplete',
}

export enum Tabs {
  USERS_NOT_REGISTERED = 'Users Not Registered',
  IMPORT_ERRORS = 'Import Errors',
  FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION = 'Users > 4 weeks Overdue on Annual Education',
}

export interface ITotalCounts {
  [Tabs.USERS_NOT_REGISTERED]: number
  [Tabs.IMPORT_ERRORS]: number
  [Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION]: number
}

export interface TabProps {
  setTotalCounts: Dispatch<SetStateAction<ITotalCounts>>
}
