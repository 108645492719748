//AUTH
export const POST_LOGIN = '/admin/auth/login'
export const SWITCH_PORTAL = '/admin/auth/switch'
export const POST_FORGOT_PASSWORD = '/admin/auth/forgot-password'
export const POST_RESET_PASSWORD = '/admin/auth/reset-password'
export const POST_SEND_CODE = '/admin/auth/register-verification'
export const POST_VERIFY_REGISTRATION = '/admin/auth/verify/register'

//ROLE&PERMISSIONS
export const GET_ROLES = '/roles'
export const POST_ROLES = GET_ROLES
export const DELETE_ROLE = GET_ROLES
export const PATCH_ROLE = GET_ROLES
export const POST_ROLE_ORDER = '/roles/order'
export const ASSIGN_ROLE = '/roles/assign'

//SUPPORT
export const GET_SUPPORT_CATEGORIES = '/resource/category'
export const GET_SUPPORT_CATEGORY = '/resource/category'
export const POST_SUPPORT_CATEGORY = '/resource/category'
export const PUT_SUPPORT_CATEGORY = '/resource/category'

export const DELETE_SUPPORT_CATEGORY = '/resource/category'

export const GET_TUTORIALS = '/resource/tutorials'
export const POST_TUTORIALS = '/resource/tutorials'
export const PUT_TUTORIALS = '/resource/tutorials'
export const DELETE_TUTORIALS = '/resource/tutorials'

//USERS
export const GET_USERS = '/users'
export const SEARCH_USERS = '/users/search'
export const PUT_CHANGE_PASSWORD = '/users/password'
export const USER_REPORT = GET_USERS + '/report'
export const USER_COURSE_STATUS_ANALYTICS =
  GET_USERS + '/course-status/analytics'
export const USER_STATUS_ANALYTICS = GET_USERS + '/status/analytics'
export const ANALYTICS_FOR_LAST_30_DAYS = GET_USERS + '/recent/analytics'
export const UPDATE_USER_CREDENTIAL = GET_USERS + '/update-credential'
export const DELETE_USER_CREDENTIAL = GET_USERS + '/delete-credential'
//ADMINS
export const GET_ADMINS = '/admins'
export const UPDATE_ME = '/admins/me'
export const PATCH_ADMIN = '/admins'
export const MY_NOTIFICATION_SETTINGS = '/admins/notification-settings'

// INTERCOM
export const POST_EDIT_REQUEST = '/admin/intercom/edit-request'
export const GET_EDIT_REQUEST = '/admin/intercom/edit-request'

//DOCUMENTS
export const GET_DOCUMENTS = '/users/documents'
export const POST_DOCUMENT = '/users/documents'
export const DELETE_DOCUMENT = '/users/documents'

//SCHEDULED COURSES
export const SCHEDULED_COURSES = '/scheduled-courses'
export const GET_SCHEDULED_CATEGORIES = SCHEDULED_COURSES + '/categories'
export const GET_SCHEDULED_CATEGORIES_USED = GET_SCHEDULED_CATEGORIES + '/used'
export const POST_SCHEDULED_CATEGORY = GET_SCHEDULED_CATEGORIES
export const PUT_SCHEDULED_CATEGORY = GET_SCHEDULED_CATEGORIES
export const DELETE_SCHEDULED_CATEGORY = GET_SCHEDULED_CATEGORIES
export const GET_SCHEDULED_INSTRUCTORS = SCHEDULED_COURSES + '/instructors'

//ONLINE COURSES CATEGORIES
export const ONLINE_COURSE_CATEGORIES = '/courses/categories'
export const POST_ONLINE_COURSE_CATEGORIES = '/courses/categories'

//ONLINE COURSES
export const GET_ONLINE_COURSES = '/courses'
export const GET_ONLINE_COURSE = '/courses/general'
export const GET_SCHEDULED_COURSES = '/courses/scheduled'
export const GET_SCHEDULED_EVENTS = GET_SCHEDULED_COURSES + '/events'
export const PATCH_SCHEDULED_COURSES = GET_SCHEDULED_COURSES
export const DELETE_SCHEDULED_COURSES = GET_SCHEDULED_COURSES
export const DELETE_ONLINE_COURSE = '/courses'
export const CLONE_ONLINE_COURSE = '/courses/clone'
export const CREATE_COURSE_REVISION = '/courses/revision'

export const POST_GENERAL_DRAFT_ONLINE_COURSE = '/courses/general/draft'
export const POST_GENERAL_ONLINE_COURSE = '/courses/general'
export const POST_DIGITAL_COURSE_FILE = '/courses/digital/file'
export const PUT_DIGITAL_COURSE = '/courses/digital'
export const PUT_COMPETENCY_GENERAL_COURSE = '/courses/competency/general'

export const POST_COURSE_DEFAULT = '/courses/default'
export const POST_COURSE_FORMAT = '/courses/format'
export const DELETE_COURSE_FORMAT = '/courses/format'
export const PUBLISH_COURSE = '/courses/publish'

export const GET_COURSE_REPORT = GET_ONLINE_COURSES + '/report'
export const GET_COURSE_FEEDBACK = '/courses/feedback'
export const GET_LIVE_COURSE = '/courses/live'

export const ADD_LIVE_COURSE_MEDIA = '/courses/live/media'
export const DELETE_LIVE_COURSE_MEDIA = '/courses/live/media'
export const GET_COURSE_QUESTIONS = '/questions'
export const POST_COURSE_QUESTION = '/questions'
export const POST_COURSE_QUESTIONS_ORDER = '/questions/order'
export const DELETE_COURSE_QUESTION = '/questions'
export const PUT_COURSE_QUESTION_GROUP = (courseId: number, groupId: string) =>
  `/questions/${courseId}/group/${groupId}`
export const GET_COURSE_QUESTION_GROUP = (courseId: number, groupId: string) =>
  `/questions/${courseId}/group/${groupId}`

export const COURSE_PACKAGES = '/packages'
export const COURSE_POSITIONS = '/courses/positions'

//ASSIGNMENTS
export const COURSE_ASSIGNMENTS = 'admin/courses/assignment'
export const COURSE_ASSIGNMENT_TEST_UPLOAD = COURSE_ASSIGNMENTS + '/upload-test'
export const COURSE_ASSIGNMENTS_VALIDATE = COURSE_ASSIGNMENTS + '/validate'
export const SCHEDULE_ASSIGNMENTS = GET_SCHEDULED_COURSES + '/users'
export const SCHEDULE_ASSIGNMENT_CHECK_IN = COURSE_ASSIGNMENTS + '/check-in'
export const SCHEDULE_ASSIGNMENT_UNDO = COURSE_ASSIGNMENTS + '/undo'
export const RELEASE_TEST = GET_SCHEDULED_COURSES + '/release-test'
export const ALL_COURSES_ASSIGNMENTS = 'admin/courses/assignment' + '/history'

export const USERS_WITH_COURSE_OVERDUE =
  COURSE_ASSIGNMENTS + '/annual-education-overdue'

//ONLINE TAGS
export const ONLINE_TAGS = '/courses/tags'
export const ONLINE_TAGS_ENTIRES = '/courses/tags/entries'
export const POST_ONLINE_TAG = '/courses/tags'
export const PUT_ONLINE_TAG = '/courses/tags'
export const DELETE_ONLINE_TAG = '/courses/tags'

// LANGUAGES
export const LANGUAGES = '/languages'
export const LANGUAGES_LIST = '/languages/list'
export const GET_TRANSLATABLE_LANGUAGES = LANGUAGES + '/translatable'
export const COURSE_TRANSLATIONS = '/raw-translations'
export const SUBMIT_COURSE_TRANSLATION = '/raw-translations/submit'

// DEFAULT SETTINGS
export const DEFAULT_COURSE_SETTINGS = '/courses/settings'

// ADMIN COURSE NOTIFICATION SETTINGS
export const ADMIN_COURSE_NOTIFICATION_SETTINGS = '/courses/notification'

// CERTIFICATE TEMPLATES
export const CERTIFICATE_TEMPLATES = '/courses/settings/templates'

//ME
export const GET_ME = '/users/me'

//INSTRUCTORS
export const GET_INSTRUCTORS = '/instructors'
export const POST_INSTRUCTORS = '/instructors'
export const PUT_INSTRUCTORS = '/instructors'
export const DELETE_INSTRUCTOR = '/instructors'
export const GET_QUALIFICATIONS = '/instructors/qualifications'

//FACILITIES
export const GET_FACILITIES = '/facilities'
export const POST_FACILITIES = GET_FACILITIES
export const PATCH_FACILITIES = GET_FACILITIES

export const POST_FACILITY_SIGNATORY = `${GET_FACILITIES}/signatory`

//DEPARTMENTS
export const GET_DEPARTMENTS = '/departments'

//AGENCIES
export const GET_AGENCIES = '/agencies'

//POSITIONS
export const GET_POSITIONS = '/positions'

//LOGS
export const GET_LOGS = '/logs'

//COMPANIES
export const GET_COMPANIES_FACILITIES = '/companies/facilities'

//GROUPS
export const POST_GROUP = '/groups'
export const PATCH_GROUP = '/groups'

//NEWS
export const COMPANY_NEWS = '/company-news'

export const PUSH_NOTIFICATIONS = '/push-notifications'
export const NOTIFICATIONS = '/notifications'
export const ADMIN_NOTIFICATIONS = NOTIFICATIONS + '/admin'

export const SURVEYS = '/surveys'

//ATTACHMENTS
export const ATTACHMENTS = '/attachments'

export const COMPANIES = '/companies'
