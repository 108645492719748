import { Card, CardBody } from 'reactstrap'
import React from 'react'
import notAdmin from '../../assets/images/not-admin.png'
import appStore from '../../assets/images/download/app-store.png'
import google from '../../assets/images/download/google.png'

const NotAdminBlock = () => {
  return (
    <Card className='mt-4'>
      <CardBody className='p-0 position-relative bg-white rounded overflow-hidden'>
        <img
          className='position-absolute z-0 inset-0 w-full h-full object-cover'
          height='120'
          src={notAdmin}
          alt='NO IMAGE'
        />
        <div className='position-relative z-10'>
          <div className='d-flex justify-content-end pt-4'>
            <div className='text-center'>
              <h5 className='fw-normal text-black'>Not an Admin?</h5>
              <p className='fw-normal text-muted'>
                Download the app to
                <br />
                find your courses
              </p>
            </div>
            <div className='d-flex flex-column gap-2 mx-4'>
              <a href='https://play.google.com/store/apps/details?id=com.mastered'>
                <img
                  className='cursor-pointer'
                  height='30'
                  src={google}
                  alt='NO IMAGE'
                />
              </a>
              <a href='https://apps.apple.com/us/app/mastered-it/id6483932643'>
                <img
                  className='cursor-pointer'
                  height='30'
                  src={appStore}
                  alt='NO IMAGE'
                />
              </a>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default NotAdminBlock
