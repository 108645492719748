import {
  AttachmentsAccessEnum,
  AttachmentsTypesEnum,
  TAttachment,
  AttachmentsMimeTypeEnum,
} from '../models'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'

export enum AttachmentsSortBy {
  ID = 'id',
  NAME = 'name',
  TYPE = 'type',
  ACCESS = 'access',
  USER = 'user',
  DATE = 'createdAt',
}

export namespace GetAttachmentsDTO {
  export interface Request extends PaginationRequest {
    sortBy?: AttachmentsSortBy
    key?: string
    type?: AttachmentsTypesEnum[]
    access?: AttachmentsAccessEnum[]
    mimetype?: AttachmentsMimeTypeEnum[]
  }
  export interface Response extends PaginatedBaseResponse {
    attachments: TAttachment[]
  }
}

export namespace UpdateAttachmentsDTO {
  export interface Request {
    name: string
  }
  export interface Response extends TAttachment {}
}
