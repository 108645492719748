import { ASSIGNMENT_STATUS, TAssignment } from '../sharedTypes'

export const getAssignmentStatus = (status: ASSIGNMENT_STATUS) => {
  if (status === ASSIGNMENT_STATUS.ASSIGNED) {
    return 'Not Started'
  }

  return status
}

export const getTestAttempts = (assignment: TAssignment) => {
  if (!assignment.totalCompetencyTestTries) {
    return '-'
  }

  return `${assignment.totalCompetencyTestTries}/${assignment.competencyTestMaxTries}`
}
