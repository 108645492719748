export enum OrderType {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface PaginationRequest {
  limit?: number
  page?: number
  orderBy?: OrderType
  key?: string | null
}

export interface PaginatedBaseResponse {
  count: number
  page: number
  pages: number
}
