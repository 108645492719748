import React, { useCallback, useEffect, useState } from 'react'
import { OnlineCourseItem } from '../../sharedTypes'
import _ from 'lodash'
import { FormikProvider } from 'formik'
import { ViewResultReportEnum } from '../../sharedTypes'
import {
  Input,
  Col,
  FormFeedback,
  Row,
  Label,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import { IUseCompetencyStep } from '../../hooks/course/useCompetencyStep'

interface IGeneralCompetencyInfo extends IUseCompetencyStep {
  course: OnlineCourseItem | null
  viewOnly?: boolean
  defaultShow?: boolean
  questionsAmount: number
}

const GeneralCompetencyInfo = ({
  selectedLanguage,
  setSelectedLanguage,
  form,
  course,
  viewOnly,
  defaultShow,
  questionsAmount,
}: IGeneralCompetencyInfo) => {
  const [show, setShow] = useState(!!defaultShow)

  const translationError = useCallback(
    (index: number, field: string) => {
      const error = _.get(form, `errors.messages[${index}].${field}`)
      const touched = _.get(form, `touched.messages[${index}].${field}`)

      return error && touched ? (
        <FormFeedback type='invalid'>{error}</FormFeedback>
      ) : null
    },
    [form],
  )

  const translationTabError = useCallback(
    (languageId: number) => {
      const index = form.values.messages.findIndex(
        t => t.languageId === languageId,
      )
      return (
        translationError(index, 'success') ||
        translationError(index, 'fail') ||
        translationError(index, 'retake')
      )
    },
    [form],
  )

  const listenAddQuestionBtn = useCallback(() => {
    const button: HTMLElement = document.getElementById(
      'add-new-question',
    ) as HTMLElement
    button.addEventListener('click', () => {
      setShow(true)
    })
  }, [questionsAmount])

  useEffect(() => {
    listenAddQuestionBtn()
  }, [questionsAmount])

  return (
    <div className='w-100'>
      <Row>
        <Col sm='12'>
          <p
            className='p-3 badge-soft-grey d-flex justify-content-between'
            style={{ background: '#F8F0EB' }}
          >
            <span>General Info</span>
            <i
              className={`cursor-pointer  bx bx-chevron-${
                show ? 'up' : 'down'
              }`}
              onClick={() => {
                setShow(p => !p)
              }}
            ></i>
          </p>
        </Col>
      </Row>
      {show && (
        <div>
          <Row className='mb-4'>
            <Col sm='3'>
              <div>
                <label htmlFor='bio' className='form-label'>
                  Randomize Questions
                </label>
                <div className='form-check mb-2'>
                  <Input
                    className='form-check-input'
                    type='radio'
                    disabled={viewOnly}
                    name='randomizeQuestions'
                    checked={form.values.randomizeQuestions}
                    onChange={() => {
                      form.setFieldValue('randomizeQuestions', true)
                    }}
                    id='flexRadioDefault1'
                  />
                  <Label
                    className='form-check-label'
                    htmlFor='flexRadioDefault1'
                  >
                    Yes
                  </Label>
                </div>
                <div className='form-check'>
                  <Input
                    disabled={viewOnly}
                    className='form-check-input'
                    type='radio'
                    name='randomizeQuestions'
                    checked={!form.values.randomizeQuestions}
                    onChange={() => {
                      form.setFieldValue('randomizeQuestions', false)
                    }}
                    defaultChecked
                  />
                  <Label
                    className='form-check-label'
                    htmlFor='flexRadioDefault2'
                  >
                    No
                  </Label>
                </div>
              </div>
            </Col>

            <Col sm='4'>
              <div className='mb-3'>
                <label htmlFor='title' className='form-label'>
                  Number of Test Questions to Display
                </label>
                <Input
                  disabled={viewOnly}
                  className='form-control'
                  id='title'
                  min='1'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  name='quizQuestionsNumber'
                  value={form.values.quizQuestionsNumber}
                  placeholder=''
                  type='number'
                  invalid={
                    !!(
                      form.touched.quizQuestionsNumber &&
                      form.errors.quizQuestionsNumber
                    )
                  }
                />
                {form.touched.quizQuestionsNumber &&
                form.errors.quizQuestionsNumber ? (
                  <FormFeedback type='invalid'>
                    {form.errors.quizQuestionsNumber}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm='4'>
              <div>
                <label htmlFor='quizAttempts' className='form-label'>
                  Test attempts before having to restart the course
                </label>
                <Input
                  className='form-control'
                  id='quizAttempts'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  name='quizAttempts'
                  placeholder=''
                  type='number'
                  value={form.values.quizAttempts}
                  invalid={
                    !!(form.touched.quizAttempts && form.errors.quizAttempts)
                  }
                />
                {form.touched.quizAttempts && form.errors.quizAttempts ? (
                  <FormFeedback type='invalid'>
                    {form.errors.quizAttempts}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col sm='5'>
              <div>
                <label htmlFor='bio' className='form-label'>
                  Allow Learner to View Result Report
                </label>
                <div className='form-check mb-3'>
                  <Input
                    className='form-check-input'
                    type='radio'
                    disabled={viewOnly}
                    name='viewResultReport'
                    checked={
                      form.values.viewResultReport === ViewResultReportEnum.NO
                    }
                    onChange={() => {
                      form.setFieldValue(
                        'viewResultReport',
                        ViewResultReportEnum.NO,
                      )
                    }}
                    id='flexRadioDefault1'
                  />
                  <Label
                    className='form-check-label'
                    htmlFor='flexRadioDefault1'
                  >
                    No
                  </Label>
                </div>
                <div className='form-check mb-3'>
                  <Input
                    className='form-check-input'
                    type='radio'
                    name='viewResultReport'
                    disabled={viewOnly}
                    checked={
                      form.values.viewResultReport ===
                      ViewResultReportEnum.ALWAYS
                    }
                    onChange={() => {
                      form.setFieldValue(
                        'viewResultReport',
                        ViewResultReportEnum.ALWAYS,
                      )
                    }}
                    defaultChecked
                  />
                  <Label
                    className='form-check-label'
                    htmlFor='flexRadioDefault2'
                  >
                    Always display correct answers
                  </Label>
                </div>
                <div className='form-check mb-3'>
                  <Input
                    className='form-check-input'
                    type='radio'
                    disabled={viewOnly}
                    name='viewResultReport'
                    checked={
                      form.values.viewResultReport ===
                      ViewResultReportEnum.NEVER
                    }
                    onChange={() => {
                      form.setFieldValue(
                        'viewResultReport',
                        ViewResultReportEnum.NEVER,
                      )
                    }}
                    defaultChecked
                  />
                  <Label
                    className='form-check-label'
                    htmlFor='flexRadioDefault2'
                  >
                    Never display correct answers
                  </Label>
                </div>
                <div className='form-check mb-3'>
                  <Input
                    className='form-check-input'
                    type='radio'
                    disabled={viewOnly}
                    name='viewResultReport'
                    checked={
                      form.values.viewResultReport ===
                      ViewResultReportEnum.AFTER_PASSING
                    }
                    onChange={() => {
                      form.setFieldValue(
                        'viewResultReport',
                        ViewResultReportEnum.AFTER_PASSING,
                      )
                    }}
                    defaultChecked
                  />
                  <Label
                    className='form-check-label'
                    htmlFor='flexRadioDefault2'
                  >
                    Only display correct answers after passing
                  </Label>
                </div>
              </div>
            </Col> */}
          </Row>
          <Row>
            <Col sm='12'>
              <div className='flex-shrink-0 my-2'>
                <Nav
                  tabs
                  className='nav justify-content-start mb-2 nav-tabs-custom rounded card-header-tabs border-bottom-0'
                >
                  {course?.competencyTest.messages.length !== 1 &&
                    course?.competencyTest.messages.map(l => (
                      <NavItem key={l.languageId}>
                        <NavLink
                          style={{
                            cursor: 'pointer',
                            color: translationTabError(l.languageId)
                              ? '#F25959'
                              : '#0039C7',
                          }}
                          className={classnames({
                            active: selectedLanguage === l.languageId,
                          })}
                          onClick={() => {
                            setSelectedLanguage(l.languageId)
                          }}
                        >
                          {l.language.name}
                        </NavLink>
                      </NavItem>
                    ))}
                </Nav>
              </div>
            </Col>
          </Row>

          <FormikProvider value={form}>
            <>
              {form.values.messages.map((t, index: number) => (
                <Col
                  style={{
                    display:
                      selectedLanguage === t.languageId ? 'flex' : 'none',
                  }}
                  key={index}
                  sm='12'
                >
                  <Row className='w-100 pt-3'>
                    <Col sm='4'>
                      <div>
                        <label htmlFor='title' className='form-label'>
                          Test Success Message*
                        </label>
                        <Input
                          className='form-control'
                          id='title'
                          disabled={viewOnly}
                          name={`messages[${index}]['success']`}
                          placeholder='Enter message'
                          type='textarea'
                          rows='3'
                          value={
                            (form.values['messages'][index] as any).success
                          }
                          invalid={!!translationError(index, 'success')}
                          onChange={e => {
                            form.setFieldValue(
                              `messages[${index}]['success']`,
                              e.target.value,
                            )
                          }}
                          onBlur={() =>
                            form.setFieldTouched(
                              `messages[${index}]['success']`,
                            )
                          }
                        />
                        {translationError(index, 'success')}
                      </div>
                    </Col>

                    <Col sm='4'>
                      <div>
                        <label htmlFor='title' className='form-label'>
                          Test Fail Message*
                        </label>
                        <Input
                          className='form-control'
                          disabled={viewOnly}
                          id='title'
                          name={`messages[${index}]['fail']`}
                          placeholder='Enter message'
                          type='textarea'
                          rows='3'
                          value={(form.values['messages'][index] as any).fail}
                          invalid={!!translationError(index, 'fail')}
                          onChange={e => {
                            form.setFieldValue(
                              `messages[${index}]['fail']`,
                              e.target.value,
                            )
                          }}
                          onBlur={() =>
                            form.setFieldTouched(`messages[${index}]['fail']`)
                          }
                        />
                        {translationError(index, 'fail')}
                      </div>
                    </Col>

                    <Col sm='4'>
                      <div>
                        <label htmlFor='title' className='form-label'>
                          Test Retake Message*
                        </label>
                        <Input
                          className='form-control'
                          id='title'
                          disabled={viewOnly}
                          name={`messages[${index}]['retake']`}
                          placeholder='Enter message'
                          type='textarea'
                          rows='3'
                          value={(form.values['messages'][index] as any).retake}
                          invalid={!!translationError(index, 'retake')}
                          onChange={e => {
                            form.setFieldValue(
                              `messages[${index}]['retake']`,
                              e.target.value,
                            )
                          }}
                          onBlur={() =>
                            form.setFieldTouched(`messages[${index}]['retake']`)
                          }
                        />
                        {translationError(index, 'retake')}
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </>
          </FormikProvider>
        </div>
      )}
      <div
        style={{
          height: '1px',
          width: 'calc(100% + 30px)',
          background: 'rgb(233, 235, 236)',
          marginLeft: '-15px',
          marginTop: '20px',
        }}
      ></div>
    </div>
  )
}
export default GeneralCompetencyInfo
