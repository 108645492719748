import React from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'

interface WarningModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  title: string
  message: string
  icon?: string
}

const WarningModal = ({
  onClose,
  isOpen,
  title,
  message,
  icon = 'ri-alert-line',
}: WarningModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div className='avatar-xl mx-auto'>
            <div className='avatar-title rounded bg-soft-danger text-danger'>
              <i className={`${icon} fs-48`}></i>
            </div>
          </div>

          <h3 className='mt-4'>{title}</h3>
          <span className='text-muted fw-light fs-20'>{message}</span>
        </div>
        <div className='hstack gap-2 justify-content-center mt-4'>
          <Button color='primary' className={'w-50'} onClick={onClose}>
            OK
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default WarningModal
