import React, { Fragment, MutableRefObject, useCallback, useRef } from 'react'
import _ from 'lodash'
import { Input, Spinner, Table, UncontrolledTooltip } from 'reactstrap'
import Highlighter from 'react-highlight-words'

import {
  COURSE_FILTER_FORMATS,
  CoursesTabs,
  OnlineCourseItem,
} from '../../../sharedTypes'
import { getCourseFormatPrefix } from '../../../helpers/course'
import { CoursesTableProps } from './types'
import CourseDetailInfo from './CourseDetailInfo'
import PackageDetailInfo from './PackageDetailInfo'
import { TCoursePackage } from '../../../sharedTypes/models/coursePackage'
import { Link } from 'react-router-dom'

const Columns = [
  {
    name: 'Name',
    style: { width: '60%' },
  },
  {
    name: 'Code',
    style: { width: '25%' },
  },
  {
    name: 'More Info',
    style: { width: '5%' },
  },
]

const CoursesTable = ({
  data,
  isLoading,
  dataType,
  selected,
  setSelected,
  searchKey,
}: CoursesTableProps) => {
  const tooltipRefs = useRef<{ [key: string]: HTMLElement | null }>({})

  const renderDetailInfo = useCallback(
    (
      activeTab: CoursesTabs,
      item: OnlineCourseItem | TCoursePackage,
      index: number,
      tooltipRefs: MutableRefObject<{ [p: string]: HTMLElement | null }>,
    ) => {
      switch (activeTab) {
        case CoursesTabs.COURSES:
          return (
            <CourseDetailInfo
              course={item as OnlineCourseItem}
              index={index}
              tooltipRefs={tooltipRefs}
            />
          )
        case CoursesTabs.PACKAGES:
        case CoursesTabs.TRAINING_DAYS:
          return <PackageDetailInfo coursePackage={item as TCoursePackage} />
        default:
          return null
      }
    },
    [],
  )

  const isSelectedCourse = (courseId: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return !!selected[dataType].find(item =>
      'course' in item ? item.course.id === courseId : item.id === courseId,
    )
  }

  const isSelectedCourseFormat = (
    courseId: number,
    format: COURSE_FILTER_FORMATS,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return !!selected[dataType].find(item =>
      'format' in item
        ? item.course.id === courseId && item.format === format
        : false,
    )
  }

  const isDisabled = (courseId: number) => {
    const existsInPackages = selected[CoursesTabs.PACKAGES]?.some(pkg =>
      (pkg.courses || []).some(course => course.id === courseId),
    )

    const existsInTrainingDays = selected[CoursesTabs.TRAINING_DAYS]?.some(
      pkg => (pkg.courses || []).some(course => course.id === courseId),
    )

    return existsInPackages || existsInTrainingDays
  }

  const handleScroll = (e: React.UIEvent<HTMLTableElement>) => {
    const isAtBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight

    if (isAtBottom) {
      /* setQuery((prev: { page: number }) => ({
        ...prev,
        page: prev.page + 1,
      }))*/
    }
  }

  return (
    <Fragment>
      {!isLoading ? (
        <div
          className='choose-courses-container rounded'
          onScroll={handleScroll}
        >
          <Table className='align-middle table-nowrap mb-0 choose-courses'>
            <thead className='rounded'>
              <tr className='text-muted fs-14'>
                {Columns.map(column => (
                  <th
                    scope='col'
                    className={`fw-semibold align-middle ${dataType}-header`}
                    style={column?.style}
                    key={column.name}
                  >
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((course, i) => (
                <Fragment key={i}>
                  <tr className={`fs-14 fw-light ${dataType}-item`}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <Input
                          className='form-check-input me-2 my-0 cursor-pointer'
                          type='checkbox'
                          name={`course-${course.id}`}
                          id={`course-${course.id}`}
                          disabled={isDisabled(course.id)}
                          checked={isSelectedCourse(course.id)}
                          onChange={() => {
                            setSelected(course)
                          }}
                        />
                        {dataType !== CoursesTabs.COURSES && (
                          <i
                            className={`${
                              dataType === CoursesTabs.PACKAGES
                                ? 'ri-file-copy-line'
                                : 'ri-calendar-2-line'
                            }
                            text-muted me-1 fs-17`}
                          ></i>
                        )}
                        <label
                          className='fw-light mb-0 py-0 cursor-pointer'
                          htmlFor={`course-${course.id}`}
                        >
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={[searchKey || '']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={
                              dataType === CoursesTabs.COURSES
                                ? _.get(
                                    course,
                                    'translations[0].content.name',
                                    '-',
                                  )
                                : _.get(course, 'name', '-')
                            }
                          />
                        </label>
                      </div>
                    </td>
                    <td className={`text-muted ${dataType}-code`}>
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[searchKey || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={course.code}
                      />
                    </td>
                    <td className='text-center'>
                      <i
                        className='ri-information-line cursor-pointer fs-16 text-muted'
                        id={`course-info-${course.id}`}
                        ref={ref =>
                          (tooltipRefs.current[`course-${course.id}`] = ref)
                        }
                      ></i>
                      <UncontrolledTooltip
                        className='course-details--tooltip'
                        placement='top'
                        target={`course-info-${course.id}`}
                        trigger='click'
                      >
                        {renderDetailInfo(dataType, course, i, tooltipRefs)}
                      </UncontrolledTooltip>

                      {dataType !== CoursesTabs.COURSES && (
                        <>
                          <Link
                            to={`/courses/packages/manage/${course.id}`}
                            target='_blank'
                          >
                            <i
                              className='ri-eye-line cursor-pointer fs-16 text-muted ms-2'
                              id={`package-info-${course.id}`}
                            ></i>
                          </Link>

                          <UncontrolledTooltip
                            placement='top'
                            target={`package-info-${course.id}`}
                            trigger='click'
                          >
                            View
                          </UncontrolledTooltip>
                        </>
                      )}
                    </td>
                  </tr>
                  {'available_formats' in course
                    ? course.available_formats.map((format, index) => (
                        <tr
                          className={`fs-14 fw-light course-format-item ${
                            _.last(course.available_formats) === format &&
                            'last-format'
                          }`}
                          key={index}
                        >
                          <td className='ps-4 py-0'>
                            <Input
                              className='form-check-input me-2 cursor-pointer'
                              type='radio'
                              name={`course-${course.id}-format`}
                              id={`course-${course.id}-format-${index}`}
                              checked={isSelectedCourseFormat(
                                course.id,
                                format,
                              )}
                              disabled={isDisabled(course.id)}
                              onChange={() => setSelected(course, format)}
                            />
                            <label
                              className='fw-light mb-0 py-0 cursor-pointer'
                              htmlFor={`course-${course.id}-format-${index}`}
                            >
                              {format}
                            </label>
                          </td>
                          <td className='text-muted'>
                            {getCourseFormatPrefix(format)}-{course.code}
                          </td>
                          <td></td>
                        </tr>
                      ))
                    : null}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className='my-5 text-center'>
          <Spinner />
        </div>
      )}
    </Fragment>
  )
}

export default CoursesTable
