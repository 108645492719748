import React, { useMemo } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { USER_STATUSES } from '../../../sharedTypes'
import DetailsInfoModal from '../../../Components/Modals/DetailsInfoModal'
import { v4 as uuidv4 } from 'uuid'

interface FilterTabsProps {
  navTab?: USER_STATUSES
  navToggle: (tab?: USER_STATUSES) => void
}
const tabs = [
  { label: 'All', value: undefined },
  {
    label: 'Active',
    value: USER_STATUSES.ACTIVE,
    info: 'User is an active employee. ',
  },
  {
    label: 'Inactive',
    value: USER_STATUSES.INACTIVE,
    info: ' User is no longer active at this facility.',
  },
]

const FilterTabs = ({ navTab, navToggle }: FilterTabsProps) => {
  const memoizedTabs = useMemo(() => {
    return tabs.map(item => ({
      ...item,
      id: uuidv4(),
    }))
  }, [tabs])
  return (
    <Nav className='nav-customs-bordered'>
      {memoizedTabs.map(tab => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
            <DetailsInfoModal
              info={tab.info}
              id={tab.id}
              name={'admin-break-down'}
              onHover={true}
            />
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
