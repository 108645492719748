import React from 'react'
import { Button, Col, Modal, ModalBody, ModalProps, Row } from 'reactstrap'
import { TAttachment } from '../../../sharedTypes'
import { jsPDF } from 'jspdf'
import _ from 'lodash'

interface UploadWrittenTestProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  testFile: TAttachment
}

const TestPreview = ({ onClose, isOpen, testFile }: UploadWrittenTestProps) => {
  const isPDF = (filename: string) => {
    const pdfExtensions = ['pdf']
    const extension = filename.split('.').pop()?.toLowerCase()
    return extension ? _.endsWith(extension, pdfExtensions[0]) : false
  }

  const openAndPrint = (pdfUrl: string) => {
    const pdfWindow = window.open(pdfUrl, '_blank')
    if (pdfWindow) {
      pdfWindow.onload = () => {
        pdfWindow.print()
      }
    }
  }

  const generatePDF = async () => {
    if (isPDF(testFile.name)) {
      openAndPrint(testFile.path)
    } else {
      const img = new Image()
      img.src = testFile.path

      img.onload = () => {
        const pdf = new jsPDF()
        const imgWidth = pdf.internal.pageSize.getWidth()
        const imgHeight = (img.height * imgWidth) / img.width
        pdf.addImage(testFile.path, 'PNG', 0, 0, imgWidth, imgHeight)

        const pdfBlob = new Blob([pdf.output('blob')], {
          type: 'application/pdf',
        })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        openAndPrint(pdfUrl)
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Written Test Preview</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>

        <Row>
          <Col className='w-50 vstack'>
            {isPDF(testFile.name) ? (
              <iframe
                className='w-100'
                style={{ aspectRatio: 0.8 }}
                src={
                  'https://docs.google.com/gview?embedded=true&url=' +
                  testFile.path
                }
              ></iframe>
            ) : (
              <img
                id='written-test'
                className='card-img-top img-fluid'
                src={testFile.path}
                alt={testFile.name}
              />
            )}
          </Col>
        </Row>
        <div className='hstack gap-2 justify-content-end mt-4 px-4'>
          <Button className='btn-soft-primary' onClick={onClose}>
            Cancel
          </Button>
          <Button color='primary' onClick={generatePDF}>
            <i className='ri-printer-line me-2 fs-16'></i>
            Print
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default TestPreview
