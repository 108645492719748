import { API } from '../api_helper'
import { COURSE_TRANSLATIONS, SUBMIT_COURSE_TRANSLATION } from '../url_helper'
import { TCourseRawTranslation } from '../../sharedTypes'
import {
  SubmitTranslationDto,
  UpdateTranslationDto,
} from '../../sharedTypes/api/rawTranslations'

export const getTranslationsByLanguage = (
  courseId: number,
  languageId: number,
) => {
  return API.get<TCourseRawTranslation>(
    `${COURSE_TRANSLATIONS}/${courseId}/${languageId}`,
  ).then(d => d.data)
}

export const updateTranslation = (
  id: number,
  data: UpdateTranslationDto.Request,
) => {
  return API.patch<TCourseRawTranslation>(COURSE_TRANSLATIONS + `/${id}`, data)
}

export const submitTranslation = (
  id: number,
  data: SubmitTranslationDto.Request,
) => {
  return API.post<TCourseRawTranslation>(
    SUBMIT_COURSE_TRANSLATION + `/${id}`,
    data,
  )
}
