import {
  ONLINE_COURSE_STATUS,
  OnlineCourseFormatsEnum,
  OnlineCourseItem,
} from '../../../sharedTypes'
import React from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import defaultCourseCover from '../../../assets/images/course-default-cover.png'
import { CoursePermissions } from './types'
import _ from 'lodash'
import moment from 'moment/moment'
import {
  BadgeDarkColorByStatus,
  formattedDuration,
} from '../../../helpers/course'
import { useAppSelector } from '../../../hooks/redux'
import ShowMore from '../../../Components/Common/ShowMore'
import { Link } from 'react-router-dom'
import CourseTags from '../../../Components/Course/CourseTags'

interface CoursePanelPreviewProps {
  course: OnlineCourseItem
  permissions: CoursePermissions
  handleClone: (course: OnlineCourseItem) => void
  handleDelete: (course: OnlineCourseItem) => void
  onClose: () => void
  onAssign: () => void
  onSelect: (course: OnlineCourseItem) => void
}

const CoursePanelPreview = ({
  course,
  permissions,
  handleClone,
  handleDelete,
  onClose,
  onAssign,
  onSelect,
}: CoursePanelPreviewProps) => {
  const user = useAppSelector(state => state.User.user)

  const hasPermissionToActions = !(
    user?.companyId && !course.createdBy?.companyId
  )

  return (
    <div className='course-preview'>
      <Card>
        <div className='course-cover position-relative'>
          <img
            className='card-img-top img-fluid rounded w-100 h-100'
            src={course.cover?.path ?? defaultCourseCover}
            alt='NO IMAGE'
            style={{
              aspectRatio: 1,
              objectFit: 'cover',
            }}
          />
          <div className='position-absolute preview-close'>
            <i
              className='ri-close-line fs-24 cursor-pointer text-light-purple rounded p-1'
              onClick={onClose}
            ></i>
          </div>
        </div>
        <CardBody className='d-flex flex-column gap-2'>
          <div className='d-flex justify-content-between w-100'>
            <span className='text-muted fs-14'>{course.code}</span>
          </div>

          <div className='fs-24 text-body fw-medium'>
            {_.get(course, 'translations[0].content.name', '-')}
          </div>
          <div className='text-muted fs-16 course-description'>
            <ShowMore
              content={_.get(
                course,
                'translations[0].content.description',
                '-',
              )}
            />
          </div>
          <div className='d-flex gap-2 flex-wrap'>
            <span
              className={`badge badge-outline-info fs-12 fw-normal text-capitalize`}
            >
              {course.type}
            </span>

            <CourseTags tags={course.tags} />
          </div>

          <Row>
            {!(course.formats || []).includes(
              OnlineCourseFormatsEnum.COMPETENCY,
            ) && (
              <Col>
                <div>
                  <div className='fw-medium text-body fs-16'>Duration :</div>
                  <div className='text-muted fs-12'>
                    {formattedDuration(course.duration)}
                  </div>
                </div>
              </Col>
            )}
            <Col>
              <div>
                <div className='fw-medium text-body fs-16 form-label'>
                  Status
                </div>
                <div className='text-muted fs-12'>
                  <span
                    className={`badge badge-${
                      BadgeDarkColorByStatus[course.status]
                    } fs-12 fw-medium text-capitalize`}
                  >
                    {course.status}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <div>
            <div className='fw-medium text-body fs-16 form-label'>
              Date Published :
            </div>
            <div className='text-muted fs-16'>
              {course.publishedDate
                ? moment(course.publishedDate).format('MM/DD/YYYY')
                : '-'}
            </div>
          </div>

          {course.expirationDate && (
            <div>
              <div className='fw-medium text-body fs-16 form-label'>
                Expiration Date :
              </div>
              <div className='text-muted fs-16'>
                {moment(course.expirationDate).format('MM/DD/YYYY')}
              </div>
            </div>
          )}

          <div>
            <div className='fw-medium text-body fs-16 form-label'>
              Formats Available :
            </div>
            <div className='text-muted fs-16'>
              {course.available_formats.join(', ')}
            </div>
          </div>
          {course.positions?.length && (
            <div>
              <div className='fw-medium text-body fs-16 form-label'>
                Positions :
              </div>
              <div className='text-muted fs-16'>
                {course.positions.join(', ')}
              </div>
            </div>
          )}

          <div className='d-flex flex-shrink-0 mb-2 gap-2 flex-wrap'>
            {hasPermissionToActions && (
              <>
                {permissions.delete && (
                  <Button
                    color={'soft-danger'}
                    className='align-middle'
                    onClick={() => {
                      handleDelete(course)
                    }}
                  >
                    <i className='ri-delete-bin-2-line me-1 fs-14'></i>Delete
                  </Button>
                )}
                {permissions.addEdit && (
                  <Link
                    to={`/courses/general/${course.id}`}
                    target={'_blank'}
                    className='d-flex gap-2 text-muted'
                  >
                    <Button
                      color={'primary'}
                      className='btn btn-soft-primary align-middle'
                    >
                      <i className='ri-pencil-line me-1 fs-14'></i>Edit
                    </Button>
                  </Link>
                )}
                {permissions.clone && (
                  <Button
                    color={'primary'}
                    className='btn btn-soft-primary align-middle'
                    onClick={() => {
                      handleClone(course)
                    }}
                  >
                    <i className='bx bx-duplicate me-1 fs-14'></i>Clone
                  </Button>
                )}
              </>
            )}

            {permissions.assign &&
              ![
                ONLINE_COURSE_STATUS.DRAFT,
                ONLINE_COURSE_STATUS.INACTIVE,
              ].includes(course.status) && (
                <Button
                  color={'primary'}
                  className='btn btn-primary align-middle'
                  onClick={() => {
                    onSelect(course)
                    onAssign()
                  }}
                >
                  <i className='ri-edit-box-line me-1 fs-14'></i>Assign
                </Button>
              )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default CoursePanelPreview
