import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import Highlighter from 'react-highlight-words'
import {
  CREDENTIALS_TYPE,
  GetInstructorsDTO,
  InstructorModalForm,
  SettingsPermissions,
  TInstructor,
} from '../../../sharedTypes'
import { getInstructors, deletInstructor } from '../../../helpers/api_helper'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { OrderType, UsersSortBy } from '../../../sharedTypes'
import { Pagination } from '../../../Components/Common/Pagination'
import InstructorModal from '../../../Components/Modals/InstructorModal'
import { successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { usePermissions } from '../../../hooks/usePermissions'
const Columns = [
  {
    name: 'User',
    sortBy: UsersSortBy.USER,
    style: { width: '31%' },
  },
  {
    name: 'Bio',
    style: { width: '31%' },
  },
  {
    name: 'Credentials',
    style: { width: '31%' },
  },
  {
    name: 'Actions',
    style: { width: 70 },
  },
]

const InstructorManagement = () => {
  document.title = 'Instructor Management | Mastered - Admin & Dashboard'

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetInstructorsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    instructors: [],
  })
  const [query, setQuery] = useState<GetInstructorsDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    orderBy: OrderType.ASC,
    permission: SettingsPermissions.VIEW_INSTRUCTOR_LISTING,
  })

  const [createModal, setCreateModal] = useState<InstructorModalForm | null>(
    null,
  )
  const [editModal, setEditModal] = useState<InstructorModalForm | null>(null)

  const [deleteId, setDeleteId] = useState<null | number>()

  const permissions = {
    add: usePermissions(SettingsPermissions.ADD_INSTRUCTOR),
    edit: usePermissions(SettingsPermissions.EDIT_INSTRUCTOR),
    delete: usePermissions(SettingsPermissions.DELETE_INSTRUCTOR),
  }

  useEffect(() => {
    getInstructors(query)
      .then(response => {
        setData(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [query])

  const handleSort = useCallback(() => {
    setQuery(prev => ({
      ...prev,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onDelete = useCallback(async () => {
    try {
      await deletInstructor(deleteId as number)
      setDeleteId(null)
      toast('Successfully deleted', successToastOptions)
      setQuery(prev => ({ ...prev, page: 1 }))
    } catch (e) {}
  }, [data.page, deleteId])

  const onSubmit = useCallback(() => {
    setQuery(prev => ({ ...prev, page: 1 }))
    setCreateModal(null)
    setEditModal(null)
  }, [data.page])

  const transformToEditObject = ({
    signature,
    qualification,
    firstName,
    lastName,
    credentials,
    ...rest
  }: TInstructor): InstructorModalForm => {
    const email = credentials?.find(
      i => i.credentialType === CREDENTIALS_TYPE.EMAIL,
    )?.credential
    return {
      ...rest,
      email: email || '',
      name: `${firstName} ${lastName}`,
      signature: signature || undefined,
      qualification: qualification.map((v: string) => ({ value: v, label: v })),
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getInstructors(query)
      .then(response => {
        setData(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [query])

  const columns = useMemo(() => {
    if (!permissions.edit && !permissions.delete) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [permissions])

  const handleRowClick = (item: TInstructor) => {
    if (permissions.edit) {
      setEditModal(transformToEditObject(item))
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <DeleteConfirmation
          isOpen={!!deleteId}
          title='Delete Instructor'
          message='Are you sure you want to delete Instructor ?'
          onDelete={onDelete}
          onClose={() => {
            setDeleteId(null)
          }}
        />

        <InstructorModal
          title={editModal ? 'Edit Instructor' : 'New Instructor'}
          isOpen={!!createModal || !!editModal}
          initialValues={editModal || createModal}
          onSubmit={onSubmit}
          onClose={() => {
            setEditModal(null)
            setCreateModal(null)
          }}
        />

        <Container fluid>
          <BreadCrumb
            title='Instructor Management'
            items={[
              {
                title: 'Settings',
                linkTo: '/',
              },
              {
                title: 'Instructor Management',
                active: true,
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      value={query.key || ''}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                    />
                    {permissions.add && (
                      <div className='d-flex flex-shrink-0 gap-3'>
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setCreateModal({
                              id: -1,
                              name: '',
                              email: '',
                              bio: '',
                              photo: undefined,
                              signature: undefined,
                              qualification: [],
                            })
                          }}
                          className='btn btn-primary align-middle'
                        >
                          <i className='ri-edit-box-line me-1 fs-16'></i>Add
                          Instructor
                        </Button>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <Table className='align-middle table-nowrap mb-0'>
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {columns.map(column => (
                            <th
                              scope='col'
                              className='align-middle'
                              style={column.style}
                              key={column.name}
                            >
                              {column.name}
                              {!!column.sortBy && (
                                <ColumnSortIcon<UsersSortBy>
                                  sortOrder={query.orderBy}
                                  sortedColumn={UsersSortBy.USER}
                                  column={column.sortBy}
                                  handleSort={handleSort}
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.instructors.map((item: TInstructor, i) => {
                          const email = item.credentials?.find(
                            c => c.credentialType === CREDENTIALS_TYPE.EMAIL,
                          )?.credential
                          return (
                            <tr
                              key={i}
                              className='fs-14'
                              onClick={() => {
                                handleRowClick(item)
                              }}
                            >
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='flex-shrink-0'>
                                    {item.photo ? (
                                      <img
                                        src={item.photo}
                                        alt=''
                                        className='avatar-xs rounded-circle'
                                      />
                                    ) : (
                                      <div className='avatar-xs'>
                                        <div className='avatar-title rounded-circle bg-soft-primary text-primary'>
                                          {item.firstName[0]} {item.lastName[0]}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className='flex-grow-1 ms-2'>
                                    <p className='card-title mb-0 fs-14'>
                                      <Highlighter
                                        highlightClassName='text-highlight'
                                        searchWords={[query.key || '']}
                                        highlightTag={'span'}
                                        autoEscape={true}
                                        textToHighlight={`${item.firstName} ${item.lastName}`}
                                      />
                                    </p>
                                    <p className='text-muted mb-0 fs-12'>
                                      <Highlighter
                                        highlightClassName='text-highlight'
                                        searchWords={[query.key || '']}
                                        highlightTag={'span'}
                                        autoEscape={true}
                                        textToHighlight={`${email}`}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  className='d-inline-block text-truncate instructor-name'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={item.bio}
                                />
                              </td>
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={
                                    item.qualification?.join(',') || ''
                                  }
                                />
                              </td>
                              {(permissions.edit || permissions.delete) && (
                                <td>
                                  <span
                                    className='d-flex gap-2'
                                    onClick={e => {
                                      e.stopPropagation()
                                    }}
                                  >
                                    {permissions.edit && (
                                      <i
                                        onClick={() =>
                                          setEditModal(
                                            transformToEditObject(item),
                                          )
                                        }
                                        className='bx bx-edit-alt cursor-pointer'
                                      ></i>
                                    )}
                                    {permissions.delete && (
                                      <i
                                        onClick={() => setDeleteId(item.id)}
                                        className='bx bx-trash text-danger cursor-pointer	'
                                      ></i>
                                    )}
                                  </span>
                                </td>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    <div className='mx-3 mt-3'>
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InstructorManagement
