import { CREDENTIALS_TYPE, TUser } from '../sharedTypes'
import { setAuthorization, switchToCompanyPortal } from './api_helper'
import { setLoginUser, setMasteredToken } from '../slices/auth/user/reducer'
import { handleError } from './toast_helper'
import type { AppDispatch } from '../slices'
import { clearFacilities } from '../slices/facilities/reducer'

export const switchToPortal = async (
  admin: TUser,
  companyId: number,
  dispatch: AppDispatch,
  redirectLink?: string,
) => {
  try {
    if (admin) {
      const email =
        admin?.credentials?.find(
          i => i.credentialType === CREDENTIALS_TYPE.EMAIL,
        )?.credential || ''

      const { data } = await switchToCompanyPortal({
        login: email,
        companyId,
      })
      const { user, access_token, permissionSet } = data

      const userData = {
        user,
        accessToken: access_token,
        permissions: permissionSet,
        switchedToPortal: true,
        switched: true,
      }

      const authUser = sessionStorage.getItem('authUser')
      const token = authUser ? JSON.parse(authUser).accessToken : null
      sessionStorage.setItem('masteredToken', token)
      dispatch(setMasteredToken(token))

      setAuthorization(access_token)
      sessionStorage.setItem('authUser', JSON.stringify(userData))
      dispatch(setLoginUser(userData))

      setTimeout(() => {
        window.location.replace(redirectLink ?? '/')
      }, 100)
    }
  } catch (e) {
    handleError(e)
  }
}

export const switchBackToMasteredPortal = async (
  email: string,
  dispatch: AppDispatch,
  shouldRedirect = true,
) => {
  try {
    setAuthorization(sessionStorage.getItem('masteredToken') as string)

    const { data } = await switchToCompanyPortal({
      login: email,
      companyId: null,
    })
    const { user, access_token, permissionSet } = data

    const userData = {
      user,
      accessToken: access_token,
      permissions: permissionSet,
      switchedToPortal: true,
      switched: false,
    }
    dispatch(setLoginUser(userData))
    dispatch(clearFacilities())

    sessionStorage.removeItem('masteredToken')
    setAuthorization(access_token)

    sessionStorage.setItem('authUser', JSON.stringify(userData))

    if (shouldRedirect) {
      setTimeout(() => {
        window.location.replace('/company-management')
      }, 100)
    }
  } catch (e) {
    handleError(e)
  }
}
