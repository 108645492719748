//INSTRUCTORS
import { CreateInstructorDTO, GetInstructorsDTO } from '../../sharedTypes'
import {
  DELETE_INSTRUCTOR,
  GET_INSTRUCTORS,
  GET_QUALIFICATIONS,
  POST_INSTRUCTORS,
  PUT_INSTRUCTORS,
} from '../url_helper'
import { API } from '../api_helper'

export const getInstructors = (params: GetInstructorsDTO.Request) => {
  return API.get<GetInstructorsDTO.Response>(GET_INSTRUCTORS, { params }).then(
    d => d.data,
  )
}

export const deletInstructor = (id: number) => {
  return API.delete(`${DELETE_INSTRUCTOR}/${id}`).then(d => d.data)
}

export const postInstructor = (body: FormData) => {
  return API.post<CreateInstructorDTO.Response>(POST_INSTRUCTORS, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(d => d.data)
}

export const putInstructor = (id: number, body: FormData) => {
  return API.put<CreateInstructorDTO.Response>(
    `${PUT_INSTRUCTORS}/${id}`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}

export const getQualificationOptions = () => {
  return API.get<{ qualifications: string[] }>(GET_QUALIFICATIONS).then(
    d => d.data,
  )
}
