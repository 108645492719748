import { TLanguage } from './language'
import { AssignTo } from '../forms'
import { TUser } from './user'
import { Question } from '../api/questions'
import { TFacility } from './facility'
import { TPosition } from './position'
import { TDepartment } from './department'

export type TSurveyTranslations = {
  id: number
  languageId: number
  content: {
    name: string
    description: string
    title: string
    message: string
  }
  createdAt: string
  updatedAt: string
  language: TLanguage
}

export type TSurveyTranslationItem = {
  id?: number
  name: string
  description: string
  title: string
  message: string
  languageId: number
}

export type TSurvey = {
  id: number
  status: SurveyStatusTypes
  sendTo: AssignTo | null
  recipients: number[] | null
  recipientsCount?: number
  completedUsersCount?: number
  createdBy?: TUser
  sendDate: Date | null
  deadline: Date | null
  createdAt: string
  updatedAt: string
  translations: TSurveyTranslations[]
  questions: Question[]
  allowComments: boolean
  allowAnonymousResponses: boolean
}

export enum SurveyStatusTypes {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  SENT = 'sent',
  COMPLETED = 'completed',
}

export interface QuestionAnswer {
  answer: string
  id?: string
  order?: number
  userAnswersCount?: number
}

export type TSurveyData = {
  survey: TSurvey
  recipients: Array<TFacility | TUser | TPosition | TDepartment>
}

export interface SurveyStatisticsItem {
  question: string
  questionId: number
  responsesAmount: number
  surveyId: number
  data: {
    amount: number
    name: string
    percentage: number
  }[]
}

export interface UserSurveyAnswers {
  userid: number
  date: Date
  q1?: string
  q2?: string
  q3?: string
  q4?: string
  q5?: string
  firstName: string | null
  lastName: string | null
  position: string | null
  facility: string | null
}
