import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import { LOG_ERROR_STATUS, LOG_TYPES, TLog } from '../models'

export enum LogsSortBy {
  FACILITY_NAME = `(CASE WHEN "user->facility"."name" is not NULL THEN "user->facility"."name" ELSE "rawUser"->>'facility' END)`,
  DEPARTMENT_NAME = `(CASE WHEN "user->departments"."name" is not NULL THEN "user->departments"."name" ELSE "rawUser"->>'department' END)`,
  POSITION_NAME = `(CASE WHEN "user->position"."name" is not NULL THEN "user->position"."name" ELSE "rawUser"->>'position' END)`,
  EMPLOYEE_ID = '"ImportLogs"."employeeId"',
  TYPE = '"ImportLogs"."type"',
  IMPORT_DATE = '"ImportLogs"."createdAt"',
  NOTES = '"ImportLogs"."notes"',
  STATUS = '"ImportLogs"."status"',
}

export namespace GetLogsDTO {
  export interface Request extends PaginationRequest {
    facilityIds?: number[]
    groupIds?: number[]
    departmentIds?: number[]
    positionIds?: number[]
    sortBy?: LogsSortBy
    type?: LOG_TYPES
    searchDate?: Date[]
    key?: string
    includeAgencyEmployees?: boolean
    status?: LOG_ERROR_STATUS
  }
  export interface Response extends PaginatedBaseResponse {
    logs: TLog[]
  }
}
