import React, { ChangeEvent, useCallback, useRef } from 'react'

const AvatarUploader = ({
  file,
  onChange,
}: {
  file: string | Blob | undefined
  onChange: (file: File) => void
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const createPreview = (file: Blob) => URL.createObjectURL(file)

  const handleClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current?.click()
    }
  }, [])

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target

      const fileObj = input.files && input.files[0]
      if (!fileObj) {
        return
      }

      onChange(fileObj)
    },
    [],
  )
  return (
    <div
      className='avatar-xl rounded-circle position-relative img-thumbnail cursor-pointer'
      onClick={handleClick}
    >
      <span
        className={`position-absolute ${
          file ? 'bottom-0 end-0' : 'top-50 start-50 translate-middle'
        }`}
        style={{
          background: '#f7f8f9',
          width: 32,
          height: 32,
          borderRadius: 50,
          lineHeight: '32px',
          textAlign: 'center',
        }}
      >
        <i style={{ fontSize: file ? 15 : 28 }} className='ri-camera-fill'></i>
      </span>
      <input
        id='profile-foreground-img-file-input'
        ref={inputRef}
        onChange={handleFileChange}
        type='file'
        className='d-none'
        accept='image/*'
      />
      <section className='w-100 h-100 rounded-circle'>
        {file && (
          <img
            className='w-100 h-100 rounded-circle'
            src={typeof file === 'string' ? file : createPreview(file)}
          />
        )}
      </section>
    </div>
  )
}

export default AvatarUploader
