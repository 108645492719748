import React, { useCallback, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Form,
  Input,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { SignatureUpload } from '../../Components/Common/SignatureUpload'
import {
  TUser,
  TFacility,
  TAttachment,
  FacilityManagementPermissions,
} from '../../sharedTypes'
import handleAcceptedFile from '../../utils/handleAcceptedFile'
import { getUserDisplayName } from '../../helpers/user'
import { AsyncSelectWithSearch } from '../../Components/Common/SelectWithSearch'
import { getAdmins, postFacilitySignatory } from '../../helpers/api_helper'

interface Option {
  value: TUser
  label: string | number
}

interface SignatoryForm {
  signatoryPosition?: string | undefined | null
  signature: TAttachment | Blob | undefined
  signatory?: Option | null
}

interface SignatoryModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (facility: TFacility) => void
  title: string
  facility: TFacility
}

const SignatoryModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  facility,
}: SignatoryModalProps) => {
  const fetchAdmins = (inputValue?: string): Promise<Option[]> => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return getAdmins({
      ...params,
      facilityIds: [facility.id],
      permission: FacilityManagementPermissions.VIEW_FACILITY_MANAGEMENT,
    })
      .then(d => d.data)
      .then(({ admins }) => {
        return admins.map(admin => ({
          value: admin,
          label: getUserDisplayName(admin),
        }))
      })
      .catch(() => [])
  }

  const form = useFormik<SignatoryForm>({
    enableReinitialize: true,
    initialValues: {
      signatory: facility?.signatory
        ? {
            value: facility.signatory,
            label: getUserDisplayName(facility.signatory),
          }
        : null,
      signatoryPosition:
        facility.signatoryPosition || facility?.signatory?.rawPosition,
      signature: (facility?.signatory as any)?.signature,
    },
    validationSchema: Yup.object({
      signatory: Yup.object().required('required'),
      signatoryPosition: Yup.string().required('required'),
      signature: Yup.mixed()
        .nullable()
        .required('Signature is required')
        .test(
          'valid-size',
          'Max allowed size is 1mb',
          value => value?.size <= 1000000,
        ),
    }),
    onSubmit: async values => {
      try {
        const body = {
          signatoryId: values?.signatory?.value.id,
          facilityId: facility.id,
          signature: values.signature,
          signatoryPosition: values.signatoryPosition,
        }
        onSubmit(await postFacilitySignatory(body as any))
      } catch (e) {}
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  useEffect(() => {
    if (form.values?.signatory) {
      form.setFieldValue(
        'signatoryPosition',
        facility.signatoryPosition || facility?.signatory?.rawPosition,
      )
      form.setFieldValue('signature', form.values?.signatory?.value?.signature)
    }
  }, [form.values.signatory])

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>
        <Form
          className='vstack gap-3'
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div>
            <label htmlFor='email' className='form-label'>
              Facility
            </label>
            <Input
              name='facility'
              className='form-control'
              id='facility'
              type='text'
              disabled
              value={facility.name}
            />
          </div>

          <div>
            <label className='form-label'>Signatory</label>
            <div className='d-flex'>
              <div className='flex-grow-1'>
                <AsyncSelectWithSearch
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      borderRadius: '4px 0px 0px 4px',
                      minHeight: 39,
                      borderColor:
                        form.touched.signatory && form.errors.signatory
                          ? '#f06548 !important'
                          : undefined,
                    }),
                  }}
                  placeholder='Select signatory'
                  className='select2-container is-invalid'
                  name='signatory'
                  id='signatory'
                  onChange={option => form.setFieldValue('signatory', option)}
                  onBlur={() => {
                    form.setFieldTouched('signatory', true)
                  }}
                  value={form.values.signatory}
                  isMulti={false}
                  isClearable={true}
                  isSearchable={true}
                  defaultOptions
                  loadOptions={fetchAdmins}
                  error={undefined}
                />
              </div>
              {form.touched.signatory && form.errors.signatory ? (
                <FormFeedback type='invalid'>
                  {form.errors.signatory}
                </FormFeedback>
              ) : null}
            </div>
          </div>

          <div>
            <label htmlFor='signatoryPosition' className='form-label'>
              Position
            </label>
            <Input
              name='signatoryPosition'
              className='form-control'
              id='signatoryPosition'
              type='text'
              value={form.values?.signatoryPosition || ''}
              invalid={
                !!(
                  form.touched.signatoryPosition &&
                  form.errors.signatoryPosition
                )
              }
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {form.touched.signatoryPosition && form.errors.signatoryPosition ? (
              <FormFeedback type='invalid'>
                {form.errors.signatoryPosition}
              </FormFeedback>
            ) : null}
          </div>
          <SignatureUpload
            error={form.errors.signature as any}
            signature={form.values.signature}
            isSubmitting={form.isSubmitting}
            onDrop={acceptedFiles => {
              handleAcceptedFile(acceptedFiles, 'signature', form.setFieldValue)
            }}
            onDelete={() => {
              form.setFieldValue('signature', null)
            }}
          ></SignatureUpload>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={beforeClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={form.isSubmitting || !form.dirty || !form.isValid}
            >
              {form.isSubmitting ? <Spinner size={'sm'} /> : 'Submit'}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default SignatoryModal
