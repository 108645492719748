import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { logoutUser } from '../../slices/thunks'

import { useAppDispatch } from '../../hooks/redux'
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import ParticlesAuthHeader from '../../Components/Common/ParticlesAuthHeader'
import { LordIcon } from '../../Components/Common/lord-icon'

const Logout = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(logoutUser())
  }, [])

  document.title = 'Log Out | Mastered - Premium Admin & Dashboard Platform'
  return (
    <React.Fragment>
      <div className='auth-page-content'>
        <div className='auth-page-wrapper'>
          <ParticlesAuth>
            <div className='auth-page-content'>
              <Container>
                <ParticlesAuthHeader />
                <Row className='justify-content-center'>
                  <Col md={8} lg={6} xl={5}>
                    <Card className='mt-4'>
                      <CardBody className='p-4 text-center'>
                        <LordIcon
                          src='https://cdn.lordicon.com/hzomhqxz.json'
                          colors={{ primary: '#249fe1', secondary: '#02bc9d' }}
                          style={{ width: '180px', height: '180px' }}
                        ></LordIcon>

                        <div className='mt-2'>
                          <h4>You are Logged Out</h4>
                          <div className='mt-4'>
                            <Link to='/login' className='btn btn-primary w-100'>
                              Log In
                            </Link>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </ParticlesAuth>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Logout
