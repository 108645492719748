import React, { Fragment, useMemo } from 'react'
import masteredLogo from '../../../assets/images/logo.png'
import { Table } from 'reactstrap'
import { COMPANY_STATUSES, TCompany } from '../../../sharedTypes'
import moment from 'moment'
import _ from 'lodash'

export interface IColumn {
  name: string
  style?: React.HTMLProps<HTMLTableColElement>['style']
}

const Columns: IColumn[] = [
  {
    name: 'Company ID',
    style: { width: 80 },
  },
  {
    name: 'Company Name',
    style: { width: 220 },
  },
  {
    name: 'Status (Active/Inactive)',
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    name: 'Activation Date',
    style: { width: 85 },
  },
  {
    name: 'Number of \n Active Facilities',
    style: { width: 120 },
  },
  {
    name: 'Number of \n Active Users',
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    name: 'Number of \n Registered Users',
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    name: 'Number of \n UnRegistered Users',
    style: { width: 130, whiteSpace: 'pre-line' },
  },
]

interface CompanyReportSelectedFilters {
  activationDate?: Date[]
  status?: COMPANY_STATUSES[]
}

interface ExportCompanyReportProps {
  companyReport: TCompany[]
  page: number
  selectedFilters: CompanyReportSelectedFilters
}

const ExportCompanyReportPdf = ({
  companyReport,
  page,
  selectedFilters,
}: ExportCompanyReportProps) => {
  const shouldRenderFilters = useMemo(() => {
    return Object.values(selectedFilters).some(filter => {
      if (Array.isArray(filter)) {
        return filter.length > 0
      }
      return true
    })
  }, [selectedFilters])

  const filtersData = useMemo(() => {
    return [
      {
        label: 'Activation Date',
        data: selectedFilters.activationDate || [],
        key: 'activationDate',
      },
      {
        label: 'Active Status',
        data: selectedFilters.status || [],
        key: 'status',
      },
    ]
  }, [selectedFilters])

  return (
    <div id='company-report-pdf w-100'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-3 align-items-center'>
          <h1 className='text-body'>Company Report</h1>
          <h2 className='text-body'>(Page {page})</h2>
        </div>
        <div>
          <img
            className='card-img-top img-fluid w-100'
            src={masteredLogo}
            alt='Mastered'
          />
        </div>
      </div>
      {page === 1 && (
        <>
          {shouldRenderFilters ? (
            <div className='mt-3'>
              <h2 className='fw-normal text-muted'>Filters Selected</h2>
            </div>
          ) : (
            ''
          )}

          <div className='d-flex flex-wrap gap-2 mb-2 mt-1'>
            {filtersData.map(filterItem => (
              <Fragment key={filterItem.key}>
                {filterItem.data.length > 0 && (
                  <>
                    {['hireDate', 'signupDate'].includes(filterItem.key) &&
                    filterItem.data.length > 1 ? (
                      <>
                        <h3 className='fw-normal text-muted'>
                          {filterItem.key === 'hireDate'
                            ? 'Hire '
                            : 'Registration '}{' '}
                          Date:
                        </h3>
                        <h3 className='text-body'>
                          {moment(filterItem.data[0]).format('MM/DD/YYYY')}
                        </h3>
                        {filterItem.data[1] && (
                          <h3 className='text-body'>
                            {' '}
                            - {moment(filterItem.data[1]).format('MM/DD/YYYY')}
                          </h3>
                        )}
                      </>
                    ) : (
                      <>
                        <h3 className='fw-normal text-muted text-capitalize'>
                          {filterItem.label}:
                        </h3>
                        <h3 className='text-body'>
                          {filterItem.data.join(', ')}
                        </h3>
                      </>
                    )}
                    <h3 className='text-muted filter-separator'>|</h3>
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}
      <div className='table-card mt-5'>
        <Table className='align-middle mb-0' id={'user-report'}>
          <thead className='table-light'>
            <tr className='text-muted fs-3'>
              {Columns.map(column => (
                <th
                  scope='col'
                  className='fw-light align-middle'
                  style={column.style}
                  key={column.name}
                >
                  <div className='d-flex align-items-center gap-1'>
                    <span>{column.name}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {companyReport.map(company => (
              <tr key={company.id} className='fs-14 fw-light'>
                <td>
                  <span className='fs-4 text-muted'>{company.id}</span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>{company.name}</span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {_.capitalize(company.status)}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {company.activationDate
                      ? moment(company.activationDate).format('MM/DD/YYYY')
                      : '-'}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {company.facilitiesCount}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>{company.usersCount}</span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {company.registeredUsersCount}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {company.notRegisteredUsersCount}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ExportCompanyReportPdf
