import { clearUser } from './reducer'
import { AppDispatch } from '../../index'
import { API } from '../../../helpers/api_helper'
import { clearFacilities } from '../../facilities/reducer'

export const logoutUser = () => (dispatch: AppDispatch) => {
  sessionStorage.removeItem('authUser')
  sessionStorage.removeItem('masteredToken')
  delete API.defaults.headers.common.Authorization
  dispatch(clearUser())
  dispatch(clearFacilities())
}
