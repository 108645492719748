import React from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'

interface UnsavedDataModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onConfirm: () => void
  message?: string
  confirmBtnLabel?: string
  cancelBtnLabel?: string
  confirmBtnColor?: string
}

const UnsavedDataModal = ({
  onClose,
  isOpen,
  onConfirm,
  message = 'You have unsaved changes. Do you want to save them before leaving?',
  confirmBtnLabel = 'Save',
  cancelBtnLabel = "Don't save",
  confirmBtnColor = 'secondary',
}: UnsavedDataModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-danger text-danger'>
              <i
                className=' ri-error-warning-line'
                style={{ fontSize: 62 }}
              ></i>
            </div>
          </div>

          <h3 className='mt-4'>Unsaved Data</h3>
          <span className='text-muted fw-light'>{message}</span>
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-danger w-100'
            onClick={onClose}
            active={false}
          >
            {cancelBtnLabel}
          </Button>
          <Button color={confirmBtnColor} className='w-100' onClick={onConfirm}>
            {confirmBtnLabel}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UnsavedDataModal
