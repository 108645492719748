import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'

import { SearchInput } from '../../Common/SearchInput'
import { CoursesTabs, SelectedCourse } from '../../../sharedTypes'
import CourseTabs from '../../Course/CoursesTabs'
import Filters from '../../Course/Filters'
import { Step2Filters } from './types'

export interface FiltersProps {
  filters: Step2Filters
  onFiltersChange: Dispatch<SetStateAction<Step2Filters>>
  selectedCourses: SelectedCourse
}

const FiltersBlock = ({
  filters,
  onFiltersChange,
  selectedCourses,
}: FiltersProps) => {
  const [showFilters, setFilters] = useState(false)

  const selected = useMemo(() => {
    return {
      [CoursesTabs.COURSES]: selectedCourses[CoursesTabs.COURSES].length,
      [CoursesTabs.PACKAGES]: selectedCourses[CoursesTabs.PACKAGES].length,
      [CoursesTabs.TRAINING_DAYS]:
        selectedCourses[CoursesTabs.TRAINING_DAYS].length,
    }
  }, [selectedCourses])

  return (
    <div className={`border-bottom assign-courses mt-3`}>
      <Row className='gap-4 mb-3 mt-0 mx-0'>
        <Col sm='3' className='mt-0 p-0 '>
          <SearchInput
            onChange={key => {
              onFiltersChange(prev => ({ ...prev, key, page: 1 }))
            }}
            value={filters.key}
            background={'#fff'}
          />
        </Col>
        <>
          <Col sm={6} className='d-flex flex-1 flex-grow-2 justify-content-end'>
            <CourseTabs
              activeTab={filters.activeTab}
              onTabChange={activeTab => {
                onFiltersChange(prev => ({
                  ...prev,
                  activeTab: activeTab,
                  page: 1,
                }))
              }}
              extraTabData={selected}
            />
          </Col>
          <Col className='flex-grow-0 d-flex justify-content-end px-0' sm={3}>
            {filters.activeTab === CoursesTabs.COURSES && (
              <div className='d-flex flex-shrink-0 gap-3 '>
                <Button
                  className='btn-soft-primary align-middle'
                  onClick={() => setFilters(v => !v)}
                >
                  <i className='ri-equalizer-fill me-1 fs-16'></i>
                  {showFilters ? 'Hide ' : 'Show '}
                  Filters
                </Button>
              </div>
            )}
          </Col>
        </>
      </Row>
      {showFilters && (
        <Row className='gap-4 mb-3 mt-0 mx-0'>
          <Filters<Step2Filters>
            availableFilters={['format', 'type']}
            onFilterChange={onFiltersChange}
          />
        </Row>
      )}
    </div>
  )
}

export default FiltersBlock
