import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Calendar from '../../../assets/images/svg/calendar.svg'
import {
  CoursesPermissions,
  GetScheduledCourseDTO,
  OrderType,
  RecurringTypes,
  ScheduledCourseInitialValues,
  ScheduledCourseItem,
  ScheduledCourseSortBy,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
} from '../../../sharedTypes'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { Button, Card } from 'reactstrap'
import { EmptyPackageCourses } from './ManageCoursePackage'
import ScheduledCourseModal from '../../../Components/Course/ScheduledCourseModal'
import {
  deleteScheduledCourse,
  getScheduledCourses,
} from '../../../helpers/api_helper'
import ScheduledEntitiesList from '../OnlineCourses/Manage/ScheduledCoursesList'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../helpers/toast_helper'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { TrainingDayProps } from './types'
import { usePermissions } from '../../../hooks/usePermissions'

const TrainingDay = ({
  coursePackageId,
  trainingDaysCount,
  setTrainingDaysCount,
}: TrainingDayProps) => {
  const [scheduleModal, setScheduleModal] =
    useState<ScheduledCourseInitialValues | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<null | number>()
  const [data, setData] = useState<GetScheduledCourseDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    scheduledEntities: [],
  })

  const [query, setQuery] = useState<GetScheduledCourseDTO.Request>({
    page: 1,
    limit: 10,
    entityId: coursePackageId,
    entityType: ScheduledEntitiesTypes.TRAINING_DAY,
  })

  const permissions = {
    addCourse: usePermissions(CoursesPermissions.ADD_COURSES_TO_PACKAGE),
    addSchedule: usePermissions(CoursesPermissions.ADD_PACKAGE_SCHEDULE),
    editSchedule: usePermissions(CoursesPermissions.EDIT_PACKAGE_SCHEDULE),
    deleteTrainingDay: usePermissions(CoursesPermissions.DELETE_PACKAGE),
  }

  const onScheduleModalOpen = useCallback(() => {
    setScheduleModal({
      type: ScheduledCourseTypes.IN_PERSON,
      recurring: RecurringTypes.ONE_TIME,
      facilityId: null,
      recurringEnd: undefined,
      location: '',
      facilitatorId: null,
      startDate: undefined,
      startTime: undefined,
      endTime: undefined,
    })
  }, [])

  const onScheduleModalClose = useCallback((refetch?: boolean) => {
    setScheduleModal(null)
    if (refetch) {
      setQuery(prev => ({ ...prev, page: 1 }))
      setTrainingDaysCount(trainingDaysCount + 1)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getScheduledCourses(query)
      .then(response => {
        setData(response)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const onEdit = useCallback((item: ScheduledCourseItem) => {
    setScheduleModal({
      id: item.id,
      type: item.type,
      recurring: item.recurring,
      facilityId: item.facilityId,
      location: item.location,
      facilitatorId: item.facilitatorId,
      startDate: item.startDate,
      recurringEnd: item.recurringEnd,
      startTime: moment(item.startTime, 'h:mm').toDate(),
      endTime: moment(item.endTime, 'h:mm').toDate(),
    })
  }, [])

  const onDelete = useCallback(async () => {
    try {
      await deleteScheduledCourse(
        +coursePackageId,
        Number(deleteId),
        ScheduledEntitiesTypes.TRAINING_DAY,
      )
      setDeleteId(null)
      setTrainingDaysCount(trainingDaysCount - 1)
      toast(
        'Training day schedule has been successfully deleted',
        successToastOptions,
      )
      setQuery(prev => ({ ...prev, page: 1 }))
    } catch (e) {}
  }, [data.page, deleteId])

  const handleSort = useCallback((column: ScheduledCourseSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  return (
    <Fragment>
      {!trainingDaysCount ? (
        <EmptyPackageCourses
          emptyCoursesLabel={'No Schedule Added yet'}
          emptyImg={Calendar}
          addLabel={'Add Schedule'}
          onAdd={onScheduleModalOpen}
          hasPermissionToAddCourse={permissions.addSchedule}
        />
      ) : (
        <div className='package-courses-container'>
          <div className=' bg-white p-3'>
            <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
              <SearchInput
                style={{ maxWidth: 400 }}
                onChange={key => {
                  setQuery(prev => ({ ...prev, key, page: 1 }))
                }}
                value={query.key || undefined}
              />
              {permissions.addSchedule && (
                <div className='d-flex flex-shrink-0 gap-3'>
                  <Button
                    color={'primary'}
                    className='btn btn-primary align-middle'
                    onClick={onScheduleModalOpen}
                  >
                    <i className='ri-add-line me-1 fs-16'></i>Add New Schedule
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Card>
            <ScheduledEntitiesList
              data={data}
              query={query}
              setQuery={setQuery}
              onEdit={onEdit}
              onDelete={(id: number) => setDeleteId(id)}
              isLoading={isLoading}
              handleSort={handleSort}
              entityType={ScheduledEntitiesTypes.TRAINING_DAY}
            />
          </Card>
        </div>
      )}

      {(permissions.editSchedule || permissions.addSchedule) && (
        <ScheduledCourseModal
          entityId={coursePackageId}
          isOpen={!!scheduleModal}
          initialValues={scheduleModal}
          onClose={onScheduleModalClose}
          modalTitle={'Schedule Training Day'}
          webinarTitle={'Zoom Only'}
          entityType={ScheduledEntitiesTypes.TRAINING_DAY}
        />
      )}

      {permissions.deleteTrainingDay && (
        <DeleteConfirmation
          isOpen={!!deleteId}
          title='Delete Scheduled Training Day'
          message={`Are you sure you want to delete this Training Day?`}
          onDelete={onDelete}
          onClose={() => {
            setDeleteId(null)
          }}
        />
      )}
    </Fragment>
  )
}

export default TrainingDay
