import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import { OnlineCourseItem } from '../../../sharedTypes'
import { CoursePermissions } from './types'

interface GridModeActionsProps {
  course: OnlineCourseItem
  permissions: CoursePermissions
  onClone: (course: OnlineCourseItem) => void
  onDelete: (course: OnlineCourseItem) => void
  fromPreview?: boolean
}

export const GridModeActions = ({
  course,
  permissions,
  onClone,
  onDelete,
  fromPreview,
}: GridModeActionsProps) => {
  const tooltipId = `Course${course.id}${fromPreview ? 'preview' : ''}`

  return (
    <div className='d-flex align-items-center gap-2'>
      {permissions.addEdit && (
        <>
          <UncontrolledTooltip placement='top' target={`edit${tooltipId}`}>
            Edit
          </UncontrolledTooltip>
          <Link
            to={`/courses/general/${course.id}`}
            target={'_blank'}
            className='d-flex gap-2 text-muted'
          >
            <i
              className='ri-pencil-line cursor-pointer'
              id={`edit${tooltipId}`}
            ></i>
          </Link>
        </>
      )}

      {permissions.clone && (
        <>
          <UncontrolledTooltip placement='top' target={`clone${tooltipId}`}>
            Clone
          </UncontrolledTooltip>
          <i
            className='bx bx-duplicate cursor-pointer text-muted'
            id={`clone${tooltipId}`}
            onClick={e => {
              e.stopPropagation()
              onClone(course)
            }}
          ></i>
        </>
      )}

      {permissions.delete && (
        <>
          <UncontrolledTooltip placement='top' target={`delete${tooltipId}`}>
            Delete
          </UncontrolledTooltip>
          <i
            className={`${
              !course.deletable
                ? 'pe-none text-disabled'
                : 'text-danger cursor-pointer'
            } ri-delete-bin-2-line`}
            id={`delete${tooltipId}`}
            onClick={e => {
              e.stopPropagation()
              onDelete(course)
            }}
          ></i>
        </>
      )}
    </div>
  )
}
