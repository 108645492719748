import React, { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setSelectedFacility } from '../../slices/facilities/reducer'
import { Option } from '../../helpers/facility'
import { SingleValue } from 'react-select'
import { GroupSelectWithSearch } from './SelectWithSearch'

const FacilitiesDropdown = () => {
  const { selectedFacilityId, groupOptions, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))

  const dispatch = useAppDispatch()

  const changeFacility = (facilityId: number, type: string) => {
    dispatch(setSelectedFacility({ type, id: facilityId }))
  }

  const selectedValue = useMemo(() => {
    if (user?.groupId && !selectedFacilityId && !selectedGroupId) {
      return groupOptions.find(
        option => option.value === `group:${user?.groupId}`,
      )
    }

    if (!selectedFacilityId && !selectedGroupId) {
      return groupOptions.find(option => option.level === 0)
    }

    if (selectedFacilityId) {
      return groupOptions.find(
        option => option.value === `facility:${selectedFacilityId}`,
      )
    }

    return groupOptions.find(
      option => option.value === `group:${selectedGroupId}`,
    )
  }, [selectedFacilityId, selectedGroupId, groupOptions, user])

  const _groupOptions = useMemo(() => {
    if (user?.groupId) {
      return groupOptions.filter(option => option.level != 0)
    }

    return groupOptions
  }, [groupOptions, user])

  return (
    <>
      {(!user?.isFacilityAdmin || user?.hasAccessToMultipleFacilities) && (
        <div style={{ minWidth: 250 }}>
          <GroupSelectWithSearch<Option>
            name='group'
            id='group'
            className='facilities-dropdown-container'
            onChange={option => {
              const singleOption = option as SingleValue<Option>
              if (singleOption) {
                const [type, id] = singleOption.value.split(':')
                changeFacility(+id, type)
              }
            }}
            value={selectedValue}
            isMulti={false}
            isClearable={false}
            isSearchable={true}
            options={_groupOptions}
          />
        </div>
      )}
    </>
  )
}

export default FacilitiesDropdown
