import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import ParticlesAuth from '../ParticlesAuth'

// Import Images
import error from '../../../assets/images/500-error.png'

import ParticlesAuthHeader from '../../../Components/Common/ParticlesAuthHeader'

const Basic404 = () => {
  document.title = '404 Error | Mastered - React Admin & Dashboard Platform'
  return (
    <div className='auth-page-wrapper'>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <ParticlesAuthHeader />
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <img
                        src={error}
                        alt=''
                        className='error-basic-img'
                        height={210}
                      />
                      <div className='mt-4'>
                        <h1 className='display-4 error-message title my-4'>
                          Oops !
                        </h1>
                        <h3 className='text-uppercase error-message mb-2 fw-light'>
                          SORRY, PAGE NOT FOUND 😭
                        </h3>
                        <p className='error-message text-muted mb-4 fw-light'>
                          The page you are looking for not available!
                        </p>
                        <Link to='/' className='btn btn-primary'>
                          <i className='mdi mdi-home me-1'></i>Back to home
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </div>
  )
}

export default Basic404
