import { OnlineCourseItem, TLanguage } from '../../../../sharedTypes'
import { Dispatch, SetStateAction } from 'react'
import {
  TranslationQuestions,
  TranslationValueItem,
} from '../../../../sharedTypes/api/rawTranslations'

export enum TRANSLATION_TABS {
  GENERAL_INFORMATION = 'generalInformation',
  COMPETENCY_TEST = 'competencyTest',
  PREVIEW = 'preview',
}

export interface TranslationTabs {
  label: string
  value: TRANSLATION_TABS
  error: boolean
}

export const initialTranslationTabs: TranslationTabs[] = [
  {
    label: 'General Information',
    value: TRANSLATION_TABS.GENERAL_INFORMATION,
    error: false,
  },
  {
    label: 'Competency Test',
    value: TRANSLATION_TABS.COMPETENCY_TEST,
    error: false,
  },
]

export interface GeneralInformationProps {
  course: OnlineCourseItem | null
  activeTab: TRANSLATION_TABS
  activeLanguage: TLanguage
  isLive: boolean
  fetchData: boolean
  setFetchData: Dispatch<SetStateAction<boolean>>
  changeTabs: (tabs: TRANSLATION_TABS[]) => void
}

export interface IForm {
  content: {
    name: TranslationValueItem
    description: TranslationValueItem
    objective: TranslationValueItem
    questions: TranslationQuestions
  }
}

export const propertiesToRender = [
  { label: 'Name', contentKey: 'name', showEditor: false },
  {
    label: 'Description',
    contentKey: 'description',
    showEditor: false,
    isTextarea: true,
  },
  { label: 'Objectives', contentKey: 'objective', showEditor: true },
  { label: 'Resources', contentKey: 'resources', showEditor: true },
]

interface BooleanAnswers {
  [language: string]: {
    true: string
    false: string
  }
}

export const booleanAnswers: BooleanAnswers = {
  en: {
    true: 'True',
    false: 'True',
  },
  es: {
    true: 'Verdadero',
    false: 'FALSO',
  },
  ht: {
    true: 'Se vre',
    false: 'Fo',
  },
}

export const ALL_FIELDS_APPROVED_MESSAGE =
  'All translations must have Approve toggle selected in order to submit'
