import React from 'react'
import { CardBody } from 'reactstrap'
import { SurveyStatusTypes, TLanguage, Question } from '../../../../sharedTypes'
import NoResults from '../../../../assets/images/no-result.png'
import SurveyQuestionsBasePreview from '../../../../Components/Common/SurveyQuestionsBasePreview'
interface PreviewProps {
  questions?: Question[]
  languages: TLanguage[]
  status: SurveyStatusTypes
  id: number | undefined
}

const Preview = ({ questions = [], languages, status, id }: PreviewProps) => {
  const areQuestionsEmpty = (questions: Question[]) => {
    if (!questions.length) {
      return true
    }

    return Object.values(questions).every(({ question }) => question === '')
  }
  return (
    <>
      {!areQuestionsEmpty(questions) ? (
        <SurveyQuestionsBasePreview
          questions={questions}
          status={status}
          languages={languages}
          id={id}
        />
      ) : (
        <CardBody>
          <div className='d-flex flex-column align-items-center py-5'>
            <img src={NoResults} className='mb-5' alt='search-pic' />
            <p>There are no questions to this survey yet.</p>
          </div>
        </CardBody>
      )}
    </>
  )
}

export default Preview
