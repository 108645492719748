import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
} from 'reactstrap'
import React from 'react'
import { GetAdminsDTO, SettingsPermissions } from '../../sharedTypes'
import { Formik } from 'formik'
import { AsyncSelectWithSearch } from '../Common/SelectWithSearch'
import { getUserDisplayName } from '../../helpers/user'
import { Options } from './AssignCourseModal/types'
import { getAdmins } from '../../helpers/api/admins'

interface AssignUserModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  handleAdd: (userIds: number[]) => void
}

export interface FormValues {
  users: Options[]
}

const AssignRoleToUsersModal = ({
  onClose,
  isOpen,
  handleAdd,
}: AssignUserModalProps) => {
  const fetchUsers = (inputValue?: string) => {
    const params: GetAdminsDTO.Request = {
      page: 1,
      limit: 20,
      key: inputValue,
      permission: SettingsPermissions.ASSIGN_ROLE_TO_USERS,
    }

    return getAdmins(params)
      .then(res => {
        return res.data.admins.map(user => ({
          value: user.id,
          label: getUserDisplayName(user),
        }))
      })
      .catch(() => [])
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      className='media-library-modal'
      centered
    >
      <ModalHeader toggle={onClose}>
        <h5 className='fw-normal text-muted'>Assign</h5>
      </ModalHeader>
      <ModalBody className='p-4'>
        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={{
            users: [],
          }}
          onSubmit={values => {
            const userIds = values.users.map(user => user.value)
            handleAdd(userIds)
          }}
        >
          {({
            values,
            handleBlur,
            setFieldValue,
            isValid,
            dirty,
            isSubmitting,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} action='#'>
              <ul className='mx-auto list-unstyled overflow-auto general-info'>
                <div>
                  <label htmlFor='departments' className='form-label'>
                    Users*
                  </label>
                  <AsyncSelectWithSearch
                    name='users'
                    id='users'
                    onChange={option => {
                      setFieldValue(
                        'users',
                        Array.isArray(option) ? option : [option],
                      )
                    }}
                    onBlur={handleBlur}
                    value={values.users}
                    isMulti={true}
                    isClearable={false}
                    isSearchable={true}
                    placeholder={'Enter name'}
                    defaultOptions
                    loadOptions={fetchUsers}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </ul>

              <div className='hstack gap-3 justify-content-end'>
                <Button
                  className='btn-soft-primary align-middle'
                  color='secondary'
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className='align-middle'
                  color='primary'
                  type='submit'
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Assign
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default AssignRoleToUsersModal
