const google = {
  API_KEY: '',
  CLIENT_ID: '',
  SECRET: '',
}
const facebook = {
  APP_ID: '',
}
const api = {
  API_URL: process.env.REACT_APP_API_URL as string,
}

export { google, facebook, api }
