import React, { Fragment } from 'react'

const prefix = 'gridjs'

export function className(...args: string[]): string {
  return `${prefix}${args.reduce(
    (prev: string, cur: string) => `${prev}-${cur}`,
    '',
  )}`
}
export function classJoin(...classNames: (undefined | string)[]): string {
  return classNames
    .map(x => (x ? x.toString() : ''))
    .filter(x => x)
    .reduce((className, prev) => `${className || ''} ${prev}`, '')
    .trim()
}

interface Props {
  currentPage: number
  totalPages: number
  totalRecords: number
  limit?: number
  buttonsCount?: number
  setPage: (page: number) => void
}

export function Pagination({
  currentPage,
  totalPages,
  totalRecords,
  limit = 10,
  buttonsCount = 3,
  setPage,
}: Props) {
  const renderPages = () => {
    if (buttonsCount <= 0) {
      return null
    }

    // how many pagination buttons to render?
    const maxCount: number = Math.min(totalPages, buttonsCount)

    let pagePivot = Math.min(currentPage, Math.floor(maxCount / 2))
    if (currentPage + Math.floor(maxCount / 2) >= totalPages) {
      pagePivot = maxCount - (totalPages - currentPage)
    }

    return (
      <Fragment>
        {totalPages > maxCount && currentPage - pagePivot > 0 && (
          <Fragment>
            <button tabIndex={0} role='button' onClick={() => setPage(0)}>
              1
            </button>
            <button tabIndex={-1} className={classJoin(className('spread'))}>
              ...
            </button>
          </Fragment>
        )}

        {Array.from(Array(maxCount).keys())
          .map(i => currentPage + (i - pagePivot))
          .map(i => (
            <button
              key={i}
              tabIndex={0}
              role='button'
              onClick={() => setPage(i)}
              className={classJoin(
                currentPage === i
                  ? classJoin(className('currentPage'))
                  : undefined,
              )}
            >
              {i + 1}
            </button>
          ))}

        {totalPages > maxCount && totalPages > currentPage + pagePivot + 1 && (
          <Fragment>
            <button tabIndex={-1} className={classJoin(className('spread'))}>
              ...
            </button>
            <button
              tabIndex={0}
              role='button'
              onClick={() => setPage(totalPages - 1)}
            >
              {totalPages}
            </button>
          </Fragment>
        )}
      </Fragment>
    )
  }

  const renderSummary = () => {
    return (
      <Fragment>
        {totalRecords > 0 && (
          <div
            role='status'
            aria-live='polite'
            className={classJoin(
              className('summary'),
              'text-muted fs-14 fw-light',
            )}
          >
            Showing {currentPage * limit + 1} to{' '}
            {Math.min((currentPage + 1) * limit, totalRecords)} of{' '}
            {totalRecords} results
          </div>
        )}
      </Fragment>
    )
  }

  return (
    <div className={classJoin(className('pagination'))}>
      {renderSummary()}

      <div className={className('pages')}>
        <button
          tabIndex={0}
          role='button'
          disabled={currentPage === 0}
          onClick={() => setPage(currentPage - 1)}
          className={classJoin()}
        >
          Previous
        </button>

        {renderPages()}

        <button
          tabIndex={0}
          role='button'
          disabled={totalPages === currentPage + 1 || totalPages === 0}
          onClick={() => setPage(currentPage + 1)}
          className={classJoin()}
        >
          Next
        </button>
      </div>
    </div>
  )
}
