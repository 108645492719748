import React from 'react'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'
import moment from 'moment/moment'

import {
  CoursesPermissions,
  notificationOption,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
  ScheduledEvent,
} from '../../sharedTypes'
import { copyToClipboard } from '../../utils/clipboard'
import { scheduledEventTypes } from '../../pages/Calendar/data'
import { Link } from 'react-router-dom'
import { usePermissions } from '../../hooks/usePermissions'

interface Props {
  isOpen: boolean
  event: ScheduledEvent | null
  toggle: () => void
  linkToNavigate: string
}

export const defaultNotifications = [
  {
    value: 10,
    type: notificationOption.MINUTES,
  },
  {
    value: 1,
    type: notificationOption.HOURS,
  },
  {
    value: 1,
    type: notificationOption.DAYS,
  },
]

const EventModal = ({ isOpen, event, toggle, linkToNavigate }: Props) => {
  if (!event) {
    return <></>
  }

  const hasPermissionToViewCourse = usePermissions(
    CoursesPermissions.ADD_EDIT_COURSE,
  )

  const getEventType = (type: ScheduledCourseTypes) => {
    const result = scheduledEventTypes.find(
      eventType => eventType.value === type,
    )

    return result ? result.title : ''
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      id='event-modal'
      centered
      style={{ maxWidth: 422 }}
    >
      <ModalBody>
        <div className='hstack w-100 mb-3 flex-1 align-items-center justify-content-between'>
          <h5>{getEventType(event.extendedProps.type)}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={toggle}
          ></i>
        </div>

        <div className='vstack gap-3 text-start course-details-info'>
          <div className='hstack w-100 flex-1 align-items-center justify-content-between'>
            <span className='fw-medium fs-18'>{event.title}</span>
            {hasPermissionToViewCourse && (
              <Link to={linkToNavigate} target='_blank'>
                <Button color='soft-primary' className='fs-12 fw-medium'>
                  <i className='ri-eye-line me-1 fs-12'></i>View
                </Button>
              </Link>
            )}
          </div>
          <Row>
            <Col md={4}>
              <span className='fw-medium text-body fs-14'>Date: </span>
            </Col>
            <Col md={8}>
              <span className='text-muted fs-14'>
                {moment(event.start).format('MM/DD/YYYY')}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium text-body fs-14'>
                Start/End Time:{' '}
              </span>
            </Col>
            <Col md={8}>
              <span className='text-muted fs-14 badge badge-soft-grey fw-normal'>
                {`${moment(event.start).format('hh:mm A')} to ${moment(
                  event.end,
                ).format('hh:mm A')}`}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium text-body fs-14'>Location: </span>
            </Col>
            <Col md={8}>
              <span className='text-muted fs-14'>
                {event.extendedProps.location}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium text-body fs-14'>Facilitator: </span>
            </Col>
            <Col md={8}>
              <span className='text-muted fs-14'>
                {event.extendedProps.facilitator?.name || '-'}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium text-body fs-14'>Meeting ID: </span>
            </Col>
            <Col md={8}>
              <div className='d-flex'>
                <span className='text-truncate text-secondary fw-medium'>
                  {event.extendedProps.meetingLink}
                </span>
                <i
                  className='ri-file-copy-line cursor-pointer text-primary'
                  onClick={() => {
                    copyToClipboard(event.extendedProps.meetingLink)
                  }}
                ></i>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className='fw-medium text-body fs-14'>Description: </span>
            </Col>
            <Col md={8}>
              <span className='text-muted fs-14'>
                {event.extendedProps.description}
              </span>
            </Col>
          </Row>
          {event.extendedProps.entityType === ScheduledEntitiesTypes.COURSE && (
            <Row>
              <Col md={4}>
                <span className='fw-medium text-body fs-14'>Notes: </span>
              </Col>
              <Col md={8}>
                <span
                  className='text-muted fs-14'
                  dangerouslySetInnerHTML={{
                    __html: event.extendedProps.notes || '-',
                  }}
                ></span>
              </Col>
            </Row>
          )}
        </div>
      </ModalBody>
      <div className='d-flex gap-2 justify-content-end p-3 border-top'>
        <Button
          color='ghost-primary'
          className='text-light-purple'
          onClick={toggle}
        >
          Cancel
        </Button>
        <Link
          to={{
            pathname: `/facilitator-view/${event.id.split('-')[0]}`,
            search: `?start=${event.start}&end=${event.end}`,
          }}
          target='_blank'
        >
          <Button color='primary' className='d-flex align-items-center'>
            Facilitator View
            <i className='ri-arrow-right-line align-middle ms-2'></i>
          </Button>
        </Link>
      </div>
    </Modal>
  )
}

export default EventModal
