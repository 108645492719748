import { Card, CardBody } from 'reactstrap'
import { get } from 'lodash'
import moment from 'moment/moment'
import ShowMore from '../../../../Components/Common/ShowMore'
import React from 'react'
import { OnlineCourseItem } from '../../../../sharedTypes'
import { formattedDuration } from '../../../../helpers/course'

const AdditionalCourseInfo = ({ course }: { course: OnlineCourseItem }) => {
  return (
    <Card className='m-0'>
      <CardBody>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <span className='ml-1 fs-16 text-dark-gray fw-normal'>
            Additional Info
          </span>
        </div>
        {course?.cover?.path && (
          <div className='text-center'>
            <img
              src={course.cover?.path}
              className='mb-5 w-100'
              alt='search-pic'
            />
          </div>
        )}
        <p className='text-center mb-3 fs-16 text-dark-gray'>
          {get(course, 'translations[0].content.name', '-')}
        </p>
        <ul className='mx-auto list-unstyled'>
          <li className='mb-4 gap-3 d-flex course-info-item'>
            <span className='key'>Code :</span>
            <span className='value'>{course?.code}</span>
          </li>
          <li className='mb-4 gap-3 d-flex course-info-item'>
            <span className='key'>Effective Date :</span>
            <span className='value'>
              {course.effectiveDate
                ? moment(course.effectiveDate).format('MM/DD/YYYY')
                : '-'}
            </span>
          </li>
          <li className='mb-4 gap-3 d-flex course-info-item'>
            <span className='key'>Type :</span>
            <span className='value'>{course.type}</span>
          </li>
          <li className='mb-4 gap-3 d-flex course-info-item'>
            <span className='key'>Duration :</span>
            <span className='value'>{formattedDuration(course?.duration)}</span>
          </li>
          <li className='mb-4 gap-3 d-flex course-info-item'>
            <span className='key'>Notes :</span>
            <span>
              <ShowMore
                content={get(course, 'translations[0].content.notes', '-')}
              />
            </span>
          </li>
        </ul>
      </CardBody>
    </Card>
  )
}

export default AdditionalCourseInfo
