import React from 'react'
import { Col, Button } from 'reactstrap'
import { usePermissions } from '../../../hooks/usePermissions'
import { CoursesPermissions } from '../../../sharedTypes'

export interface BottomNavigationProps {
  onBack: () => void
  onSaveAsDraft: () => void
  onSubmit: () => void
  backText: string
  isDisabledPublish?: boolean
  isDisabledSaveAsDraft?: boolean
  isFirstStep?: boolean
  tabChange?: () => void | undefined
}

export const BottomNavigation = ({
  backText,
  onBack,
  onSaveAsDraft,
  onSubmit,
  tabChange,
  isFirstStep = false,
  isDisabledPublish = false,
  isDisabledSaveAsDraft = false,
}: BottomNavigationProps) => {
  const hasPermissionToPublish = usePermissions(
    CoursesPermissions.PUBLISH_PACKAGE,
  )

  return (
    <Col sm='12'>
      <div className='d-flex justify-content-between'>
        <Button color='light' className='btn-label' onClick={onBack}>
          <i className='ri-arrow-left-line label-icon align-middle me-2'></i>
          {backText}
        </Button>
        <div className='hstack gap-3'>
          <Button
            color='soft-primary'
            onClick={onSaveAsDraft}
            disabled={isDisabledSaveAsDraft}
          >
            Save as Draft
          </Button>
          {isFirstStep ? (
            <Button
              color='primary'
              className='btn-label right'
              onClick={onSubmit}
            >
              <i className='ri-arrow-right-line label-icon align-middle ms-2'></i>
              Next
            </Button>
          ) : tabChange ? (
            <Button color='primary' onClick={tabChange}>
              Next
            </Button>
          ) : (
            <>
              {hasPermissionToPublish && (
                <Button
                  color='primary'
                  onClick={onSubmit}
                  disabled={isDisabledPublish}
                >
                  Publish
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </Col>
  )
}
