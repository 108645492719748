import React, { useCallback } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  ScheduledCategoryInitialValues,
  ScheduledCategoryModalSubmitedValues,
} from '../../../sharedTypes'
import { FormikHelpers } from 'formik'
import { Spinner } from 'reactstrap'
import ScheduledCategoryBaseForm from './ScheduledCategoryBaseForm'

interface ScheduledCategoryModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (
    values: ScheduledCategoryModalSubmitedValues,
    formikHelpers: FormikHelpers<ScheduledCategoryModalSubmitedValues>,
  ) => void
  title: string
  initialValues: ScheduledCategoryInitialValues | null
}

const ScheduledCategory = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
}: ScheduledCategoryModalProps) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: -1,
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
    }),
    onSubmit: (values, formik) => {
      onSubmit(
        values as ScheduledCategoryModalSubmitedValues,
        formik as FormikHelpers<ScheduledCategoryModalSubmitedValues>,
      )
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>
        <ScheduledCategoryBaseForm form={form} />

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-primary'
            onClick={beforeClose}
            active={false}
            disabled={form.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            color='success'
            type='button'
            onClick={() => form.handleSubmit()}
            disabled={!form.dirty || !form.isValid || form.isSubmitting}
          >
            {form.isSubmitting ? <Spinner size={'sm'} /> : 'Submit'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ScheduledCategory
