import BreadCrumb from '../../../Components/Common/BreadCrumb'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Row,
  Spinner,
} from 'reactstrap'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { useFormik } from 'formik'
import { COMPANY_STATUSES, StatesOptions, TCompany } from '../../../sharedTypes'
import Select from 'react-select'
import FilterTabs from '../../../Components/Common/FilterTabs'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'
import Dropzone from 'react-dropzone'
import { companiesSchema } from '../../../schemas'
import {
  createCompany,
  getCompanyById,
  updateCompany,
} from '../../../helpers/api/company'
import axios from 'axios'
import { toast } from 'react-toastify'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import Statistics from './Statistics'
import { ICompanyForm } from '../types'
import { getMediaDetails } from '../../../helpers/common'
import { Link } from 'react-router-dom'
import formatBytes from '../../../utils/formatBytes'
import PrimaryConfirmationModal from '../../../Components/Modals/PrimaryConfirmationModal'
import { switchToPortal } from '../../../helpers/company'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { MAX_LOGO_SIZE } from '../../../common/files'

const ManageCompany = ({ router }: WithRouterProps) => {
  const [existingCompany, setExistingCompany] = useState<TCompany>()
  const [isOpenWarningPopup, setIsOpenWarningPopup] = useState<boolean>(false)

  const admin = useAppSelector(state => state.User.user)
  const dispatch = useAppDispatch()

  const { id } = router.params

  useEffect(() => {
    if (id && (Number.isNaN(+id) || typeof +id !== 'number')) {
      return router.navigate('/404')
    }

    if (id) {
      getCompanyById(parseInt(id))
        .then(res => {
          setExistingCompany(res.data)
        })
        .catch(error => {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            router.navigate('/404')
          }
        })
    }
  }, [id])

  const _onSubmit = async () => {
    const {
      name,
      address,
      city,
      state,
      status,
      notes,
      accountContact,
      billingContact,
      logo,
      logoId,
    } = form.values

    try {
      const formData = new FormData()
      formData.append('name', name)
      formData.append('address', address)
      formData.append('city', city)
      formData.append('state', state?.value as string)
      formData.append('status', status)
      formData.append('accountContact', JSON.stringify(accountContact))

      if (logoId) {
        formData.append('logoId', logoId.toString())
      }

      if (notes) {
        formData.append('notes', notes)
      }

      if (billingContact) {
        formData.append('billingContact', JSON.stringify(billingContact))
      }

      if (logo) {
        formData.append('logo', logo as any)
      }

      if (id) {
        await updateCompany(+id, formData)
      } else {
        await createCompany(formData)
      }

      router.navigate('/company-management')
      toast(
        `Success - Company has been ${id ? 'updated' : 'created'}`,
        successToastOptions,
      )
    } catch (e) {
      handleError(e)
    }
  }

  const handleAcceptedFiles = (file: File) => {
    form.setFieldValue(
      'logo',
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    )
  }

  const form = useFormik<ICompanyForm>({
    enableReinitialize: true,
    initialValues: {
      name: existingCompany?.name || '',
      address: existingCompany?.address || '',
      city: existingCompany?.city || '',
      state: existingCompany?.state
        ? { label: existingCompany?.state, value: existingCompany?.state }
        : undefined,
      status: existingCompany?.status || COMPANY_STATUSES.INACTIVE,
      activationDate: existingCompany?.activationDate || undefined,
      notes: existingCompany?.notes || '',
      logo: existingCompany?.logo
        ? getMediaDetails(existingCompany.logo)
        : undefined,
      logoId: existingCompany?.logoId || undefined,
      accountContact: existingCompany?.accountContact || {
        name: '',
        email: '',
        phone: '',
      },
      billingContact: existingCompany?.billingContact || {
        name: '',
        email: '',
        phone: '',
      },
    },
    validationSchema: companiesSchema,
    onSubmit: _onSubmit,
  })

  const analyticsData = useMemo(
    () =>
      existingCompany
        ? [
            {
              label: 'Active Users',
              value: existingCompany.usersCount as number,
              icon: 'ri-group-line',
            },
            {
              label: 'Active Admins',
              value: existingCompany.adminsCount as number,
              icon: 'ri-briefcase-line',
            },
            {
              label: 'Active Facilities',
              value: existingCompany.facilitiesCount as number,
              icon: 'ri-building-line',
            },
          ]
        : undefined,
    [existingCompany],
  )

  const hasUnsavedData = useMemo(
    () => JSON.stringify(form.values) !== JSON.stringify(form.initialValues),
    [form.initialValues, form.values],
  )

  const viewCompany = async () => {
    if (hasUnsavedData) {
      setIsOpenWarningPopup(true)
    } else {
      await switchTo()
    }
  }

  const switchTo = async () => {
    if (id && admin) {
      await switchToPortal(admin, +id, dispatch)
    }
  }

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Company Management'
            items={[
              {
                title: 'Company Management',
                linkTo: '/company-management',
              },
            ]}
          />
          <Form
            onSubmit={e => {
              e.preventDefault()
              form.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <div className='ml-1 mb-4 text-dark-gray fs-16'>
                      General Info
                    </div>

                    <div className='vstack gap-3'>
                      <div>
                        <label htmlFor='name' className='form-label'>
                          Name*
                        </label>
                        <Input
                          name='name'
                          className='form-control'
                          id='name'
                          placeholder='Company name'
                          type='text'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.name || ''}
                          invalid={!!(form.touched.name && form.errors.name)}
                        />
                        {form.touched.name && form.errors.name ? (
                          <FormFeedback type='invalid'>
                            {form.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row>
                        <Col>
                          <label htmlFor='address' className='form-label'>
                            Address*
                          </label>
                          <Input
                            name='address'
                            className='form-control'
                            id='address'
                            placeholder='1st street'
                            type='text'
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.address}
                            invalid={
                              !!(form.touched.address && form.errors.address)
                            }
                          />
                          {form.touched.address && form.errors.address ? (
                            <FormFeedback type='invalid'>
                              {form.errors.address}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col>
                          <label htmlFor='city' className='form-label'>
                            City*
                          </label>
                          <Input
                            name='city'
                            className='form-control'
                            id='city'
                            placeholder='City name'
                            type='text'
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.city}
                            invalid={!!(form.touched.city && form.errors.city)}
                          />
                          {form.touched.city && form.errors.city ? (
                            <FormFeedback type='invalid'>
                              {form.errors.city}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col>
                          <label htmlFor='state' className='form-label'>
                            State*
                          </label>
                          <Select
                            isSearchable={false}
                            onChange={option => {
                              form.setFieldValue('state', option)
                            }}
                            value={form.values.state}
                            options={StatesOptions}
                            name='state'
                            id='state'
                            placeholder='State name'
                            onBlur={form.handleBlur}
                            styles={{
                              control: baseStyles => ({
                                ...baseStyles,
                                borderRadius: '0px 4px 4px 0px',
                                minHeight: 39,
                              }),
                            }}
                            className='select2-container w-100'
                            classNamePrefix='select2-selection form-select'
                          />
                          {form.touched.state && form.errors.state ? (
                            <FormFeedback type='invalid' className='d-block'>
                              {form.errors.state}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col className='py-0 my-0 align-self-end h-100'>
                          <FilterTabs
                            tabs={[
                              {
                                label: 'Active',
                                value: COMPANY_STATUSES.ACTIVE,
                              },
                              {
                                label: 'Inactive',
                                value: COMPANY_STATUSES.INACTIVE,
                              },
                            ]}
                            navTab={form.values.status}
                            navToggle={status => {
                              form.setFieldValue('status', status)
                            }}
                            className='company-status'
                          />
                        </Col>
                        <Col>
                          <div>
                            <label
                              htmlFor='activationDate'
                              className='form-label'
                            >
                              Activation Date
                            </label>
                            <div className='form-icon right'>
                              <Flatpickr
                                className='form-control form-control-icon'
                                id='activationDate'
                                disabled={true}
                                name='activationDate'
                                placeholder='--/--/----'
                                value={form.values.activationDate}
                                options={{
                                  formatDate: date =>
                                    moment(date).format('MM/DD/YYYY'),
                                  allowInvalidPreload: true,
                                }}
                                style={{ background: '#F6F9FE' }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <label htmlFor='notes' className='form-label'>
                          Notes
                        </label>
                        <Input
                          name='notes'
                          className='form-control'
                          id='notes'
                          placeholder='You can type any notes to this company here'
                          type='textarea'
                          rows={3}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.notes || ''}
                          invalid={!!(form.touched.notes && form.errors.notes)}
                        />
                        {form.touched.notes && form.errors.notes ? (
                          <FormFeedback type='invalid'>
                            {form.errors.notes}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div>
                      <label htmlFor='name' className='form-label'>
                        Logo
                      </label>
                    </div>
                    <Dropzone
                      maxFiles={1}
                      disabled={form.isSubmitting}
                      multiple={false}
                      accept={{
                        'image/svg+xml': [],
                        'image/jpg': [],
                        'image/png': [],
                      }}
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles[0])
                      }}
                      maxSize={MAX_LOGO_SIZE}
                    >
                      {({ getRootProps }) => (
                        <div className='dropzone dz-clickable cursor-pointer'>
                          <div
                            className='dz-message needsclick'
                            {...getRootProps()}
                          >
                            <div className='mt-2'>
                              <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                            </div>
                            <p className='m-0 text-muted fw-light fs-14'>
                              Drop files here or click to upload.<br></br>
                              (jpg, png, svg)
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {form.errors.logo ? (
                      <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                        {form.errors.logo as string}
                      </p>
                    ) : null}
                    {form.values?.logo && (
                      <>
                        <ul
                          className='list-unstyled mb-0'
                          id='dropzone-preview'
                        >
                          <Card
                            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                            key={'-file'}
                          >
                            <div className='p-2'>
                              <Row className='align-items-center'>
                                <Col className='col-auto'>
                                  <img
                                    data-dz-thumbnail=''
                                    height='80'
                                    className='avatar-sm rounded bg-light'
                                    alt={'NO IMAGE'}
                                    src={
                                      'preview' in form.values.logo
                                        ? form.values?.logo.preview
                                        : ''
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to='#'
                                    className='text-muted font-weight-bold'
                                  >
                                    {form.values?.logo.name}
                                  </Link>
                                  <p className='mb-0'>
                                    <strong>
                                      {form.values?.logo.formattedSize}
                                    </strong>
                                  </p>
                                </Col>
                                <Col className='d-flex justify-content-end align-items-end'>
                                  <Button
                                    color='danger'
                                    onClick={() => {
                                      form.setFieldValue('logo', null)
                                      form.setFieldValue('logoId', null)
                                    }}
                                  >
                                    Delete{' '}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </ul>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>

              <Col md={6}>
                {analyticsData && <Statistics analytics={analyticsData} />}
                <Card>
                  <CardBody>
                    <div className='ml-1 mb-4 text-dark-gray fs-16'>
                      Account Contact
                    </div>

                    <div className='vstack gap-3'>
                      <div>
                        <label
                          htmlFor='accountContactName'
                          className='form-label'
                        >
                          Name*
                        </label>
                        <Input
                          name='accountContact.name'
                          className='form-control'
                          id='accountContactName'
                          placeholder='Name'
                          type='text'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.accountContact.name || ''}
                          invalid={
                            !!(
                              form.touched.accountContact?.name &&
                              form.errors.accountContact?.name
                            )
                          }
                        />
                        {form.touched.accountContact?.name &&
                        form.errors.accountContact?.name ? (
                          <FormFeedback type='invalid'>
                            {form.errors.accountContact.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div>
                        <label
                          htmlFor='accountContactEmail'
                          className='form-label'
                        >
                          Email*
                        </label>
                        <Input
                          name='accountContact.email'
                          className='form-control'
                          id='accountContactEmail'
                          placeholder='example@email.com'
                          type='email'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.accountContact.email || ''}
                          invalid={
                            !!(
                              form.touched.accountContact?.email &&
                              form.errors.accountContact?.email
                            )
                          }
                        />
                        {form.touched.accountContact?.email &&
                        form.errors.accountContact?.email ? (
                          <FormFeedback type='invalid'>
                            {form.errors.accountContact.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div>
                        <label
                          htmlFor='accountContactPhone'
                          className='form-label'
                        >
                          Phone*
                        </label>
                        <Input
                          name='accountContact.phone'
                          className='form-control'
                          id='accountContactPhone'
                          placeholder='+1 000 0000-000'
                          type='text'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.accountContact.phone || ''}
                          invalid={
                            !!(
                              form.touched.accountContact?.phone &&
                              form.errors.accountContact?.phone
                            )
                          }
                        />
                        {form.touched.accountContact?.phone &&
                        form.errors.accountContact?.phone ? (
                          <FormFeedback type='invalid'>
                            {form.errors.accountContact.phone}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div className='ml-1 mb-4 text-dark-gray fs-16'>
                      Billing Contact
                    </div>

                    <div className='vstack gap-3'>
                      <div>
                        <label
                          htmlFor='billingContactName'
                          className='form-label'
                        >
                          Name
                        </label>
                        <Input
                          name='billingContact.name'
                          className='form-control'
                          id='billingContactName'
                          placeholder='Name Surname'
                          type='text'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.billingContact?.name || ''}
                          invalid={
                            !!(
                              form.touched.billingContact?.name &&
                              form.errors.billingContact?.name
                            )
                          }
                        />
                        {form.touched.billingContact?.name &&
                        form.errors.billingContact?.name ? (
                          <FormFeedback type='invalid'>
                            {form.errors.billingContact.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div>
                        <label
                          htmlFor='billingContactEmail'
                          className='form-label'
                        >
                          Email
                        </label>
                        <Input
                          name='billingContact.email'
                          className='form-control'
                          id='billingContactEmail'
                          placeholder='example@email.com'
                          type='email'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.billingContact?.email || ''}
                          invalid={
                            !!(
                              form.touched.billingContact?.email &&
                              form.errors.billingContact?.email
                            )
                          }
                        />
                        {form.touched.billingContact?.email &&
                        form.errors.billingContact?.email ? (
                          <FormFeedback type='invalid'>
                            {form.errors.billingContact.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div>
                        <label
                          htmlFor='billingContactPhone'
                          className='form-label'
                        >
                          Phone
                        </label>
                        <Input
                          name='billingContact.phone'
                          className='form-control'
                          id='billingContactPhone'
                          placeholder='+1 000 000-000'
                          type='text'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.billingContact?.phone || ''}
                          invalid={
                            !!(
                              form.touched.billingContact?.phone &&
                              form.errors.billingContact?.phone
                            )
                          }
                        />
                        {form.touched.billingContact?.phone &&
                        form.errors.billingContact?.phone ? (
                          <FormFeedback type='invalid'>
                            {form.errors.billingContact.phone}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className='sticky-row'>
              <Col>
                <Card>
                  <CardBody>
                    <div className='d-flex justify-content-between'>
                      <Button
                        color='light'
                        onClick={() => router.navigate('/company-management')}
                      >
                        Cancel
                      </Button>

                      <div className='hstack gap-3'>
                        <Button
                          color={`${
                            id ? 'soft-primary' : 'primary'
                          } fw-medium d-flex align-items-center gap-1`}
                          disabled={!id}
                          onClick={viewCompany}
                        >
                          <i className='ri-external-link-line label-icon align-middle'></i>
                          View Company
                        </Button>

                        <Button
                          color='primary'
                          type='submit'
                          disabled={
                            !(form.isValid && form.dirty) || form.isSubmitting
                          }
                        >
                          {form.isSubmitting ? <Spinner size={'sm'} /> : 'Save'}
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>

          <PrimaryConfirmationModal
            onConfirm={switchTo}
            className='company-switch-warning-modal'
            confirmButtonLabel='Proceed without saving'
            icon='ri-error-warning-line'
            isLoading={false}
            isOpen={isOpenWarningPopup}
            title='Unsaved Data'
            message={`Switching to the ${existingCompany?.name} portal will cause you to lose your unsaved data.`}
            onClose={() => {
              setIsOpenWarningPopup(false)
            }}
          />
        </Container>
      </div>
    </Fragment>
  )
}

export default withRouter(ManageCompany)
