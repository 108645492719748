import React, { useState } from 'react'
import { OnlineTagItem } from '../../sharedTypes'
import { tagColors } from '../../helpers/course'

const LIMIT = 3

const CourseTags = ({ tags }: { tags: OnlineTagItem[] }) => {
  const [showMore, setShowMore] = useState(false)
  const displayedTags = showMore ? tags : tags.slice(0, LIMIT)

  return (
    <div className='d-flex gap-2 flex-wrap align-items-center'>
      {displayedTags.map((tag, i) => (
        <span key={i} className={`badge ${tagColors[i]} fs-12 fw-normal`}>
          {tag.name}
        </span>
      ))}
      {tags.length > LIMIT && (
        <span
          className='cursor-pointer m-1'
          style={{ color: '#0039C7', fontWeight: '500' }}
          onClick={() => setShowMore(prev => !prev)}
        >
          {showMore ? 'Show less' : 'Show more'}
        </span>
      )}
    </div>
  )
}

export default CourseTags
