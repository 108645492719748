import React from 'react'
import { Col, Row } from 'reactstrap'
import logoLight from '../../assets/images/logo-header.png'

interface ParticlesAuthHeaderProps {
  marginTop?: React.CSSProperties['marginTop']
  paddingTop?: React.CSSProperties['paddingTop']
}

const ParticlesAuthHeader = ({
  marginTop = 'mt-sm-5',
  paddingTop = 'pt-5',
}: ParticlesAuthHeaderProps) => {
  return (
    <Row>
      <Col lg={12}>
        <div
          className={`text-center ${marginTop} text-white-50 ${paddingTop} mb-4`}
        >
          <div className='d-inline-block auth-logo'>
            <img src={logoLight} alt='' height='26' />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default ParticlesAuthHeader
