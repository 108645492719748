import React, { useState, useCallback } from 'react'
import { deletetOnlineCourseQuestion } from '../../../helpers/api_helper'
import {
  QuestionItem,
  QuestionableTypes,
  QuestionsSortBy,
} from '../../../sharedTypes'
import NoResultTableWrapper from '../../Common/NoResultTableWrapper'
import { Button, Row, Table, Col } from 'reactstrap'
import ColumnSortIcon from '../../Common/ColumnSortIcon'
import { SearchInput } from '../../Common/SearchInput'
import { toast } from 'react-toastify'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import DeleteConfirmation from '../../Modals/DeleteConfirmation'
import { IUseQuestions } from '../../../hooks/course/useQuestions'
import Highlighter from 'react-highlight-words'
import { Pagination } from '../../../Components/Common/Pagination'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const Columns = [
  {
    name: '#',
    style: { width: '5%' },
    sortBy: QuestionsSortBy.ID,
  },
  {
    name: 'Question',
    style: { width: '45%' },
    sortBy: QuestionsSortBy.QUESTION,
  },
  {
    name: 'Type',
    style: { width: '45%' },
    sortBy: QuestionsSortBy.TYPE,
  },
  {
    name: 'Actions',
    style: { width: '10%' },
  },
]

interface IQuestionList extends IUseQuestions {
  courseId: number
  viewOnly?: boolean
  questionableType: QuestionableTypes
  onAddNew: () => void
  onEdit: (questionGroupId: string) => void
  isPublishing?: boolean
}

export const QuestionList = ({
  courseId,
  setQuery,
  query,
  onAddNew,
  onEdit,
  data,
  handleSort,
  viewOnly,
  isPublishing,
  isLoading,
  onReOrder,
}: IQuestionList) => {
  const [deleteId, setDeleteId] = useState<null | string>()

  const onDelete = useCallback(async () => {
    try {
      await deletetOnlineCourseQuestion(courseId, deleteId as string)

      setQuery(prev => ({ ...prev, page: 1 }))
      setDeleteId(null)
      toast(`Successfully deleted`, successToastOptions)
    } catch (e) {
      handleError(e)
    }
  }, [courseId, deleteId])

  function getStyle(style: any, snapshot: any) {
    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      transitionDuration: `0.001s`,
    }
  }

  return (
    <>
      <Row>
        <Col>
          <SearchInput
            style={{ maxWidth: 400 }}
            onChange={key => {
              setQuery(prev => ({ ...prev, key, page: 1 }))
            }}
            value={query.key || ''}
          />
        </Col>
        <Col>
          <div className='d-flex justify-content-end'>
            <Button
              id='add-new-question'
              color='ghost-primary'
              className='text-light-purple'
              onClick={onAddNew}
            >
              <i className='ri-add-line me-1 fs-16'></i>Add New Question
            </Button>
          </div>
        </Col>
      </Row>
      <DragDropContext onDragEnd={onReOrder}>
        <Droppable
          droppableId='droppable-questions'
          type='questions'
          direction='vertical'
        >
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {provided.placeholder}
              <NoResultTableWrapper
                className='p-0 mt-3'
                isLoading={isLoading}
                isFiltering={!!query.key}
                pages={data.pages}
                noDataErrorMessageColor={
                  isPublishing && data.count === 0 ? '#F25959' : 'black'
                }
                noDataErrorMessage={
                  data.count === 0 && isPublishing
                    ? 'At least one question is required'
                    : 'Sorry, no questions found'
                }
              >
                <div className=''>
                  <Table className='align-middle table-nowrap mb-0'>
                    <thead className='table-light'>
                      <tr className='text-muted fs-14'>
                        <th
                          scope='col'
                          className='align-middle'
                          key='order'
                        ></th>
                        {Columns.map(column => (
                          <th
                            scope='col'
                            className='align-middle'
                            style={column.style}
                            key={column.name}
                          >
                            {column.name}
                            {!!column.sortBy && (
                              <ColumnSortIcon<QuestionsSortBy>
                                sortOrder={query.orderBy}
                                sortedColumn={query.sortBy}
                                column={column.sortBy}
                                handleSort={handleSort}
                              />
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.questions.map((item: QuestionItem, i) => (
                        <Draggable draggableId={`q-${i}`} index={i} key={i}>
                          {(provided: any, snapshot) => (
                            <tr
                              // key={i}
                              className={`fs-14 fw-light ${
                                i + 1 === data.questions.length ? 'mint-bg' : ''
                              }`}
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getStyle(
                                provided.draggableProps.style,
                                snapshot,
                              )}
                            >
                              <td>
                                <i
                                  className='ri-draggable text-muted fs-5  drag-icon'
                                  {...provided.dragHandleProps}
                                ></i>
                              </td>
                              <td>
                                #
                                {i +
                                  1 +
                                  (data.page > 1 ? data.page * 10 - 10 : 0)}
                              </td>
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  id={`packageDescription${item.id}`}
                                  className='text-truncate-two-lines course-description text-muted'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={item.question}
                                />
                              </td>
                              <td>{item.type}</td>
                              <td>
                                <span className='d-flex gap-2'>
                                  <i
                                    onClick={() => {
                                      onEdit(item.groupId)
                                    }}
                                    className='bx bx-edit-alt cursor-pointer'
                                  ></i>
                                  <i
                                    onClick={() => setDeleteId(item.groupId)}
                                    className='bx bx-trash text-danger cursor-pointer'
                                  ></i>
                                </span>
                              </td>
                              {provided.placeholder}
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    </tbody>
                  </Table>
                  <DeleteConfirmation
                    isOpen={!!deleteId}
                    title='Delete question?'
                    message={`Are you sure you want to delete "${data.questions.find(
                      q => q.groupId === deleteId,
                    )?.question}" ?`}
                    onDelete={onDelete}
                    onClose={() => {
                      setDeleteId(null)
                    }}
                  />
                </div>
                <div className='mx-3 mt-3'>
                  <Pagination
                    currentPage={data.page - 1}
                    totalPages={data.pages}
                    totalRecords={data.count}
                    setPage={page => {
                      setQuery(prev => ({ ...prev, page: ++page }))
                    }}
                  />
                </div>
              </NoResultTableWrapper>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
