import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import { CalendarPermissions, CoursesPermissions } from '../lib/types'

export enum QuestionTypes {
  MULTIPLE_CHOICE = 'Multiple Choice',
  IMAGE_CHOICE = 'Image Choice',
  SHORT_ANSWER = 'Short Answer',
  TRUE_FALSE = 'True/False',
}

export enum QuestionableTypes {
  COMPETENCY_ONLY = 'Competency Only',
  KNOWLEDGE_CHECK = 'Knowledge_Check',
  SURVEY = 'Survey',
}
export enum QuestionsSortBy {
  ID = 'id',
  QUESTION = 'question',
  TYPE = 'type',
}

export interface ImageAnswerItem {
  path: string
  size: number
  correct: boolean
  attachmentId: number
}

export interface MultiChoiceAnswerItem {
  answer: string
  correct: boolean
}

export interface BooleanAnswerItem {
  correct: boolean
}

export interface QuestionItem {
  id: number
  type: QuestionTypes
  question: string
  languageId: number
  groupId: string
  answer?: { correct: boolean }
  answers?: ImageAnswerItem[] | MultiChoiceAnswerItem[] | BooleanAnswerItem
}

export namespace GetCourseQuestionsDTO {
  export interface Request extends PaginationRequest {
    entityId: number
    languageId?: number
    questionableType: QuestionableTypes
    sortBy?: QuestionsSortBy
    permission:
      | CalendarPermissions.VIEW_FACILITATOR_COURSE_VIEW
      | CoursesPermissions.ADD_EDIT_COURSE
      | CoursesPermissions.VIEW_TRANSLATIONS
  }

  export interface Response extends PaginatedBaseResponse {
    questions: QuestionItem[]
  }
}

export namespace UpdateCourseQuestionsOrderDTO {
  export interface Request {
    entityId: number
    limit: number
    questionableType: QuestionableTypes
    page: number
    groupIdsOrder: string[]
    permission:
      | CalendarPermissions.VIEW_FACILITATOR_COURSE_VIEW
      | CoursesPermissions.ADD_EDIT_COURSE
  }

  export interface Response {}
}

export interface ImageAnswer {
  id: number
  path: string
  size: string
  name: string
  attachmentId: number
  correct: boolean
}

export interface TextAnswer {
  id: number
  answer: string
  correct: boolean
}

export interface Question {
  question: string
  type?: QuestionTypes
  languageId: number
  answer?: { correct: boolean }
  answers?: Array<ImageAnswer | TextAnswer>
}

export interface IQuestionForm {
  groupId?: string
  type: QuestionTypes
  questions: Question[]
}

interface QuestionRequest {
  question: string
  languageId: number
}
interface BoolChoiceAnswerRequest {
  correct: boolean
}

interface ImageChoiceAnswerRequest extends BoolChoiceAnswerRequest {
  attachmentId: number
  correct: boolean
}

interface MultipleChoiceAnswerRequest extends BoolChoiceAnswerRequest {
  answer: string
  correct: boolean
}

interface ImageChoiceQuestionDto extends QuestionRequest {
  answers: ImageChoiceAnswerRequest[]
}

interface MultipleChoiceQuestionDto extends QuestionRequest {
  answers: MultipleChoiceAnswerRequest[]
}

interface BoolChoiceQuestionDto extends QuestionRequest {
  answer: BoolChoiceAnswerRequest
}

export interface MultipleChoiceRequest {
  questionableType: QuestionableTypes
  type: QuestionTypes.MULTIPLE_CHOICE
  questions: MultipleChoiceQuestionDto[]
}

export interface ShortAnswerRequest {
  questionableType: QuestionableTypes
  type: QuestionTypes.SHORT_ANSWER
  questions: QuestionRequest[]
}

export interface ImageChoiceRequest {
  questionableType: QuestionableTypes
  type: QuestionTypes.IMAGE_CHOICE
  questions: ImageChoiceQuestionDto[]
}

export interface BoolChoiceRequest {
  questionableType: QuestionableTypes
  type: QuestionTypes.TRUE_FALSE
  questions: BoolChoiceQuestionDto[]
}

export namespace PostOnlineCourseQuestionDTO {
  export type Request =
    | ImageChoiceRequest
    | MultipleChoiceRequest
    | BoolChoiceRequest
    | ShortAnswerRequest
  export type Response =
    | ImageChoiceRequest[]
    | MultipleChoiceRequest[]
    | ShortAnswerRequest
    | BoolChoiceRequest[]
}
