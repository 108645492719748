import React from 'react'
import { CardBody } from 'reactstrap'
import SearchImg from '../../assets/images/search.png'

const NoResultTableWrapper = ({
  isFiltering,
  isLoading,
  pages,
  children,
  style,
  className,
  noDataErrorMessage,
  noDataErrorMessageColor,
  noDataErrorMessageImage,
}: {
  isLoading: boolean
  pages: number
  isFiltering?: boolean
  children?: any
  style?: any
  className?: string
  noDataErrorMessage?: string
  noDataErrorMessageColor?: string
  noDataErrorMessageImage?: string
}) => {
  if (isLoading) {
    return (
      <CardBody>
        <div className='d-flex flex-column align-items-center py-5'>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Loading...</p>
        </div>
      </CardBody>
    )
  } else if (isFiltering && pages === 0) {
    return (
      <CardBody>
        <div className='d-flex flex-column align-items-center py-5'>
          <img src={SearchImg} className='mb-5' alt='search-pic' />
          <p style={{ fontSize: 20, fontWeight: 600 }}>
            Sorry, no results found
          </p>
          <p>Please check the spelling or let’s try to find something else.</p>
        </div>
      </CardBody>
    )
  } else if (!isLoading && pages === 0) {
    return (
      <CardBody>
        <div className='d-flex flex-column align-items-center py-5'>
          {noDataErrorMessageImage && (
            <img
              src={noDataErrorMessageImage}
              className='mb-5'
              alt='search-pic'
            />
          )}
          <p
            style={{
              fontSize: 20,
              fontWeight: 600,
              color: noDataErrorMessageColor || 'black',
            }}
          >
            {noDataErrorMessage || 'Sorry, no data available.'}
          </p>
        </div>
      </CardBody>
    )
  } else {
    return (
      <CardBody style={style} className={className}>
        {children}
      </CardBody>
    )
  }
}

export default NoResultTableWrapper
