import React, { useCallback } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  Col,
  Card,
  Row,
  FormFeedback,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  DocumentModalInitialValues,
  DocumentModalSubmitedValues,
} from '../../../sharedTypes'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import { FormikHelpers } from 'formik'
import Select from 'react-select'
import { Spinner } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import handleAcceptedFile from '../../../utils/handleAcceptedFile'

interface Option {
  readonly label: string
  readonly value: string
}

interface DocumentModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (
    values: DocumentModalSubmitedValues,
    formikHelpers: FormikHelpers<DocumentModalSubmitedValues>,
  ) => void
  title: string
  initialValues: DocumentModalInitialValues | null
}

const DocumentModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
}: DocumentModalProps) => {
  const [options, setOptions] = React.useState<Option[]>([
    {
      value: 'Nursing Certificate',
      label: 'Nursing Certificate',
    },
    {
      value: 'CPR Card',
      label: 'CPR Card',
    },
  ])

  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: -1,
      type: '',
      certificateId: undefined,
      organization: undefined,
      validDate: undefined,
      expiryDate: undefined,
      document: undefined,
    },
    validationSchema: Yup.object({
      type: Yup.string().required('Required'),
      certificateId: Yup.string().nullable().required('Required'),
      organization: Yup.string().nullable().required('Required'),
      document: Yup.mixed().nullable().required('Required'),
      validDate: Yup.date().nullable().required('Required'),
      expiryDate: Yup.date().nullable().required('Required'),
    }),
    onSubmit: (values, formik) => {
      onSubmit(
        values as DocumentModalSubmitedValues,
        formik as FormikHelpers<DocumentModalSubmitedValues>,
      )
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <div>
              <label htmlFor='title' className='form-label'>
                Document Type*
              </label>
              <Select
                name='type'
                id='type'
                isSearchable={false}
                onChange={option => {
                  if (option) {
                    form.setFieldValue('type', option.value)
                  }
                }}
                onBlur={form.handleBlur}
                value={options.find(o => o.value === form.values.type)}
                options={options}
                className='select2-container is-invalid'
                classNamePrefix='select2-selection form-select'
              />
              {form.touched.type && form.errors.type ? (
                <FormFeedback type='invalid'>{form.errors.type}</FormFeedback>
              ) : null}
            </div>

            <div>
              <label htmlFor='title' className='form-label'>
                Certificate
              </label>
              {!form.values.document && (
                <div className='cursor-pointer'>
                  <Dropzone
                    maxFiles={1}
                    disabled={form.isSubmitting}
                    multiple={false}
                    accept={{ image: ['.png', '.jpg'] }}
                    onDrop={acceptedFiles => {
                      handleAcceptedFile(
                        acceptedFiles,
                        'document',
                        form.setFieldValue,
                      )
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone dz-clickable'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}
                        >
                          <div className='mt-2'>
                            <i className='display-7 text-muted ri-upload-cloud-2-fill' />
                          </div>
                          <p className='m-0'>
                            Drop file here or click to upload.<br></br>
                            (jpg,png)
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>{' '}
                </div>
              )}
              {form.errors.document ? (
                <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                  {(form.errors.document as string) || ''}
                </p>
              ) : null}
              {form.values.document && (
                <ul className='list-unstyled mb-0' id='dropzone-preview'>
                  <Card
                    className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                    key={'-file'}
                  >
                    <div className='p-2'>
                      <Row className='align-items-center'>
                        <Col>
                          <Link to='#' className='text-muted font-weight-bold'>
                            {form.values.document.name}
                          </Link>
                          <p className='mb-0'>
                            <strong>
                              {form.values.document.formattedSize}
                            </strong>
                          </p>
                        </Col>
                        <Col className='d-flex justify-content-end align-items-end'>
                          <Button
                            color='danger'
                            onClick={() => {
                              form.setFieldValue('document', null)
                            }}
                          >
                            {' '}
                            Delete{' '}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </ul>
              )}
            </div>

            <div className='row g-3'>
              <Col xxl={6}>
                <label htmlFor='certificateId' className='form-label'>
                  License/Certificate ID*
                </label>
                <Input
                  name='certificateId'
                  className='form-control'
                  id='certificateId'
                  disabled={form.isSubmitting}
                  placeholder=''
                  type='text'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.certificateId || ''}
                  invalid={
                    !!(form.touched.certificateId && form.errors.certificateId)
                  }
                />
                {form.touched.certificateId && form.errors.certificateId ? (
                  <FormFeedback type='invalid'>
                    {form.errors.certificateId as string}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xxl={6}>
                <label htmlFor='organization' className='form-label'>
                  Organization*
                </label>
                <Input
                  name='organization'
                  className='form-control'
                  id='organization'
                  disabled={form.isSubmitting}
                  placeholder=''
                  type='text'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.organization || ''}
                  invalid={
                    !!(form.touched.organization && form.errors.organization)
                  }
                />
                {form.touched.organization && form.errors.organization ? (
                  <FormFeedback type='invalid'>
                    {form.errors.organization as string}
                  </FormFeedback>
                ) : null}
              </Col>
            </div>

            <div className='row g-3'>
              <Col xxl={6}>
                <label htmlFor='startDate'>Valid Date*</label>
                <div className='input-group is-invalid'>
                  <Flatpickr
                    className='form-control'
                    id='validDate'
                    name='validDate'
                    placeholder='--.--.----'
                    onChange={option => {
                      form.setFieldValue('validDate', option[0])
                    }}
                    onBlur={form.handleBlur}
                    value={form.values.validDate}
                    options={{
                      dateFormat: 'd-m-Y',
                      formatDate: date => moment(date).format('MM/DD/YYYY'),
                    }}
                  />
                  <span className='input-group-text'>
                    <i className='ri-calendar-event-line'></i>
                  </span>
                </div>
                {form.touched.validDate && form.errors.validDate ? (
                  <FormFeedback type='invalid'>
                    {form.errors.validDate as string}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xxl={6}>
                <label htmlFor='endDate'>Expiry Date*</label>
                <div className='input-group is-invalid'>
                  <Flatpickr
                    className='form-control'
                    id='expiryDate'
                    name='expiryDate'
                    placeholder='--.--.----'
                    onChange={option => {
                      form.setFieldValue('expiryDate', option[0])
                    }}
                    onBlur={form.handleBlur}
                    value={form.values.expiryDate}
                    options={{
                      dateFormat: 'd-m-Y',
                      formatDate: date => moment(date).format('MM/DD/YYYY'),
                    }}
                  />
                  <span className='input-group-text'>
                    <i className='ri-calendar-event-line'></i>
                  </span>
                </div>
                {form.touched.expiryDate && form.errors.expiryDate ? (
                  <FormFeedback type='invalid'>
                    {form.errors.expiryDate as string}
                  </FormFeedback>
                ) : null}
              </Col>
            </div>

            <div className='hstack gap-2 justify-content-end mt-4'>
              <Button
                className='btn-soft-primary'
                onClick={beforeClose}
                active={false}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                color='success'
                type='submit'
                disabled={!form.dirty || form.isSubmitting}
              >
                {form.isSubmitting ? <Spinner size={'sm'} /> : 'Upload'}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default DocumentModal
