import React, { useEffect, useRef, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import Highlighter from 'react-highlight-words'
import { Link } from 'react-router-dom'
import { PackagesGridViewProps } from './types'
import packageIcon from '../../../assets/images/package-icon.png'
import { BadgeColorByStatus } from '../../../helpers/course'
import _ from 'lodash'
import { CoursePackageStatus } from '../../../sharedTypes/models/coursePackage'
import { getCoursePackageStatus } from './PackageTableView'

const PackagesGridView = ({
  packages,
  query,
  handleDuplicate,
  onSelect,
  selectedEntities,
  permissions,
  isMasteredAdmin,
}: Omit<PackagesGridViewProps, 'handleSort'>) => {
  const [showMore, setShowMore] = useState<number | null>(null)

  const refsArray = useRef<{ [key: string]: HTMLSpanElement | null }>({})
  const [overflow, setOverflow] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    const updatedOverflow: { [key: string]: boolean } = {}

    Object.keys(refsArray.current).forEach(packageId => {
      updatedOverflow[packageId] =
        (refsArray.current[packageId]?.scrollHeight ?? 0) > 42
    })
    setOverflow(updatedOverflow)
  }, [refsArray])

  return (
    <Row className='d-flex mt-4 flex-wrap position-relative course-card-container'>
      {packages.map((coursePackage, index) => (
        <Col
          md={4}
          lg={3}
          className='course-card position-relative'
          key={index}
        >
          <Card
            className={showMore === coursePackage.id ? 'expended-card' : ''}
          >
            <CardHeader className='p-0'>
              <span id={`span-info-${coursePackage.id}`}></span>
              {!isMasteredAdmin && (
                <div className='position-absolute select-course'>
                  <Input
                    className='form-check-input cursor-pointer m-0'
                    type='checkbox'
                    name={`selectPackage${coursePackage.id}`}
                    id={`selectPackage${coursePackage.id}`}
                    disabled={coursePackage.isDraft}
                    onChange={() => {
                      onSelect(coursePackage)
                    }}
                    checked={
                      !!_.find(selectedEntities, { id: coursePackage.id })
                    }
                  />
                </div>
              )}
              <div className='delete-action position-absolute d-flex align-items-start'>
                <span
                  className={`badge badge-soft-${
                    BadgeColorByStatus[
                      coursePackage.isDraft
                        ? CoursePackageStatus.DRAFT
                        : CoursePackageStatus.PUBLISHED
                    ]
                  } fs-12 fw-normal text-capitalize`}
                >
                  {getCoursePackageStatus(coursePackage)}
                </span>
              </div>
              <Link
                className='cursor-pointer'
                to={`/courses/packages/manage/${coursePackage.id}`}
                target='_blank'
              >
                <div className='course-cover d-flex align-items-center justify-content-center'>
                  <img
                    className='card-img-top img-fluid rounded'
                    src={packageIcon}
                    alt='NO IMAGE'
                    style={{
                      aspectRatio: 1,
                      objectFit: 'cover',
                      width: 60,
                      height: 60,
                    }}
                  />
                </div>
              </Link>
            </CardHeader>
            <CardBody className='d-flex p-0'>
              <Col
                className='d-flex flex-column align-items-start gap-2 p-3'
                style={{ minHeight: 159 }}
              >
                <div className='d-flex justify-content-between w-100'>
                  <div className='d-flex gap-2 flex-wrap'>
                    <Link
                      className='text-muted cursor-pointer'
                      to={`/courses/packages/manage/${coursePackage.id}`}
                      target='_blank'
                    >
                      <span className='text-muted fs-14'>
                        <Highlighter
                          highlightClassName='text-highlight'
                          searchWords={[query.key || '']}
                          highlightTag={'span'}
                          autoEscape={true}
                          textToHighlight={coursePackage.code}
                        />
                      </span>
                    </Link>
                    <span className='text-muted'>|</span>
                    <span className='fs-14 text-muted'>
                      {coursePackage.coursesCount} Course
                      {(coursePackage.coursesCount ?? 0) > 1 ? 's' : ''}
                    </span>
                  </div>
                  {permissions.clone && (
                    <div className='d-flex align-items-center gap-2'>
                      <UncontrolledTooltip
                        placement='top'
                        target={`duplicatePackage${coursePackage.id}`}
                      >
                        Duplicate
                      </UncontrolledTooltip>
                      <i
                        className='bx bx-duplicate cursor-pointer text-muted'
                        id={`duplicatePackage${coursePackage.id}`}
                        onClick={() => {
                          handleDuplicate(coursePackage)
                        }}
                      ></i>
                    </div>
                  )}
                </div>

                <Link
                  className='fs-14 text-body fw-medium cursor-pointer'
                  to={`/courses/packages/manage/${coursePackage.id}`}
                  target='_blank'
                >
                  <Highlighter
                    highlightClassName='text-highlight'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={coursePackage.name}
                  />
                </Link>

                <span
                  className={`text-muted fs-14 course-description
                ${
                  showMore !== coursePackage.id ? 'text-truncate-two-lines' : ''
                }`}
                >
                  <span
                    ref={ref => (refsArray.current[coursePackage.id] = ref)}
                    style={{ display: 'block' }}
                    className={`text-muted fs-14'}`}
                  >
                    <Highlighter
                      highlightClassName='text-highlight'
                      searchWords={[query.key || '']}
                      highlightTag={'span'}
                      autoEscape={true}
                      textToHighlight={coursePackage.description ?? ''}
                    />
                  </span>
                </span>
                {showMore !== coursePackage.id &&
                  overflow[coursePackage.id] && (
                    <span
                      className='text-primary fw-medium cursor-pointer'
                      onClick={() => {
                        setShowMore(coursePackage.id)
                      }}
                    >
                      Show More
                      <i className='ri-arrow-down-s-line ms-1'></i>
                    </span>
                  )}
                {showMore === coursePackage.id && (
                  <>
                    <span
                      className='text-primary fw-medium cursor-pointer'
                      onClick={() => {
                        setShowMore(null)
                      }}
                    >
                      Show Less
                      <i className='ri-arrow-up-s-line ms-1'></i>
                    </span>
                  </>
                )}
              </Col>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default PackagesGridView
