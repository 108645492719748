import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import Highlighter from 'react-highlight-words'
import _ from 'lodash'
import {
  GetScheduledCategoriesDTO,
  ScheduledCategoryItem,
  ScheduledCategoriesSortBy,
} from '../../../sharedTypes'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { OrderType } from '../../../sharedTypes'
import { Pagination } from '../../../Components/Common/Pagination'
import { successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import {
  getScheduledCategories,
  postScheduledCategory,
  deleteScheduledCategory,
} from '../../../helpers/api_helper'
import { FormikHelpers } from 'formik'
import { errorToastOptions } from '../../../helpers/toast_helper'
import {
  ScheduledCategoryInitialValues,
  ScheduledCategoryModalSubmitedValues,
} from '../../../sharedTypes'
import ScheduledCategoryModal from './ScheduledCategoryModal'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import { useNavigate } from 'react-router-dom'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'

const Columns = [
  {
    name: 'Category',
    sortBy: ScheduledCategoriesSortBy.NAME,
    style: { width: '31%' },
  },
  {
    name: 'Courses',
    sortBy: ScheduledCategoriesSortBy.COURSES_COUNT,
    style: { width: '31%' },
  },
  {
    name: 'Description',
    style: { width: '31%' },
  },
  {
    name: 'Actions',
    style: { width: '7%' },
  },
]

const ScheduledCategories = () => {
  document.title = 'COURSE ASSIGNMENT BY POSITION| Mastered - Admin & Dashboard'
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetScheduledCategoriesDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    scheduled_categories: [],
  })

  const [query, setQuery] = useState<GetScheduledCategoriesDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    sortBy: ScheduledCategoriesSortBy.NAME,
    orderBy: OrderType.ASC,
  })
  const [globalSearch, setSearch] = useState<string>('')
  const [createModal, setCreateModal] =
    useState<ScheduledCategoryInitialValues | null>(null)

  const [deleteId, setDeleteId] = useState<null | number>()

  const handleSort = useCallback((column: ScheduledCategoriesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onDelete = useCallback(async () => {
    try {
      await deleteScheduledCategory(deleteId as number)
      setDeleteId(null)
      toast('Successfully deleted', successToastOptions)
      setQuery(prev => ({ ...prev, page: 1 }))
    } catch (e) {}
  }, [data.page, deleteId])

  const onCreate = useCallback(
    async (
      values: ScheduledCategoryModalSubmitedValues,
      form: FormikHelpers<ScheduledCategoryModalSubmitedValues>,
    ) => {
      try {
        await postScheduledCategory(values)
        form.resetForm()
        toast('Successfully added', successToastOptions)
        setQuery(prev => ({ ...prev, page: 1 }))
        setCreateModal(null)
      } catch (e: any) {
        toast(e.response.data.message, errorToastOptions)
      }
    },
    [data.page],
  )

  useEffect(() => {
    setIsLoading(true)
    getScheduledCategories(query)
      .then(response => {
        setData(response)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  return (
    <React.Fragment>
      <div className='page-content'>
        <DeleteConfirmation
          isOpen={!!deleteId}
          title='Delete Category'
          message={`Are you sure you want to delete the "${data.scheduled_categories.find(
            s => s.id === deleteId,
          )?.name}" Category ?`}
          onDelete={onDelete}
          onClose={() => {
            setDeleteId(null)
          }}
        />
        <ScheduledCategoryModal
          isOpen={!!createModal}
          title='Add Category'
          initialValues={{ name: '', description: '' }}
          onClose={() => {
            setCreateModal(null)
          }}
          onSubmit={onCreate}
        />
        <Container fluid>
          <BreadCrumb
            title='Scheduled Course Categories'
            items={[
              {
                title: 'Courses',
                linkTo: '/',
              },
              {
                title: 'Scheduled Course Categories',
                active: true,
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                        setSearch(key)
                      }}
                      value={globalSearch}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        color={'primary'}
                        onClick={() => {
                          setCreateModal({
                            name: '',
                            description: '',
                          })
                        }}
                        className='btn btn-primary align-middle'
                      >
                        <i className='ri-add-line me-1 fs-16'></i>Add Category
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  {' '}
                  <div className='table-card'>
                    <Table className='align-middle table-nowrap mb-0'>
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {Columns.map(column => (
                            <th
                              scope='col'
                              className='fw-light align-middle'
                              style={column.style}
                              key={column.name}
                            >
                              {column.name}
                              {!!column.sortBy && (
                                <ColumnSortIcon<ScheduledCategoriesSortBy>
                                  sortOrder={query.orderBy}
                                  sortedColumn={query.sortBy}
                                  column={column.sortBy}
                                  handleSort={handleSort}
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.scheduled_categories.map(
                          (item: ScheduledCategoryItem, i) => (
                            <tr key={i} className='fs-14 fw-light'>
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[globalSearch || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={item.name}
                                />
                              </td>
                              <td>{item.coursesCount}</td>
                              <td>{item.description}</td>
                              <td>
                                <span className='d-flex gap-2'>
                                  <i
                                    onClick={() => {
                                      navigate(
                                        '/courses/scheduled-categories/' +
                                          item.id,
                                      )
                                    }}
                                    className='bx bx-edit-alt cursor-pointer'
                                  ></i>
                                  <i
                                    onClick={() => {
                                      if (!item.coursesCount) {
                                        setDeleteId(item.id)
                                      }
                                    }}
                                    className={`bx bx-trash text-${
                                      item.coursesCount
                                        ? 'muted'
                                        : 'danger cursor-pointer'
                                    }`}
                                  ></i>
                                </span>
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                    <div className='mx-3 mt-3'>
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ScheduledCategories
