import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import Highlighter from 'react-highlight-words'
import _ from 'lodash'
import {
  SupportPermissions,
  GetSupportCategoriesDTO,
  TSupportCategory,
} from '../../../sharedTypes'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { OrderType, ResourceType } from '../../../sharedTypes'
import { Pagination } from '../../../Components/Common/Pagination'
import { successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import {
  getSupportCategories,
  postSupportCategory,
  putSupportCategory,
  deleteSupportCategory,
  getSupportCategory,
} from '../../../helpers/api_helper'
import { FormikHelpers } from 'formik'
import FaqCategoriesModal from './FaqCategoriesModal'
import { errorToastOptions } from '../../../helpers/toast_helper'
import { ResourceCategoryModalValues } from '../../../sharedTypes'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { usePermissions } from '../../../hooks/usePermissions'

enum CategoriesSortBy {
  NAME = 'name',
  TUTORIAL_COUNT = 'tutorialCount',
  FAQ_COUNT = 'faqCount',
}

const Columns = [
  {
    name: 'Category',
    sortBy: CategoriesSortBy.NAME,
    style: { width: '20%' },
  },
  {
    name: 'Icon',
    style: { width: '10%' },
  },
  {
    name: 'Number of Tutorials',
    sortBy: CategoriesSortBy.TUTORIAL_COUNT,
    style: { width: '30%', textAlign: 'center' } as any,
  },
  {
    name: 'Actions',
    style: { width: '10%', textAlign: 'right' },
  },
]

const Categories = () => {
  document.title = 'Support Categories | Mastered - Admin & Dashboard'
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetSupportCategoriesDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    categories: [],
  })

  const [query, setQuery] = useState<GetSupportCategoriesDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    sortBy: CategoriesSortBy.NAME,
    orderBy: OrderType.ASC,
  })
  const [modal, setModal] = useState<ResourceCategoryModalValues | null>(null)

  const [deleteId, setDeleteId] = useState<null | number>()

  const handleSort = useCallback((column: CategoriesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const hasPermissionToAdd = usePermissions(
    SupportPermissions.ADD_SUPPORT_CATEGORIES,
  )
  const hasPermissionToEdit = usePermissions(
    SupportPermissions.EDIT_SUPPORT_CATEGORIES,
  )
  const hasPermissionToDelete = usePermissions(
    SupportPermissions.DELETE_SUPPORT_CATEGORIES,
  )

  const onDelete = useCallback(async () => {
    try {
      await deleteSupportCategory(deleteId as number)
      setDeleteId(null)
      toast('Successfully deleted', successToastOptions)
      setQuery(prev => ({ ...prev, page: 1 }))
    } catch (e) {}
  }, [data.page, deleteId])

  const beforeEdit = useCallback(async (id: number) => {
    const category = await getSupportCategory(id)
    setModal({
      id: category.id,
      name: category.name,
      icon: category.icon
        ? {
            size: category.icon.size,
            preview: category.icon.url,
            name: category.icon.originalname,
          }
        : null,
      faq: _.map(
        _.filter(category.resources, {
          type: ResourceType.FAQ,
        }),
        'id',
      ),
      tutorials: _.map(
        _.filter(category.resources, {
          type: ResourceType.TUTORIAL,
        }),
        'id',
      ),
    })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getSupportCategories(query)
      .then(response => {
        setData(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [query])

  const getColumns = useCallback(() => {
    if (!hasPermissionToEdit && !hasPermissionToDelete) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [])

  return (
    <React.Fragment>
      <div className='page-content'>
        <FaqCategoriesModal
          isOpen={!!modal}
          initialValues={modal}
          onClose={submited => {
            if (submited) {
              setQuery(prev => ({ ...prev, page: 1 }))
            }
            setModal(null)
          }}
        />
        <DeleteConfirmation
          isOpen={!!deleteId}
          title='Delete Category'
          message='Are you sure you want to delete the "Support" Category ?'
          onDelete={onDelete}
          onClose={() => {
            setDeleteId(null)
          }}
        />

        <Container fluid>
          <BreadCrumb
            title='SUPPORT CATEGORIES'
            items={[
              {
                title: 'Support',
                linkTo: '/',
              },
              {
                title: 'Categories',
                active: true,
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key || ''}
                    />
                    {hasPermissionToAdd && (
                      <div className='d-flex flex-shrink-0 gap-3'>
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setModal({
                              id: -1,
                              name: '',
                              faq: [],
                              tutorials: [],
                              icon: null,
                            })
                          }}
                          className='btn btn-primary align-middle'
                        >
                          <i className='ri-edit-box-line me-1 fs-16'></i>Add FAQ
                          Category
                        </Button>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <Table className='align-middle table-nowrap mb-0'>
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {getColumns().map(column => (
                            <th
                              scope='col'
                              className='align-middle'
                              style={column.style}
                              key={column.name}
                            >
                              {column.name}
                              {!!column.sortBy && (
                                <ColumnSortIcon<CategoriesSortBy>
                                  sortOrder={query.orderBy}
                                  sortedColumn={query.sortBy}
                                  column={column.sortBy}
                                  handleSort={handleSort}
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.categories.map((item: TSupportCategory, i) => (
                          <tr key={i} className='fs-14 fw-light'>
                            <td>
                              <Highlighter
                                highlightClassName='text-highlight'
                                searchWords={[query.key || '']}
                                highlightTag={'span'}
                                autoEscape={true}
                                textToHighlight={item.name}
                              />
                            </td>
                            <td>
                              {' '}
                              {item?.icon?.url ? (
                                <img
                                  style={{ maxWidth: 20 }}
                                  src={item?.icon.url}
                                />
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className='text-center'>
                              {item.tutorialCount}
                            </td>
                            {(hasPermissionToEdit || hasPermissionToDelete) && (
                              <td className='text-center'>
                                <span className='d-flex gap-2 justify-content-end'>
                                  {hasPermissionToEdit && (
                                    <i
                                      onClick={() => beforeEdit(item.id)}
                                      className='bx bx-edit-alt cursor-pointer'
                                    ></i>
                                  )}
                                  {hasPermissionToDelete && (
                                    <i
                                      onClick={() => setDeleteId(item.id)}
                                      className='bx bx-trash text-danger cursor-pointer	'
                                    ></i>
                                  )}
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className='mx-3 mt-3'>
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Categories
