import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'

import { TFacilityStatus } from '../../../sharedTypes'

interface FilterTabsProps {
  navTab?: TFacilityStatus
  navToggle: (tab?: TFacilityStatus) => void
}
const tabs = [
  { label: 'All', value: undefined },
  { label: 'Active', value: TFacilityStatus.ACTIVE },
  { label: 'Inactive', value: TFacilityStatus.INACTIVE },
]

const FilterTabs = ({ navTab, navToggle }: FilterTabsProps) => {
  return (
    <Nav
      className='nav-customs-bordered'
      style={{ maxHeight: 45, minWidth: 217 }}
    >
      {tabs.map(tab => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
