import React, { useEffect, useState, useCallback } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Button,
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import ScheduledCategoryBaseForm from './ScheduledCategoryBaseForm'
import {
  getScheduledCategory,
  getScheduledCourse,
  putScheduledCategory,
} from '../../../helpers/api_helper'
import { useParams } from 'react-router-dom'
import {
  OrderType,
  ScheduledCoursesSortBy,
  GetScheduledCoursesDTO,
} from '../../../sharedTypes'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import UserCell from '../../../Components/Common/UserItemCell'
import _ from 'lodash'
import { BadgeColorByStatus } from '../../../helpers/course'
import { Pagination } from '../../../Components/Common/Pagination'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
const Columns = [
  {
    name: 'ID',
    sortBy: ScheduledCoursesSortBy.ID,
    style: { width: 120 },
  },
  {
    name: 'Course',
    sortBy: ScheduledCoursesSortBy.NAME,
  },
  {
    name: 'Status',
    sortBy: ScheduledCoursesSortBy.STATUS,
  },
  {
    name: 'Instructor',
    sortBy: ScheduledCoursesSortBy.INSTRUCTOR,
  },
]

const EditScheduledCategory = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [query, setQuery] = useState<GetScheduledCoursesDTO.Request>({
    page: 1,
    limit: 10,
    key: undefined,
    categoryIds: [Number(id)],
    sortBy: ScheduledCoursesSortBy.ID,
    orderBy: OrderType.ASC,
  })

  const [data, setData] = useState<GetScheduledCoursesDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    courses: [],
  })

  const [initialValues, setInitialValues] = useState({
    id: -1,
    name: '',
    description: '',
  })

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
    }),
    onSubmit: async ({ name, description }, formik) => {
      try {
        await putScheduledCategory(Number(id), { name, description })
        toast('Successfully updated', successToastOptions)
      } catch (e) {}
    },
  })

  const handleSort = useCallback(
    (column: ScheduledCoursesSortBy) => {
      setQuery(prev => ({
        ...prev,
        sortBy: column,
        orderBy:
          prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
      }))
    },
    [data],
  )

  useEffect(() => {
    getScheduledCategory(Number(id)).then(cat => {
      setInitialValues(cat)
    })
  }, [id])

  useEffect(() => {
    setIsLoading(true)
  }, [query])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Edit Category'
            items={[
              {
                title: 'Courses',
                linkTo: '/',
              },
              {
                title: 'Scheduled Course Categories',
                linkTo: '/courses/scheduled-categories',
              },
              {
                title: 'Edit Category',
                active: true,
              },
            ]}
          />
          <Row>
            <Col xxl='5'>
              <Card>
                <CardHeader>General Information</CardHeader>
                <div className='p-3'>
                  <ScheduledCategoryBaseForm form={form} />
                </div>
              </Card>
            </Col>
            <Col xxl='7'>
              <Card>
                <CardBody>
                  <div className='d-flex justify-content-between pb-3 align-items-cente'>
                    <span style={{ lineHeight: '32px' }}>
                      Courses ({data.count})
                    </span>
                    <div>
                      {' '}
                      <Button
                        color={'primary'}
                        size='sm'
                        onClick={() => {
                          navigate('/courses/scheduled-courses/manage/new', {
                            replace: true,
                          })
                        }}
                        className='btn btn-primary align-middle'
                      >
                        <i className='ri-add-line me-1 fs-16'></i>Add Course
                      </Button>
                    </div>
                  </div>

                  <NoResultTableWrapper
                    isLoading={isLoading}
                    isFiltering={!!query.key}
                    pages={data.pages}
                  >
                    <div className='table-card mt-0'>
                      <Table className='align-middle table-nowrap mb-0'>
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {Columns.map(column => (
                              <th
                                scope='col'
                                className='fw-light align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                {column.name}
                                {!!column.sortBy && (
                                  <ColumnSortIcon<ScheduledCoursesSortBy>
                                    sortOrder={query.orderBy}
                                    sortedColumn={query.sortBy}
                                    column={column.sortBy}
                                    handleSort={handleSort}
                                  />
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.courses.map((course, i) => (
                            <tr key={i} className='fs-14 fw-light'>
                              <th scope='row'>
                                <Link
                                  to={
                                    '/courses/scheduled-courses/manage/' +
                                    course.id
                                  }
                                  className='fw-normal'
                                >
                                  {course.id.toString()}
                                </Link>
                              </th>
                              <td>{course.name}</td>
                              <td>
                                <span
                                  className={`badge badge-soft-${
                                    BadgeColorByStatus[course.status]
                                  } fs-12 fw-normal text-capitalize`}
                                >
                                  {course.status}
                                </span>
                              </td>
                              <UserCell
                                user={_.get(course, 'instructors[0]')}
                                globalSearch={''}
                              />
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className='mx-3 mt-3'>
                        <Pagination
                          currentPage={data.page - 1}
                          totalPages={data.pages}
                          totalRecords={data.count}
                          setPage={(page: number) => {
                            setQuery(prev => ({ ...prev, page: ++page }))
                          }}
                        />
                      </div>
                    </div>
                  </NoResultTableWrapper>
                </CardBody>
              </Card>
              <div className='d-flex justify-content-end'>
                <Button
                  color={'primary'}
                  disabled={!form.dirty || !form.isValid}
                  size='md'
                  onClick={() => {
                    form.handleSubmit()
                  }}
                  className='btn btn-success align-middle'
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditScheduledCategory
