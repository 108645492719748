import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useMemo,
} from 'react'
import classnames from 'classnames'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  TabContent,
  TabPane,
  NavLink,
  CardHeader,
  Nav,
  NavItem,
  Input,
  Label,
} from 'reactstrap'
import {
  getMe,
  forgotPassword,
  getMyNotificationSettings,
  putMyNotificationSettings,
} from '../../../helpers/api_helper'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import AdminModal from '../../../Components/Modals/AdminModal'
import { updateMe } from '../../../helpers/api_helper'
import { CREDENTIALS_TYPE, PatchAdminsDTO } from '../../../sharedTypes'
import { successToastOptions, handleError } from '../../../helpers/toast_helper'

import { TUser, AdminNotificationSettings } from '../../../sharedTypes'
import DocumentsTable from './DocumentsTable'
import { UncontrolledAlert } from 'reactstrap'
import EditRequestModal from './EditRequestModal'
import ChangePasswordModal from './ChangePasswordModal'
import { getUserInitials } from '../../../helpers/user'
import _ from 'lodash'

interface NotificationPreferences {
  label: string
  value: keyof AdminNotificationSettings
}
const notificationPreferences: NotificationPreferences[] = [
  {
    label: 'User is 4 weeks overdue on a course',
    value: 'userCourseOverdue',
  },
  {
    label: 'Course feedback received',
    value: 'courseFeedbackReceived',
  },
  {
    label: 'Survey response received',
    value: 'surveyResponseReceived',
  },
  {
    label: 'User completed a course',
    value: 'userCourseCompleted',
  },
  {
    label: 'New course added',
    value: 'courseCreated',
  },
  {
    label: 'User failed all test attempts',
    value: 'userCourseRetaking',
  },
  {
    label: 'User registered with no courses assigned',
    value: 'userOnboardedWithoutCourses',
  },
  {
    label: 'User did not attend a live course',
    value: 'userScheduleMissed',
  },
]

const UserProfile = () => {
  document.title = 'Profile | Mastered - Admin & Dashboard'
  const [showAlert, setShowAlert] = useState(false)
  const [changePasswordModal, setChangePasswordModal] = useState<boolean>(false)
  const [isSubmitting, setIsSubmiting] = useState(false)
  const [data, setData] = useState<TUser>()
  const [settings, setSettings] = useState<AdminNotificationSettings>({
    userCourseOverdue: true,
    courseFeedbackReceived: false,
    surveyResponseReceived: false,
    userCourseCompleted: true,
    courseCreated: true,
    userCourseRetaking: true,
    userOnboardedWithoutCourses: true,
    userScheduleMissed: true,
  })
  const [activeTab, setActiveTab] = useState('1')
  const [showEditModalByType, setShowEditModalByType] = useState<
    'editRequest' | 'edit' | null
  >(null)

  const tabChange = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    getMe().then(setData)
    getMyNotificationSettings().then(setSettings)
  }, [])

  const emails = useMemo(() => {
    return (data?.credentials || []).filter(
      i => i.credentialType === CREDENTIALS_TYPE.EMAIL,
    )
  }, [data])

  const phones = useMemo(() => {
    return (data?.credentials || []).filter(
      i => i.credentialType === CREDENTIALS_TYPE.PHONE,
    )
  }, [data])

  const onSubmitAdminModal = useCallback((data: PatchAdminsDTO.Request) => {
    return updateMe(data)
      .then(res => {
        setData(res)
        setShowEditModalByType(null)
        toast('Profile was successfully updated', successToastOptions)
      })
      .catch(handleError)
  }, [])

  const onSettingChange = useCallback(
    (prop: keyof AdminNotificationSettings, value: boolean) => {
      setSettings(prev => ({ ...prev, [prop]: value }))
    },
    [],
  )

  const onUpdateSettings = useCallback(async () => {
    await putMyNotificationSettings(settings)
      .then(() => {
        toast(
          'Notification Preferences were successfully updated',
          successToastOptions,
        )
      })
      .catch(handleError)
  }, [settings])

  const getFacilityOrGroupName = useCallback(
    (data: TUser | undefined) => {
      if (data) {
        if (data.facilityId) {
          return data.facility?.name
        }

        if (data.groupId) {
          return data.group?.name
        }

        return data.company?.name
      }
    },
    [data],
  )

  return (
    <Fragment>
      <EditRequestModal
        onClose={() => {
          setShowEditModalByType(null)
        }}
        isOpen={showEditModalByType === 'editRequest'}
      />
      {showEditModalByType === 'edit' && (
        <AdminModal
          onClose={() => {
            setShowEditModalByType(null)
          }}
          isOpen={showEditModalByType === 'edit'}
          onSubmit={onSubmitAdminModal}
          title={'Edit'}
          editAdmin={data}
        />
      )}

      <div className='page-content'>
        <ChangePasswordModal
          isOpen={changePasswordModal}
          onClose={() => {
            setChangePasswordModal(false)
          }}
        />
        <Container fluid>
          <div className='profile-foreground position-relative mx-n4 mt-n4'>
            <div className='profile-wid-bg'>
              {/* <img src="https://marmelab.com/images/blog/ascii-art-converter/homer.png" alt="" className="profile-wid-img" /> */}
            </div>
          </div>
          <div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
            <Row className='g-4'>
              <div className='col-auto'>
                <div className='avatar-lg'>
                  {data?.photo ? (
                    <img
                      src={data.photo}
                      alt='user-img'
                      className='img-thumbnail rounded-circle w-100 h-100 object-fit-contain'
                    />
                  ) : (
                    <div className='avatar-title rounded-circle bg-white text-primary fs-3'>
                      {data && getUserInitials(data)}
                    </div>
                  )}
                </div>
              </div>

              <Col>
                <div className='p-2'>
                  <h3 className='text-white mb-1'>
                    {data?.firstName || '---'} {data?.lastName || '---'}
                  </h3>
                  <p className='text-white-75'>{data?.rawPosition}</p>
                  <div className='hstack text-white-50 gap-1'>
                    <div className='me-2'>
                      <i className='ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle'></i>
                      {data?.state && `${data?.state},`} {data?.country}
                    </div>
                    <div>
                      <i className='ri-building-line me-1 text-white-75 fs-16 align-middle'></i>
                      {getFacilityOrGroupName(data)}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xxl={4}>
              <Card>
                <CardBody>
                  <h5 className='card-title mb-3 d-flex justify-content-between'>
                    <span className='text-muted'>Your Info</span>
                    <span
                      onClick={() => {
                        setShowEditModalByType(
                          data?.employeeId ? 'editRequest' : 'edit',
                        )
                      }}
                      className='badge badge-soft-primary fs-12 fw-normal p-2 cursor-pointer'
                    >
                      <i className='ri-edit-box-line me-1 fs-12'></i>
                      {data?.employeeId ? 'Request Edit' : 'Edit'}
                    </span>
                  </h5>
                  <p className='d-flex justify-content-between'>
                    <span className='ps-0'>Full Name :</span>
                    <span className='text-muted'>
                      {data?.firstName || '-'} {data?.lastName || '-'}
                    </span>
                  </p>
                  <p className='d-flex justify-content-between'>
                    <span className='ps-0'>Position :</span>
                    <span className='text-muted'>
                      {data?.position?.name || data?.rawPosition || '-'}
                    </span>
                  </p>
                  <p className='d-flex justify-content-between'>
                    <span className='ps-0'>Mobile :</span>
                    {_.isEmpty(phones) ? (
                      <span className='text-muted'>-</span>
                    ) : (
                      phones.map(phone => (
                        <span className='text-muted' key={phone.id}>
                          {phone.credential || '-'}
                        </span>
                      ))
                    )}
                  </p>
                  <p className='d-flex justify-content-between'>
                    <span className='ps-0'>E-mail :</span>
                    <div className='d-flex flex-column align-items-end'>
                      {_.isEmpty(emails) ? (
                        <span className='text-muted'>-</span>
                      ) : (
                        emails.map(email => (
                          <span className='text-muted' key={email.id}>
                            {email.credential || '-'}
                          </span>
                        ))
                      )}
                    </div>
                  </p>
                  <p className='d-flex justify-content-between'>
                    <span className='ps-0'>Location :</span>
                    <span className='text-muted'>
                      {data?.state && `${data?.state},`} {data?.country}
                    </span>
                  </p>
                  <p className='d-flex justify-content-between'>
                    <span className='ps-0'>Facility :</span>
                    <span className='text-muted'>
                      {data?.facility?.name || '-'}
                    </span>
                  </p>
                  <p className='d-flex justify-content-between'>
                    <span className='ps-0'>Hire Date:</span>
                    <span className='text-muted'>
                      {' '}
                      {data?.hireDate
                        ? moment(data.hireDate).format('MM/DD/YYYY')
                        : '-'}
                    </span>
                  </p>

                  <UncontrolledAlert
                    color='primary'
                    isOpen={showAlert}
                    toggle={() => {
                      setShowAlert(false)
                    }}
                    className='alert-left-border'
                  >
                    <i className='ri-user-smile-line me-3 align-middle fs-16'></i>
                    We’ve sent you a link to your email
                  </UncontrolledAlert>
                  <Button
                    color='primary'
                    disabled={showAlert || isSubmitting}
                    onClick={() => {
                      setChangePasswordModal(true)
                    }}
                    className='w-100 d-flex justify-content-center align-items-center gap-3'
                    outline
                  >
                    <span>Change Password</span>
                    {isSubmitting ? <Spinner size={'sm'} /> : ''}
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={8}>
              <Card>
                {/* <CardHeader>
                  <Nav
                    className='nav-tabs-custom rounded card-header-tabs border-bottom-0'
                    role='tablist'
                  >
                    <NavItem>
                      <NavLink
                        to='#'
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          tabChange('1')
                        }}
                      >
                        Notifications
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to='#'
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          tabChange('2')
                        }}
                        type='button'
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader> */}
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId='1'>
                      <h5 className='fw-light mb-4 text-muted'>
                        Notification Preferences
                      </h5>

                      <div className='row g-3'>
                        {notificationPreferences.map(preference => (
                          <Col xxl={6} key={preference.value}>
                            <div className='form-check form-switch mb-2'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id={`switch${preference.value}`}
                                checked={settings[preference.value]}
                                onChange={e => {
                                  onSettingChange(
                                    preference.value,
                                    e.target.checked,
                                  )
                                }}
                              />
                              <Label
                                className='form-check-label'
                                chekc
                                htmlFor={`switch${preference.value}`}
                              >
                                {preference.label}
                              </Label>
                            </div>
                          </Col>
                        ))}
                        <Col xxl='12'>
                          <div
                            className='d-flex justify-content-end pt-3'
                            style={{ borderTop: '1px solid #e9ebec' }}
                          >
                            <Button
                              color='success'
                              type='submit'
                              onClick={onUpdateSettings}
                            >
                              Update
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </TabPane>
                    <TabPane tabId='2'>
                      <DocumentsTable />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default UserProfile
