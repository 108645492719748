import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface IGoTo {
  direction?: 'next' | 'prev' | string | undefined
  discard?: boolean
}

export const useShouldGoTo = (props: {
  goNext: () => void
  onBack: () => void
}) => {
  const navigate = useNavigate()
  const [shouldGoTo, setShouldGoTo] = useState<IGoTo>()

  const onSuccess = useCallback(() => {
    if (shouldGoTo?.direction === 'next') {
      props.goNext()
    } else if (shouldGoTo?.direction === 'prev') {
      props.onBack()
    } else if (shouldGoTo?.direction) {
      navigate(shouldGoTo?.direction as string)
    }
  }, [shouldGoTo])

  return { onSuccess, setShouldGoTo }
}
