import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap'
import moment from 'moment/moment'
import Flatpickr from 'react-flatpickr'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import Select from 'react-select'
import { toast } from 'react-toastify'
import _ from 'lodash'
import BreadCrumb, {
  BreadCrumbProps,
} from '../../../Components/Common/BreadCrumb'
import {
  SCHEDULED_COURSE_FORMATS,
  notificationOption,
  TInstructor,
  COURSE_TYPES,
  PostScheduledCoursesDTO,
  TScheduledCourse,
  CourseTypesOptions,
} from '../../../sharedTypes'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { defaultNotifications } from '../../../Components/Modals/EventModal'
import InstructorModal from '../../../Components/Modals/InstructorModal'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import {
  getInstructors,
  getScheduledCategories,
  getScheduledCourseById,
  postScheduledCourse,
  updateScheduledCourseById,
} from '../../../helpers/api_helper'
import { getUserDisplayName } from '../../../helpers/user'
import {
  handleError,
  successToastOptions,
  errorToastOptions,
} from '../../../helpers/toast_helper'

interface IForm {
  isDraft: boolean
  //section 1
  name: string
  type?: { value: COURSE_TYPES; label: COURSE_TYPES }
  category?: { value: number; label: string }
  description?: string
  date?: Date
  time?: Date
  endTime?: Date
  instructor?: {
    value: number
    label: string
  }
  //section 2
  format: SCHEDULED_COURSE_FORMATS
  location?: string
  registrationDeadline?: Date
  attendees?: []
  notificationsEnabled: boolean
  notifications: typeof defaultNotifications
}

interface ManageCourseProps extends WithRouterProps {
  fromCalendar?: boolean
}

const ManageCourse = ({ router }: ManageCourseProps) => {
  document.title = 'Manage Course | Mastered - Admin & Dashboard'

  const [showCreateInstructorModal, setCreateInstructorModal] =
    useState<boolean>(false)
  const [isNew, setIsNew] = useState(false)
  const [editCourse, setEditCourse] = useState<TScheduledCourse | null>(null)

  useEffect(() => {
    if (router.params.eventId === 'new') {
      setIsNew(true)
    } else if (
      !router.params.eventId ||
      Number.isNaN(+router.params.eventId) ||
      typeof +router.params.eventId !== 'number'
    ) {
      return router.navigate('/404')
    } else {
      getScheduledCourseById(+router.params.eventId)
        .then(({ data }) => {
          setEditCourse(data)
        })
        .catch(() => {
          router.navigate('/404')
        })
    }
  }, [router.params.eventId])

  const onSubmit = (formValues: IForm, formikHelpers: FormikHelpers<IForm>) => {
    const {
      isDraft,
      name,
      date,
      time,
      endTime,
      type,
      category,
      instructor,
      format,
      location,
      registrationDeadline,
      description,
      attendees,
      notifications,
      notificationsEnabled,
    } = formValues
    const startDate = moment(date)
      .set('hours', moment(time).hours())
      .set('minute', moment(time).minutes())

    const endDate = moment(startDate)
      .set('hours', moment(endTime).hours())
      .set('minute', moment(endTime).minutes())

    if (!type || !category || !instructor) {
      formikHelpers.setSubmitting(false)
      return
    }

    const payload: PostScheduledCoursesDTO.Request = {
      name,
      isDraft,
      type: type.value,
      categoryId: category.value,
      instructorId: instructor.value,
      startDate: date ? startDate.toISOString() : undefined,
      startTime: time ? moment(time).format('h:mm A') : undefined,
      endTime: endTime ? moment(endTime).format('h:mm A') : undefined,
      endDate: date && endTime ? endDate.toISOString() : undefined,
      format,
      location,
      registrationDeadline: registrationDeadline?.toISOString(),
      description,
      notificationsEnabled,
    }
    if (notificationsEnabled) {
      if (_.isEmpty(notifications)) {
        toast('Error - Please add at least one notification', errorToastOptions)
        return Promise.resolve()
      }
      payload.notifications = notifications
    } else {
      payload.notifications = null
    }

    formikHelpers.setSubmitting(true)

    const courseId = router.params.eventId

    const action =
      courseId && !isNew
        ? updateScheduledCourseById(+courseId, payload)
        : postScheduledCourse(payload)

    return action
      .then(() => {
        toast(
          `Success - Scheduled Course has been ${
            courseId ? 'updated' : 'created'
          }`,
          successToastOptions,
        )
        if (router.location.pathname.includes('calendar')) {
          router.navigate('/calendar')
        } else {
          router.navigate(-1)
        }
      })
      .catch(handleError)
      .finally(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  const form = useFormik<IForm>({
    enableReinitialize: true,

    initialValues: {
      isDraft: true,
      //section 1
      name: editCourse?.name || '',
      type: editCourse
        ? { value: editCourse.type, label: editCourse.type }
        : undefined,
      category: editCourse?.category
        ? { value: editCourse.categoryId, label: editCourse.category.name }
        : undefined,
      description: editCourse?.description || undefined,
      date:
        editCourse && editCourse.startDate
          ? moment(editCourse.startDate).toDate()
          : undefined,
      time:
        editCourse && editCourse.startTime
          ? moment(editCourse.startTime, 'h:mm A').toDate()
          : undefined,
      endTime:
        editCourse && editCourse.endTime
          ? moment(editCourse.endTime, 'hh:mm').toDate()
          : undefined,
      instructor: editCourse?.instructor
        ? {
            value: editCourse.instructor.id,
            label: getUserDisplayName(editCourse.instructor),
          }
        : undefined,
      //section 2
      format: editCourse?.format || SCHEDULED_COURSE_FORMATS.IN_PERSON,
      location: editCourse?.location || undefined,
      registrationDeadline: editCourse?.registrationDeadline
        ? new Date(editCourse.registrationDeadline)
        : undefined,
      attendees: undefined,
      notificationsEnabled: editCourse?.notificationsEnabled || false,
      notifications: editCourse?.notifications
        ? [...editCourse.notifications]
        : defaultNotifications,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Course Name'),
      type: Yup.object().required('Please Choose Type'),
      category: Yup.object().required('Please Select Category'),
      instructor: Yup.object().required('Please Select Instructor'),

      date: Yup.date().when('isDraft', {
        is: false,
        then: Yup.date().required('Please Enter Date'),
      }),
      time: Yup.date().when('isDraft', {
        is: false,
        then: Yup.date().required('Please Enter Time'),
      }),
      endTime: Yup.date().when('isDraft', {
        is: false,
        then: Yup.date().required('Please Enter End Time'),
      }),
      location: Yup.string().when('isDraft', {
        is: false,
        then: Yup.string().required('Please Enter Location'),
      }),
    }),
    onSubmit: onSubmit,
  })

  const onCreateInstructor = (instructor: TInstructor) => {
    form.setFieldValue('instructor', {
      label: getUserDisplayName(instructor),
      value: instructor.id,
    })
    setCreateInstructorModal(false)
  }

  const fetchInstructors = (inputValue?: string) => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return getInstructors(params)
      .then(({ instructors }) => {
        return instructors.map(instructor => ({
          value: instructor.id,
          label: getUserDisplayName(instructor),
        }))
      })
      .catch(() => [])
  }

  const fetchCategories = (inputValue?: string) => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return getScheduledCategories(params)
      .then(({ scheduled_categories }) => {
        return scheduled_categories.map(category => ({
          value: category.id,
          label: category.name,
        }))
      })
      .catch(() => [])
  }

  const onEditNotification = (
    field: 'value' | 'type',
    _index: number,
    value: number | notificationOption,
  ) => {
    form.setFieldValue(
      'notifications',
      form.values.notifications.map((notification, index) => {
        if (index === _index) {
          return {
            ...notification,
            [field]: value,
          }
        }
        return notification
      }),
    )
  }

  const onDeleteNotification = (index: number) => {
    const _notifications = [...form.values.notifications]
    _notifications.splice(index, 1)
    form.setFieldValue('notifications', _notifications)
  }

  const onAddNewNotification = () => {
    form.setFieldValue('notifications', [
      ...form.values.notifications,
      {
        value: 1,
        type: 'hours',
      },
    ])
  }

  const breadCrumb = useMemo(() => {
    const path: BreadCrumbProps['items'] = [
      {
        active: true,
        title: isNew ? 'Add New Event' : 'Edit',
      },
    ]

    if (router.location.pathname.includes('calendar')) {
      const items = [
        {
          linkTo: '/calendar',
          title: 'Calendar',
        },
      ]
      if (isNew) {
        path.unshift(...items)
      } else {
        path.unshift(...items, {
          linkTo: '#',
          title: 'Manage',
        })
      }
    } else {
      const items = [
        {
          linkTo: '/',
          title: 'Courses',
        },
        {
          linkTo: '/courses/scheduled-courses',
          title: 'Schedule Courses',
        },
      ]
      if (isNew) {
        path.unshift(...items)
      } else {
        path.unshift(...items, {
          linkTo: '#',
          title: 'Manage',
        })
      }
    }

    return path
  }, [router, isNew])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={isNew ? 'Create New Event' : 'Manage Course'}
            items={breadCrumb}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <span className='fs-17 fw-light'>
                    General Course Information
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              e.preventDefault()
              form.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className='vstack gap-4'>
                      <div>
                        <label htmlFor='groupName' className='form-label'>
                          Name*
                        </label>
                        <Input
                          name='name'
                          className='form-control'
                          id='groupName'
                          placeholder='Enter name'
                          type='text'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.name || ''}
                          invalid={!!(form.touched.name && form.errors.name)}
                        />
                        {form.touched.name && form.errors.name ? (
                          <FormFeedback type='invalid'>
                            {form.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div>
                        <label className='form-label'>Type*</label>
                        <div className='input-group'>
                          <div className='w-25'>
                            <Select
                              isSearchable={false}
                              styles={{
                                control: baseStyles => ({
                                  ...baseStyles,
                                  borderRadius: '4px 0px 0px 4px',
                                  backgroundColor: '#E9EBEC !important',
                                  borderRight:
                                    form.touched.type && form.errors.type
                                      ? '1 !important'
                                      : 0,
                                  borderColor:
                                    form.touched.type && form.errors.type
                                      ? '#f06548 !important'
                                      : undefined,
                                }),
                              }}
                              options={CourseTypesOptions}
                              name='type'
                              id='type'
                              onChange={option =>
                                form.setFieldValue('type', option)
                              }
                              onBlur={() => {
                                form.setFieldTouched('type', true)
                              }}
                              value={form.values.type}
                              placeholder='Choose type'
                              className='select2-container w-100 is-invalid'
                              classNamePrefix='select2-selection form-select'
                            />
                            {form.touched.type && form.errors.type ? (
                              <div className='invalid-feedback'>
                                {form.errors.type}
                              </div>
                            ) : null}
                          </div>
                          <div className='flex-grow-1'>
                            <AsyncSelectWithSearch
                              styles={{
                                control: baseStyles => ({
                                  ...baseStyles,
                                  borderRadius: '0px 4px 4px 0px',
                                  borderColor:
                                    form.touched.category &&
                                    form.errors.category
                                      ? '#f06548 !important'
                                      : undefined,
                                }),
                              }}
                              placeholder='Select the category'
                              className='select2-container w-100 is-invalid'
                              name='category'
                              id='category'
                              onChange={option =>
                                form.setFieldValue('category', option)
                              }
                              onBlur={() => {
                                form.setFieldTouched('category', true)
                              }}
                              value={form.values.category}
                              isMulti={false}
                              isClearable={false}
                              isSearchable={true}
                              defaultOptions
                              loadOptions={fetchCategories}
                              error={
                                form.touched.category
                                  ? form.errors.category
                                  : undefined
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label htmlFor='description' className='form-label'>
                          Description
                        </label>
                        <Input
                          name='description'
                          className='form-control'
                          id='description'
                          type='textarea'
                          rows={5}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.description || ''}
                          invalid={
                            !!(
                              form.touched.description &&
                              form.errors.description
                            )
                          }
                        />
                      </div>
                      <Row className='g-3'>
                        <Col>
                          <div>
                            <label htmlFor='date' className='form-label'>
                              Date
                            </label>
                            <div className='form-icon right'>
                              <Flatpickr
                                className={`form-control form-control-icon ${
                                  form.touched.date && form.errors.date
                                    ? ' is-invalid'
                                    : ''
                                }`}
                                id='date'
                                name='date'
                                placeholder='dd-mm-yyyy'
                                onChange={option => {
                                  form.setFieldValue('date', option[0])
                                }}
                                onBlur={() => {
                                  form.setFieldTouched('date', true)
                                }}
                                value={form.values.date}
                                options={{
                                  dateFormat: 'd-m-Y',
                                  formatDate: date =>
                                    moment(date).format('MM/DD/YYYY'),
                                  minDate: 'today',
                                  allowInvalidPreload: true,
                                }}
                              />
                              <i className='ri-calendar-event-line fs-20'></i>
                            </div>
                            {form.touched.date && form.errors.date ? (
                              <div className='invalid-feedback d-block'>
                                {form.errors.date}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <label htmlFor='time' className='form-label'>
                              Start Time
                            </label>
                            <div className='form-icon right'>
                              <Flatpickr
                                className={`form-control form-control-icon ${
                                  form.touched.time && form.errors.time
                                    ? ' is-invalid'
                                    : ''
                                }`}
                                id='time'
                                name='time'
                                placeholder='--:--'
                                onChange={option => {
                                  form.setFieldValue('time', option[0])
                                }}
                                onBlur={() => {
                                  form.setFieldTouched('time', true)
                                }}
                                value={form.values.time}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: 'h:i K',
                                  minTime: moment(form.values.date).isSame(
                                    moment().startOf('day'),
                                  )
                                    ? Date.now()
                                    : undefined,
                                  allowInvalidPreload: true,
                                }}
                              />
                              <i className='ri-time-line fs-20'></i>
                            </div>
                            {form.touched.time && form.errors.time ? (
                              <div className='invalid-feedback d-block'>
                                {form.errors.time}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <label htmlFor='endTime' className='form-label'>
                              End Time
                            </label>
                            <div className='form-icon right'>
                              <Flatpickr
                                className={`form-control form-control-icon ${
                                  form.touched.endTime && form.errors.endTime
                                    ? ' is-invalid'
                                    : ''
                                }`}
                                id='endTime'
                                name='endTime'
                                placeholder='--:--'
                                onChange={option => {
                                  form.setFieldValue('endTime', option[0])
                                }}
                                onBlur={() => {
                                  form.setFieldTouched('endTime', true)
                                }}
                                value={form.values.endTime}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: 'h:i K',
                                  minTime: form.values.time,
                                  allowInvalidPreload: true,
                                }}
                              />
                              <i className='ri-time-line fs-20'></i>
                            </div>
                            {form.touched.endTime && form.errors.endTime ? (
                              <div className='invalid-feedback d-block'>
                                {form.errors.endTime}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <label className='form-label'>Instructor*</label>
                        <div className='d-flex'>
                          <div className='flex-grow-1'>
                            <AsyncSelectWithSearch
                              styles={{
                                control: baseStyles => ({
                                  ...baseStyles,
                                  borderRadius: '4px 0px 0px 4px',
                                  minHeight: 39,
                                  borderColor:
                                    form.touched.instructor &&
                                    form.errors.instructor
                                      ? '#f06548 !important'
                                      : undefined,
                                }),
                              }}
                              placeholder='Select instructor'
                              className='select2-container is-invalid'
                              name='instructor'
                              id='instructor'
                              onChange={option =>
                                form.setFieldValue('instructor', option)
                              }
                              onBlur={() => {
                                form.setFieldTouched('instructor', true)
                              }}
                              value={form.values.instructor}
                              isMulti={false}
                              isClearable={false}
                              isSearchable={true}
                              defaultOptions
                              loadOptions={fetchInstructors}
                              error={
                                form.touched.instructor
                                  ? form.errors.instructor
                                  : undefined
                              }
                            />
                          </div>
                          <div>
                            <div
                              className='btn btn-light w-auto text-nowrap border-1'
                              style={{
                                borderColor: '#CED4DA',
                                borderLeft: 0,
                                borderRadius: '0px 4px 4px 0px',
                              }}
                              onClick={() => setCreateInstructorModal(true)}
                            >
                              Add instructor
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <div className='vstack gap-4'>
                      <Nav
                        className='nav-customs-bordered flex-1'
                        style={{ maxWidth: 'unset' }}
                      >
                        {Object.values(SCHEDULED_COURSE_FORMATS).map(tab => (
                          <NavItem key={tab} className='flex-1'>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={
                                classnames({
                                  active: form.values.format === tab,
                                }) + ' text-capitalize'
                              }
                              onClick={() => {
                                form.setFieldValue('format', tab)
                              }}
                            >
                              {tab}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                      <div>
                        <label htmlFor='groupName' className='form-label'>
                          Location
                        </label>
                        <Input
                          name='location'
                          className='form-control'
                          id='location'
                          placeholder='Enter location'
                          type='text'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.location || ''}
                          invalid={
                            !!(form.touched.location && form.errors.location)
                          }
                        />
                        {form.touched.location && form.errors.location ? (
                          <FormFeedback type='invalid'>
                            {form.errors.location}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div>
                        <label
                          htmlFor='registrationDeadline'
                          className='form-label'
                        >
                          Registration deadline
                        </label>
                        <div className='form-icon right'>
                          <Flatpickr
                            data-enable-time
                            className={`form-control form-control-icon ${
                              form.touched.registrationDeadline &&
                              form.errors.registrationDeadline
                                ? ' is-invalid'
                                : ''
                            }`}
                            id='registrationDeadline'
                            name='registrationDeadline'
                            placeholder='dd-mm-yyyy  --:--'
                            onChange={option => {
                              form.setFieldValue(
                                'registrationDeadline',
                                option[0],
                              )
                            }}
                            onBlur={() => {
                              form.setFieldTouched('registrationDeadline', true)
                            }}
                            value={form.values.registrationDeadline}
                            options={{
                              enableTime: true,
                              dateFormat: 'd-m-Y',
                              formatDate: date =>
                                moment(date).format('MM/DD/YYYY hh:mm A'),
                              maxDate: moment(form.values.date)
                                .set('hours', moment(form.values.time).hours())
                                .set(
                                  'minute',
                                  moment(form.values.time).minutes(),
                                )
                                .toDate(),
                              minDate: Date.now(),
                              allowInvalidPreload: true,
                            }}
                          />
                          <i className='ri-calendar-event-line fs-20'></i>
                        </div>
                        {form.touched.registrationDeadline &&
                        form.errors.registrationDeadline ? (
                          <div className='invalid-feedback d-block'>
                            {form.errors.registrationDeadline}
                          </div>
                        ) : null}
                      </div>

                      <div>
                        <label htmlFor='groupName' className='form-label'>
                          Attendees
                        </label>
                        <Input
                          name='attendees'
                          className='form-control'
                          id='attendees'
                          placeholder='Selecting from groups of users or facilities'
                          type='text'
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.attendees || ''}
                        />
                      </div>
                      <div className='hstack w-100 flex-1 align-items-center justify-content-start'>
                        <div className='form-check form-switch mb-0 me-2'>
                          <Input
                            className='form-check-input'
                            type='checkbox'
                            role='switch'
                            name='notificationsEnabled'
                            id='notificationsEnabled'
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.notificationsEnabled}
                          />
                        </div>
                        <span className='fw-light fs-14'>Notifications</span>
                      </div>
                      {form.values.notificationsEnabled && (
                        <>
                          {form.values.notifications.map(
                            (notification, index) => (
                              <div
                                key={index}
                                className='hstack w-100 flex-1 align-items-center justify-content-between'
                              >
                                <Input
                                  className='form-control form-control-icon'
                                  style={{
                                    borderRadius: '4px 0px 0px 4px',
                                    background: '#E9EBEC',
                                    borderRight: 0,
                                  }}
                                  onChange={event => {
                                    onEditNotification(
                                      'value',
                                      index,
                                      +event.target.value,
                                    )
                                  }}
                                  type='number'
                                  value={notification.value}
                                  required
                                />
                              </div>
                            ),
                          )}
                          <div>
                            <Button
                              color='ghost-primary'
                              className='fs-14 p-0'
                              onClick={onAddNewNotification}
                            >
                              <i className='ri-add-line me-1'></i>Add
                              Notification
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className='d-flex justify-content-between'>
              <Button
                color='light'
                className='btn-label'
                onClick={() => router.navigate(-1)}
              >
                <i className='ri-arrow-left-line label-icon align-middle me-2'></i>
                Cancel
              </Button>
              <div className='hstack gap-3'>
                <Button
                  color='ghost-primary'
                  disabled={!form.dirty || form.isSubmitting}
                  onClick={() => {
                    form.setFieldValue('isDraft', true)
                    form.validateForm().finally(() => {
                      return form.submitForm()
                    })
                  }}
                >
                  Save as Draft
                </Button>
                <Button
                  color='primary'
                  className='btn-label right'
                  disabled={!(form.isValid && form.dirty) || form.isSubmitting}
                  onClick={() => {
                    form.setFieldValue('isDraft', false)
                    form.validateForm().finally(() => {
                      return form.submitForm()
                    })
                  }}
                >
                  <i className='ri-arrow-right-line label-icon align-middle ms-2'></i>
                  Publish
                </Button>
              </div>
            </div>
          </Form>
        </Container>
      </div>
      <InstructorModal
        title={'New Instructor'}
        isOpen={showCreateInstructorModal}
        initialValues={null}
        onSubmit={onCreateInstructor}
        onClose={() => {
          setCreateInstructorModal(false)
        }}
      />
    </React.Fragment>
  )
}

export default withRouter(ManageCourse)
