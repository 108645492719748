//Course Assignments
import {
  CreateAssignmentDTO,
  GetTranscriptDTO,
  GetScheduleAssignmentsDTO,
  TAssignment,
  UpdateAssignmentDTO,
  ValidateAssignmentDTO,
  ReleaseTestDTO,
  OverrideTestScoreDTO,
  UploadWrittenTestDTO,
  DeleteUserAssignmentDTO,
} from '../../sharedTypes'
import {
  ALL_COURSES_ASSIGNMENTS,
  COURSE_ASSIGNMENT_TEST_UPLOAD,
  COURSE_ASSIGNMENTS,
  COURSE_ASSIGNMENTS_VALIDATE,
  RELEASE_TEST,
  SCHEDULE_ASSIGNMENT_CHECK_IN,
  SCHEDULE_ASSIGNMENT_UNDO,
  SCHEDULE_ASSIGNMENTS,
} from '../url_helper'
import { API } from '../api_helper'
import {
  GetAssignmentsDTO,
  GetCoursesAssignmentsDTO,
} from '../../sharedTypes/api/courseHistory'

export const validateAssignment = (data: ValidateAssignmentDTO.Request) => {
  return API.post<ValidateAssignmentDTO.Response>(
    COURSE_ASSIGNMENTS_VALIDATE,
    data,
  )
}

export const createAssignment = (data: CreateAssignmentDTO.Request) => {
  return API.post(COURSE_ASSIGNMENTS, data)
}

export const getUserAssignments = (params: GetAssignmentsDTO.Request) => {
  return API.get(COURSE_ASSIGNMENTS, {
    params,
  })
}

export const getCoursesAssignments = (
  params: GetCoursesAssignmentsDTO.Request,
) => {
  return API.get<GetCoursesAssignmentsDTO.Response>(ALL_COURSES_ASSIGNMENTS, {
    params,
  })
}

export const getUserAssignmentsTranscript = (
  id: number,
  params: GetTranscriptDTO.Request,
) => {
  return API.get(COURSE_ASSIGNMENTS + `/transcript/user/${id}`, { params })
}

export const deleteUserAssignment = (
  assignmentId: number,
  params: DeleteUserAssignmentDTO.Request,
) => {
  return API.delete(COURSE_ASSIGNMENTS + `/${assignmentId}`, { params })
}

export const overrideUserAssignmentScore = (
  assignmentId: number,
  params: OverrideTestScoreDTO.Request,
) => {
  return API.patch(COURSE_ASSIGNMENTS + `/${assignmentId}/override`, params)
}

export const updateUserAssignment = (
  assignmentId: number,
  data: UpdateAssignmentDTO.Request,
) => {
  return API.patch(COURSE_ASSIGNMENTS + `/${assignmentId}`, data)
}

export const getScheduleAssignments = (
  params: GetScheduleAssignmentsDTO.Request,
) => {
  return API.get<GetScheduleAssignmentsDTO.Response>(SCHEDULE_ASSIGNMENTS, {
    params,
  }).then(d => d.data)
}

export const checkinUserAssignment = (id: number) => {
  return API.post(`${SCHEDULE_ASSIGNMENT_CHECK_IN}/${id}`).then(d => d.data)
}

export const undoUserAssignment = (id: number) => {
  return API.post<TAssignment>(`${SCHEDULE_ASSIGNMENT_UNDO}/${id}`).then(
    d => d.data,
  )
}

export const releaseTest = (data: ReleaseTestDTO.Request) => {
  return API.post(RELEASE_TEST, data)
}

export const uploadWrittenTest = (assignmentId: number, body: FormData) => {
  return API.post<UploadWrittenTestDTO.Response>(
    `${COURSE_ASSIGNMENT_TEST_UPLOAD}/${assignmentId}`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}
