import { AssignTo } from '../forms'
import {
  SurveyStatusTypes,
  TSurvey,
  TSurveyTranslationItem,
  TSurveyTranslations,
} from '../models'
import { PaginationRequest } from './pagination'

export interface SurveyRequest {
  sendDate?: Date | null
  status: SurveyStatusTypes
  deadline?: string | null
  sendTo: AssignTo
  recipients: number[] | null
  translations: TSurveyTranslationItem[]
  questions: any[]
  allowAnonymousResponses: boolean
}

export namespace CreateSurveyDTO {
  export interface Request extends SurveyRequest {}
  export interface Response extends TSurvey {}
}

export namespace GetSurveysDTO {
  export interface Request extends PaginationRequest {
    page: number
    limit: number
    sortBy?: SurveysSortBy
    key?: string
    status?: SurveyStatusTypes | undefined
  }
  export interface Response {
    count: number
    surveys: TSurvey[]
    page: number
    pages: number
  }
}

export namespace DuplicateSurveyDTO {
  export interface Response extends TSurvey {}
}

export namespace SendSurveyDto {
  export interface Request {
    translations: TSurveyTranslations[]
  }
  export interface Response extends TSurvey {}
}

export enum SurveysSortBy {
  NAME = 'name',
  DESCRIPTION = 'description',
  RECIPIENTS = 'recipientsCount',
  COMPLETED = 'completedUsersCount',
  SEND_DATE = 'sendDate',
  DEADLINE = 'deadline',
  STATUS = 'status',
}
