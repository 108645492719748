import React, { useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { ONLINE_COURSE_STATUS, OnlineCourseItem } from '../../../sharedTypes'
import { CoursePermissions } from './types'

interface CoursesActionsDropDownProps {
  course: OnlineCourseItem
  permissions: CoursePermissions
  onClone: (course: OnlineCourseItem) => void
  onDelete: (course: OnlineCourseItem) => void
}

export const ActionsDropDown = ({
  course,
  permissions,
  onClone,
  onDelete,
}: CoursesActionsDropDownProps) => {
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)

  return (
    <Dropdown
      isOpen={actionDropdownOpen}
      toggle={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setActionDropdownOpen(prevState => !prevState)
      }}
    >
      <DropdownToggle
        className={`badge btn ${
          actionDropdownOpen
            ? 'btn-primary'
            : 'text-light-purple btn-soft-primary'
        }`}
        tag='button'
      >
        <i className='mdi mdi-dots-horizontal align-middle' />
      </DropdownToggle>
      <DropdownMenu container={'body'}>
        {permissions.addEdit && (
          <DropdownItem className='d-flex gap-2 text-muted'>
            <Link
              to={`/courses/general/${course.id}`}
              target={'_blank'}
              className='d-flex gap-2 text-body w-100'
            >
              <i
                className='ri-pencil-line cursor-pointer'
                id={`editCourse${course.id}`}
              ></i>
              Edit
            </Link>
          </DropdownItem>
        )}

        {permissions.clone && (
          <DropdownItem
            className='cursor-pointer d-flex gap-2'
            onClick={() => onClone(course)}
          >
            <i
              className='bx bx-duplicate cursor-pointer text-body'
              id={`cloneCourse${course.id}`}
            />
            Clone
          </DropdownItem>
        )}
        {course.status !== ONLINE_COURSE_STATUS.INACTIVE &&
          permissions.viewTranslations && (
            <DropdownItem className='cursor-pointer d-flex gap-2 text-body'>
              <Link
                to={`/courses/translations/${course.id}`}
                target={'_blank'}
                className='d-flex gap-2 text-body'
              >
                <i
                  className='ri-eye-line'
                  id={`viewTranslations${course.id}`}
                />
                View Translations
              </Link>
            </DropdownItem>
          )}

        {permissions.delete && (
          <DropdownItem
            className={`${
              !course.deletable
                ? 'pe-none text-disabled'
                : 'text-danger cursor-pointer'
            } d-flex gap-2`}
            onClick={() => onDelete(course)}
          >
            <i
              className='ri-delete-bin-2-line'
              id={`deleteCourse${course.id}`}
            />
            Delete
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
