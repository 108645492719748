import {
  CommunicationPermissions,
  TSurveyTranslationItem,
  TSurveyTranslations,
} from '../../sharedTypes'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import classnames from 'classnames'
import { useAppSelector } from '../../hooks/redux'
import { usePermissions } from '../../hooks/usePermissions'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import _ from 'lodash'

interface SendSurveyModalProps {
  translations: TSurveyTranslations[]
  isOpen: ModalProps['isOpen']
  onClose: () => void
  onSend: (values: IForm, callback: () => void) => void
  id: number
}

export interface IForm {
  translations: TSurveyTranslationItem[]
}

const validationSchema = yup.object().shape({
  translations: yup.array().of(
    yup.object().shape({
      message: yup
        .string()
        .required('Please Enter Notification Message')
        .nullable(),
      languageId: yup.number().required(),
    }),
  ),
})

const SendSurveyModal = ({
  translations,
  isOpen,
  onClose,
  onSend,
  id,
}: SendSurveyModalProps) => {
  const languages = useMemo(
    () => translations.map(t => t.language) || [],
    [translations],
  )

  const { defaultLanguageId } = useAppSelector(state => state.Languages)
  const [activeTab, setActiveTab] = useState(defaultLanguageId)

  const hasPermissionToEdit = usePermissions(
    CommunicationPermissions.EDIT_SURVEY,
  )

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      translations: [],
    },
    validationSchema,
    onSubmit: values => {
      onSend(values, resetFormSubmit)
    },
  })

  const translationError = useCallback(
    (index: number, field: string) => {
      const error = _.get(form, `errors.translations[${index}].${field}`)
      const touched = _.get(form, `touched.translations[${index}].${field}`)

      return error && touched ? (
        <FormFeedback type='invalid'>{error}</FormFeedback>
      ) : null
    },
    [form],
  )

  useEffect(() => {
    form.setFieldValue(
      'translations',
      translations.map(translation => ({
        message: translation.content.message,
        languageId: translation.languageId,
      })),
    )
  }, [translations])

  const tabChange = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const resetFormSubmit = () => {
    form.setSubmitting(false)
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 500 }}>
      <ModalHeader toggle={onClose}>
        <h5 className='fw-normal text-muted'>Survey Notification</h5>
      </ModalHeader>
      <ModalBody className='px-2 pb-4 pt-2'>
        <Nav
          className='nav nav-tabs nav-tabs-custom rounded card-header-tabs px-2'
          role='tablist'
        >
          {languages.map((language, index) => (
            <NavItem key={index}>
              <NavLink
                to='#'
                className={`${classnames({
                  active: activeTab === language.id,
                })} cursor-pointer`}
                onClick={() => {
                  tabChange(language.id)
                }}
              >
                {language.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab} className='px-3'>
          {languages.map((language, index: number) => (
            <TabPane tabId={language.id} key={index}>
              <div className='vstack gap-2 mt-3'>
                <div className='d-flex justify-content-between'>
                  <div>
                    <label className='form-label fw-medium'>Survey Name</label>
                    <p className='text-muted'>
                      {translations.find(t => t.languageId === activeTab)
                        ?.content.name || ''}
                    </p>
                  </div>
                  {hasPermissionToEdit && (
                    <div>
                      <Link to={`/surveys/manage/${id}`} target='_blank'>
                        <span className='badge text-capitalize text-light-purple fs-12 py-2 cursor-pointer'>
                          <i className='ri-eye-line mx-1'></i>View Details
                        </span>
                      </Link>
                    </div>
                  )}
                </div>

                <div>
                  <label className='form-label fw-medium'>
                    Notification Message
                  </label>
                  <Input
                    name='message'
                    className='form-control'
                    id='message'
                    type='textarea'
                    onChange={e => {
                      form.setFieldValue(
                        `translations[${index}]['message']`,
                        e.target.value,
                      )
                    }}
                    onBlur={() =>
                      form.setFieldTouched(`translations[${index}]['message']`)
                    }
                    value={form.values.translations[index]?.message || ''}
                    invalid={!!translationError(index, 'message')}
                  />
                </div>
              </div>
            </TabPane>
          ))}
        </TabContent>

        <div className='d-flex justify-content-end mt-3 gap-3 px-3'>
          <Button className='btn-soft-primary' onClick={onClose} active={false}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            disabled={!(form.isValid && form.dirty) || form.isSubmitting}
            onClick={() => form.handleSubmit()}
          >
            {form.isSubmitting ? <Spinner size={'sm'} /> : 'Send Now'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SendSurveyModal
