import React, { useCallback } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { FormikHelpers } from 'formik'
import { Spinner } from 'reactstrap'
import {
  OnlineTagInitialValues,
  OnlineTagModalSubmitedValues,
} from '../../../sharedTypes'

interface OnlineTagModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (
    values: OnlineTagModalSubmitedValues,
    formikHelpers: FormikHelpers<OnlineTagModalSubmitedValues>,
  ) => void
  title: string
  initialValues: OnlineTagInitialValues | null
}

const OnlineTagModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
}: OnlineTagModalProps) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: -1,
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().nullable().required('Required'),
    }),
    onSubmit: (values, formik) => {
      onSubmit(
        values as OnlineTagModalSubmitedValues,
        formik as FormikHelpers<OnlineTagModalSubmitedValues>,
      )
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>

        <div className='vstack gap-3'>
          <div>
            <label htmlFor='title' className='form-label'>
              Name
            </label>
            <Input
              name='name'
              className='form-control'
              id='title'
              disabled={form.isSubmitting}
              placeholder='Enter name'
              type='text'
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.name || ''}
              invalid={!!(form.touched.name && form.errors.name)}
            />
            {form.touched.name && form.errors.name ? (
              <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
            ) : null}
          </div>
        </div>
        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-primary'
            onClick={beforeClose}
            active={false}
            disabled={form.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            color='success'
            type='button'
            onClick={() => form.handleSubmit()}
            disabled={!form.dirty || !form.isValid || form.isSubmitting}
          >
            {form.isSubmitting ? (
              <Spinner size={'sm'} />
            ) : (
              `${initialValues?.id ? 'Update' : 'Submit'}`
            )}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default OnlineTagModal
