import React from 'react'
import { Input, Label } from 'reactstrap'

export const BoolAnswer = ({
  value,
  index,
  onChange,
}: {
  value: boolean
  index: number
  onChange: (value: boolean) => void
}) => {
  return (
    <div className='d-flex flex-column gap-2'>
      <section>
        <label htmlFor='date' className='form-label mb-2'>
          Answer*
        </label>
        <div className='form-check mb-2'>
          <Input
            className='form-check-input'
            type='radio'
            name={`answer-${index}`}
            checked={value}
            onChange={() => {
              onChange(true)
            }}
            id='flexRadioDefault1'
          />
          <Label className='form-check-label' htmlFor='flexRadioDefault1'>
            True
          </Label>
        </div>
        <div className='form-check'>
          <Input
            className='form-check-input'
            type='radio'
            name={`answer-${index}`}
            checked={!value}
            onChange={() => {
              onChange(false)
            }}
            id='flexRadioDefault2'
            defaultChecked
          />
          <Label className='form-check-label' htmlFor='flexRadioDefault2'>
            False
          </Label>
        </div>
      </section>
    </div>
  )
}
