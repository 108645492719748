import { createSlice } from '@reduxjs/toolkit'

export const initialState: ModalState = {
  openModalId: null,
}

const detailsInfoSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setOpenModalId(state, { payload }: { payload: number | null | string }) {
      state.openModalId = payload
    },
    clearModalState(state) {
      state.openModalId = null
    },
  },
})

export const { setOpenModalId, clearModalState } = detailsInfoSlice.actions

export type ModalState = {
  openModalId: number | null | string
}

export default detailsInfoSlice.reducer
