import { Col, Row } from 'reactstrap'
import _ from 'lodash-es'
import { AllValue, Step1PassedProps } from './types'
import React from 'react'
import ShowMoreWithPopover from '../../Common/ShowMoreWithPopover'

const Step1Passed = ({ assignTo }: Step1PassedProps) => {
  return (
    <div className='me-1'>
      <Row className='gap-1 pt-3 text-start bg-body rounded px-3'>
        {!(
          !Array.isArray(assignTo.users) ||
          _.isEmpty(assignTo.users) ||
          _.isEqual(assignTo.users, AllValue)
        ) ? (
          <Col sm={3}>
            <div className='v-stack gap-2'>
              <span className='text-muted fs-14'>Users: </span>
              <span className='text-muted fs-14'>
                <ShowMoreWithPopover
                  limit={3}
                  data={assignTo.users.map(f => f.label)}
                />
              </span>
            </div>
          </Col>
        ) : (
          <>
            {!(
              !Array.isArray(assignTo.facilities) ||
              _.isEmpty(assignTo.facilities) ||
              _.isEqual(assignTo.facilities, AllValue)
            ) && (
              <Col sm={3}>
                <div className='v-stack gap-2'>
                  <span className='text-muted fs-14'>Facilities: </span>
                  <span className='text-body fs-14'>
                    <ShowMoreWithPopover
                      limit={3}
                      data={assignTo.facilities.map(f => f.label)}
                    />
                  </span>
                </div>
              </Col>
            )}

            {!(
              !Array.isArray(assignTo.departments) ||
              _.isEmpty(assignTo.departments) ||
              _.isEqual(assignTo.departments, AllValue)
            ) && (
              <Col sm={3}>
                <div className='v-stack gap-2'>
                  <span className='text-muted fs-14'>Departments: </span>
                  <span className='text-muted fs-14'>
                    <ShowMoreWithPopover
                      limit={3}
                      data={assignTo.departments.map(f => f.label)}
                    />
                  </span>
                </div>
              </Col>
            )}

            {!(
              !Array.isArray(assignTo.positions) ||
              _.isEmpty(assignTo.positions) ||
              _.isEqual(assignTo.positions, AllValue)
            ) && (
              <Col sm={3}>
                <div className='v-stack gap-2'>
                  <span className='text-muted fs-14'>Positions: </span>
                  <span className='text-muted fs-14'>
                    <ShowMoreWithPopover
                      limit={3}
                      data={assignTo.positions.map(f => f.label)}
                    />
                  </span>
                </div>
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  )
}

export default Step1Passed
