import React from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import { OnlineCourseItem } from '../../sharedTypes'
import { get } from 'lodash'
import { WithRouterProps } from '../Common/withRouter'
import ShowMore from '../../Components/Common/ShowMore'

import { copyToClipboard } from '../../utils/clipboard'
import moment from 'moment'
import { formattedDuration, getBadge } from '../../helpers/course'

interface GeneralInfoProps extends WithRouterProps {
  course: OnlineCourseItem
}

export const GeneralInfo = ({ course, router }: GeneralInfoProps) => {
  const tagColors = [
    'badge-outline-primary',
    'badge badge-outline-secondary',
    'badge-outline-success',
    'badge-outline-info',
    'badge-outline-warning',
    'badge-outline-danger',
    'badge-outline-dark',
  ]

  return (
    <Card className='m-0'>
      <CardBody>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <span className='ml-1' style={{ color: '#7C8193', fontSize: 16 }}>
            General Info
          </span>
          <Button
            size='sm'
            className='btn-soft-primary align-middle fs-12'
            onClick={() => router.navigate(`/courses/general/${course.id}`)}
          >
            <i className='bx ri-edit-box-line me-1'></i>
            Edit General Info
          </Button>
        </div>
        {course?.cover?.path && (
          <div className='text-center'>
            <img
              src={course.cover?.path}
              className='mb-5 w-100'
              alt='search-pic'
            />
          </div>
        )}
        <p className='text-center mb-3' style={{ fontSize: 16 }}>
          {get(course, 'translations[0].content.name', '-')}
        </p>
        <ul className='mx-auto list-unstyled'>
          <li className='mb-4 gap-3 d-flex'>
            <span style={{ minWidth: 100 }}>Code :</span>
            <span style={{ color: '#7C8193' }}>{course?.code}</span>
          </li>
          <li className='mb-4 gap-3 d-flex'>
            <span style={{ minWidth: 100 }}>Effective Date :</span>
            <span style={{ color: '#7C8193' }}>
              {course?.effectiveDate
                ? moment(course.effectiveDate).format('MM/DD/YYYY')
                : '-'}
            </span>
          </li>
          {course?.type && (
            <li className='mb-4 gap-3 d-flex'>
              <span style={{ minWidth: 100 }}>Type :</span>
              <span style={{ color: '#7C8193' }}>{course.type}</span>
            </li>
          )}
          {course?.category && (
            <li className='mb-4 gap-3 d-flex'>
              <span style={{ minWidth: 100 }}>Category :</span>
              <span style={{ color: '#7C8193' }}>{course?.category?.name}</span>
            </li>
          )}
          {course?.instructor && (
            <li className='mb-4 gap-3 d-flex'>
              <span style={{ minWidth: 100 }}>Instructor :</span>
              <span style={{ color: '#7C8193' }}>
                {course?.instructor?.firstName} {course?.instructor?.lastName}
              </span>
            </li>
          )}
          <li className='mb-4 gap-3 d-flex'>
            <span style={{ minWidth: 100 }}>Duration :</span>
            <span style={{ color: '#7C8193' }}>
              {formattedDuration(course?.duration)}
            </span>
          </li>
          <li className='mb-4 gap-3 d-flex'>
            <span style={{ minWidth: 100 }}>Language :</span>
            <span style={{ color: '#7C8193' }}>
              {course?.translations.map(t => t.language.name).join(',')}
            </span>
          </li>
          {course?.digital?.file && (
            <li className='mb-4 gap-3 d-flex'>
              <span style={{ minWidth: 100 }}>Course Link:</span>
              <span style={{ color: '#7C8193' }} className='d-flex'>
                <span className='text-truncate' style={{ maxWidth: 130 }}>
                  {course?.digital?.file?.path}
                </span>{' '}
                <i
                  className='ri-file-copy-line cursor-pointer'
                  onClick={() => {
                    copyToClipboard(course?.digital?.file?.path as string)
                  }}
                ></i>
              </span>
            </li>
          )}

          {course?.liveCourse &&
            course.translations.map(t => {
              const medialList = course?.liveCourse?.media.filter(
                m => m.languageId === t.languageId,
              )
              return (
                <li key={t.id} className='mb-4 gap-3 d-flex'>
                  <span>Media ({t.language.name}): </span>{' '}
                  <span className='gap-1 d-flex flex-wrap'>
                    {medialList?.length
                      ? medialList?.map(m => getBadge(m))
                      : '-'}
                  </span>
                </li>
              )
            })}
          <li className='mb-4 gap-3 d-flex'>
            <span style={{ minWidth: 100 }}>Tags :</span>
            <span style={{ color: '#7C8193' }}>
              {course?.tags
                .map((t: any) => t?.name)
                .map((t, i) => (
                  <span
                    key={t}
                    className={`badge ${tagColors[i]} fs-12 fw-normal`}
                  >
                    {t}
                  </span>
                ))}
            </span>
          </li>
          <li className='mb-4 gap-3 d-flex'>
            <span style={{ minWidth: 100 }}>Description :</span>
            <span>
              <ShowMore
                content={get(
                  course,
                  'translations[0].content.description',
                  '-',
                )}
              />
            </span>
          </li>
          <li className='mb-4 gap-3 d-flex'>
            <span style={{ minWidth: 100 }}>Objective :</span>
            <ShowMore
              content={get(course, 'translations[0].content.objective', '-')}
            />
          </li>
        </ul>
      </CardBody>
    </Card>
  )
}
