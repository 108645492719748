import { PaginationRequest } from './pagination'
import {
  StatusTypes,
  TPushNotification,
  TPushNotificationTranslationItem,
} from '../models/pushNotifications'
import { AssignTo } from '../forms'

export namespace GetPushNotificationsDTO {
  export interface Request extends PaginationRequest {
    page: number
    limit: number
    sortBy?: PushNotificationsSortBy
    key?: string
    status?: StatusTypes | undefined
  }
  export interface Response {
    count: number
    pushNotifications: TPushNotification[]
    page: number
    pages: number
  }
}

export interface PushNotificationRequest {
  status: StatusTypes
  sendDate: Date | null
  sendTo: AssignTo
  recipients: number[] | null
  link: string | null
  translations: TPushNotificationTranslationItem[]
}

export namespace CreatePushNotificationDTO {
  export interface Request extends PushNotificationRequest {}
  export interface Response extends TPushNotification {}
}

export enum PushNotificationsSortBy {
  TITLE = 'translations.title',
  MESSAGE = 'translations.message',
  RECIPIENTS = 'recipientsCount',
  CLICKS_COUNT = 'readCount',
  USER = 'createdBy',
  STATUS = 'status',
  SEND_DATE = 'sendDate',
}
