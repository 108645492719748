import {
  TSupportCategory,
  TSupportCategoryName,
} from '../models/supportСategory'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'

export enum CategoriesSortBy {
  NAME = 'name',
  TUTORIAL_COUNT = 'tutorialCount',
  FAQ_COUNT = 'faqCount',
}

export interface SupportCategoryRequest {
  name: string
  resourceIds: number[]
}

export namespace GetSupportCategoriesDTO {
  export interface Request extends PaginationRequest {
    sortBy?: CategoriesSortBy
  }

  export interface Response extends PaginatedBaseResponse {
    categories: TSupportCategory[]
  }
}

export namespace CreateSupportCategoryDTO {
  export interface Request extends SupportCategoryRequest {}
  export type Response = any
}

export namespace EditSupportCategoryDTO {
  export interface Request extends SupportCategoryRequest {}
  export type Response = any
}

export namespace GetSupportCategoryNamesDTO {
  export interface Request extends PaginationRequest {}
  export interface Response extends PaginatedBaseResponse {
    categories: TSupportCategoryName[]
  }
}
