import React, { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react'
import { Input } from 'reactstrap'
import { FormikValues } from 'formik'
import { CREDENTIALS_TYPE, TUserCredentials } from '../../../sharedTypes'
import _ from 'lodash'
import { EditMode, IAddCredential } from './ManageCredentials'

interface CredentialManagerProps {
  credentials: TUserCredentials[]
  credentialType: CREDENTIALS_TYPE
  editMode: EditMode
  setEditMode: Dispatch<SetStateAction<EditMode>>
  form: FormikValues
  handleChange: (
    id: number,
    index: number,
    e: ChangeEvent<HTMLInputElement>,
    credentialType: CREDENTIALS_TYPE,
  ) => void
  onUpdate: (
    id: number,
    index: number,
    credentialType: CREDENTIALS_TYPE,
  ) => void
  addCredential: IAddCredential
  onAdd: (credentialType: CREDENTIALS_TYPE) => void
  handleAdd: (credentialType: CREDENTIALS_TYPE) => void
  setAddCredential: Dispatch<SetStateAction<IAddCredential>>
  setSelectedCredential: Dispatch<SetStateAction<TUserCredentials | null>>
}

const CredentialItem = ({
  credentials,
  credentialType,
  editMode,
  setEditMode,
  form,
  handleChange,
  onUpdate,
  addCredential,
  onAdd,
  handleAdd,
  setAddCredential,
  setSelectedCredential,
}: CredentialManagerProps) => {
  const typeKey = useMemo(() => {
    return credentialType === CREDENTIALS_TYPE.EMAIL ? 'emails' : 'phones'
  }, [credentialType])

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const newCredentialKey = useMemo(() => {
    return 'new' + capitalizeFirstLetter(credentialType)
  }, [credentialType])

  return (
    <div className='text-muted fw-light w-100'>
      {credentials.map((row, i) => (
        <div key={i}>
          {editMode[credentialType][row.id] ? (
            <div className='my-1'>
              <div className='d-flex gap-1 align-items-center'>
                <Input
                  name={`${typeKey}[${i}].${credentialType}`}
                  className='form-control py-1'
                  type='text'
                  onBlur={form.handleBlur}
                  value={
                    form.values[typeKey][i]?.[credentialType] ?? row.credential
                  }
                  onChange={e => handleChange(row.id, i, e, credentialType)}
                  invalid={
                    !!(
                      _.get(
                        form.touched,
                        `${typeKey}[${i}].${credentialType}`,
                      ) &&
                      _.get(form.errors, `${typeKey}[${i}].${credentialType}`)
                    )
                  }
                />
                <i
                  className='bx bx-check fs-16 text-green-500 cursor-pointer'
                  onClick={() => onUpdate(row.id, i, credentialType)}
                ></i>
                <i
                  className='ri-close-line fs-16 text-danger cursor-pointer'
                  onClick={() => {
                    setEditMode(prev => ({
                      ...prev,
                      [credentialType]: {
                        ...prev[credentialType],
                        [row.id]: false,
                      },
                    }))
                    form.setFieldValue(
                      `${typeKey}[${i}].${credentialType}`,
                      row.credential,
                    )
                  }}
                ></i>
              </div>
              {_.get(form.touched, `${typeKey}[${i}].${credentialType}`) &&
              _.get(form.errors, `${typeKey}[${i}].${credentialType}`) ? (
                <div className='invalid-feedback d-block'>
                  {_.get(form.errors, `${typeKey}[${i}].${credentialType}`)}
                </div>
              ) : null}
            </div>
          ) : (
            <div className='d-flex gap-1 justify-content-between my-1 user-credential-value'>
              <p className='mb-0'>{row.credential}</p>
              <div className='d-flex gap-1'>
                <i
                  className='ri-pencil-line text-muted cursor-pointer'
                  onClick={() => {
                    setEditMode(prev => ({
                      ...prev,
                      [credentialType]: {
                        ...prev[credentialType],
                        [row.id]: true,
                      },
                    }))
                  }}
                ></i>
                <i
                  className='bx bx-trash cursor-pointer'
                  onClick={() => {
                    setSelectedCredential(row)
                  }}
                ></i>
              </div>
            </div>
          )}
        </div>
      ))}
      <div>
        {addCredential[credentialType].isOpen ? (
          <>
            <div className='d-flex gap-2'>
              <Input
                className='form-control py-1'
                type='text'
                name={newCredentialKey}
                value={form.values[newCredentialKey]}
                invalid={
                  !!(
                    form.touched[newCredentialKey] &&
                    form.errors[newCredentialKey]
                  )
                }
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
              <i
                className='bx bx-check fs-16 text-green-500 cursor-pointer'
                onClick={() => onAdd(credentialType)}
              ></i>
              <i
                className='ri-close-line fs-16 text-danger cursor-pointer'
                onClick={() => {
                  setAddCredential(prev => ({
                    ...prev,
                    [credentialType]: {
                      isOpen: false,
                    },
                  }))
                  form.setFieldValue(newCredentialKey, '')
                  form.setFieldTouched(newCredentialKey, false)
                }}
              ></i>
            </div>
            <p>
              {form.touched[newCredentialKey] &&
              form.errors[newCredentialKey] ? (
                <div className='invalid-feedback d-block'>
                  {form.errors[newCredentialKey]}
                </div>
              ) : null}
            </p>
          </>
        ) : (
          <i
            className='ri-add-line me-1 fs-16 cursor-pointer user-credential-value'
            onClick={() => handleAdd(credentialType)}
          ></i>
        )}
      </div>
    </div>
  )
}

export default CredentialItem
