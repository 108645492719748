import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import FilterTabs from './Tabs'
import {
  GetLogsDTO,
  LOG_TYPES,
  LogsSortBy,
  OrderType,
  UserManagementPermissions,
} from '../../../sharedTypes'
import Filters from '../Filters'
import { getImportLogs } from '../../../helpers/api_helper'
import { Pagination } from '../../../Components/Common/Pagination'
import UserInfo from './UserInfo'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { usePermissions } from '../../../hooks/usePermissions'
import { SearchInput } from '../../../Components/Common/SearchInput'
import Highlighter from 'react-highlight-words'
import { useAppSelector } from '../../../hooks/redux'
import UserAssociations from '../../../Components/Common/UserAssociations'
import ItemsPerPageDropdown from '../../../Components/Common/ItemsPerPageDropdown'

const Columns = [
  {
    name: 'Import Date',
    sortBy: LogsSortBy.IMPORT_DATE,
    style: { width: 110, minWidth: 125 },
  },
  {
    name: 'ID',
    sortBy: LogsSortBy.EMPLOYEE_ID,
    style: { width: 120 },
  },
  {
    name: 'User',
  },
  {
    name: 'Facility',
    sortBy: LogsSortBy.FACILITY_NAME,
    style: { minWidth: 85 },
  },
  {
    name: 'Department',
    style: { minWidth: 120 },
  },
  {
    name: 'Position',
    sortBy: LogsSortBy.POSITION_NAME,
  },
  {
    name: 'Type',
    style: { width: 120 },
    sortBy: LogsSortBy.TYPE,
  },
  {
    name: 'Notes',
    sortBy: LogsSortBy.NOTES,
    style: { minWidth: 85 },
  },
]

const BadgeColorByType = {
  [LOG_TYPES.NEW]: 'success',
  [LOG_TYPES.UPDATED]: 'primary',
  [LOG_TYPES.ERROR]: 'warning',
}

const ImportLog = () => {
  document.title = 'Import log | Mastered - Admin & Dashboard'

  const user = useAppSelector(state => state.User.user)

  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [showFilters, setShowFilters] = useState(true)
  const [data, setData] = useState<GetLogsDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    logs: [],
  })

  const [query, setQuery] = useState<GetLogsDTO.Request>({
    page: 1,
    limit: 10,
    facilityIds: [],
    departmentIds: [],
    positionIds: [],
    sortBy: LogsSortBy.IMPORT_DATE,
    orderBy: OrderType.DESC,
    type: undefined,
    searchDate: [moment().toDate(), moment().toDate()],
  })

  const isFiltering = useMemo<boolean>(
    () =>
      _.size(query.positionIds) > 0 ||
      _.size(query.departmentIds) > 0 ||
      _.size(query.facilityIds) > 0 ||
      _.size(query.searchDate) > 0 ||
      !!query.type ||
      !!query.key,
    [query],
  )

  const hasPermission = usePermissions(
    UserManagementPermissions.VIEW_USER_PROFILE,
  )

  const columns = useMemo(() => {
    if (user && user.facilityId) {
      return Columns.filter(column => column.name !== 'Facility')
    }

    return Columns
  }, [user])

  const handleSort = useCallback((column: LogsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onPageChanged = (page: number) => {
    setQuery(prev => ({ ...prev, page: ++page }))
  }

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      const _query = _.omitBy(query, _.isNil)
      if (!_.isEmpty(_query.searchDate)) {
        _query.searchDate = [
          moment(_query.searchDate[0]).format('YYYY-MM-DD'),
          moment(_query.searchDate[1]).format('YYYY-MM-DD'),
        ]
      }

      getImportLogs(_query)
        .then(d => d.data)
        .then(res => {
          setData(res)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Import Log'
            items={[
              {
                linkTo: '/',
                title: 'User Management',
              },
              {
                active: true,
                title: 'Import Log',
                info: 'A list of the users imported from your HR system.',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />

                    <FilterTabs
                      navTab={query.type}
                      navToggle={type => {
                        setQuery(prev => ({ ...prev, type, page: 1 }))
                      }}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                    </div>
                  </div>
                  <Row
                    className={`gap-4 ${
                      showFilters ? 'my-3' : 'my-0'
                    } mx-0 flex-1`}
                  >
                    <Filters
                      visible={showFilters}
                      setFilters={data => {
                        setQuery(prev => ({
                          ...prev,
                          facilityIds: data.facility,
                          groupIds: data.groups,
                          departmentIds: data.department,
                          positionIds: data.position,
                          searchDate: data.searchDate,
                          page: 1,
                        }))
                        if (!fetchData) {
                          setFetchData(true)
                        }
                      }}
                      filterBy={{
                        departments: true,
                        positions: true,
                        agencies: false,
                        groups: false,
                        status: false,
                        registered: false,
                        searchDate: true,
                      }}
                      initialValues={{
                        searchDate: [moment().toDate(), moment().toDate()],
                      }}
                    />
                  </Row>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={isFiltering}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-x-auto'>
                      <Table className='align-middle mb-0'>
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                {column.name}
                                {!!column.sortBy && (
                                  <ColumnSortIcon<LogsSortBy>
                                    sortOrder={query.orderBy}
                                    sortedColumn={query.sortBy}
                                    column={column.sortBy}
                                    handleSort={handleSort}
                                  />
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.logs.map(log => (
                            <tr key={log.id} className='fs-14'>
                              <td>
                                {moment(log.createdAt).format('MM/DD/YYYY')}
                              </td>
                              <td>
                                {log.userId ? (
                                  <Link
                                    to={
                                      hasPermission
                                        ? `/user-listing/profile/${log.userId}`
                                        : '#'
                                    }
                                    target='_blank'
                                    className='fw-normal text-primary'
                                  >
                                    <Highlighter
                                      highlightClassName='text-highlight'
                                      searchWords={[query.key || '']}
                                      highlightTag={'span'}
                                      autoEscape={true}
                                      textToHighlight={log.employeeId.toString()}
                                    />
                                  </Link>
                                ) : (
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={log.employeeId.toString()}
                                  />
                                )}
                              </td>
                              <td>
                                <UserInfo log={log} globalSearch={query.key} />
                              </td>
                              {(!user?.isFacilityAdmin ||
                                user?.hasAccessToMultipleFacilities) && (
                                <td>
                                  {log.user?.facility?.name ||
                                    log.rawUser?.facility}
                                </td>
                              )}

                              {log.user?.departments ? (
                                <UserAssociations
                                  data={log.user?.departments}
                                  key={query.key || ''}
                                />
                              ) : (
                                <td
                                  className='text-break'
                                  style={{ whiteSpace: 'normal' }}
                                >
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={
                                      log.rawUser?.department || ''
                                    }
                                  />
                                </td>
                              )}
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={
                                    log.user?.position?.name ||
                                    log.rawUser?.position ||
                                    ''
                                  }
                                />
                              </td>
                              <td
                                className='text-break'
                                style={{ whiteSpace: 'normal' }}
                              >
                                <span
                                  className={`badge badge-soft-${
                                    BadgeColorByType[log.type]
                                  } fs-12 fw-normal`}
                                >
                                  {log.type}
                                </span>
                              </td>
                              <td
                                className='text-break'
                                style={{ whiteSpace: 'normal' }}
                              >
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={log.notes || ''}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className='mx-3 mt-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ? query.limit : 10}
                        onChange={limit =>
                          setQuery(prev => ({ ...prev, limit: +limit.label }))
                        }
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={onPageChanged}
                        limit={query.limit}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ImportLog
