import { TLanguage } from './language'
import { TUser } from './user'
import { AssignTo } from '../forms'

export type TPushNotification = {
  id: number
  status: StatusTypes
  sendTo: AssignTo | null
  recipients: number[] | null
  recipientsCount?: number
  readCount?: number
  link: string | null
  createdBy: TUser
  sendDate: Date | null
  createdAt: string
  updatedAt: string
  translations: TPushNotificationTranslations[]
}

export type TPushNotificationTranslations = {
  id: number
  pushNotificationId: number
  languageId: number
  title: string
  message: string
  createdAt: string
  updatedAt: string
  language: TLanguage
}

export type TPushNotificationTranslationItem = {
  id?: number
  title: string
  message: string
  languageId: number
}

export enum StatusTypes {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  SENT = 'sent',
}

export type TPushNotificationData = {
  notification: TPushNotification
  recipients: number[] | []
}
