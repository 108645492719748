import React, { Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import _, { get } from 'lodash'
import moment from 'moment'
import { formattedDuration, tagColors } from '../../../helpers/course'
import ShowMore from '../../Common/ShowMore'
import { CourseDetailInfoProps } from './types'
import defaultCourseCover from '../../../assets/images/course-default-cover.png'
import { OnlineCourseFormatsEnum } from '../../../sharedTypes'

const CourseDetailInfo = ({
  course,
  index,
  tooltipRefs,
}: CourseDetailInfoProps) => {
  return (
    <Fragment>
      <div className='border-bottom pb-3'>
        <Row>
          <Col className='position-relative'>
            <div className='course-detail-image'>
              <img
                src={course.cover?.path ?? defaultCourseCover}
                className='rounded w-100'
                alt='NO IMAGE'
              />
            </div>
            <span className='position-absolute badge text-light-purple fs-12 fw-normal course-duration'>
              {!course.formats.includes(OnlineCourseFormatsEnum.COMPETENCY) &&
                formattedDuration(course.duration)}
            </span>
          </Col>
          <Col className='d-flex flex-column align-items-start gap-2'>
            <div className='d-flex justify-content-between align-items-center w-100'>
              <span className='text-muted fs-14'>{course.code}</span>
              <i
                className='ri-close-line fs-16 text-body cursor-pointer'
                id={`course-info-${course.id}`}
                onClick={() => {
                  ;(
                    tooltipRefs.current[`course-${course.id}`] as HTMLElement
                  ).click()
                }}
              ></i>
            </div>
            <span className='fs-14 text-body fw-medium text-start'>
              {get(course, 'translations[0].content.name', '-')}
            </span>
            <span
              className={`badge badge-outline-info fs-12 fw-normal text-capitalize`}
            >
              {course.type}
            </span>
            {/*<span*/}
            {/*  className={`badge badge-soft-info fs-12 fw-normal text-capitalize`}*/}
            {/*>*/}
            {/*  {'Infusion Therapy'}*/}
            {/*</span>*/}
          </Col>
        </Row>
      </div>
      <div className='vstack gap-2 pt-3 text-start course-details-info'>
        <Row>
          <Col md={4}>
            <span className='fw-medium text-body fs-14'>Effective Date: </span>
          </Col>
          <Col md={8}>
            <span className='text-muted fs-14'>
              {course?.effectiveDate
                ? moment(course.effectiveDate).format('MM/DD/YYYY')
                : '-'}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span className='fw-medium text-body fs-14'>Instructor: </span>
          </Col>
          <Col md={8}>
            <span className='text-muted fs-14'>
              {course?.instructor?.firstName} {course?.instructor?.lastName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span className='fw-medium text-body fs-14'>Language: </span>
          </Col>
          <Col md={8}>
            <span className='text-muted fs-14'>
              {course?.translations.map(t => t.language.name).join(',')}
            </span>
          </Col>
        </Row>
        {/*<Row>*/}
        {/*  <Col md={4}>*/}
        {/*    <span className='fw-medium text-body fs-14'>Tags: </span>*/}
        {/*  </Col>*/}
        {/*  <Col md={8}>*/}
        {/*    <div className='d-flex gap-2'>*/}
        {/*      <span className={`badge ${tagColors[index]} fs-12 fw-normal`}>*/}
        {/*        {'medicine'}*/}
        {/*      </span>*/}
        {/*      <span className={`badge badge-outline-primary fs-12 fw-normal`}>*/}
        {/*        {'health'}*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <Row>
          <Col md={4}>
            <span className='fw-medium text-body fs-14'>Description: </span>
          </Col>
          <Col md={8}>
            <span className='text-muted fs-14 course-description'>
              <ShowMore
                content={_.get(
                  course,
                  'translations[0].content.description',
                  '-',
                )}
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span className='fw-medium text-body fs-14'>Objectives: </span>
          </Col>
          <Col md={8}>
            <span className='text-muted fs-14'>
              <ShowMore
                content={_.get(
                  course,
                  'translations[0].content.objective',
                  '-',
                )}
              />
            </span>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default CourseDetailInfo
