import React from 'react'
import CourseActionModal from './CourseActionModal'

const CourseCloneModal = ({
  onClose,
  onSubmit,
  isOpen,
}: {
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}) => {
  return (
    <CourseActionModal
      confirmText='Clone'
      icon={'bx bx-duplicate'}
      title={'Clone this Course'}
      message={
        'This course will be cloned and put into Draft mode for editing.'
      }
      isOpen={isOpen}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  )
}

export default CourseCloneModal
