import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { getScheduleById } from '../../../helpers/api/scheduledCourses'
import {
  COURSE_FILTER_FORMATS,
  ScheduledCourseItem,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
} from '../../../sharedTypes'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import _ from 'lodash'
import { getCourseFormatPrefix } from '../../../helpers/course'
import { releaseTest } from '../../../helpers/api/assignments'
import { toast } from 'react-toastify'
import GeneralInfo from './GeneralInfo'
import AssignmentsList from './AssignmentsList'
import classnames from 'classnames'
import TrainingDayCourses from './TrainingDayCoursesList'
import moment from 'moment/moment'
import axios from 'axios'

export enum TRAINING_DAY_TABS {
  ATTENDANCE = 'attendance',
  COURSES = 'courses',
}

const tabs = [
  {
    label: 'Attendance',
    key: TRAINING_DAY_TABS.ATTENDANCE,
  },
  {
    label: 'Courses',
    key: TRAINING_DAY_TABS.COURSES,
  },
]

const FacilitatorView = ({ router }: WithRouterProps) => {
  document.title = 'Facilitator View | Mastered - Admin & Dashboard'

  const [schedule, setSchedule] = useState<ScheduledCourseItem>()
  const [start, setStart] = useState<string | null>(null)
  const [fetchAssignmentsList, setFetchAssignmentsList] = useState(false)
  const [activeTab, setActiveTab] = useState(TRAINING_DAY_TABS.ATTENDANCE)

  useEffect(() => {
    if (router.params.scheduleId) {
      getScheduleById(+router.params.scheduleId)
        .then(res => {
          setSchedule(res)
        })
        .catch(e => {
          if (axios.isAxiosError(e) && e.response?.status === 404) {
            router.navigate('/404')
          } else {
            handleError(e)
          }
        })
    }
  }, [router.params.scheduleId])

  useEffect(() => {
    const searchParams = new URLSearchParams(router.location.search)
    const start = searchParams.get('start')
    if (!start) {
      router.navigate('/404')
    }
    setStart(start)
  }, [router.location.search])

  const onReleaseTest = async (courseId: number) => {
    if (schedule && start) {
      try {
        await releaseTest({
          startDate: start,
          scheduleId: schedule.id,
          courseId,
        })

        setFetchAssignmentsList(true)
        toast('Test has been released', successToastOptions)
      } catch (e) {
        handleError(e)
      }
    }
  }

  const scheduleInProgress = useMemo(() => {
    if (schedule) {
      const end = moment(start).set({
        hour: moment.utc(schedule.endTime, 'HH:mm:ss').local().hour(),
        minute: moment.utc(schedule.endTime, 'HH:mm:ss').local().minute(),
      })

      return (
        end.isAfter(moment(), 'minute') &&
        moment(start).isBefore(moment(), 'minute')
      )
    }

    return false
  }, [schedule, start])

  if (!schedule) {
    return <></>
  }

  const getCode = () => {
    const type =
      schedule.type === ScheduledCourseTypes.IN_PERSON
        ? COURSE_FILTER_FORMATS.IN_PERSON
        : COURSE_FILTER_FORMATS.WEBINAR

    const entityCode = schedule.liveCourse
      ? schedule.liveCourse.course.code
      : schedule.trainingDay.code

    return `${getCourseFormatPrefix(type)}-${entityCode}`
  }

  const getPageTitle = () => {
    if (schedule.entityType === ScheduledEntitiesTypes.COURSE) {
      const entityName = _.get(
        schedule.liveCourse.course,
        'translations[0].content.name',
        '-',
      )

      return `${entityName} ${getCode()}`
    }

    return schedule.trainingDay.name
  }

  const getEntityDetail = (value: 'name' | 'desc') => {
    if (schedule.entityType === ScheduledEntitiesTypes.COURSE) {
      return _.get(
        schedule.liveCourse.course,
        `translations[0].content.${value}`,
        '-',
      )
    }

    return _.get(schedule.trainingDay, value, '-')
  }

  const tabChange = (tab: TRAINING_DAY_TABS) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={getPageTitle()}
            items={[
              {
                linkTo: '/courses',
                title: 'All Courses',
              },
              {
                active: true,
                title: _.capitalize(getPageTitle()),
              },
            ]}
          />
          <Row>
            {start && schedule && (
              <GeneralInfo
                getEntityDetail={getEntityDetail}
                start={start}
                onReleaseTest={onReleaseTest}
                schedule={schedule}
                disableReleaseBtn={!scheduleInProgress}
              />
            )}

            <Col sm={7} xl={9}>
              {schedule.entityType === ScheduledEntitiesTypes.TRAINING_DAY && (
                <Nav
                  className='nav nav-tabs nav-tabs-custom rounded bg-white course-package-tabs p-2 pb-0'
                  role='tablist'
                >
                  {tabs.map((tab, index) => (
                    <NavItem key={index}>
                      <NavLink
                        to='#'
                        className={`${classnames({
                          active: activeTab === tab.key,
                        })} cursor-pointer`}
                        onClick={() => {
                          tabChange(tab.key)
                        }}
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              )}
              <TabContent activeTab={activeTab}>
                <TabPane tabId={TRAINING_DAY_TABS.ATTENDANCE}>
                  <AssignmentsList
                    schedule={schedule}
                    start={start}
                    entityName={getEntityDetail('name')}
                    code={getCode()}
                    fetchAssignmentsList={fetchAssignmentsList}
                    router={router}
                  />
                </TabPane>
                {activeTab === TRAINING_DAY_TABS.COURSES && (
                  <TabPane tabId={TRAINING_DAY_TABS.COURSES}>
                    <TrainingDayCourses
                      trainingDayId={schedule.entityId}
                      onReleaseTest={onReleaseTest}
                      disableReleaseBtn={!scheduleInProgress}
                    />
                  </TabPane>
                )}
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default withRouter(FacilitatorView)
