import React from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { useState } from 'react'
import {
  TPushNotification,
  StatusTypes,
} from '../../sharedTypes/models/pushNotifications'

export interface IPushNotificationsPermissions {
  add: boolean
  edit: boolean
  view: boolean
  send: boolean
  delete: boolean
}

interface PushNotificationDropDownProps {
  notification: TPushNotification
  permissions: IPushNotificationsPermissions
  onDelete: () => void
  onDuplicate: () => void
  onEdit: () => void
  onSend: () => void
  onView: () => void
}

export const PushNotificationDropDown = ({
  notification,
  permissions,
  onView,
  onSend,
  onEdit,
  onDuplicate,
  onDelete,
}: PushNotificationDropDownProps) => {
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)
  const isScheduled = notification.status === StatusTypes.SCHEDULED
  const isSent = notification.status === StatusTypes.SENT
  const isClickable = isScheduled || isSent
  const disabledClasses = 'text-disabled pe-none'
  return (
    <Dropdown
      isOpen={actionDropdownOpen}
      toggle={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setActionDropdownOpen(prevState => !prevState)
      }}
    >
      <DropdownToggle
        className={`badge btn ${
          actionDropdownOpen
            ? 'btn-primary'
            : 'text-light-purple btn-soft-primary'
        }`}
        tag='button'
      >
        <i className='mdi mdi-dots-horizontal align-middle' />
      </DropdownToggle>
      <DropdownMenu container={'body'}>
        {permissions.view && (
          <DropdownItem
            className={`d-flex gap-2 ${isClickable ? '' : disabledClasses}`}
            onClick={onView}
          >
            <i
              className='ri-eye-line cursor-pointer'
              id={`viewNotification${notification.id}`}
            />
            View
          </DropdownItem>
        )}

        {permissions.send && (
          <DropdownItem
            className={`${
              isScheduled ? 'cursor-pointer' : disabledClasses
            } d-flex gap-2`}
            onClick={onSend}
          >
            <i
              className={`ri-external-link-line`}
              id={`sendNotification${notification.id}`}
            />
            Send
          </DropdownItem>
        )}

        {permissions.edit && (
          <DropdownItem
            className={`${
              isSent ? disabledClasses : 'cursor-pointer'
            } d-flex gap-2`}
            onClick={onEdit}
          >
            <i
              className={`ri-pencil-line`}
              id={`sendNotification${notification.id}`}
            />
            Edit
          </DropdownItem>
        )}
        {permissions.add && (
          <DropdownItem
            className={'cursor-pointer d-flex gap-2'}
            onClick={onDuplicate}
          >
            <i
              className={`bx bx-duplicate cursor-pointer`}
              id={`sendNotification${notification.id}`}
            />
            Duplicate
          </DropdownItem>
        )}
        {permissions.delete && (
          <DropdownItem
            className={`${
              isSent ? disabledClasses : 'text-danger cursor-pointer'
            } d-flex gap-2`}
            onClick={onDelete}
          >
            <i
              className={`ri-delete-bin-2-line`}
              id={`sendNotification${notification.id}`}
            />
            Delete
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}
