export type ResourceCategoryModalValues = {
  id: number
  name: string
  faq: number[]
  tutorials: number[]
  icon: any
}

export interface PrintTranscriptModalValues {
  dateFrom?: Date
  dateTo?: Date
  rangeType?: string
}

export enum notificationOption {
  'MINUTES' = 'minutes',
  'HOURS' = 'hours',
  'DAYS' = 'days',
}

export enum AssignTo {
  USERS = 'users',
  POSITIONS = 'positions',
  FACILITIES = 'facilities',
  DEPARTMENTS = 'departments',
  AGENCIES = 'agencies',
  COMPANIES = 'companies',
}

export interface IAssignToOption {
  value: AssignTo
  label: string
}
export const AssignToOptions: IAssignToOption[] = [
  { value: AssignTo.USERS, label: 'Users' },
  { value: AssignTo.POSITIONS, label: 'Positions' },
  { value: AssignTo.FACILITIES, label: 'Facilities' },
  { value: AssignTo.DEPARTMENTS, label: 'Departments' },
  { value: AssignTo.AGENCIES, label: 'Agencies' },
  { value: AssignTo.COMPANIES, label: 'Companies' },
]

export interface FilterOption {
  value: number | string
  label: string
}
