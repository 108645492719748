import ExportConfirmation from '../../../Components/Modals/PrimaryConfirmationModal'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { handleError } from '../../../helpers/toast_helper'
import { EXPORT_TYPES } from '../CourseHistory/types'
import { jsPDF } from 'jspdf'
import { renderToString } from 'react-dom/server'
import html2canvas from 'html2canvas'
import { addImageToPdf, generateXLSOrCSV } from '../../../helpers/export'
import { GetCourseReportDTO } from '../../../sharedTypes/api/courseReport'
import _ from 'lodash'
import { getCourseReport } from '../../../helpers/api/courses'
import ExportCourseReportPdf from './ExportCourseReportPdf'
import { generateFileName } from '../../../helpers/getExportFileName'

interface ExportUserReportProps {
  exportType: EXPORT_TYPES
  setExportType: Dispatch<SetStateAction<EXPORT_TYPES | null>>
  count: number
  query: GetCourseReportDTO.Request
}

const ROWS_PER_PAGE = 15

const fileHeaders = [
  'Code',
  'Name',
  'Type',
  'Format',
  'Number of Completion',
  'Avg. Test Score (1st attempt)',
  'Avg. Days Taken to Complete the Course',
  'Retake Test',
  'Retake Course',
  'Avg. User Rating',
]

const ExportCourseReport = ({
  exportType,
  setExportType,
  count,
  query,
}: ExportUserReportProps) => {
  const [data, setData] = useState<GetCourseReportDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    courseReport: [],
  })

  const [dataGetSuccess, setDataGetSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const dataToExport = useMemo(() => {
    return data.courseReport.map(row => ({
      code: row.code,
      courseName: _.get(row.course, 'translations[0].content.name', '-'),
      type: row.course.type,
      format: row.courseFormat,
      completions: row.completions,
      avgScoreFirstAttempt: row.avgScoreFirstAttempt + '%',
      avgDaysToComplete: row.avgDaysToComplete,
      retakeTest: row.retakeTest + '%',
      retakeCourse: row.retakeCourse ? row.retakeCourse + '%' : '-',
      avgUserRating: row.avgUserRating,
    }))
  }, [data.courseReport])

  const getFilteredCourseReport = () => {
    setIsLoading(true)
    const { limit, ...courseReportQuery } = query
    getCourseReport(courseReportQuery)
      .then(res => {
        setData(res)
        setDataGetSuccess(true)
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (dataGetSuccess) {
      exportData()
    }
  }, [data, dataGetSuccess])

  useEffect(() => {
    if (exportType && count <= 100) {
      getFilteredCourseReport()
    }
  }, [exportType, count])

  const exportData = () => {
    if (exportType === EXPORT_TYPES.PDF) {
      generatePDF()
    } else {
      // XLS or CSV
      generateXLSOrCSV(
        exportType,
        fileHeaders,
        dataToExport,
        generateFileName('Course_Report'),
      )
      setIsLoading(false)
      setExportType(null)
    }
  }

  const generatePDF = () => {
    const pdf = new jsPDF({
      unit: 'px',
      format: 'a4',
      orientation: 'landscape',
    })

    const totalPages = Math.ceil(data.courseReport.length / ROWS_PER_PAGE)

    addContentToPDF(0, pdf, totalPages)
  }

  const addContentToPDF = (
    currentPageIndex: number,
    pdf: jsPDF,
    totalPages: number,
  ) => {
    const pdfContent = document.createElement('div')

    const startIndex = currentPageIndex * ROWS_PER_PAGE
    const endIndex = Math.min(
      startIndex + ROWS_PER_PAGE,
      data.courseReport.length,
    )
    const chunk = data.courseReport.slice(startIndex, endIndex)
    pdfContent.innerHTML = renderToString(
      <ExportCourseReportPdf
        courseReport={chunk}
        page={currentPageIndex + 1}
        query={query}
      />,
    )

    document.body.appendChild(pdfContent)
    html2canvas(pdfContent).then(function (canvas) {
      addImageToPdf(canvas, pdf, currentPageIndex)

      if (totalPages > 1) {
        currentPageIndex = currentPageIndex + 1
        totalPages = totalPages - 1
        addContentToPDF(currentPageIndex, pdf, totalPages)
      } else {
        const pdfBlob = new Blob([pdf.output('blob')], {
          type: 'application/pdf',
        })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        link.href = pdfUrl
        link.download = `${generateFileName('Course_Report')}.pdf`
        link.click()
        setIsLoading(false)
        setExportType(null)
      }
    })

    if (document.body.contains(pdfContent)) {
      document.body.removeChild(pdfContent)
    }
  }

  return (
    <>
      {exportType && count > 100 && (
        <ExportConfirmation
          onConfirm={getFilteredCourseReport}
          onClose={() => setExportType(null)}
          isOpen={!!exportType}
          message={`You have ${count} results in this export. Go back to filter or continue to export?`}
          title={'Warning'}
          confirmButtonLabel={'Export'}
          cancelLabel={'Go Back'}
          icon={'ri-error-warning-line'}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default ExportCourseReport
