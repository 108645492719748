import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import _ from 'lodash'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { SearchInput } from '../../../Components/Common/SearchInput'
import FilterTabs from './Tabs'
import {
  AdminsSortBy,
  GetAdminsDTO,
  OrderType,
  TUser,
  USER_STATUSES,
  UserManagementPermissions,
} from '../../../sharedTypes'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { deactivateAdminById, getAdmins } from '../../../helpers/api_helper'
import AdminModal from '../../../Components/Modals/AdminModal'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import { Pagination } from '../../../Components/Common/Pagination'
import UserCell from '../../../Components/Common/UserItemCell'
import DeactivateModal from '../../../Components/Modals/DeactivateModal'
import { toast } from 'react-toastify'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import { usePermissions } from '../../../hooks/usePermissions'
import { PatchAdminsDTO } from '../../../sharedTypes'
import { patchAdmin } from '../../../helpers/api_helper'
import { useAppSelector } from '../../../hooks/redux'
import Highlighter from 'react-highlight-words'
import UserAssociations from '../../../Components/Common/UserAssociations'
import ItemsPerPageDropdown from '../../../Components/Common/ItemsPerPageDropdown'

const Columns = [
  {
    name: 'ID',
    sortBy: AdminsSortBy.ID,
    style: { width: 70 },
  },
  {
    name: 'Admin',
    sortBy: AdminsSortBy.USER,
    style: { width: 230 },
  },
  {
    name: 'Position',
    sortBy: AdminsSortBy.POSITION,
    style: { minWidth: 100 },
  },
  {
    name: 'Role',
    sortBy: AdminsSortBy.ROLE_NAME,
    style: { minWidth: 80 },
  },
  {
    name: 'Group',
    sortBy: AdminsSortBy.GROUP_NAME,
    style: { minWidth: 80 },
  },
  {
    name: 'Facility',
    style: { minWidth: 100 },
  },
  {
    name: 'Department',
    style: { minWidth: 100 },
  },
  {
    name: 'Status',
    style: { width: 100, minWidth: 100 },
    sortBy: AdminsSortBy.STATUS,
  },
  {
    name: 'Actions',
    style: { width: 100 },
  },
]

export const BadgeColorByStatus = {
  [USER_STATUSES.ACTIVE]: 'primary',
  [USER_STATUSES.INACTIVE]: 'warning',
}

const AdminListing = () => {
  document.title = 'Admin listing | Mastered - Admin & Dashboard'

  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )

  const [deactivateAdmin, setDeactivateAdmin] = useState<TUser | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [showAdminModal, setShowAdminModal] = useState(false)
  const [editAdmin, setEditAdmin] = useState<TUser | undefined>()
  const [data, setData] = useState<GetAdminsDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    admins: [],
  })

  const [query, setQuery] = useState<GetAdminsDTO.Request>({
    page: 1,
    limit: 10,
    permission: UserManagementPermissions.VIEW_ADMIN_LISTING,
  })

  const isFiltering = useMemo<boolean>(
    () => _.size(query.facilityIds) > 0 || !!query.status || !!query.key,
    [query],
  )

  const hasPermissionToAdd = usePermissions(
    UserManagementPermissions.ADD_NEW_ADMIN,
  )
  const hasPermissionToEdit = usePermissions(
    UserManagementPermissions.EDIT_ADMIN,
  )
  const hasPermissionToDeactivate = usePermissions(
    UserManagementPermissions.DEACTIVATE_ADMIN,
  )

  const hasPermissionToViewProfile = usePermissions(
    UserManagementPermissions.VIEW_USER_PROFILE,
  )

  const handleSort = useCallback((column: AdminsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      getAdmins({
        ..._.omitBy(query, _.isNil),
        permission: UserManagementPermissions.VIEW_ADMIN_LISTING,
      })
        .then(d => d.data)
        .then(res => {
          setData(res)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  const onEditAdmin = useCallback((edit: TUser) => {
    setEditAdmin(edit)
    setShowAdminModal(true)
  }, [])

  useEffect(() => {
    if (selectedGroupId && !_.isEqual([selectedGroupId], query.groupIds)) {
      setQuery(q => ({ ...q, groupIds: [selectedGroupId], facilityIds: [] }))
    }

    if (
      selectedFacilityId &&
      !_.isEqual([selectedFacilityId], query.facilityIds)
    ) {
      setQuery(q => ({ ...q, facilityIds: [selectedFacilityId], groupIds: [] }))
    }

    if (!selectedFacilityId && !selectedGroupId) {
      setQuery(q => ({ ...q, facilityIds: [], groupIds: [] }))
    }
    setFetchData(true)
  }, [selectedFacilityId, selectedGroupId])

  const onCloseAdminModal = () => {
    setEditAdmin(undefined)
    setShowAdminModal(false)
  }

  const onSubmitAdminModal = useCallback((data: PatchAdminsDTO.Request) => {
    return patchAdmin(data)
      .then(res => {
        toast(
          `Admin was successfully ${data.id ? 'updated' : 'created'}`,
          successToastOptions,
        )
        if (data.id) {
          setData(_data => {
            return {
              ..._data,
              admins: _data.admins.map(admin => {
                if (admin.id === res.id) {
                  return res
                }
                return admin
              }),
            }
          })
        } else {
          setQuery(q => ({ ...q, page: 1 }))
        }
        setShowAdminModal(false)
        setEditAdmin(undefined)
      })
      .catch(handleError)
  }, [])

  const onDeactivate = () => {
    if (!deactivateAdmin) {
      return
    }
    deactivateAdminById(deactivateAdmin.id)
      .then(({ data }) => {
        toast(
          `Success - Admin successfully ${
            deactivateAdmin.deactivated ? 'activated' : 'deactivated'
          }`,
          successToastOptions,
        )
        setData(_data => {
          return {
            ..._data,
            admins: _data.admins.map(admin => {
              if (admin.id === deactivateAdmin.id) {
                return data
              }
              return admin
            }),
          }
        })
        setDeactivateAdmin(undefined)
      })
      .catch(handleError)
  }

  const columns = useMemo(() => {
    let _columns =
      user?.isFacilityAdmin && !user?.hasAccessToMultipleFacilities
        ? Columns.filter(column => column.name !== 'Facility')
        : [...Columns]

    if (!hasPermissionToEdit && !hasPermissionToDeactivate) {
      _columns = _columns.filter(column => column.name !== 'Actions')
    }

    if (!user?.companyId) {
      _columns = _columns.filter(
        column => !['Group', 'Facility', 'Department'].includes(column.name),
      )
    }

    return _columns
  }, [user, hasPermissionToEdit, hasPermissionToDeactivate])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Admin Listing'
            items={[
              {
                linkTo: '/',
                title: 'User Management',
              },
              {
                active: true,
                title: 'Admin Listing',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <FilterTabs
                      navTab={query.status}
                      navToggle={status => {
                        setQuery(prev => ({ ...prev, status, page: 1 }))
                      }}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      {hasPermissionToAdd && (
                        <Button
                          color={'primary'}
                          className='btn btn-primary align-middle'
                          onClick={() => setShowAdminModal(true)}
                        >
                          <i className='ri-edit-box-line me-1 fs-16'></i>Add New
                          Admin
                        </Button>
                      )}
                    </div>
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={isFiltering}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-x-auto'>
                      <Table className='align-middle mb-0'>
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                {column.name}
                                {!!column.sortBy && (
                                  <ColumnSortIcon<AdminsSortBy>
                                    sortOrder={query.orderBy}
                                    sortedColumn={query.sortBy}
                                    column={column.sortBy}
                                    handleSort={handleSort}
                                  />
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.admins.map(admin => (
                            <tr
                              key={admin.id}
                              className='fs-14'
                              onClick={
                                hasPermissionToEdit
                                  ? () => onEditAdmin(admin)
                                  : undefined
                              }
                            >
                              <td
                                scope='row'
                                onClick={e => {
                                  e.stopPropagation()
                                  if (hasPermissionToViewProfile) {
                                    window.open(
                                      `/user-listing/profile/${admin.id}`,
                                      '_blank',
                                    )
                                  }
                                }}
                              >
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  className='text-primary cursor-pointer'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={admin.id.toString()}
                                />
                              </td>
                              <UserCell user={admin} globalSearch={query.key} />
                              <td>
                                <span>{admin.rawPosition}</span>
                              </td>
                              <td>{admin.role?.name}</td>
                              {user?.companyId && (
                                <>
                                  <td>{admin.group?.name}</td>

                                  {(!user?.isFacilityAdmin ||
                                    user?.hasAccessToMultipleFacilities) && (
                                    <UserAssociations
                                      data={admin.adminFacilities || []}
                                      key={query.key || ''}
                                      type={'facility'}
                                    />
                                  )}

                                  <UserAssociations
                                    data={admin.adminDepartments || []}
                                    key={query.key || ''}
                                  />
                                </>
                              )}

                              <td>
                                <span
                                  className={`badge badge-soft-${
                                    BadgeColorByStatus[admin.status]
                                  } fs-12 fw-normal text-capitalize`}
                                >
                                  {admin.status}
                                </span>
                              </td>
                              {(hasPermissionToDeactivate ||
                                hasPermissionToEdit) && (
                                <td>
                                  <div className='hstack align-items-center gap-2'>
                                    {hasPermissionToDeactivate &&
                                      admin.id !== user?.id && (
                                        <div
                                          className='form-check form-switch mb-0'
                                          onClick={e => {
                                            e.stopPropagation()
                                          }}
                                        >
                                          <Input
                                            className='form-check-input'
                                            type='checkbox'
                                            role='switch'
                                            checked={!admin.deactivated}
                                            onChange={() => {
                                              setDeactivateAdmin(admin)
                                            }}
                                          />
                                        </div>
                                      )}
                                    {hasPermissionToEdit && (
                                      <>
                                        <UncontrolledTooltip
                                          placement='top'
                                          target={'admin' + admin.id}
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                        <i
                                          className='ri-pencil-line cursor-pointer fs-13'
                                          id={'admin' + admin.id}
                                          onClick={() => onEditAdmin(admin)}
                                        ></i>
                                      </>
                                    )}
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className='mx-3 mt-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ? query.limit : 10}
                        onChange={limit =>
                          setQuery(prev => ({ ...prev, limit: +limit.label }))
                        }
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                        limit={query.limit}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {(hasPermissionToAdd || hasPermissionToEdit) && showAdminModal && (
        <AdminModal
          onClose={onCloseAdminModal}
          isOpen={showAdminModal}
          onSubmit={onSubmitAdminModal}
          title={editAdmin ? 'Edit Admin' : 'New Admin'}
          editAdmin={editAdmin}
        />
      )}

      {deactivateAdmin && (
        <DeactivateModal
          onClose={() => setDeactivateAdmin(undefined)}
          isOpen={true}
          user={deactivateAdmin}
          onDeactivate={onDeactivate}
          isActivate={deactivateAdmin.deactivated}
        />
      )}
    </React.Fragment>
  )
}

export default AdminListing
