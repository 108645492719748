import * as XLSX from 'xlsx'
import { EXPORT_TYPES } from '../pages/Reporting/CourseHistory/types'
import { jsPDF } from 'jspdf'
import { calculateAspectRatioFit } from '../pages/Calendar'

export const generateXLSOrCSV = (
  exportType: EXPORT_TYPES,
  fileHeaders: string[],
  dataToExport: Array<any>,
  exportFileName: string,
) => {
  // XLS or CSV
  const worksheet = XLSX.utils.json_to_sheet(dataToExport)

  XLSX.utils.sheet_add_aoa(worksheet, [fileHeaders])
  let url = ''

  if (exportType === EXPORT_TYPES.XLS) {
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xls',
      type: 'array',
    })

    const file = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    })
    url = URL.createObjectURL(file)
  } else {
    const csvData = XLSX.utils.sheet_to_csv(worksheet)

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    url = window.URL.createObjectURL(blob)
  }

  const fileName =
    exportType === EXPORT_TYPES.CSV
      ? `${exportFileName}.csv`
      : `${exportFileName}.xls`
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()

  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}

export const addImageToPdf = (
  canvas: HTMLCanvasElement,
  pdf: jsPDF,
  currentPageIndex: number,
) => {
  const imgData = canvas.toDataURL('image/jpeg')

  const { width, height } = calculateAspectRatioFit(
    canvas.width,
    canvas.height,
    pdf.internal.pageSize.getWidth() - 30,
    pdf.internal.pageSize.getHeight() - 30,
  )
  if (currentPageIndex > 0) {
    pdf.addPage()
  }

  const imageWidth = pdf.internal.pageSize.getWidth() - 30
  const x = (pdf.internal.pageSize.getWidth() - imageWidth) / 2 // Calculate x coordinate for centering

  pdf.addImage({
    imageData: imgData,
    x,
    y: 30,
    width: imageWidth,
    height: (height * imageWidth) / width,
    compression: 'NONE',
  })
}
