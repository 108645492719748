import React from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'
import { TRole } from '../../../sharedTypes'

interface CreateNewRoleModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  role: TRole | null
  onDelete: () => void
}

const DeleteRoleModal = ({
  onClose,
  isOpen,
  role,
  onDelete,
}: CreateNewRoleModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-danger text-danger'>
              <i className='ri-delete-bin-2-line' style={{ fontSize: 62 }}></i>
            </div>
          </div>

          <h3 className='mt-4'>Delete Role</h3>
          <span className='text-muted fw-light'>
            Are you sure you want to delete role {role?.name}?
          </span>
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-primary w-100'
            onClick={onClose}
            active={false}
          >
            Cancel
          </Button>
          <Button color='danger' className='w-100' onClick={onDelete}>
            Delete
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteRoleModal
