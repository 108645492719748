import React, { useCallback, useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'
import Highlighter from 'react-highlight-words'
import {
  OrderType,
  DocumentSortBy,
  GetDocumentsDTO,
} from '../../../sharedTypes'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { getDocumentsDTO, postDocument } from '../../../helpers/api_helper'
import moment from 'moment'
import DocumentModal from './DocumentModal'
import {
  IDocumentItem,
  DocumentModalSubmitedValues,
  DocumentModalInitialValues,
} from '../../../sharedTypes'
import { FormikHelpers } from 'formik'
import { Pagination } from '../../../Components/Common/Pagination'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'

const Columns = [
  {
    name: 'Document',
    sortBy: DocumentSortBy.TYPE,
    style: { width: '23%' },
  },
  {
    sortBy: DocumentSortBy.VALID_DATE,
    name: 'Valid Date',
    style: { width: '23%' },
  },
  {
    sortBy: DocumentSortBy.EXPIRY_DATE,
    name: 'Expiry Date',
    style: { width: '23%' },
  },
  {
    name: 'ID',
    sortBy: DocumentSortBy.CERTIFICATED_ID,
    style: { width: '23%' },
  },
  {
    sortBy: DocumentSortBy.ORGANIZATION,
    name: 'Organization',
    style: { width: '23%' },
  },
  {
    name: 'Actions',
    style: { width: '8%' },
  },
]

const DocumentsTable = () => {
  const [createModal, setCreateModal] =
    useState<DocumentModalInitialValues | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetDocumentsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    documents: [],
  })

  const [query, setQuery] = useState<GetDocumentsDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    sortBy: DocumentSortBy.TYPE,
    orderBy: OrderType.ASC,
  })

  useEffect(() => {
    setIsLoading(true)
    getDocumentsDTO(query)
      .then(response => {
        setData(response)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const handleSort = useCallback((column: DocumentSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onCreate = useCallback(
    async (
      values: DocumentModalSubmitedValues,
      form: FormikHelpers<DocumentModalSubmitedValues>,
    ) => {
      try {
        form.setSubmitting(true)

        await postDocument(values)

        form.setSubmitting(false)

        form.resetForm()

        setQuery(prev => ({ ...prev, page: 1 }))

        setCreateModal(null)
      } catch (e: any) {}
    },
    [data.page],
  )

  return (
    <div className='vstack gap-4 '>
      <DocumentModal
        title='Upload Document'
        initialValues={createModal}
        onClose={() => {
          setCreateModal(null)
        }}
        isOpen={!!createModal}
        onSubmit={onCreate}
      />
      <div className='d-flex px-3 mx-n3 justify-content-between'>
        <SearchInput
          style={{ maxWidth: 400 }}
          onChange={key => {
            setQuery(prev => ({ ...prev, key, page: 1 }))
          }}
          value={query.key || ''}
        />
        <div className='d-flex flex-shrink-0 gap-3'>
          <Button
            color={'primary'}
            onClick={() => {
              setCreateModal({
                id: -1,
                type: '',
                certificateId: undefined,
                organization: undefined,
                validDate: undefined,
                expiryDate: undefined,
                document: undefined,
              })
            }}
            className='btn btn-primary align-middle'
          >
            <i className='ri-edit-box-line me-1 fs-16'></i>Upload
          </Button>
        </div>
      </div>
      <NoResultTableWrapper
        isLoading={isLoading}
        isFiltering={!!query.key}
        pages={data.pages}
      >
        <div className='table-card'>
          <Table className='align-middle table-nowrap mb-0'>
            <thead className='table-light'>
              <tr className='text-muted fs-14'>
                {Columns.map(column => (
                  <th
                    scope='col'
                    className='align-middle'
                    style={column.style}
                    key={column.name}
                  >
                    {column.name}
                    {!!column.sortBy && (
                      <ColumnSortIcon<DocumentSortBy>
                        sortOrder={query.orderBy}
                        sortedColumn={query.sortBy}
                        column={column.sortBy}
                        handleSort={handleSort}
                      />
                    )}{' '}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.documents.map((item: IDocumentItem, i) => (
                <tr key={i} className='fs-14 fw-light'>
                  <td>
                    <Highlighter
                      highlightClassName='text-highlight'
                      searchWords={[query.key || '']}
                      highlightTag={'span'}
                      autoEscape={true}
                      textToHighlight={item.type}
                    />
                  </td>
                  <td>
                    {moment(new Date(item.expiryDate)).format('MM/DD/YYYY')}
                  </td>
                  <td>
                    {moment(new Date(item.validDate)).format('MM/DD/YYYY')}
                  </td>
                  <td>
                    <span className='text-dark badge bg-light fs-12 fw-normal'>
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={item.certificateId}
                      />
                    </span>
                  </td>
                  <td>
                    <Highlighter
                      highlightClassName='text-highlight'
                      searchWords={[query.key || '']}
                      highlightTag={'span'}
                      autoEscape={true}
                      textToHighlight={item.organization}
                    />
                  </td>
                  <td>
                    <span className='d-flex gap-2'>
                      <i
                        onClick={() => {}}
                        className='ri-eye-line  cursor-pointer'
                      ></i>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className='mx-3 my-3'>
            <Pagination
              currentPage={data.page - 1}
              totalPages={data.pages}
              totalRecords={data.count}
              setPage={page => {
                setQuery(prev => ({ ...prev, page: ++page }))
              }}
            />
          </div>
        </div>
      </NoResultTableWrapper>
    </div>
  )
}

export default DocumentsTable
