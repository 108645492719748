import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import {
  AttachmentsAccessEnum,
  AttachmentsSortBy,
  AttachmentsTypesEnum,
  GetAttachmentsDTO,
  TAttachment,
  BadgeColorByAttachmentAccess,
  BadgeColorByAttachmentType,
  CoursesPermissions,
} from '../../../sharedTypes'
import Highlighter from 'react-highlight-words'
import { Table, UncontrolledTooltip } from 'reactstrap'
import { getUserDisplayName } from '../../../helpers/user'
import moment from 'moment/moment'
import React, { useCallback } from 'react'
import MIcon from '../../../assets/images/svg/M.svg'
import { usePermissions } from '../../../hooks/usePermissions'

const Columns = [
  {
    name: '',
    style: { width: 73 },
  },
  {
    name: 'Name',
    sortBy: AttachmentsSortBy.NAME,
  },
  {
    name: 'Type',
    sortBy: AttachmentsSortBy.TYPE,
  },
  {
    name: 'Access',
    sortBy: AttachmentsSortBy.ACCESS,
    style: { width: 90, 'text-align': 'center' },
  },
  {
    name: 'Uploaded by',
    sortBy: AttachmentsSortBy.USER,
  },
  {
    name: 'Uploaded date',
    sortBy: AttachmentsSortBy.DATE,
  },
  {
    name: 'Actions',
    style: { width: 100 },
  },
]

interface MediaTableViewProps {
  query: GetAttachmentsDTO.Request
  attachments: TAttachment[]
  handleSort: (column: AttachmentsSortBy) => void
  setDeleteMedia: (media: TAttachment) => void
  onShow: (media: TAttachment) => void
  onRowClick?: (id: number) => void
  isFromModal?: boolean
  selectedRowId?: number | null
}

const MediaTableView = ({
  query,
  attachments,
  handleSort,
  setDeleteMedia,
  onShow,
  onRowClick = () => {},
  isFromModal = false,
  selectedRowId,
}: MediaTableViewProps) => {
  const hasPermissionToView = usePermissions(CoursesPermissions.VIEW_MEDIA)
  const hasPermissionToDelete = usePermissions(CoursesPermissions.DELETE_MEDIA)

  const getColumns = useCallback(() => {
    if (!hasPermissionToView && !hasPermissionToDelete) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [])

  return (
    <Table className='align-middle table-nowrap mb-0'>
      <thead className='table-light'>
        <tr className='text-muted fs-14'>
          {getColumns().map(column => (
            <th
              scope='col'
              className='align-middle'
              style={column.style}
              key={column.name}
            >
              {column.name}
              {!!column.sortBy && (
                <ColumnSortIcon<AttachmentsSortBy>
                  sortOrder={query.orderBy}
                  sortedColumn={query.sortBy}
                  column={column.sortBy}
                  handleSort={handleSort}
                />
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {attachments.map((attachment, i) => (
          <tr
            key={i}
            className={`fs-14 ${
              selectedRowId === attachment.id ? 'selected-row' : ''
            }`}
            onClick={() => onRowClick(attachment.id)}
          >
            <td>
              <div className='avatar-sm flex-shrink-0'>
                <span className='avatar-title rounded fs-3 image-container'>
                  {attachment.type === AttachmentsTypesEnum.DOCUMENT && (
                    <i className='ri-file-list-3-line'></i>
                  )}
                  {attachment.type === AttachmentsTypesEnum.IMAGE && (
                    <img
                      alt={attachment.name}
                      src={attachment.path}
                      className='rounded avatar-sm'
                    ></img>
                  )}
                  {attachment.type === AttachmentsTypesEnum.VIDEO && (
                    <video
                      data-dz-thumbnail=''
                      autoPlay={false}
                      controls={false}
                      className='avatar-sm rounded bg-body'
                      src={attachment.path}
                    />
                  )}
                </span>
              </div>
            </td>
            <td>
              <Highlighter
                highlightClassName='text-highlight'
                searchWords={[query.key || '']}
                highlightTag={'span'}
                autoEscape={true}
                textToHighlight={attachment.name}
              />
            </td>
            <td>
              <span
                className={`badge badge-${
                  BadgeColorByAttachmentType[attachment.type]
                } fs-12 fw-normal text-capitalize`}
              >
                {attachment.type}
              </span>
            </td>
            <td className='text-center'>
              <UncontrolledTooltip
                placement='top'
                target={'accessAttachment' + attachment.id}
              >
                {attachment.access} Access
              </UncontrolledTooltip>
              <span
                className={`badge badge-${
                  BadgeColorByAttachmentAccess[attachment.access]
                } fs-14 fw-normal p-1`}
                id={'accessAttachment' + attachment.id}
              >
                {attachment.access === AttachmentsAccessEnum.MASTERED ? (
                  <img src={MIcon} className='img-fluid' alt='' />
                ) : (
                  <i className='ri-shield-keyhole-line fs-16'></i>
                )}
              </span>
            </td>
            <td>
              <p className='card-title mb-0 fs-14'>
                <Highlighter
                  highlightClassName='text-highlight'
                  searchWords={[query.key || '']}
                  highlightTag={'span'}
                  autoEscape={true}
                  textToHighlight={getUserDisplayName(attachment.uploadedBy)}
                />
              </p>
            </td>
            <td>
              <span className='badge badge-soft-grey fs-12 fw-normal text-capitalize'>
                {moment(attachment.createdAt).format('MM/DD/YYYY')}
              </span>
            </td>
            {(hasPermissionToView || hasPermissionToDelete) && (
              <td>
                <div className='d-flex justify-content-between'>
                  <span className='d-flex gap-2 text-muted justify-content-end'>
                    {hasPermissionToView && (
                      <>
                        <UncontrolledTooltip
                          placement='top'
                          target={'viewAttachment' + attachment.id}
                        >
                          View
                        </UncontrolledTooltip>
                        <i
                          className='ri-eye-line cursor-pointer'
                          id={'viewAttachment' + attachment.id}
                          onClick={() => onShow(attachment)}
                        ></i>
                      </>
                    )}

                    {attachment.canDelete &&
                      !isFromModal &&
                      hasPermissionToDelete && (
                        <UncontrolledTooltip
                          placement='top'
                          target={'deleteAttachment' + attachment.id}
                        >
                          Delete
                        </UncontrolledTooltip>
                      )}
                    {!isFromModal && hasPermissionToDelete && (
                      <i
                        className={`ri-delete-bin-2-line ${
                          attachment.canDelete
                            ? 'cursor-pointer text-danger'
                            : 'text-disabled'
                        }`}
                        id={'deleteAttachment' + attachment.id}
                        onClick={
                          attachment.canDelete && hasPermissionToDelete
                            ? () => setDeleteMedia(attachment)
                            : undefined
                        }
                      ></i>
                    )}
                  </span>
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default MediaTableView
