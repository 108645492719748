import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import {
  CommunicationPermissions,
  SettingsPermissions,
  SurveyStatusTypes,
  TSurveyData,
} from '../../../sharedTypes'
import { IRecipientsData } from '../../../Components/Modals/PushNotificationModal'
import { getUserDisplayName } from '../../../helpers/user'
import { getSurveyById } from '../../../helpers/api/surveys'
import classnames from 'classnames'
import Preview from './View/Preview'
import GeneralInfo from './View/GeneralInfo'
import Overview from './View/Overview'
import Responses from './View/Responses'
import { usePermissions } from '../../../hooks/usePermissions'

interface Tabs {
  value: string
  label: string
  permission: SettingsPermissions
}
const ViewSurvey = () => {
  document.title = 'Survey Details | Mastered - Admin & Dashboard'

  const navigate = useNavigate()
  const { id } = useParams()
  const [data, setData] = useState<TSurveyData>()

  const [recipientsData, setRecipientsData] = useState<IRecipientsData[]>([])
  const permissions = {
    edit: usePermissions(CommunicationPermissions.EDIT_SURVEY),
    view: usePermissions(CommunicationPermissions.VIEW_SURVEYS),
    responses: usePermissions(
      CommunicationPermissions.VIEW_INDIVIDUAL_SURVEY_RESPONSES,
    ),
    overview: usePermissions(
      CommunicationPermissions.VIEW_SURVEY_RESPONSE_OVERVIEW,
    ),
  }

  const [tabs, setTabs] = useState<Tabs[]>([
    {
      label: 'Preview',
      value: 'preview',
      permission: permissions.view,
    },
  ])
  const [activeTab, setActiveTab] = useState(tabs[0].value)

  useEffect(() => {
    if (id) {
      getSurveyById(+id).then(res => {
        setData(res)

        setTabs(
          tabs.concat([
            {
              label: 'Responses',
              value: 'responses',
              permission: permissions.responses,
            },
            {
              label: 'Overview',
              value: 'overview',
              permission: permissions.overview,
            },
          ]),
        )

        if (
          ![SurveyStatusTypes.DRAFT, SurveyStatusTypes.SCHEDULED].includes(
            res.survey.status,
          )
        ) {
          setTabs(
            tabs.concat([
              {
                label: 'Responses',
                value: 'responses',
                permission: permissions.responses,
              },
              {
                label: 'Overview',
                value: 'overview',
                permission: permissions.overview,
              },
            ]),
          )
        }
      })
    }
  }, [id])

  useEffect(() => {
    if (data?.recipients.length) {
      setRecipientsData(
        data.recipients.map((entity: any) =>
          'name' in entity ? entity.name : getUserDisplayName(entity) || '',
        ),
      )
    }
  }, [data?.recipients])

  const tabChange = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const renderTab = (activeTab: string) => {
    switch (activeTab) {
      case 'preview':
        return (
          <Preview
            questions={data?.survey.questions}
            languages={(data?.survey?.translations || []).map(t => t.language)}
            id={data?.survey.id}
            status={data?.survey.status || SurveyStatusTypes.DRAFT}
          />
        )
      case 'overview':
        return <Overview surveyId={Number(id)} />
      case 'responses':
        return (
          <Responses
            surveyId={Number(id)}
            questions={data?.survey.questions as any}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className='page-content'>
      <Container fluid className='survey-container'>
        <BreadCrumb
          title={'Surveys'}
          items={[
            {
              title: 'Surveys',
              linkTo: '/surveys',
            },
            {
              title: 'View Survey',
              active: true,
            },
          ]}
        />

        <Row>
          <Col md={4}>
            <Card className='news-manage-page'>
              <CardHeader className='border-bottom-0 text-muted pb-0 '>
                <div className='d-flex justify-content-between align-items-center'>
                  <h5 className='text-muted fw-light fs-16'>General Info</h5>
                  {data?.survey &&
                  [
                    SurveyStatusTypes.DRAFT,
                    SurveyStatusTypes.SCHEDULED,
                  ].includes(data.survey.status) &&
                  permissions.edit ? (
                    <span
                      className='badge text-capitalize text-light-purple fs-12 py-2 cursor-pointer'
                      onClick={() => navigate(`/surveys/manage/${id}`)}
                    >
                      <i className='ri-edit-box-line mx-1'></i>Edit
                    </span>
                  ) : null}
                </div>
              </CardHeader>
              <CardBody>
                <GeneralInfo
                  survey={data?.survey}
                  recipientsData={recipientsData}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={8}>
            <Card>
              <CardBody className='px-0'>
                <Row>
                  <Col md={12}>
                    <Nav
                      className='nav nav-tabs nav-tabs-custom rounded card-header-tabs px-3'
                      role='tablist'
                    >
                      {tabs.map((tab, index) => (
                        <NavItem key={index}>
                          {tab.permission && (
                            <NavLink
                              to='#'
                              className={`${classnames({
                                active: activeTab === tab.value,
                              })} cursor-pointer`}
                              onClick={() => {
                                tabChange(tab.value)
                              }}
                            >
                              {tab.label}
                            </NavLink>
                          )}
                        </NavItem>
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={activeTab}>
                        {renderTab(activeTab)}
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ViewSurvey
