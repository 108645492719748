import React from 'react'
import {
  getUserDisplayName,
  getUserPhotoDisplayName,
} from '../../../helpers/user'
import { Link } from 'react-router-dom'
import { TUser } from '../../../sharedTypes'
import { UncontrolledTooltip } from 'reactstrap'

interface Props {
  admins?: TUser[]
}

const AdminsBlock = ({ admins }: Props) => {
  const count = admins?.length || 0
  return (
    <div className='avatar-group'>
      {count === 1 && admins ? (
        <Link
          to='#'
          className='avatar-group-item d-flex align-items-center'
          style={{ transform: 'unset' }}
        >
          {admins[0].photo ? (
            <img
              src={admins[0].photo}
              alt=''
              className='rounded-circle avatar-xxs'
            />
          ) : (
            <div className='avatar-xxs'>
              <div className='avatar-title rounded-circle bg-soft-primary'>
                {getUserPhotoDisplayName(admins[0])}
              </div>
            </div>
          )}
          <span className='mx-2 text-black fw-light'>
            {getUserDisplayName(admins[0])}
          </span>
        </Link>
      ) : (
        admins?.map((admin, index) => (
          <Link key={index} to='#' className='avatar-group-item'>
            <UncontrolledTooltip placement='top' target={'admin' + admin.id}>
              {getUserDisplayName(admin)}
            </UncontrolledTooltip>
            {admin.photo ? (
              <img
                src={admin.photo}
                alt=''
                className='rounded-circle avatar-xxs'
                id={'admin' + admin.id}
              />
            ) : (
              <div className='avatar-xxs' id={'admin' + admin.id}>
                <div className='avatar-title rounded-circle bg-soft-primary'>
                  {getUserPhotoDisplayName(admin)}
                </div>
              </div>
            )}
            {count === 1 && (
              <span className='mx-2 text-black fw-light'>
                {getUserDisplayName(admin)}
              </span>
            )}
          </Link>
        ))
      )}
    </div>
  )
}
export default AdminsBlock
