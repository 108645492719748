import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { SurveyStatusTypes } from '../../../sharedTypes'
interface FilterTabsProps {
  navTab?: SurveyStatusTypes
  navToggle: (tab?: SurveyStatusTypes) => void
}

interface Tab {
  label: string
  value: SurveyStatusTypes | undefined
}

const tabs: Tab[] = [
  { label: 'All', value: undefined },
  { label: 'Draft', value: SurveyStatusTypes.DRAFT },
  { label: 'Scheduled', value: SurveyStatusTypes.SCHEDULED },
  { label: 'Sent', value: SurveyStatusTypes.SENT },
  { label: 'Completed', value: SurveyStatusTypes.COMPLETED },
]

const FilterTabs = ({ navTab, navToggle }: FilterTabsProps) => {
  return (
    <Nav className='nav-customs-bordered'>
      {tabs.map(tab => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
