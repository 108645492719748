import React, { useEffect } from 'react'
import withRouter, { WithRouterProps } from '../Components/Common/withRouter'
import { useAppDispatch } from '../hooks/redux'
import { useProfile } from '../Components/Hooks/UserHooks'
import { setAuthorization } from '../helpers/api_helper'
import { clearUser } from '../slices/auth/user/reducer'
import { Navigate } from 'react-router-dom'
import { useIs500Error } from '../hooks/network'
import Error500 from '../pages/AuthenticationInner/Errors/Error500'

interface NonAuthLayoutProps extends WithRouterProps {
  children: React.ReactNode
  forceLogin: boolean
}

const NonAuthLayout = ({ children, forceLogin }: NonAuthLayoutProps) => {
  const dispatch = useAppDispatch()
  const is500Error = useIs500Error()

  const { userProfile, token } = useProfile()
  useEffect(() => {
    if (userProfile && token) {
      setAuthorization(token)
    } else if (!userProfile && !token) {
      dispatch(clearUser())
    }
  }, [token, userProfile])

  useEffect(() => {
    document.body.setAttribute('data-layout-mode', 'light')
    document.body.style.backgroundColor = '#F0EFFD'
    return () => {
      document.body.removeAttribute('data-layout-mode')
      document.body.removeAttribute('style')
    }
  }, [])

  /*
    Navigate is un-auth access protected routes via url
    */

  if (token && forceLogin) {
    return <Navigate to={{ pathname: '/' }} />
  }

  if (is500Error) {
    return <Error500 />
  }

  return <div>{children}</div>
}

export default withRouter(NonAuthLayout)
