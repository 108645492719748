import React, { useCallback, useState } from 'react'
import { cloneOnlineCourse } from '../../helpers/api_helper'
import { successToastOptions } from '../../helpers/toast_helper'
import _ from 'lodash'
import CourseCloneModal from '../../Components/Modals/CourseCloneModal'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export const useCloneCourse = () => {
  const navigate = useNavigate()
  const [clonedCourseId, setClonedCourseId] = useState<number | null>(null)

  const onSubmit = useCallback(async () => {
    try {
      setClonedCourseId(null)
      const course = await cloneOnlineCourse(Number(clonedCourseId))
      toast('Success - Course has been duplicated', successToastOptions)
      navigate(`/courses/general/${course.id}`)
    } catch (e) {}
  }, [clonedCourseId])

  const renderCloneModal = useCallback(() => {
    return (
      <CourseCloneModal
        isOpen={!!clonedCourseId}
        onClose={() => setClonedCourseId(null)}
        onSubmit={onSubmit}
      />
    )
  }, [clonedCourseId, onSubmit])

  return { renderCloneModal, setClonedCourseId }
}
