import {
  COMPANY_STATUSES,
  CompanyAccountContact,
  CompanyBillingContact,
  DocumentFile,
  StatesEnum,
} from '../../sharedTypes'

export const BadgeColorByStatus = {
  [COMPANY_STATUSES.ACTIVE]: 'primary',
  [COMPANY_STATUSES.INACTIVE]: 'warning',
}

export interface ICompanyForm {
  name: string
  address: string
  city: string
  state?: { value: StatesEnum; label: StatesEnum }
  status: COMPANY_STATUSES
  activationDate?: Date
  notes?: string
  logo?: DocumentFile
  logoId?: number
  accountContact: CompanyAccountContact
  billingContact: CompanyBillingContact
}

export interface AnalyticsData {
  label: string
  value: number
  icon: string
}
