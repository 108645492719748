import React, { useEffect, useState } from 'react'
import { Col } from 'reactstrap'

import {
  AttachmentsTypesEnum,
  AttachmentsAccessEnum,
} from '../../../sharedTypes'
import MultiSelect from '../../../Components/Common/MultiSelect'
import { FilterOption } from '../../../sharedTypes'

export interface IFilters {
  type: AttachmentsTypesEnum[]
  access: AttachmentsAccessEnum[]
}

interface FiltersProps {
  setFilters: (filter: IFilters) => void
  visible: boolean
}

const Filters = ({ visible, setFilters }: FiltersProps) => {
  const [selectedTypes, setSelectedTypes] = useState<AttachmentsTypesEnum[]>([])
  const [selectedAccess, setSelectedAccess] = useState<AttachmentsAccessEnum[]>(
    [],
  )

  const onSelectType = (types: AttachmentsTypesEnum[]) => {
    setSelectedTypes(types)
  }
  const onSelectAccess = (accesses: AttachmentsAccessEnum[]) => {
    setSelectedAccess(accesses)
  }

  useEffect(() => {
    setFilters({
      type: selectedTypes,
      access: selectedAccess,
    })
  }, [selectedTypes, selectedAccess])

  if (!visible) {
    return null
  }

  return (
    <>
      <Col className='mt-0 p-0'>
        <div>
          <label htmlFor='type' className='form-label'>
            Filter by type
          </label>

          <MultiSelect
            name='type'
            id='type'
            isMulti
            options={Object.values(AttachmentsTypesEnum).map(value => ({
              value: value,
              label: value,
            }))}
            isSearchable={true}
            placeholder='Select type'
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={(selectedOptions: FilterOption[]) => {
              onSelectType(
                selectedOptions.map(
                  option => option.value,
                ) as AttachmentsTypesEnum[],
              )
            }}
          />
        </div>
      </Col>
      <Col className='mt-0 p-0'>
        <div>
          <label htmlFor='access' className='form-label'>
            Filter by access
          </label>

          <MultiSelect
            name='access'
            id='access'
            isMulti
            options={Object.values(AttachmentsAccessEnum)
              .filter(value => value !== 'Public')
              .map(value => ({
                value: value,
                label: value,
              }))}
            isSearchable={true}
            placeholder='Select access'
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={(selectedOptions: FilterOption[]) => {
              onSelectAccess(
                selectedOptions.map(
                  option => option.value,
                ) as AttachmentsAccessEnum[],
              )
            }}
          />
        </div>
      </Col>
    </>
  )
}

export default Filters
