import React, { useCallback, useMemo } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Form,
  Col,
  Row,
} from 'reactstrap'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import {
  COURSE_FILTER_FORMATS,
  TAssignment,
  TUser,
  UpdateAssignmentDTO,
} from '../../sharedTypes'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'
import {
  BadgeColorByAllFormats,
  loadScheduleOptions,
} from '../../helpers/course'
import AsyncSelect from 'react-select/async'

interface EditAssignmentModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  user: TUser
  assignment: TAssignment
  onSubmit: (
    values: UpdateAssignmentDTO.Request,
    formik: FormikHelpers<UpdateAssignmentDTO.Request>,
  ) => void
}

const EditAssignmentModal = ({
  onClose,
  isOpen,
  user,
  assignment,
  onSubmit,
}: EditAssignmentModalProps) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateAvailable: moment(assignment.dateAvailable).toDate(),
      dueDate: moment(assignment.dueDate).toDate(),
      scheduleId: assignment.scheduleId,
    },
    validationSchema: Yup.object({
      dateAvailable: Yup.date().required(),
      dueDate: Yup.date().required(),
    }),
    onSubmit: (values, formik) => {
      onSubmit(
        values as UpdateAssignmentDTO.Request,
        formik as FormikHelpers<UpdateAssignmentDTO.Request>,
      )
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  const assignmentItem = useMemo(
    () => ({
      scheduleId: assignment.scheduleId,
      course: assignment.course || assignment.package,
      format: assignment.format,
      isValid: true,
    }),
    [assignment],
  )

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Edit Assignment</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          />
        </div>

        <Row className='mb-4'>
          <Col md={3}>
            <span className='fw-medium text-body fs-14'>Users: </span>
          </Col>
          <Col md={8}>
            <span className='text-muted fs-14'>
              {`${user.firstName} ${user.lastName}`}
            </span>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col md={3}>
            <span className='fw-medium text-body fs-14'>Course: </span>
          </Col>
          <Col md={5}>
            <span className='text-muted fs-14'>
              {assignment.course?.translations[0].content.name ||
                assignment.package?.name}
            </span>
          </Col>
          <Col md={4} className='text-end'>
            <span
              className={`badge badge-${
                BadgeColorByAllFormats[assignment.format]
              } fs-12 fw-normal text-capitalize`}
            >
              {assignment.format}
            </span>
          </Col>
        </Row>

        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            {assignment.format === COURSE_FILTER_FORMATS.IN_PERSON ||
            assignment.format === COURSE_FILTER_FORMATS.WEBINAR ? (
              <div className='w-100 gap-2 flex-1 align-items-center justify-content-between'>
                <div>
                  <label htmlFor='date-available' className='form-label'>
                    Date/Time*
                  </label>
                  <div className='form-icon right'>
                    <AsyncSelect
                      className='select2-container'
                      classNamePrefix='select2-selection form-select'
                      isSearchable={false}
                      placeholder={'Select Date & Time'}
                      defaultOptions
                      loadOptions={() =>
                        loadScheduleOptions(
                          assignmentItem,
                          user.facilityId ?? undefined,
                        )
                      }
                      onChange={option => {
                        form.setFieldValue(
                          'dueDate',
                          moment(option?.label).toDate(),
                        )
                        form.setFieldValue('scheduleId', option?.value)
                      }}
                      value={{
                        value: form.values.scheduleId,
                        label: moment(form.values.dueDate).format(
                          'MM/DD/YYYY, hh:mm A',
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className='hstack w-100 gap-2 flex-1 align-items-center justify-content-between'>
                <div>
                  <label htmlFor='date-available' className='form-label'>
                    Date Available*
                  </label>
                  <div className='form-icon right'>
                    <Flatpickr
                      className={`form-control form-control-icon ${
                        !form.isValid && !form.values.dateAvailable
                          ? 'is-invalid'
                          : ''
                      } ${
                        moment(form.values.dateAvailable).isBefore(moment())
                          ? 'text-disabled'
                          : ''
                      }`}
                      id='date-available'
                      name='dateAvailable'
                      placeholder='--/--/----'
                      onChange={option => {
                        form.setFieldValue('dateAvailable', option[0])
                      }}
                      disabled={moment(form.values.dateAvailable).isBefore(
                        moment(),
                      )}
                      value={form.values.dateAvailable}
                      options={{
                        dateFormat: 'd-m-Y',
                        formatDate: date => moment(date).format('MM/DD/YYYY'),
                        minDate: moment(form.values.dateAvailable).isBefore(
                          moment(),
                        )
                          ? undefined
                          : Date.now(),
                        maxDate: form.values.dueDate,
                        allowInvalidPreload: true,
                      }}
                    />
                    <i className='ri-calendar-2-line fs-20 text-primary' />
                  </div>
                </div>
                <div>
                  <label htmlFor='due-date' className='form-label'>
                    Due Date*
                  </label>
                  <div className='form-icon right'>
                    <Flatpickr
                      className={`form-control form-control-icon ${
                        !form.isValid &&
                        'dueDate' in form.values &&
                        (!form.values.dueDate ||
                          !moment(form.values.dueDate).isAfter(
                            moment(form.values.dateAvailable),
                          ))
                          ? 'is-invalid'
                          : ''
                      }`}
                      id='due-date'
                      name='dueDate'
                      placeholder='--/--/----'
                      onChange={option => {
                        form.setFieldValue('dueDate', option[0])
                      }}
                      value={form.values.dueDate}
                      options={{
                        dateFormat: 'd-m-Y',
                        formatDate: date => moment(date).format('MM/DD/YYYY'),
                        minDate: form.values.dateAvailable || Date.now(),
                        allowInvalidPreload: true,
                      }}
                    />
                    <i className='ri-calendar-2-line fs-20 text-primary' />
                  </div>
                </div>
              </div>
            )}
            <div className='hstack gap-2 justify-content-end mt-4'>
              <Button
                className='btn-soft-primary'
                onClick={beforeClose}
                active={false}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                type='submit'
                className='btn btn-primary'
                disabled={!form.isValid || form.isSubmitting}
              >
                {form.isSubmitting ? <Spinner size={'sm'} /> : 'Save'}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default EditAssignmentModal
