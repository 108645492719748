import React, { useEffect, useContext } from 'react'
import { CourseLayout } from '../../../../Components/Course'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { OnlineCourseFormatsEnum } from '../../../../sharedTypes'
import { QuestionsModule } from '../../../../Components/Course/Questions'
import { QuestionableTypes } from '../../../../sharedTypes'
import { Col, Row } from 'reactstrap'
import { CourseContext } from '../../../../context/CourseContext'

const KnowledgeCheck = ({ router }: WithRouterProps) => {
  const {
    course,
    setCourseId,
    onRemoveFormat,
    deleteFormatModal,
    knowledgeQuestions,
    setDeleteFormatModal,
    tabs,
    isPublished,
    isPublishing,
    goNext,
    onBack,
    onNavigate,
  } = useContext(CourseContext)

  useEffect(() => {
    setCourseId(Number(router.params.courseId))
  }, [router.params.courseId])

  return (
    <CourseLayout
      tabs={tabs}
      backText='Back'
      leftSideBar={true}
      onBack={() => {
        onBack()
      }}
      onNavigate={path => {
        onNavigate(path)
      }}
      router={router}
      viewOnly={isPublished}
      onRemoveFormat={onRemoveFormat}
      setDeleteFormatModal={setDeleteFormatModal}
      deleteFormatModal={deleteFormatModal}
      onNext={() => {
        goNext()
      }}
      course={course}
      onSaveAsDraft={() => {}}
    >
      <div className='w-100'>
        <QuestionsModule
          {...knowledgeQuestions}
          viewOnly={isPublished}
          isPublishing={isPublishing}
          languages={course?.translations.map(t => t.language) || []}
          questionableType={QuestionableTypes.KNOWLEDGE_CHECK}
          courseId={Number(router.params.courseId)}
        />
      </div>
    </CourseLayout>
  )
}
export default withRouter(KnowledgeCheck)
