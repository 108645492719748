import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import Highlighter from 'react-highlight-words'
import {
  SupportPermissions,
  GetTutorialsDTO,
  ITutorialItem,
  TutorialModalInitialValues,
  TutorialModalSubmitedValues,
} from '../../../sharedTypes'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { OrderType, TutorialsSortBy } from '../../../sharedTypes'
import { Pagination } from '../../../Components/Common/Pagination'
import { successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import {
  getTutorials,
  postTutorial,
  putTutorial,
  deleteTutorial,
} from '../../../helpers/api_helper'
import { FormikHelpers } from 'formik'
import { errorToastOptions } from '../../../helpers/toast_helper'
import TutorialModal from './TutorialModal'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import moment from 'moment'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import { usePermissions } from '../../../hooks/usePermissions'

const Columns = [
  {
    name: 'Tutorial',
    sortBy: TutorialsSortBy.TITLE,
    style: { width: '25%' },
  },
  {
    name: 'Icon',
    style: { width: '10%' },
  },
  {
    name: 'Category',
    style: { width: '20%' },
  },
  {
    name: 'Created By',
    style: { width: '15%' },
  },
  {
    name: 'Date Created',
    sortBy: TutorialsSortBy.CREATED_AT,
    style: { width: '20%' },
  },
  {
    name: 'Actions',
    style: { width: '10%' },
  },
]

const Tutorials = () => {
  document.title = 'Tutorials | Mastered - Admin & Dashboard'
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<GetTutorialsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    tutorials: [],
  })

  const [query, setQuery] = useState<GetTutorialsDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    sortBy: TutorialsSortBy.TITLE,
    orderBy: OrderType.ASC,
  })
  const [createModal, setCreateModal] =
    useState<TutorialModalInitialValues | null>(null)
  const [editModal, setEditModal] = useState<TutorialModalInitialValues | null>(
    null,
  )

  const [deleteId, setDeleteId] = useState<null | number>()

  const transformToEditObject = ({
    title,
    content,
    video,
    categoryId,
    id,
  }: ITutorialItem): TutorialModalInitialValues => ({
    id,
    title,
    categoryId,
    content,
    video: video
      ? {
          formattedSize: `${video.info.size} KB`,
          preview: video.url,
          name: video.info.originalname,
        }
      : undefined,
  })

  const hasPermissionToAdd = usePermissions(SupportPermissions.ADD_TUTORIAL)
  const hasPermissionToEdit = usePermissions(SupportPermissions.EDIT_TUTORIAL)
  const hasPermissionToDelete = usePermissions(
    SupportPermissions.DELETE_TUTORIAL,
  )

  const handleSort = useCallback((column: TutorialsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onDelete = useCallback(async () => {
    try {
      await deleteTutorial(deleteId as number)
      setDeleteId(null)
      toast('Successfully deleted', successToastOptions)
      setQuery(prev => ({ ...prev, page: 1 }))
    } catch (e) {}
  }, [data.page, deleteId])

  const onCreate = useCallback(
    async (
      values: TutorialModalSubmitedValues,
      form: FormikHelpers<TutorialModalSubmitedValues>,
    ) => {
      try {
        const { video, categoryId, title, content } = values
        form.setSubmitting(true)

        const formData = new FormData()
        formData.append('title', title)
        formData.append('content', content)
        formData.append('video', video as File)
        formData.append('categoryId', String(categoryId))

        await postTutorial(formData)
        form.setSubmitting(false)

        form.resetForm()
        toast('Successfully added', successToastOptions)
        setQuery(prev => ({ ...prev, page: 1 }))
        setCreateModal(null)
      } catch (e: any) {
        toast(e.response.data.message, errorToastOptions)
      }
    },
    [data.page],
  )

  const onEdit = useCallback(
    async (
      values: TutorialModalSubmitedValues,
      form: FormikHelpers<TutorialModalSubmitedValues>,
    ) => {
      try {
        form.setSubmitting(true)
        const { id, video, categoryId, title, content } = values

        const formData = new FormData()
        formData.append('title', title)
        formData.append('content', content)

        if (video && video instanceof File) {
          formData.append('video', video)
        }

        formData.append('categoryId', String(categoryId))

        await putTutorial(id, formData)
        form.setSubmitting(false)
        form.resetForm()
        toast('Successfully updated', successToastOptions)
        setQuery(prev => ({ ...prev, page: 1 }))
        setEditModal(null)
      } catch (e: any) {
        toast(e.response.data.message, errorToastOptions)
      }
    },
    [data.page],
  )

  useEffect(() => {
    setIsLoading(true)
    getTutorials(query)
      .then(response => {
        setData(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [query])

  const getColumns = useCallback(() => {
    if (!hasPermissionToEdit && !hasPermissionToDelete) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [])

  const handleRowClick = (item: ITutorialItem) => {
    if (hasPermissionToEdit) {
      setEditModal(transformToEditObject(item))
    }
  }

  return (
    <Fragment>
      <div className='page-content'>
        <TutorialModal
          title={editModal ? 'Edit Tutorial' : 'Create New Tutorial'}
          isOpen={!!createModal || !!editModal}
          initialValues={editModal || createModal}
          onSubmit={editModal ? onEdit : onCreate}
          onClose={() => {
            setEditModal(null)
            setCreateModal(null)
          }}
        />
        <DeleteConfirmation
          isOpen={!!deleteId}
          title='Delete Tutorial'
          message={`Are you sure you want to delete the Tutorial "${data.tutorials.find(
            t => t.id === deleteId,
          )?.title}" ?`}
          onDelete={onDelete}
          onClose={() => {
            setDeleteId(null)
          }}
        />

        <Container fluid>
          <BreadCrumb
            title='TUTORIALS'
            items={[
              {
                title: 'Support',
                linkTo: '/',
              },
              {
                title: 'Tutorials',
                active: true,
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      value={query.key || ''}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                    />
                    {hasPermissionToAdd && (
                      <div className='d-flex flex-shrink-0 gap-3'>
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setCreateModal({
                              id: -1,
                              title: '',
                              content: '',
                              categoryId: null,
                              video: undefined,
                            })
                          }}
                          className='btn btn-primary align-middle'
                        >
                          <i className='ri-edit-box-line me-1 fs-16'></i>Add
                          Tutorial
                        </Button>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-x-auto'>
                      <Table className='align-middle table-nowrap mb-0'>
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {getColumns().map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                {column.name}
                                {!!column.sortBy && (
                                  <ColumnSortIcon<TutorialsSortBy>
                                    sortOrder={query.orderBy}
                                    sortedColumn={query.sortBy}
                                    column={column.sortBy}
                                    handleSort={handleSort}
                                  />
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.tutorials.map((item: ITutorialItem, i) => (
                            <tr
                              key={i}
                              className='fs-14'
                              onClick={() => handleRowClick(item)}
                            >
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={item.title}
                                />
                              </td>
                              <td>
                                {' '}
                                {item.category?.icon?.url ? (
                                  <img
                                    style={{ maxWidth: 20 }}
                                    src={item.category?.icon.url}
                                  />
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>{item.category?.name}</td>
                              <td>
                                {item.creator?.firstName}{' '}
                                {item.creator?.lastName}
                              </td>

                              <td>
                                {moment(new Date(item.createdAt)).format(
                                  'MM/DD/YYYY',
                                )}
                              </td>
                              {(hasPermissionToDelete ||
                                hasPermissionToEdit) && (
                                <td>
                                  <span
                                    className='d-flex gap-2'
                                    onClick={e => {
                                      e.stopPropagation()
                                    }}
                                  >
                                    {hasPermissionToEdit && (
                                      <i
                                        onClick={() =>
                                          setEditModal(
                                            transformToEditObject(item),
                                          )
                                        }
                                        className='bx bx-edit-alt cursor-pointer'
                                      ></i>
                                    )}
                                    {hasPermissionToDelete && (
                                      <i
                                        onClick={() => setDeleteId(item.id)}
                                        className='bx bx-trash text-danger cursor-pointer	'
                                      ></i>
                                    )}
                                  </span>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className='mx-3 mt-3'>
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: page + 1 }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default Tutorials
