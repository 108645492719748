import React, { useCallback } from 'react'
import { Button, Modal, ModalBody, ModalProps, Form } from 'reactstrap'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { PrintTranscriptModalValues } from '../../sharedTypes'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'
import FilterTabs from '../Common/FilterTabs'

interface PrintTranscriptModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (
    values: PrintTranscriptModalValues,
    formik: FormikHelpers<PrintTranscriptModalValues>,
  ) => void
}

enum RangeTypeEnum {
  ALL_TIME = 'allTime',
  DATE_RANGE = 'dateRange',
}

const tabs = [
  { label: 'All Time', value: RangeTypeEnum.ALL_TIME },
  { label: 'Date Range', value: RangeTypeEnum.DATE_RANGE },
]

const PrintTranscriptModal = ({
  onClose,
  isOpen,
  onSubmit,
}: PrintTranscriptModalProps) => {
  const form = useFormik({
    initialValues: {
      dateFrom: undefined,
      dateTo: undefined,
      rangeType: RangeTypeEnum.DATE_RANGE,
    },
    validationSchema: Yup.object({
      dateFrom: Yup.date().when('rangeType', {
        is: RangeTypeEnum.DATE_RANGE,
        then: Yup.date().required('Date From is required'),
        otherwise: undefined,
      }),
      dateTo: Yup.date().when('rangeType', {
        is: RangeTypeEnum.DATE_RANGE,
        then: Yup.date().required('Date To is required'),
        otherwise: undefined,
      }),
      rangeType: Yup.string().required(),
    }),
    onSubmit: (values, formik) => {
      onSubmit(
        values as PrintTranscriptModalValues,
        formik as FormikHelpers<PrintTranscriptModalValues>,
      )
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  const rangeTypeToggle = (rangeType: RangeTypeEnum) => {
    form.setFieldValue('rangeType', rangeType)
  }

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Print Transcript</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          />
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <div className='mb-2 w-md m-auto'>
              <FilterTabs
                tabs={tabs}
                navTab={form.values.rangeType}
                navToggle={rangeType =>
                  rangeTypeToggle(rangeType as RangeTypeEnum)
                }
              />
            </div>
            <div className='hstack w-100 gap-2 flex-1 align-items-center justify-content-between'>
              <div>
                <label htmlFor='date-from' className='form-label'>
                  From*
                </label>
                <div className='form-icon right'>
                  <Flatpickr
                    className={`form-control form-control-icon ${
                      !form.isValid && !form.values.dateFrom ? 'is-invalid' : ''
                    } ${
                      form.values.rangeType === RangeTypeEnum.ALL_TIME
                        ? 'text-disabled'
                        : ''
                    }`}
                    id='date-from'
                    name='dateFrom'
                    placeholder='--.--.----'
                    onChange={option => {
                      form.setFieldValue('dateFrom', option[0])
                    }}
                    disabled={form.values.rangeType === RangeTypeEnum.ALL_TIME}
                    value={form.values.dateFrom}
                    options={{
                      dateFormat: 'd.m.Y',
                      formatDate: date => moment(date).format('MM/DD/YYYY'),
                      maxDate: form.values.dateTo || Date.now(),
                      allowInvalidPreload: false,
                    }}
                  />
                  <i className='ri-calendar-2-line fs-20 text-primary' />
                </div>
              </div>
              <div>
                <label htmlFor='date-to' className='form-label'>
                  To*
                </label>
                <div className='form-icon right'>
                  <Flatpickr
                    className={`form-control form-control-icon ${
                      !form.isValid &&
                      !form.values.dateTo &&
                      (!form.values.dateTo ||
                        !moment(form.values.dateTo).isAfter(
                          moment(form.values.dateFrom),
                        ))
                        ? 'is-invalid'
                        : ''
                    } ${
                      form.values.rangeType === RangeTypeEnum.ALL_TIME
                        ? 'text-disabled'
                        : ''
                    }`}
                    id='date-to'
                    name='dateTo'
                    placeholder='--.--.----'
                    onChange={option => {
                      form.setFieldValue('dateTo', option[0])
                    }}
                    disabled={form.values.rangeType === RangeTypeEnum.ALL_TIME}
                    value={form.values.dateTo}
                    options={{
                      dateFormat: 'd.m.Y',
                      formatDate: date => moment(date).format('MM/DD/YYYY'),
                      minDate: form.values.dateFrom,
                      maxDate: Date.now(),
                      allowInvalidPreload: false,
                    }}
                  />
                  <i className='ri-calendar-2-line fs-20 text-primary' />
                </div>
              </div>
            </div>
            <div className='hstack gap-2 justify-content-end mt-4'>
              <Button
                className='btn-soft-primary'
                onClick={beforeClose}
                active={false}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                type='submit'
                className='btn-success'
                disabled={!form.isValid || form.isSubmitting}
              >
                {form.isSubmitting ? <Spinner size={'sm'} /> : 'Print'}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default PrintTranscriptModal
