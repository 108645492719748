import React from 'react'
import { Input, Form, FormFeedback } from 'reactstrap'

const ScheduledCategoryForm = ({ form }: any) => {
  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        return false
      }}
      action='#'
    >
      <div className='vstack gap-3'>
        <div>
          <label htmlFor='title' className='form-label'>
            Name
          </label>
          <Input
            name='name'
            className='form-control'
            id='title'
            disabled={form.isSubmitting}
            placeholder='Enter name'
            type='text'
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.name || ''}
            invalid={!!(form.touched.name && form.errors.name)}
          />
          {form.touched.name && form.errors.name ? (
            <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
          ) : null}
        </div>

        <div>
          <label htmlFor='description' className='form-label'>
            Description
          </label>
          <Input
            name='description'
            className='form-control'
            id='description'
            placeholder=''
            type='textarea'
            rows='6'
            disabled={form.isSubmitting}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.description || ''}
            invalid={!!(form.touched.description && form.errors.description)}
          />
          {form.touched.description && form.errors.description ? (
            <FormFeedback type='invalid'>
              {form.errors.description as string}
            </FormFeedback>
          ) : null}
        </div>
      </div>
    </Form>
  )
}

export default ScheduledCategoryForm
