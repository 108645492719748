import { Card, CardHeader } from 'reactstrap'
import defaultCourseCover from '../../../../assets/images/course-default-cover.png'
import { AsyncSelectWithSearch } from '../../../../Components/Common/SelectWithSearch'
import _ from 'lodash'
import { reportFilters } from '../index'
import { BadgeColorByAllFormats } from '../../../../helpers/course'
import React, { Dispatch, SetStateAction } from 'react'
import { CourseReportSortBy } from '../../../../sharedTypes/api/courseReport'
import { getCourseReport } from '../../../../helpers/api/courses'
import { TCourseReport } from '../../../../sharedTypes/models/courseReport'

interface GeneralInfoProps {
  courseReport: TCourseReport
  courseReportId: number | undefined
  setCourseReportId: Dispatch<SetStateAction<number | undefined>>
}

const HeaderInfo = ({
  courseReport,
  courseReportId,
  setCourseReportId,
}: GeneralInfoProps) => {
  const fetchReports = (inputValue?: string) => {
    if (courseReport) {
      const params = {
        page: 1,
        limit: 10,
        sortBy: CourseReportSortBy.NAME,
        type: courseReport.type,
        key: inputValue,
      }

      return getCourseReport(params)
        .then(res => {
          return res.courseReport
        })
        .then(data => {
          return data.map(report => ({
            value: report.id,
            label:
              _.get(report.course, 'translations[0].content.name', '-') +
              '|||' +
              report.code,
          }))
        })
        .catch(() => [])
    }
  }

  const formatOptionLabel = ({ label }: { label: string }) => {
    const data = label.split('|||')
    return (
      <div className='d-flex align-items-center gap-5 justify-content-between'>
        <span>{data[0]}</span>
        <span className='text-muted'>{data[1]}</span>
      </div>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div className='d-flex align-items-center gap-3'>
          <div className='course-detail-image'>
            <img
              src={courseReport.course.cover?.path ?? defaultCourseCover}
              className='rounded w-100'
              alt='NO IMAGE'
            />
          </div>
          <div className='d-flex flex-column w-100'>
            <div className='d-flex gap-3 align-items-center'>
              <div className='d-inline-block w-50 mb-2'>
                <AsyncSelectWithSearch
                  onChange={option => setCourseReportId(option.value)}
                  value={{
                    label: _.get(
                      courseReport.course,
                      'translations[0].content.name',
                      '-',
                    ),
                    value: courseReportId,
                  }}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={true}
                  placeholder={''}
                  defaultOptions
                  loadOptions={fetchReports}
                  formatOptionLabel={formatOptionLabel}
                />
              </div>
              <div className='fw-normal'>
                {
                  reportFilters.find(type => type.value === courseReport.type)
                    ?.label
                }{' '}
                Report
              </div>
            </div>
            <div
              className='d-flex gap-3 align-items-end justify-content-between flex-wrap'
              style={{ width: '90%' }}
            >
              <div className='d-flex flex-column align-items-start gap-2'>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info'>Code :</span>
                  <span className='text-muted'>{courseReport.code}</span>
                </li>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info'>Type :</span>
                  <span className='text-muted'>{courseReport.course.type}</span>
                </li>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info'>Format :</span>
                  <span
                    className={`badge badge-${
                      BadgeColorByAllFormats[courseReport.courseFormat]
                    } fs-12 fw-normal text-capitalize`}
                  >
                    {courseReport.courseFormat}
                  </span>
                </li>
              </div>
              <div className='d-flex flex-column align-items-start gap-2'>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info avg-info'>
                    Num. of Completions :
                  </span>
                  <span className='text-muted'>{courseReport.completions}</span>
                </li>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info avg-info'>
                    {'Avg. Days Taken to Complete \n the Course :'}
                  </span>
                  <span className='text-muted'>
                    {courseReport.avgDaysToComplete}
                  </span>
                </li>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info avg-info'>
                    Rewatch Course :
                  </span>
                  <span className='text-muted'>
                    {courseReport.retakeCourse
                      ? courseReport.retakeCourse + '%'
                      : '-'}
                  </span>
                </li>
              </div>
              <div className='d-flex flex-column align-items-start gap-2'>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info'>Avg. Test Score :</span>
                  <span className='text-muted'>
                    {courseReport.avgScoreFirstAttempt + '%'}
                  </span>
                </li>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info'>Retake Test :</span>
                  <span className='text-muted'>
                    {courseReport.retakeTest + '%'}
                  </span>
                </li>
                <li className='gap-3 d-flex'>
                  <span className='report-detail-info'>Avg. User Rating :</span>
                  <span className='text-muted'>
                    {courseReport.avgUserRating + '%'}
                  </span>
                </li>
              </div>
            </div>
          </div>
        </div>
      </CardHeader>
    </Card>
  )
}

export default HeaderInfo
