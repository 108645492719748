import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'
import { removeUserRole } from '../../helpers/api/users'
import { handleError, successToastOptions } from '../../helpers/toast_helper'
import { toast } from 'react-toastify'

interface RemoveAdminPortalAccessModalProps {
  onClose: () => void
  onAccessRemoved: () => void
  isOpen: ModalProps['isOpen']
  confirmLabel?: string
  confirmIcon?: string
  userId: number
}

export interface Option {
  value: number
  label: string
}

const RemoveAdminPortalAccessModal = ({
  onClose,
  isOpen,
  onAccessRemoved,
  userId,
  confirmLabel = 'Remove',
  confirmIcon = 'ri-shield-keyhole-line',
}: RemoveAdminPortalAccessModalProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const onSubmit = () => {
    setIsDisabled(true)
    removeUserRole(userId)
      .then(() => {
        toast('Admin portal access removed successfully!', successToastOptions)
        onAccessRemoved()
        onClose()
      })
      .catch(handleError)
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-primary text-primary'>
              <i className={confirmIcon} style={{ fontSize: 62 }}></i>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-4'>Remove Admin Portal Access</h3>
            <span className='text-muted fw-light mx-3'>
              This option removes access to the administrator portal
            </span>
          </div>
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-danger w-100'
            onClick={onClose}
            active={false}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            className='w-100'
            disabled={isDisabled}
            onClick={onSubmit}
          >
            {confirmLabel}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default RemoveAdminPortalAccessModal
