import React from 'react'
import { Col, Container, Row } from 'reactstrap'

import BreadCrumb from '../../Components/Common/BreadCrumb'
import ScheduleBreakDown from './UsersOnboarding/ScheduleBreakDown'
import NewUsers from './UsersOnboarding/NewUsers'
import Statistics from './Statistics/Statistics'
import Notifications from './Notifications'
import MainTabs from './Statistics/MainTabs'
import { useAppSelector } from '../../hooks/redux'

const DashboardAnalytics = () => {
  document.title = 'Dashboard | Mastered - Admin & Dashboard'

  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Dashboard' items={[{ title: 'Dashboard' }]} />
        <Row>
          <Col sm={6} xl={5} xxl={4}>
            <ScheduleBreakDown />
            <NewUsers />
          </Col>
          <Col sm={6} md={6} xl={7} xxl={user?.facilityId ? 5 : 8}>
            <Statistics />
            <MainTabs user={user} />
          </Col>

          {user?.facilityId && (
            <Col xxl={3}>
              <Notifications />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default DashboardAnalytics
