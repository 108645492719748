import { useState, useEffect, useCallback } from 'react'
import { subscribe, unsubscribe } from '../helpers/events'

export function useNetwork() {
  const [isOnline, setNetwork] = useState(window.navigator.onLine)

  useEffect(() => {
    window.addEventListener('offline', () =>
      setNetwork(window.navigator.onLine),
    )

    window.addEventListener('online', () => setNetwork(window.navigator.onLine))
  })

  return isOnline
}

export function useIs500Error() {
  const [is500Error, set500Error] = useState(false)

  useEffect(() => {
    subscribe('500ErrorOccurred', () => onChange(true))
    subscribe('Non500ErrorOccurred', () => onChange(false))

    return () => {
      unsubscribe('500ErrorOccurred', () => set500Error(false))
      unsubscribe('Non500ErrorOccurred', () => set500Error(false))
    }
  }, [])

  const onChange = useCallback(
    (isError: boolean) => {
      if (isError !== is500Error) {
        set500Error(isError)
      }
    },
    [is500Error],
  )

  return is500Error
}
